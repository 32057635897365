import React, { Fragment } from 'react';
// @import components
import RoutineLessonSection from './routineLessonSection';
import PlaceHolderLoader from './placeHolderLoader';
import LessonDetail from './lessonDetail';

const RoutineLessonWrapper = ({
	token,
	routinelessonId,
	routinelessons,
	isActionLessenPlan,
	hidePlan,
	actionPlanStudentDescription,
	actionPlanStudentList,
	isActionPlanLoading,
	switchedActionLessonId,
	showVideoPopup,
	handleLesson,
	handleActionLessonSwitch,
	lessonName,
	onDragEnd,
	handleRefresh,
	handleStudentClick
}) => {
	return (
		<Fragment>
			<section className="lessonLayout routeenLessonWrapper">
				{hidePlan && (
					<h3 className="sectionTitle">
						Routinelessen <span style={{ opacity: 0.7 }}>beredeneerd aanbod</span>
					</h3>
				)}
				<div className="flexWrapper">
					{routinelessons &&
						routinelessons.map((item, i) => (
							<RoutineLessonSection
								item={item}
								index={i}
								key={i}
								lessonStatus={item.routinelessonStatus}
								isActionLessenPlan={isActionLessenPlan}
								switchedActionLessonId={switchedActionLessonId}
								showVideoPopup={showVideoPopup}
								handleLesson={handleLesson}
								handleActionLessonSwitch={handleActionLessonSwitch}
							/>
						))}
				</div>
			</section>

			{(isActionLessenPlan && !hidePlan) && (
				<LessonDetail
					token={token}
					className="routeenLessonPlan"
					lessonId={routinelessonId}
					stepdescriptions={actionPlanStudentDescription}
					studentsList={actionPlanStudentList}
					lessonName={`Groepsplan ${lessonName}`}
					onDragEnd={onDragEnd}
					handleStudentClick={handleStudentClick}
					type="routeen"
					handleRefresh={handleRefresh}
				/>
			)}

			{(isActionPlanLoading && !hidePlan) && (
				<PlaceHolderLoader
					type="list"
					rows={4}
					columns={7}
					id="routeenLessonPlan"
					style={{ margin: '20px 0 0' }}
					outerClass="groupLayout"
					innerClass="flexWrapper"
				/>
			)}
		</Fragment>
	);
};

export default RoutineLessonWrapper;

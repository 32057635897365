// @import packages
import React, { PureComponent, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Slider from 'rc-slider/lib/Slider';
import { TweenMax } from 'gsap/TweenMax';
// @import constants
import {
	studentdashboard_endpoint,
	getstudentperformance_endpoint
} from 'js/constants/endpoints';
// @import actions
import { setStudentList, sendErrorToHOC } from 'actions/actions';
// @import components
import ObservationPopup from 'js/models/observationPopup';
// @import placeholder
import TextLinePlaceholder from 'js/components/placeholder/textLine';
import ImagePlaceholder from 'js/components/placeholder/Image';
// @import styles
import 'rc-slider/assets/index.css';
import 'css/containers/studentResults.scss';
// @import images
import { ReactComponent as Eye } from 'images/svg/eye.svg';
import { ReactComponent as Pencil } from 'images/svg/edit.svg';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
import { breadCrumbRoute } from 'js/utils/studentBreadCrumbs';
import { grouptoSlug, slugtoGroup } from 'js/utils/groupUtility';
import { getCurrentGroup } from 'js/utils/filterArray';

class StudentResults extends PureComponent {
	constructor(props) {
		super(props);
		this.idleTime = 0;
		this.maxIdleTime = 60;
		this.checkInactivityInterval = null;
		this.state = {
			isLoading: true,
			currentStudent: null,
			lessons: null,
			lessonHistory: null,
			showStudentPopup: false,
			refresh: false
		};
	}

	componentDidMount() {
		if (this.props.groupName) {
			this.setInitialStates();
		}
	}

	componentWillUnmount() {
		this.untrackActivity();
	}

	trackActivity() {
		document.addEventListener('mousemove', this.handleCursorMove);
		clearInterval(this.checkInactivityInterval);
		this.checkInactivityInterval = setInterval(
			() => this.checkInactivity(),
			1000
		);
	}

	untrackActivity() {
		clearInterval(this.checkInactivityInterval);
		document.removeEventListener('mousemove', this.handleCursorMove);
	}

	checkInactivity() {
		this.idleTime++;
		if (this.idleTime === this.maxIdleTime) {
			this.idleTime = 0;
			clearInterval(this.checkInactivityInterval);
			this.setState({ refresh: true });
		}
	}

	refreshData() {
		this.setState({ refresh: false }, () => {
			this.setInitialStates();
		});
	}

	handleCursorMove = () => {
		this.idleTime = 0;
		if (this.state.refresh) {
			this.refreshData();
		}
	};

	setInitialStates = () => {
		let { groupname } = this.props.match.params;
		groupname = slugtoGroup(groupname);

		const getGroup = getCurrentGroup(this.props.groupList.groups, groupname);

		if (getGroup?.numberOfTeachers > 1) {
			this.trackActivity();
		}

		setTimeout(() => {
			this.props.setStudentList(
				getGroup.groupId,
				getGroup.groupName,
				getGroup.students
			);
		}, 20);

		const getStudent = getGroup.students.find(
			x =>
				x.studentDisplayname.replace(/_/g, ' ').toLowerCase() ===
				this.props.match.params.studentname.replace(/_/g, ' ')
		);

		const currentTopic = this.props.topics && this.props.topics[0];

		this.setState(
			{
				currentStudent: getStudent,
				topicId: currentTopic.topicId,
				topicName: currentTopic.topicName,
				studentDisplayname: getStudent.studentDisplayname,
				studentFirstname: getStudent.studentFirstname
			},
			() => {
				this.getOverviewData();
			}
		);
	};

	getOverviewData = () => {
		const { currentStudent, topicId } = this.state;

		this.setState({
			isLoading: true,
			lessons: null
		});

		const dataForm = new FormData();
		dataForm.append('studentId', currentStudent.studentId);
		dataForm.append('topicId', topicId);
		dataForm.append('groupId', this.props.groupId);

		axiosPost(
			studentdashboard_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState({
						isLoading: false,
						lessons: axiosResult.data ? axiosResult.data.lessons : 'nodata'
					});
				}
			}
		);
	};

	handleChange = (toStep, lessonId, fromStep, message, clickedLesson) => {
		let stepFrom;
		switch (fromStep) {
			case 'A':
				stepFrom = 0;
				break;
			case 'B':
				stepFrom = 1;
				break;
			case 'C':
				stepFrom = 2;
				break;
			case 'D':
				stepFrom = 3;
				break;
			case 'E':
				stepFrom = 4;
				break;
			default:
				stepFrom = 0;
		}

		let stepTo;
		if (toStep !== stepFrom) {
			switch (toStep) {
				case 0:
					stepTo = 'A';
					break;
				case 1:
					stepTo = 'B';
					break;
				case 2:
					stepTo = 'C';
					break;
				case 3:
					stepTo = 'D';
					break;
				case 4:
					stepTo = 'E';
					break;
				default:
					stepTo = 'A';
			}

			const dataForm = new FormData();
			dataForm.append('studentId', this.state.currentStudent.studentId);
			dataForm.append('newStep', stepTo);
			axiosPost(
				getstudentperformance_endpoint,
				dataForm,
				this.props.token,
				axiosResult => {
					if (axiosResult.status === 'success') {
						this.setState({
							draggedStudentPerformance: axiosResult.data.studentPerformance
						});
					}
				}
			);
		}

		this.setState(
			{
				draggedFrom: fromStep,
				draggedTo: stepTo ? stepTo : fromStep,
				draggedStudentPerformance: clickedLesson.studentPerformance,
				draggedMessage: message.replace(
					/#kind/gi,
					this.state.currentStudent.studentFirstname
				),
				draggedLessonId: lessonId,
				showStudentPopup: true,
				clickedLesson,
				calledfrom: 'student results'
			},
			() => {
				TweenMax.to('.popupWrapper', 0.3, {
					autoAlpha: 1
				});
				this.untrackActivity();
			}
		);
	};

	cancelDrag = () => {
		const lessons = this.state.lessons;
		TweenMax.to('.popupWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setTimeout(() => {
					this.setState(
						{
							showStudentPopup: false,
							lessons: null
						},
						() => {
							this.setState({
								lessons
							});
							this.trackActivity();
						}
					);
				}, 200);
			}
		});
	};

	hideStudentPopup = () => {
		TweenMax.to('.popupWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setTimeout(() => {
					this.setState(
						{
							showStudentPopup: false
						},
						() => {
							this.trackActivity();
						}
					);
				}, 200);
			}
		});
	};

	changeTopic = (id, name) => {
		this.setState(
			{
				topicName: name,
				topicId: id
			},
			() => {
				this.getOverviewData();
			}
		);
	};

	addPerformance = studentPerformance => {
		if (studentPerformance === 'ahead') {
			return 'ahead';
		}
		if (studentPerformance === 'behind') {
			return 'behind';
		}
		if (studentPerformance === 'done') {
			return 'done';
		}
		if (studentPerformance === 'on track' || studentPerformance === 'unknown') {
			return 'ontrack';
		}
	};

	render() {
		// console.log('lesson state', this.state.lessons);
		return (
			<div className="mainContainer">
				<Helmet>
					<title>Mijn groep</title>
				</Helmet>
				{this.state.currentStudent ? (
					<nav className="breadCrumbMenu">
						<ul id="breadCrumb">
							<li>
								<span>{this.state.currentStudent.studentDisplayname}</span>
							</li>
							<li>
								{breadCrumbRoute.map((item, i) => (
									<NavLink
										to={`/leerling/${grouptoSlug(
											this.props.groupName
										)}/${this.state.currentStudent.studentDisplayname
											.replace(/ /g, '_')
											.toLowerCase()}${item.slug}`}
										key={i}
										exact
										className={
											this.props.location.pathname.includes(
												item.name.replace(/ /g, '-').toLowerCase()
											)
												? 'active'
												: ''
										}
									>
										{item.name}
									</NavLink>
								))}
								<NavLink
									to={`/${grouptoSlug(
										this.props.groupName
									)}/${this.state.currentStudent.studentDisplayname
										.replace(/ /g, '_')
										.toLowerCase()}/leerlingportfolio`}
									exact
									className={
										this.props.location.pathname.includes('leerlingportfolio')
											? 'active'
											: ''
									}
								>
									Leerlingportfolio
								</NavLink>
							</li>
						</ul>
					</nav>
				) : (
					<TextLinePlaceholder margin="0 0 10px" height="39px" />
				)}

				<div>
					<ul className="tabsStyle">
						{this.props.topics && this.props.topics.map((item, i) => (
							<li
								key={i}
								className={`${item.topicName
									.replace(/ /g, '-')
									.toLowerCase()}_tab`}
							>
								<button
									type="button"
									onClick={() => this.changeTopic(item.topicId, item.topicName)}
								>
									{item.topicName}
								</button>
							</li>
						))}
					</ul>
				</div>

				<section
					className={`tabBorderStyle ${
						this.state.topicName
							? this.state.topicName
									.replace(/ /g, '-')
									.toLowerCase()
									.concat('_wrapper')
							: null
					}`}
				>
					{this.state.lessons && (
						<section className="studentResultsWrapper">
							{this.state.lessons !== 'nodata' ? (
								<div className="pageContainer">
									<Fragment>
										<div className="studentIntroSec">
											<img
												src={this.state.currentStudent.studentPicture.replace(
													/(\.[^.]+)$/,
													'-100x100$1'
												)}
												srcSet={`${this.state.currentStudent.studentPicture.replace(
													/(\.[^.]+)$/,
													'-200x200$1'
												)} 2x`}
												alt={this.state.currentStudent.studentDisplayname}
											/>
											<div>
												<h1>
													{this.state.currentStudent.studentDisplayname} -
													Resultaten {this.state.topicName}
												</h1>
												{this.state.currentStudent.studentDisplayage && (
													<span>
														{this.state.currentStudent.studentDisplayage}
													</span>
												)}
											</div>
										</div>

										{this.state.lessons.map((item, i) => {
											let sliderValue;
											switch (item.currentStep) {
												case 'A':
													sliderValue = 0;
													break;
												case 'B':
													sliderValue = 1;
													break;
												case 'C':
													sliderValue = 2;
													break;
												case 'D':
													sliderValue = 3;
													break;
												case 'E':
													sliderValue = 4;
													break;
												default:
													sliderValue = 0;
											}
											return (
												<div className="overviewLesson" key={item.lessonId}>
													<h2>
														<Link to={item.periodSlug}>
															{item.lessonName}
															{item.studentColumnChanged && (
																<Eye
																	style={{
																		opacity:
																			item.studentColumnChanged > 0
																				? 1 - item.studentColumnChanged / 10
																				: 1
																	}}
																/>
															)}
														</Link>

														{item.studentNotification === 'commented' && (
															<div className="iconWrap">
																<Pencil />
															</div>
														)}
													</h2>
													<div className="flexWrapper">
														<img
															src={item.lessonPicture.replace(
																/(\.[^.]+)$/,
																'-100x100$1'
															)}
															srcSet={`${item.lessonPicture.replace(
																/(\.[^.]+)$/,
																'-200x200$1'
															)} 2x`}
															alt={item.lessonName}
														/>
														<div className="sliderWrapper">
															<div className="steps">
																<span>A</span>
																<span>B</span>
																<span>C</span>
																<span>D</span>
																<span className="bulletPoint">•</span>
															</div>
															<Slider
																min={0}
																max={4}
																defaultValue={sliderValue}
																onAfterChange={e =>
																	this.handleChange(
																		e,
																		item.lessonId,
																		item.currentStep,
																		item.currentStepDescription,
																		item
																	)
																}
																className={`sliderComponent ${this.addPerformance(
																	item.studentPerformance
																)}`}
															/>
															<p>
																{item.currentStepDescription.replace(
																	/#kind/gi,
																	this.state.studentFirstname
																)}
															</p>
															<button
																type="button"
																onClick={() =>
																	this.handleChange(
																		sliderValue,
																		item.lessonId,
																		item.currentStep,
																		item.currentStepDescription,
																		item
																	)
																}
															>
																Ontwikkelgeschiedenis
															</button>
														</div>
													</div>
												</div>
											);
										})}
									</Fragment>
								</div>
							) : (
								<div className="pageContainer">
									<div className="nodataWrapper">
										<h1 className="headingStyle">Geen resultaten</h1>
										<p>
											Resultaten worden pas zichtbaar als de observatie van een
											les gestart is
										</p>
									</div>
								</div>
							)}
						</section>
					)}

					{this.state.isLoading && (
						<div className="pageContainer">
							<div className="placeholderWrapper">
								<div className="pageIntroSec_placeholder">
									<ImagePlaceholder
										width="90px"
										height="90px"
										margin="0 30px 0 0"
									/>
									<div className="textWrapper">
										<TextLinePlaceholder width="50%" />
										<TextLinePlaceholder width="100px" height="10px" />
									</div>
								</div>

								<div className="studentResultPlaceHolder">
									<div className="pageIntroSec_placeholder">
										<ImagePlaceholder
											width="90px"
											height="90px"
											margin="0 30px 0 0"
										/>
										<div className="textWrapper">
											<TextLinePlaceholder width="50%" />
											<TextLinePlaceholder height="10px" />
										</div>
									</div>

									<div className="pageIntroSec_placeholder">
										<ImagePlaceholder
											width="90px"
											height="90px"
											margin="0 30px 0 0"
										/>
										<div className="textWrapper">
											<TextLinePlaceholder width="50%" />
											<TextLinePlaceholder height="10px" />
										</div>
									</div>

									<div className="pageIntroSec_placeholder">
										<ImagePlaceholder
											width="90px"
											height="90px"
											margin="0 30px 0 0"
										/>
										<div className="textWrapper">
											<TextLinePlaceholder width="50%" />
											<TextLinePlaceholder height="10px" />
										</div>
									</div>
									<div className="pageIntroSec_placeholder">
										<ImagePlaceholder
											width="90px"
											height="90px"
											margin="0 30px 0 0"
										/>
										<div className="textWrapper">
											<TextLinePlaceholder width="50%" />
											<TextLinePlaceholder height="10px" />
										</div>
									</div>

									<div className="pageIntroSec_placeholder">
										<ImagePlaceholder
											width="90px"
											height="90px"
											margin="0 30px 0 0"
										/>
										<div className="textWrapper">
											<TextLinePlaceholder width="50%" />
											<TextLinePlaceholder height="10px" />
										</div>
									</div>

									<div className="pageIntroSec_placeholder">
										<ImagePlaceholder
											width="90px"
											height="90px"
											margin="0 30px 0 0"
										/>
										<div className="textWrapper">
											<TextLinePlaceholder width="50%" />
											<TextLinePlaceholder height="10px" />
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</section>

				{this.state.showStudentPopup && (
					<ObservationPopup
						draggedStudent={this.state.currentStudent}
						studentPerformance={this.state.draggedStudentPerformance}
						stepFrom={this.state.draggedFrom}
						stepTo={this.state.draggedTo}
						message={this.state.draggedMessage}
						lessonId={this.state.draggedLessonId}
						routinelessonId={this.state.activeroutinelessonId}
						hidepopup={this.hideStudentPopup}
						cancelDrag={this.cancelDrag}
						calledfrom={this.state.calledfrom}
						clickedLesson={this.state.clickedLesson}
						callback={this.getOverviewData}
						topicName={this.state.topicName}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
	topics: state.initialData.topics,
	students: state.initialData.students,
	studentList: state.initialData.studentList,
	groupId: state.initialData.groupId,
	groupName: state.initialData.groupName,
	groupList: state.initialData.groupList,
	seasonId: state.initialData.seasonId,
	periodId: state.initialData.periodId,
	topicId: state.initialData.topicId,
	lessonTree: state.initialData.lessonTree
});

export default connect(mapStateToProps, { setStudentList, sendErrorToHOC })(
	StudentResults
);

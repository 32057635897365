export const breadCrumbRoute = [
	{
		name: 'Resultaten',
		slug: '/resultaten'
	},
	{
	 	name: 'Sociaal-emotioneel',
	 	slug: '/assessments/Sociaal-emotioneel'
	 },
	{
	 	name: 'Onderwijsbehoeften',
	 	slug: '/assessments/Onderwijsbehoeften'
	},
	{
	 	name: 'Motoriek',
	 	slug: '/assessments/Motoriek'
	}
];

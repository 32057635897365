// @import packages
import React from 'react';
import { Route, Switch } from 'react-router';
import { Redirect, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
// import { useSelector } from 'react-redux';
// @import components
import ManageThemes from './manageThemes';
import ManageLessons from './manageLessons';
import ManageRoutineLessons from './manageRoutineLessons';

const routes = [
	{
		path: '/curriculum/managethemes',
		component: ManageThemes,
		name: 'Thema\'s',
		exact: true
	},
	{
		path: '/curriculum/managelessons/:topicname',
		component: ManageLessons,
		name: 'Lessen',
		exact: true
	},
	{
		path: '/curriculum/manageroutinelessons/:topicname',
		component: ManageRoutineLessons,
		name: 'Routinelessen',
		exact: true
	}
];

const index = ({ location, topics }) => {
	if (location.pathname === '/curriculum')
		return <Redirect to={'/curriculum/managethemes'} />;
	return (
		<div className="mainContainer">
			<div className="curriculumContainer">
				<div className="breadCrumb">
					<ul>
						<li>
							<NavLink to="/curriculum/managethemes" activeClassName="active">
								Thema's
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`/curriculum/managelessons/${topics[0].topicName.replace(/ /g, '_').toLowerCase()}`}
								activeClassName="active"
								className={`${
									location.pathname.includes('managelessons') ? 'active' : ''
								}`}
							>
								Lessen
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`/curriculum/manageroutinelessons/${topics[0].topicName.replace(/ /g, '_').toLowerCase()}`}
								activeClassName="active"
								className={`${
									location.pathname.includes('manageroutinelessons')
										? 'active'
										: ''
								}`}
							>
								Routinelessen
							</NavLink>
						</li>
					</ul>
				</div>
				<Switch location={location}>
					{routes.map((route, index) => {
						return (
							<Route
								key={index}
								path={route.path}
								exact={route.exact}
								render={props => {
									return React.createElement(route.component, {
										...props
									});
								}}
							/>
						);
					})}
				</Switch>
			</div>
		</div>
	);
};

const mapStateToProps = state => ({
    topics: state.initialData.topics,
});

export default connect(mapStateToProps, null)(index);

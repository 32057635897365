// @import dependecies
import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { withRouter } from 'react-router-dom';
// @import components
import Authentication from './config/authentication';
import ScrollToTop from './js/HOC/ScrollToTop';
import ErrorHandler from './js/HOC/ErrorHandler';
import Layout from './js/HOC/Layout';
// @import styles
import './App.scss';
import 'react-image-crop/lib/ReactCrop.scss';

const App = props => {
	const {
		routes,
		parentsRoutes,
		coordinatorRoutes,
		location = { pathname: '/', key: '' }
	} = props;
	const currentKey = (location && location.key) || '';
	const timeout = { enter: 500, exit: 200 };
	return (
		<ScrollToTop>
			<Layout>
				<TransitionGroup component="main" className="page-main">
					<CSSTransition
						key={currentKey}
						timeout={timeout}
						classNames="fade"
						appear
					>
						<section className="page-main-inner">
							<ErrorHandler>
								<Authentication
									routes={routes}
									location={location}
									parents={parentsRoutes}
									cordinator={coordinatorRoutes}
								/>
							</ErrorHandler>
						</section>
					</CSSTransition>
				</TransitionGroup>
			</Layout>
		</ScrollToTop>
	);
};

export default withRouter(App);

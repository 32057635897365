import React from 'react';
// @import packages
// @import components
import ListItemPlaceholder from './placeholder/ListItem';
import LessonPlaceholder from './placeholder/lessonPlaceholder';

const PlaceHolderLoader = ({
  id = null,
  type,
  style = null,
  rows,
  columns,
  outerClass = null,
  innerClass = null
}) => {
  return (
    <section id={id} className={outerClass ? outerClass : ''} style={style}>
      <div className={innerClass ? innerClass : ''}>
        {type === 'lesson' &&
          Array(columns)
            .fill(null)
            .map((lesson, i) => <LessonPlaceholder key={`lesson_${i}`} />)}
        {type === 'list' &&
          Array(rows).fill(null).map((item, index) => (
            <div
              className="column"
              style={{ borderRadius: '15px' }}
              key={`column_${index}`}
            >
              {Array(columns).fill(null).map((list, i) => (
                <ListItemPlaceholder key={`list_${i}`} />
              ))}
            </div>
          ))}
      </div>
    </section>
  );
};

export default PlaceHolderLoader;

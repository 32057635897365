import React, { useState } from 'react';
import { connect } from 'react-redux';
import { TweenMax } from 'gsap/TweenMax';
// @import component
import ParentRoutineLessonPopup from 'js/models/ParentRoutineLessonPopup';
// @import endpoints
import {
	app_name,
	parentroutinelessonstatus_endpoint
} from 'js/constants/endpoints';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
// @import svgs
import { ReactComponent as Tick } from 'images/svg/tick.svg';
import { ReactComponent as Eye } from 'images/svg/eye.svg';
import { ReactComponent as CloseEye } from 'images/svg/close_eye.svg';

const OudersButton = ({ data, groupId, token, openPopup }) => {
	const [parentButtonStatus, SetParentButtonStatus] = useState(
		data.parenttipVisible
	);

	const handleParentLessonStatus = () => {
		const dataForm = new FormData();
		dataForm.append('parenttipId', data.parenttipId);
		dataForm.append('groupId', groupId);
		dataForm.append('newstatus', parentButtonStatus === 0 ? 1 : 0);
		axiosPost(
			parentroutinelessonstatus_endpoint,
			dataForm,
			token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					SetParentButtonStatus(parentButtonStatus === 0 ? 1 : 0);
				}
			}
		);
	};

	return (
		<div className="oudersButton">
			<button type="button" onClick={() => openPopup(data)}>
				Digitaal spel
			</button>
			<button className="ouderStatus" onClick={handleParentLessonStatus}>
				{parentButtonStatus === 0 ? <CloseEye /> : <Eye />}
			</button>
		</div>
	);
};

const RoutineLessonSection = ({
	item,
	lessonStatus,
	parentLesson,
	switchedActionLessonId,
	isActionLessenPlan,
	showVideoPopup,
	handleLesson,
	handleActionLessonSwitch,
	index,
	groupId,
	...props
}) => {
	const [popup, setPopup] = useState(null);

	const showPopup = data => {
		setPopup(data);
		setTimeout(() => {
			TweenMax.to('#parentRoutinePopup', 0.3, {
				autoAlpha: 1
			});
		}, 500);
	};

	const hidePopup = () => {
		TweenMax.to('#parentRoutinePopup', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setPopup(null);
			}
		});
	};

	return (
		<>
			<section className="lessonSection" key={index}>
				<img
					src={item.routinelessonPicture.replace(/(\.[^.]+)$/, '-90x90$1')}
					srcSet={`${item.routinelessonPicture.replace(
						/(\.[^.]+)$/,
						'-180x180$1'
					)} 2x`}
					alt={item.routinelessonName}
				/>
				<div>
					<h2 className="headingStyle">{item.routinelessonName}</h2>
					{item.routinelessonIntroduction && (
						<p>{item.routinelessonIntroduction}</p>
					)}
					<div className="mainButtons">
						{item.routinelessonMedia1 && (
							<a
								href={item.routinelessonMedia1}
								download={item.routinelessonMediatype1 === 'file'}
								target={
									item.routinelessonMediatype1 === 'file' ||
									item.routinelessonMediatype1 === 'link'
										? '_blank'
										: '_self'
								}
								onClick={e => {
									if (item.routinelessonMediatype1 === 'video') {
										e.preventDefault();
										showVideoPopup(
											'Doelenkaart',
											item.routinelessonMedia1,
											item.routinelessonName,
											item.routinelessonPicture
										);
									}
								}}
								rel="noopener noreferrer"
								className="outlineButton inlineBlock"
							>
								{app_name === 'onderbouwdonline' ? 'Doelenkaart' : 'Open les'}
							</a>
						)}

						{item.routinelessonMedia2 && (
							<a
								href={item.routinelessonMedia2}
								download={item.routinelessonMediatype2 === 'file'}
								target={
									item.routinelessonMediatype2 === 'file' ||
									item.routinelessonMediatype2 === 'link'
										? '_blank'
										: '_self'
								}
								onClick={e => {
									if (item.routinelessonMediatype2 === 'video') {
										e.preventDefault();
										showVideoPopup(
											item.routinelessonMedia2Title,
											item.routinelessonMedia2,
											item.routinelessonName,
											item.routinelessonPicture
										);
									}
								}}
								rel="noopener noreferrer"
								className="outlineButton inlineBlock"
							>
								{item.routinelessonMedia2Title}
							</a>
						)}

						{item.routinelessonMedia3 && (
							<a
								href={item.routinelessonMedia3}
								download={item.routinelessonMediatype3 === 'file'}
								target={
									item.routinelessonMediatype3 === 'file' ||
									item.routinelessonMediatype3 === 'link'
										? '_blank'
										: '_self'
								}
								onClick={e => {
									if (item.routinelessonMediatype3 === 'video') {
										e.preventDefault();
										showVideoPopup(
											item.routinelessonMedia3Title,
											item.routinelessonMedia3,
											item.routinelessonName,
											item.routinelessonPicture
										);
									}
								}}
								rel="noopener noreferrer"
								className="outlineButton inlineBlock"
							>
								{item.routinelessonMedia3Title}
							</a>
						)}

						{!parentLesson && (
							<button
								type="button"
								className={`outlineButton ${
									lessonStatus === 'done' ? 'disableButton' : 'doneButton'
								}`}
								onClick={() =>
									handleLesson(
										item.routinelessonId,
										lessonStatus === 'todo' ? 'planned'
										: lessonStatus === 'planned' ? 'done' : 'todo',
										'routeenlesson'
									)
								}
							>
								<Tick /> {lessonStatus === 'todo' ? 'Plan'
									: lessonStatus === 'planned' ? 'Gedaan' : 'Gedaan'
								}
							</button>
						)}

						{(!parentLesson && item.relatedlesson.observationstarted) && (
							<button
								type="button"
								onClick={() =>
									handleActionLessonSwitch(
										item.relatedlesson.lessonId,
										item.routinelessonId
									)
								}
								className={`outlineButton planButton ${
									switchedActionLessonId === item.relatedlesson.lessonId &&
									isActionLessenPlan
										? 'disableButton'
										: ''
								}`}
							>
								Groepsplan
							</button>
						)}

						{!parentLesson && props.genericSettings.schoolParentsEnabled === '1' &&
							item.parents.map(parentData => (
								<OudersButton
									data={parentData}
									groupId={groupId}
									token={props.token}
									openPopup={showPopup}
									key={parentData.parenttipId}
								/>
							))}
					</div>

					{!parentLesson ||
					item.routinelessonVideo1Url ||
					item.routinelessonVideo2Url ||
					item.routinelessonVideo3Url ||
					item.routinelessonWorksheet1 ||
					item.routinelessonWorksheet2 ? (
						<div className="subAnchors">
							{item.routinelessonVideo1Url && (
								<button
									type="button"
									onClick={() =>
										showVideoPopup(
											item.routinelessonVideo1Text,
											item.routinelessonVideo1Url,
											item.routinelessonName,
											item.routinelessonPicture
										)
									}
								>
									{item.routinelessonVideo1Text}
								</button>
							)}

							{item.routinelessonVideo2Url && (
								<button
									type="button"
									onClick={() =>
										showVideoPopup(
											item.routinelessonVideo2Text,
											item.routinelessonVideo2Url,
											item.routinelessonName,
											item.routinelessonPicture
										)
									}
								>
									{item.routinelessonVideo2Text}
								</button>
							)}

							{item.routinelessonVideo3Url && (
								<button
									type="button"
									onClick={() =>
										showVideoPopup(
											item.routinelessonVideo3Text,
											item.routinelessonVideo3Url,
											item.routinelessonName,
											item.routinelessonPicture
										)
									}
								>
									{item.routinelessonVideo3Text}
								</button>
							)}

							{item.routinelessonWorksheet1 && (
								<a
									href={item.routinelessonWorksheet1}
									download
									target="_blank"
									rel="noopener noreferrer"
								>
									{item.routinelessonWorksheet1Title}
								</a>
							)}

							{item.routinelessonWorksheet2 && (
								<a
									href={item.routinelessonWorksheet2}
									download
									target="_blank"
									rel="noopener noreferrer"
								>
									{item.routinelessonWorksheet2Title}
								</a>
							)}
						</div>
					) : null}
				</div>
			</section>
			{popup && (
				<ParentRoutineLessonPopup
					token={props.token}
					closePopup={hidePopup}
					parenttipId={popup.parenttipId}
					lessonName={item.routinelessonName}
					lessonPicture={item.routinelessonPicture}
					showVideoPopup={showVideoPopup}
				/>
			)}
		</>
	);
};

const mapStateToProps = state => ({
	token: state.initialData.token,
	groupId: state.initialData.groupId,
	genericSettings: state.initialData.genericSettings
});

export default connect(mapStateToProps, null)(RoutineLessonSection);

import React from 'react';
import ImagePlaceholder from './Image';
import TextLinePlaceholder from './textLine';

const LessonPlaceholder = () => {
  return (
    <div className="pageIntroSec_placeholder">
      <ImagePlaceholder width="90px" height="100px" margin="0 15px 0 0" />
      <div className="textWrapper">
        <TextLinePlaceholder width="50%" margin="0 0px 0 0" />
        <TextLinePlaceholder height="10px" />
        <TextLinePlaceholder width="70%" height="10px" />
        <div className="subAnchors">
          <TextLinePlaceholder width="50px" height="7px" margin="0 10px 0 0" />
          <TextLinePlaceholder width="50px" height="7px" margin="0 0 0 0" />
        </div>
      </div>
    </div>
  );
};

export default LessonPlaceholder;

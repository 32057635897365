// @import action types
import { FETCH_INPROGRESS_CHAPTERS } from './academyTypes';
// @import endpoints
import { academycoursehistory_endpoint } from '../js/constants/endpoints';
// @import utils
import { axiosGet } from '../js/utils/axiosCall';

export const getChaptersHistory = token => dispatch => {
	axiosGet(academycoursehistory_endpoint, token, axiosResult => {
		if (axiosResult.status === 'success') {
			dispatch({
				type: FETCH_INPROGRESS_CHAPTERS,
				payload: axiosResult.data
			});
		}
	});
};

// @import packages
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// @import constants
import { medialist_endpoint } from 'js/constants/endpoints';
// @import Components
import Loader from 'js/models/loader';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
import { slugtoGroup } from 'js/utils/groupUtility';
import { getCurrentGroup } from 'js/utils/filterArray';
// @import styles
import './index.scss';
// @import images
import { ReactComponent as Cross } from 'images/svg/cross.svg';

const MediaListPopup = ({
	callback,
	closePopup,
	selectedPictures,
	imageOnly = true
}) => {
	const { token, groupList } = useSelector(state => state.initialData);

	let { groupname } = useParams();
	groupname = slugtoGroup(groupname);
	const groupId = getCurrentGroup(groupList.groups, groupname).groupId;

	const [data, setData] = useState(null);
	const [isLoading, setLoading] = useState(true);
	const [selectedImages, setSelectedImages] = useState(selectedPictures);

	const escFunction = event => {
		if (event.keyCode === 27) {
			handleClose();
		}
	};

	useEffect(() => {
		getMedia();
		document.addEventListener('keydown', escFunction, false);
		return () => {
			document.removeEventListener('keydown', escFunction, false);
		};
	}, []);

	const getMedia = () => {
		const dataForm = new FormData();
		dataForm.append('groupId', groupId);

		axiosPost(medialist_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				let {
					data: { media }
				} = axiosResult;
				if (imageOnly) media = media.filter(x => x.mediaType !== 'video');
				setData(media.length > 0 ? media : 'nodata');
			}
			setLoading(false);
		});
	};

	const toggleStudentClick = item => {
		if (selectedImages.length <= 4) {
			let tempArray = [...selectedImages];
			if (selectedImages.some(x => x.mediaId === item.mediaId)) {
				tempArray = tempArray.filter(x => x.mediaId !== item.mediaId);
			} else {
				if (selectedImages.length < 4) tempArray.push(item);
			}
			setSelectedImages([...tempArray]);
		}
	};

	const handleClose = () => {
		closePopup();
		setSelectedImages([]);
	};

	return (
		<div className="popupWrapper mediaListPopup">
			<div
				className={`mediaPageContainer popup ${isLoading ? 'flexCenter' : ''}`}
			>
				<button type="button" className="closePopup" onClick={handleClose}>
					<Cross width={18} />
				</button>
				{isLoading ? (
					<Loader />
				) : (
					<>
						<h1 className="headingStyle">Voeg foto's toe</h1>
						<div className="studentListWrapper">
							{data && data !== 'nodata' ? (
								<>
									{data.map(item => {
										const isSelected =
											selectedImages.length > 0
												? selectedImages.some(x => x.mediaId === item.mediaId)
												: false;
										return (
											<button
												key={item.mediaId}
												onClick={() => toggleStudentClick(item)}
												className={`studentBox mediaPreviewBox ${
													isSelected ? 'active' : ''
												}`}
											>
												<div className="aspectContainer flexCenter">
													{item.mediaType === 'video' ? (
														<video muted playsInline>
															<source
																type="video/mp4"
																src={`${item.mediaFile}#t=0.5`}
															/>
														</video>
													) : (
														<img src={item.mediaFile} alt={item.mediaTitle} />
													)}
												</div>
												<strong>{item.mediaTitle}</strong>
											</button>
										);
									})}
								</>
							) : (
								<p className="emptyData">
									Er zijn nog geen foto’s of video’s van deze groep toegevoegd
								</p>
							)}
						</div>
						<div className="row flexBlock flexSpaceCenter">
							<div />
							<div>
								<button
									type="button"
									className="outlineButton inlineBlock"
									onClick={handleClose}
								>
									Annuleren
								</button>
								<button
									type="button"
									style={{ marginLeft: '15px' }}
									className="defaultBtn inlineBlock"
									onClick={() => {
										closePopup();
										callback(selectedImages);
									}}
								>
									Opslaan
								</button>
							</div>
						</div>
					</>
				)}
			</div>
			<span className="overlayBackground" />
		</div>
	);
};

export default MediaListPopup;

import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

const Breadcrumb = props => {
	const {
		userData: { userDisplayname }
	} = props;
	return (
		<nav className="breadCrumbMenu">
			<ul>
				<li>
					<span>{userDisplayname}</span>
				</li>
				{props.routes &&
					props.routes.map((item, i) => (
						<li key={i}>
							<NavLink
								onClick={e => {
									if (i + 1 === props.routes.length) {
										e.preventDefault();
									}
								}}
								exact
								key={i}
								to={item.path}
								activeClassName="active"
							>
								{item.name}
							</NavLink>
						</li>
					))}
			</ul>
		</nav>
	);
};

const mapStateToProps = state => ({
	userData: state.initialData.userData
});

export default connect(mapStateToProps, null)(Breadcrumb);

import React, { useEffect } from 'react';

const ErrorPopup = ({ content, closeCallback }) => {
	useEffect(() => {
		TweenMax.to('#errorPopup', 0.2, {
			autoAlpha: 1
		});

		TweenMax.set('.rightColumn', { zIndex: 2 });

		return () => {
			TweenMax.set('.rightColumn', { zIndex: 1 });
		};
	}, []);

	const handleClosePopup = () => {
		TweenMax.to('#errorPopup', 0.2, {
			autoAlpha: 0,
			onComplete: () => {
				closeCallback();
			}
		});
	};

	return (
		<div id="errorPopup" className="popupWrapper">
			<div className="popup errorPopup">
				<p>{content}</p>

				<div className="row">
					<div />
					<div>
						<button
							type="button"
							className="inlineBlock defaultBtn"
							onClick={handleClosePopup}
						>
							Ok
						</button>
					</div>
				</div>
			</div>
			<span className="overlayBackground" />
		</div>
	);
};

export default ErrorPopup;

import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// @import actions
import { sendErrorToHOC } from 'actions/actions';
// @import constants
import { groupbehandelplan_endpoint } from 'js/constants/endpoints';
// @import components
import Loader from 'js/models/loader';
import Student from 'js/components/student';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
// @import icons
import { ReactComponent as AddIcon } from 'images/svg/add.svg';
import { ReactComponent as TrashIcon } from 'images/svg/trash.svg';
import { ReactComponent as MoveIcon } from 'images/svg/move-in.svg';
import { ReactComponent as BackIcon } from 'images/svg/back-arrow.svg';
import { ReactComponent as NextIcon } from 'images/svg/next-arrow.svg';
// @import styles
import './index.scss';

const PlannerLesson = ({ topicId, topics }) => {
	return (
		<div
			className={`${topics && topics
				.find(x => x.topicId === topicId)
				.topicName.replace(/ /g, '-')
				.toLowerCase()} column`}
		>
			<section>
				<div className="lessonRow">
					<div className="lessonWrapper">
						<img
							src="https://data.demo.onderbouwdonline.nl/images/goals/pictures/woordenschat-60x60.jpg"
							// src={lessonPicture.replace(/(\.[^.]+)$/, `-60x60$1`)}
							// srcSet={`${lessonPicture.replace(
							// 	/(\.[^.]+)$/,
							// 	`-120x120$1`
							// )} 2x`}
							alt=""
						/>
						<h3>Dubbelwoord</h3>
					</div>
					<div className="buttonsWrapper">
						<button type="button">
							<MoveIcon width={16} />
						</button>
						<button type="button">
							<TrashIcon width={16} />
						</button>
					</div>
				</div>

				<button
					type="button"
					// key={`studentkey_${i}`}
					className="studentRowContainer"
					onClick={() => {}}
				>
					<Student
						size={80}
						name={'Gerard - Joost Groentje'}
						imagepath={
							'https://data.demo.onderbouwdonline.nl/images/students/pictures/572c6bbb3a16cf02476f823dbf98d6b9-80x80.jpg'
						}
					/>
				</button>
				<button
					type="button"
					// key={`studentkey_${i}`}
					className="studentRowContainer"
					onClick={() => {}}
				>
					<Student
						size={80}
						name={'Gerard - Joost Groentje'}
						imagepath={
							'https://data.demo.onderbouwdonline.nl/images/students/pictures/572c6bbb3a16cf02476f823dbf98d6b9-80x80.jpg'
						}
					/>
				</button>
				<button
					type="button"
					// key={`studentkey_${i}`}
					className="studentRowContainer"
					onClick={() => {}}
				>
					<Student
						size={80}
						name={'Gerard - Joost Groentje'}
						imagepath={
							'https://data.demo.onderbouwdonline.nl/images/students/pictures/572c6bbb3a16cf02476f823dbf98d6b9-80x80.jpg'
						}
					/>
				</button>
				<button
					type="button"
					// key={`studentkey_${i}`}
					className="studentRowContainer"
					onClick={() => {}}
				>
					<Student
						size={80}
						name={'Gerard - Joost Groentje'}
						imagepath={
							'https://data.demo.onderbouwdonline.nl/images/students/pictures/572c6bbb3a16cf02476f823dbf98d6b9-80x80.jpg'
						}
					/>
				</button>
				<button type="button" className="addButton">
					<AddIcon width={20} height={20} />
				</button>
			</section>
		</div>
	);
};

const WeekPlanner = props => {
	const componentRef = useRef(null);

	const [isLoading, setLoading] = useState(true);
	const [data, setData] = useState(null);

	let groups = props.groupList.groups;

	console.log(groups);

	useEffect(() => {
		// getBehandelplan();
		return () => {};
	}, []);

	const getCalendar = () => {
		setLoading(true);
		const dataForm = new FormData();
		dataForm.append('groupId', groupId);

		axiosPost(
			groupbehandelplan_endpoint,
			dataForm,
			props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					const data = axiosResult.data.results.filter(x => {
						const array = x.steps.filter(y => y.students.length > 0);
						if (array.length > 0) return x;
					});
					setData(data);
				}
				setLoading(false);
			}
		);
	};

	return (
		<div className="mainContainer">
			<div className="flexBlock weekplannerContainer">
				<div ref={componentRef} className={`tabBorderStyle generic_wrapper`}>
					<header className="weekplannerHeader">
						<div>
							<span className="planningWeek">Planning 21-25 maart</span>
							<ul className="weekTabs tabsStyle">
								<li className="activeTab">
									<a>Maandag</a>
								</li>
								<li>
									<a>Dinsdag</a>
								</li>
								<li>
									<a>Woensdag</a>
								</li>
								<li>
									<a>Donderdag</a>
								</li>
								<li>
									<a>Vrijdag</a>
								</li>
							</ul>
							<div className="weekButtons">
								<button type="button">
									<BackIcon width={10} />
								</button>
								<span>deze week</span>
								<button type="button">
									<NextIcon width={10} />
								</button>
							</div>
						</div>

						<select
							className="groupDropDown"
							// onChange={this.handleGroupChange}
							value={props.groupId}
						>
							{groups?.map((item, i) => (
								<option key={i} value={item.groupId}>
									{item.groupName}
								</option>
							))}
						</select>
					</header>

					<div className="pageContainer">
						<div className="pageWrapper">
							<h2 className="headingStyle">Aanbod kleine kring</h2>
							<div className="columnWrapper">
								<PlannerLesson topicId="1" />
								<PlannerLesson topicId="2" />
								<PlannerLesson topicId="3" />
								<PlannerLesson topicId="4" />
							</div>
						</div>
						<div className="pageWrapper">
							<h2 className="headingStyle">Aanbod grote kring</h2>
							<div className="columnWrapper">
								<PlannerLesson topicId="1" />
								<PlannerLesson topicId="2" />
								<PlannerLesson topicId="3" />
								<PlannerLesson topicId="4" />
							</div>
						</div>
						<div className="pageWrapper">
							<h2 className="headingStyle">Niet gepland aanbod</h2>

							<ul className="tabsStyle">
								{topics && topics.map((topic, i) => {
									return (
										<li
											key={i}
											className={`${topic.topicName
												.replace(/ /g, '-')
												.toLowerCase()}_tab`}
										>
											<Link
												to={{
													pathname: '/',
													state: {
														topic: topic
													}
												}}
											>
												{topic.topicName}
											</Link>
										</li>
									);
								})}
							</ul>

							<div className="tabBorderStyle taal_wrapper lessonColumnWrapper">
								<div className="pageContainer">
									<div>
										<Student
											imagepath={
												'https://data.demo.onderbouwdonline.nl/images/goals/pictures/discriminatie-40x40.jpg'
											}
											name={'Verschillen zien'}
											size={40}
										/>
										<Student
											imagepath={
												'https://data.demo.onderbouwdonline.nl/images/goals/pictures/discriminatie-40x40.jpg'
											}
											name={'Verschillen zien'}
											size={40}
										/>
										<Student
											imagepath={
												'https://data.demo.onderbouwdonline.nl/images/goals/pictures/discriminatie-40x40.jpg'
											}
											name={'Verschillen zien'}
											size={40}
										/>
										<Student
											imagepath={
												'https://data.demo.onderbouwdonline.nl/images/goals/pictures/discriminatie-40x40.jpg'
											}
											name={'Verschillen zien'}
											size={40}
										/>
										<Student
											imagepath={
												'https://data.demo.onderbouwdonline.nl/images/goals/pictures/discriminatie-40x40.jpg'
											}
											name={'Verschillen zien'}
											size={40}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

// export default WeekPlanner;

const mapStateToProps = state => ({
	token: state.initialData.token,
	topics: state.initialData.topics,
	userData: state.initialData.userData,
	groupId: state.initialData.groupId,
	groupList: state.initialData.groupList,
	lessonTree: state.initialData.lessonTree
});

export default connect(mapStateToProps, {
	sendErrorToHOC
})(WeekPlanner);

import React, { useEffect, useRef } from 'react';
// @import images icons
import { ReactComponent as Cross } from '../../../images/svg/cross.svg';

let iframStyles = {
	width: '100%',
	boxSizing: 'border-box',
	height: '400px'
};

const IframePopup = ({ closePopup, ...props }) => {
	let { url } = props;

	useEffect(() => {
		const handleEsc = event => {
			if (event.keyCode === 27) {
				console.log('iframe popup');
				closePopup();
			}
		};
		window.addEventListener('keydown', handleEsc);

		return () => {
			window.removeEventListener('keydown', handleEsc);
		};
	}, []);
	return (
		<div id="iframePopup" className="popupWrapper iframePopup">
			<div className="popup" style={{ maxWidth: '650px' }}>
				<button
					type="button"
					className="closePopup"
					onClick={() => closePopup()}
				>
					<Cross width={18} />
				</button>

				<div>
					{url && (
						<iframe
							style={iframStyles}
							src={url}
							title="onderbouw online"
							allowFullScreen
							frameBorder="0"
							// allow="autoplay"
						/>
					)}
				</div>
			</div>
			<span className="overlayBackground" />
		</div>
	);
};

export default IframePopup;

// @import dependencies
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Slider from 'rc-slider/lib/Slider';
// @import endpoints
import { getstudentperformance_endpoint } from 'js/constants/endpoints';
// @import actions
import {
	changeStudentStep,
	setStudentStepSuccess,
	sendErrorToHOC
} from 'actions/actions';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
// @import styles
import './index.scss';

class StudentPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputValue: '',
			stepFrom: props.stepFrom ? props.stepFrom : null,
			stepTo: props.stepTo ? props.stepTo : null,
			message: props.message ? props.message : null,
			lessonId: props.lessonId ? props.lessonId : null,
			routinelessonId: props.routinelessonId ? props.routinelessonId : null,
			calledfrom: props.calledfrom ? props.calledfrom : null,
			studentPerformance: props.studentPerformance
				? this.addPerformance(props.studentPerformance)
				: null
		};
	}

	componentDidMount() {
		if (!this.props.readOnly) {
			this.teacherComment.focus();
		}
	}

	componentDidUpdate = prevProps => {
		if (
			prevProps.students !== this.props.students &&
			this.props.lastAction !== 'delete'
		) {
			const loader = document.querySelector('.circleLoader');
			const check = loader?.querySelector('.draw');
			loader?.classList.add('loadComplete');
			check?.classList.add('checkmark');

			if (this.props.callback) {
				this.props.callback();
			}

			setTimeout(() => {
				this.props.hidepopup();
				this.props.setStudentStepSuccess();
			}, 1000);
		}

		if (
			this.props.studentStepSuccess !== prevProps.studentStepSuccess &&
			this.props.studentStepSuccess === 'success' &&
			this.props.calledfrom === 'action plan'
		) {
			this.props.updateActionPlan(
				this.props.lessonId,
				this.props.routinelessonId
			);
			setTimeout(() => {
				this.props.hidepopup();
				this.props.setStudentStepSuccess();
			}, 1000);
		}

		if (this.props.studentPerformance !== prevProps.studentPerformance) {
			this.setState({
				studentPerformance: this.addPerformance(this.props.studentPerformance)
			});
		}
	};

	handleTextarea = event => {
		this.setState({
			inputValue: event.target.value
		});
	};

	addPerformance = studentPerformance => {
		// simplify condition
		if (studentPerformance === 'ahead') {
			return 'ahead';
		}
		if (studentPerformance === 'behind') {
			return 'behind';
		}
		if (studentPerformance === 'done') {
			return 'done';
		}
		if (studentPerformance === 'on track' || studentPerformance === 'unknown') {
			return 'ontrack';
		}
	};

	handleChange = toStep => {
		let stepTo;
		const { studentId, studentFirstname } = this.props.student;
		if (toStep !== this.state.stepFrom) {
			let message = this.props.stepLegend[toStep].description;
			message = message.replace('#KIND', studentFirstname);

			switch (toStep) {
				case 0:
					this.setState({
						stepTo: 'A',
						message
					});
					break;
				case 1:
					this.setState({
						stepTo: 'B',
						message
					});
					break;
				case 2:
					this.setState({
						stepTo: 'C',
						message
					});
					break;
				case 3:
					this.setState({
						stepTo: 'D',
						message
					});
					break;
				case 4:
					this.setState({
						stepTo: 'E',
						message
					});
					break;
				default:
					this.setState({
						stepTo: 'A',
						message
					});
			}

			switch (toStep) {
				case 0:
					stepTo = 'A';
					break;
				case 1:
					stepTo = 'B';
					break;
				case 2:
					stepTo = 'C';
					break;
				case 3:
					stepTo = 'D';
					break;
				case 4:
					stepTo = 'E';
					break;
				default:
					stepTo = 'A';
			}

			const dataForm = new FormData();
			dataForm.append('studentId', studentId);
			dataForm.append('newStep', stepTo);

			axiosPost(
				getstudentperformance_endpoint,
				dataForm,
				this.props.token,
				axiosResult => {
					if (axiosResult.status === 'success') {
						this.setState({
							studentPerformance: this.addPerformance(
								axiosResult.data.studentPerformance
							)
						});
					}
				}
			);
		}
	};

	render() {
		const {
			readOnly = false,
			stepLegend,
			stepTo,
			lessonId,
			routinelessonId,
			lesson,
			calledfrom
		} = this.props;

		const {
			studentId,
			studentDisplayname,
			studentDisplayage,
			studentFirstname,
			studentPicture
		} = this.props.student;

		let sliderValue;

		switch (stepTo) {
			case 'A':
				sliderValue = 0;
				break;
			case 'B':
				sliderValue = 1;
				break;
			case 'C':
				sliderValue = 2;
				break;
			case 'D':
				sliderValue = 3;
				break;
			case 'E':
				sliderValue = 4;
				break;
			default:
				sliderValue = 0;
		}

		return (
			<div className="studentWrapper">
				<div className="studentIntro">
					<img
						src={studentPicture.replace(/(\.[^.]+)$/, '-70x70$1')}
						srcSet={`${studentPicture.replace(/(\.[^.]+)$/, '-140x140$1')} 2x`}
						alt=""
					/>
					<div>
						<h3>Observatie {studentDisplayname}</h3>
						{studentDisplayage && <span>{studentDisplayage}</span>}
					</div>
				</div>

				<h2>Registreer observatie</h2>
				{this.state.studentPerformance && (
					<div className="sliderWrapper">
						<div className="steps">
							<span>A</span>
							<span>B</span>
							<span>C</span>
							<span>D</span>
							<span className="bulletPoint">•</span>
						</div>
						<Slider
							min={0}
							max={4}
							defaultValue={sliderValue}
							disabled={this.props.readOnly || !this.props.stepLegend}
							onAfterChange={e => this.handleChange(e)}
							className={`sliderComponent ${this.state.studentPerformance}`}
						/>
					</div>
				)}
				<div className="studentDetail">
					<p>{this.state.message}</p>
					{this.state.stepTo !== this.state.stepFrom ? (
						<strong>
							{this.state.stepTo === 'E'
								? `Van ontwikkelstap ${this.state.stepFrom} naar ontwikkelstappen afgerond`
								: this.state.stepFrom === 'E'
								? `Observatie: Van ontwikkelstappen afgerond naar ${this.state.stepTo}`
								: `Observatie: Van ontwikkelstap ${this.state.stepFrom} naar ${this.state.stepTo}`}
						</strong>
					) : (
						<strong>
							{this.state.stepTo === 'E'
								? `Observatie: de ontwikkelstappen blijven afgerond`
								: `Observatie: Geobserveerd in ontwikkelstap ${this.state.stepTo}`}
						</strong>
					)}
					{!readOnly ? (
						<>
							<textarea
								placeholder="Toelichting"
								ref={textarea => {
									this.teacherComment = textarea;
								}}
								value={this.state.inputValue}
								onChange={this.handleTextarea}
							/>

							<div className="btnsWrap">
								{this.state.loaderRequested && (
									<div className="circleLoader">
										<div className="draw" />
									</div>
								)}

								<button
									type="button"
									className="outlineButton"
									onClick={this.props.handleClose}
								>
									Annuleren
								</button>
								<button
									type="button"
									className="defaultBtn"
									onClick={() => {
										this.props.changeStudentStep(
											studentId,
											lessonId,
											this.state.stepTo !== this.state.stepFrom
												? this.state.stepTo
												: '',
											this.state.inputValue,
											this.props.token,
											this.props.groupId,
											calledfrom,
											routinelessonId && calledfrom === 'action plan'
												? routinelessonId
												: ''
										);
										this.setState({
											loaderRequested: true
										});
									}}
								>
									Opslaan
								</button>
							</div>
						</>
					) : (
						<br />
					)}
				</div>
				{lesson.lessonBlockers && (
					<div className="infoBlock">
						<h2>Leerbelemmeringen</h2>
						<p
							dangerouslySetInnerHTML={{
								__html: lesson.lessonBlockers.replace(/\n/g, '<br />')
							}}
						/>
					</div>
				)}
				{lesson.lessonStimulants && (
					<div className="infoBlock">
						<h2>Leerstimulans</h2>
						<p
							dangerouslySetInnerHTML={{
								__html: lesson.lessonStimulants.replace(/\n/g, '<br />')
							}}
						/>
					</div>
				)}
				{stepLegend && (
					<div className="legendsWrap">
						{stepLegend.map((item, i) => {
							if (!item.description) return;
							return (
								<Fragment key={i}>
									{i === 0 && <h2>Legenda ontwikkelstappen</h2>}
									<div className="stepsLegend">
										{item.step === 'E' ? (
											<span className="bulletPoint">•</span>
										) : (
											<span>{item.step}</span>
										)}
										<p>
											{item.description.replace(/#kind/gi, studentFirstname)}
										</p>
									</div>
									{item.description_description && <>
										<div className="stepsLegend">
											<p>
												{item.description_description}
											</p>
										</div>
										<br/>
									</>}
								</Fragment>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
	groupId: state.initialData.groupId,
	studentStepSuccess: state.initialData.studentStepSuccess,
	students: state.initialData.students
});

export default connect(mapStateToProps, {
	changeStudentStep,
	setStudentStepSuccess,
	sendErrorToHOC
})(StudentPopup);

import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { axiosGet, axiosPost } from '../../utils/axiosCall';
import {
	getsettings_endpoint,
	postsettings_endpoint
} from '../../constants/endpoints';
// @import actions
import { fetchInitialData } from '../../../actions/actions';
// @import Styles
import '../../../css/containers/coordinator/manageSettings.scss';

const WarningPopup = ({ text, submitAnswer }) => {
	useEffect(() => {
		TweenMax.to('.warningPopup', 0.3, {
			autoAlpha: 1
		});
		return () => {
			TweenMax.to('.warningPopup', 0.3, {
				autoAlpha: 0
			});
		};
	}, []);

	return (
		<div className="popupWrapper warningPopup flexCenter">
			<div className="popup">
				<div
					dangerouslySetInnerHTML={{
						__html: text
					}}
				/>
				<button
					type="button"
					className="defaultBtn inlineBlock generic"
					onClick={() => submitAnswer(false)}
				>
					Annuleer
				</button>
				<button
					type="button"
					className="defaultBtn inlineBlock"
					onClick={() => submitAnswer(true)}
				>
					Begrepen
				</button>
			</div>
			<span className="overlayBackground" />
		</div>
	);
};

export class ManageSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			schoolParentsEnabled: null,
			schoolParentsEnableWarning: null,
			showWarningPopup: false
			// schoolParentsAllowreport: null
		};
	}

	componentDidMount() {
		this.getSettings();
	}

	getSettings = () => {
		axiosGet(getsettings_endpoint, this.props.token, axiosResult => {
			if (axiosResult.status === 'success') {
				this.setState({
					schoolParentsEnabled: axiosResult.data.schoolParentsEnabled,
					schoolParentsEnableWarning:
						axiosResult.data.schoolParentsEnableWarning
					// schoolParentsAllowreport: axiosResult.data.schoolParentsAllowreport
				});
			}
		});
	};

	handleWarning = bool => {
		console.log(bool);
		if (bool) this.submitSettings();
		else this.setState({ showWarningPopup: false, schoolParentsEnabled: '0' });
	};

	showWarningPopup = bool => {
		this.setState({ showWarningPopup: bool });
	};

	postSettings = bool => {
		if (bool && this.state.schoolParentsEnableWarning)
			this.showWarningPopup(true);
		else this.submitSettings();
	};

	submitSettings = () => {
		this.showWarningPopup(false);
		const dataForm = new FormData();
		dataForm.append('schoolParentsEnabled', this.state.schoolParentsEnabled);
		// dataForm.append(
		// 	'schoolParentsAllowreport',
		// 	this.state.schoolParentsAllowreport
		// );
		axiosPost(
			postsettings_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.getSettings();
					this.props.fetchInitialData(this.props.token);
				}
			}
		);
	};

	handleSchoolParentEnable = event => {
		this.setState(
			{
				schoolParentsEnabled:
					this.state.schoolParentsEnabled === '1' ? '0' : '1'
			},
			() => {
				this.postSettings(this.state.schoolParentsEnabled === '1');
			}
		);
	};

	// handleSchoolParentsAllowreport = event => {
	//   this.setState(
	//     {
	//       schoolParentsAllowreport:
	//         this.state.schoolParentsAllowreport === '1' ? '0' : '1'
	//     },
	//     () => {
	//       this.postSettings();
	//     }
	//   );
	// };

	render() {
		return (
			<div className="pageContainer">
				<h1 className="headingStyle">Instellingen</h1>
				{this.state.showWarningPopup && (
					<WarningPopup
						submitAnswer={this.handleWarning}
						text={this.state.schoolParentsEnableWarning}
					/>
				)}
				{this.state.schoolParentsEnabled && (
					<div className="settingsWrapper">
						<div className="row">
							<input
								id="schoolParents"
								type="checkbox"
								checked={this.state.schoolParentsEnabled === '1'}
								onChange={this.handleSchoolParentEnable}
							/>
							<label htmlFor="schoolParents">
								Onderbouwd Online voor Thuis ingeschakeld
							</label>
						</div>
						{/* <div className="row">
              <input
                id="schoolParentsReport"
                type="checkbox"
                checked={this.state.schoolParentsAllowreport === '1'}
                onChange={this.handleSchoolParentsAllowreport}
                disabled={this.state.schoolParentsEnabled !== '1'}
              />
              <label htmlFor="schoolParentsReport">
                Leerkrachten kunnen het leerlingportfolio beschikbaar maken in
                Onderbouwd Online voor Thuis
              </label>
            </div> */}
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token
});

export default connect(mapStateToProps, { fetchInitialData })(ManageSettings);

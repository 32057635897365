import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { TweenLite } from 'gsap/TweenMax';
// @import constants
import { documentlist_endpoint } from '../constants/endpoints';
// @import styles
import '../../css/containers/faq.scss';
import { ReactComponent as UpArrow } from '../../images/svg/up-arrow.svg';
// @import actions
import { sendErrorToHOC } from '../../actions/actions';
// @import utils
import { axiosPost } from '../utils/axiosCall';

export class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hasMore: true,
      documentList: [],
      offset: 0
    };
  }

  componentDidMount() {
    this.getDocuments();
  }

  getDocuments = () => {
    this.setState({
      isLoading: true
    });

    const dataForm = new FormData();
    axiosPost(
      documentlist_endpoint,
      dataForm,
      this.props.token,
      axiosResult => {
        if (axiosResult.status === 'success') {
          const documentList = [];
          for (let i = 0; i < axiosResult.data.categories.length; i++) {
            const documentArray = [];
            const categoryObject = axiosResult.data.categories[i];
            documentArray.push(categoryObject);
            const categoryRelatedDocs = axiosResult.data.documents.filter(
              x => x.categoryId === categoryObject.downloadcategoryId
            );
            documentArray.push(categoryRelatedDocs);
            documentList.push(documentArray);
            if (i === axiosResult.data.categories.length - 1) {
              this.setState({
                isLoading: false,
                documentList: documentList
              });
            }
          }
        }
        if (axiosResult.status === 'no such data') {
          this.setState({
            isLoading: false,
            hasMore: false
          });
        }
      }
    );
  };

  handleReadMore = event => {
    const button = event.currentTarget;
    const collapsableWrapper = button.nextElementSibling;
    if (collapsableWrapper.classList.contains('closed')) {
      button.classList.add('active');
      TweenLite.set(collapsableWrapper, { height: 'auto', paddingTop: '10px' });
      TweenLite.from(collapsableWrapper, 0.2, {
        height: 0,
        paddingTop: 0,
        onComplete: () => {
          collapsableWrapper.classList.remove('closed');
          collapsableWrapper.classList.add('opened');
        }
      });
    } else {
      button.classList.remove('active');
      TweenLite.to(collapsableWrapper, 0.2, {
        height: 0,
        paddingTop: 0,
        onComplete: () => {
          collapsableWrapper.classList.remove('opened');
          collapsableWrapper.classList.add('closed');
        }
      });
    }
  };

  render() {
    return (
      <div className="mainContainer">
        <div className="pageContainer documentScreen">
          <Helmet>
            <link
              href="https://fonts.googleapis.com/css?family=Schoolbell&display=swap"
              rel="stylesheet"
            />
          </Helmet>

          {this.state.documentList && (
            <div className="innerContainer">
              <section className="documentWrapper">
                <h1 className="headingStyle">Documenten</h1>
                <ul>
                  {this.state.documentList.length > 0 &&
                    this.state.documentList.map(item => {
                      return (
                        <li
                          key={`doc_${item.downloadcategoryId}`}
                          className="faq"
                        >
                          <button type="button" onClick={this.handleReadMore}>
                            <strong>{item[0].downloadcategoryTitle}</strong>
                            <UpArrow />
                          </button>
                          <div className="faqAnswer closed">
                            {item[1].map(res => {
                              return (
                                <a
                                  key={`cat_${res.categoryId}`}
                                  href={res.downloadFile}
                                  download={res.downloadTitle}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {res.downloadTitle}
                                </a>
                              );
                            })}
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </section>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.initialData.token
});

export default connect(
  mapStateToProps,
  { sendErrorToHOC }
)(Documents);

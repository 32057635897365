import React, { useEffect, useState } from 'react';
// @import endpoints
import { parentroutinelessonpreview_endpoint } from 'js/constants/endpoints';
// @import components
import Loader from 'js/models/loader';
// @import icons
import { ReactComponent as Cross } from 'images/svg/cross.svg';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
// @import styles
import './index.scss';

const ParentTipButton = ({
	data,
	lessonName,
	lessonPicture,
	showVideoPopup,
	closePopup
}) => {
	return (
		<a
			href={data.parenttipMediaUrl}
			download={data.parenttipMediaType === 'file'}
			target={
				data.parenttipMediaType === 'file' || data.parenttipMediaType === 'link'
					? '_blank'
					: '_self'
			}
			className="outlineButton inlineBtn"
			rel="noopener noreferrer"
			onClick={e => {
				if (data.parenttipMediaType === 'video') {
					e.preventDefault();
					showVideoPopup(
						data.parenttipMediaTitle,
						data.parenttipMediaUrl.replace('watch?v=', 'embed/'),
						lessonName,
						lessonPicture
					);
					closePopup();
				}
			}}
		>
			{data.parenttipMediaTitle}
		</a>
	);
};

const ParentRoutineLessonPopup = ({
	token,
	parenttipId,
	lessonName,
	lessonPicture,
	showVideoPopup,
	closePopup
}) => {
	const [data, setData] = useState(null);
	const [activeStep, setActiveStep] = useState('A');

	useEffect(() => {
		getData();

		const handleEsc = event => {
			if (event.keyCode === 27) {
				closePopup();
			}
		};
		window.addEventListener('keydown', handleEsc);

		return () => {
			window.removeEventListener('keydown', handleEsc);
		};
	}, []);

	const getData = () => {
		const dataForm = new FormData();
		dataForm.append('parenttipId', parenttipId);
		axiosPost(
			parentroutinelessonpreview_endpoint,
			dataForm,
			token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					setData(axiosResult.data);
				}
			}
		);
	};

	return (
		<div id="parentRoutinePopup" className="popupWrapper">
			<div className="popup">
				<button
					type="button"
					className="closePopup"
					onClick={() => closePopup()}
				>
					<Cross width={18} />
				</button>
				{data ? (
					<div className="parentTipSection generic">
						<div className="goalSec">
							<h3>{data.goal.goalName}</h3>
							<p>{data.goal.lessonIntroduction}</p>
							<img
								src={data.goal.goalPicture.replace(/(\.[^.]+)$/, '-150x150$1')}
								srcSet={`${data.goal.goalPicture.replace(
									/(\.[^.]+)$/,
									'-300x300$1'
								)} 2x`}
								alt={data.goal.goalName}
							/>
						</div>
						<div className="tipSec">
							<h3>{data.parenttip.parenttipTitle}</h3>
							<div className="row">
								<div>
									<div
										dangerouslySetInnerHTML={{
											__html: data.parenttip.parenttipText.replace(
												/(<p>&nbsp;<\/p>)+$/,
												''
											)
										}}
									/>
									<div className="subAnchors">
										{data.parenttip.parenttipMedia2Url && (
											<a
												href={data.parenttip.parenttipMedia2Url}
												download={data.parenttip.parenttipMedia2Type === 'file'}
												target={
													data.parenttip.parenttipMedia2Type === 'file' ||
													data.parenttip.parenttipMedia2Type === 'link'
														? '_blank'
														: '_self'
												}
												className="outlineButton inlineBtn"
												rel="noopener noreferrer"
												onClick={e => {
													if (data.parenttip.parenttipMedia2Type === 'video') {
														e.preventDefault();
														showVideoPopup(
															data.parenttip.parenttipMedia2Title,
															data.parenttip.parenttipMedia2Url.replace(
																'watch?v=',
																'embed/'
															),
															lessonName,
															lessonPicture
														);
														closePopup();
													}
												}}
											>
												{data.parenttip.parenttipMedia2Title}
											</a>
										)}

										{data.parenttip.parenttipMedia3Url && (
											<a
												href={data.parenttip.parenttipMedia3Url}
												download={data.parenttip.parenttipMedia3Type === 'file'}
												target={
													data.parenttip.parenttipMedia3Type === 'file' ||
													data.parenttip.parenttipMedia3Type === 'link'
														? '_blank'
														: '_self'
												}
												className="outlineButton inlineBtn"
												rel="noopener noreferrer"
												onClick={e => {
													if (data.parenttip.parenttipMedia3Type === 'video') {
														e.preventDefault();
														showVideoPopup(
															data.parenttip.parenttipMedia3Title,
															data.parenttip.parenttipMedia3Url.replace(
																'watch?v=',
																'embed/'
															),
															lessonName,
															lessonPicture
														);
														closePopup();
													}
												}}
											>
												{data.parenttip.parenttipMedia3Title}
											</a>
										)}
									</div>

									<ul className="stepList">
										{Object.keys(data.parenttip.step).map((keyName, i) => {
											if (
												Array.isArray(data.parenttip.step[keyName].button1) &&
												Array.isArray(data.parenttip.step[keyName].button2) &&
												data.parenttip.step[keyName].text === ''
											)
												return;
											return (
												<li
													key={i}
													className={keyName === activeStep ? 'active' : ''}
												>
													<button onClick={() => setActiveStep(keyName)}>
														{keyName}
													</button>
												</li>
											);
										})}
									</ul>

									{Array.isArray(data.parenttip.step[activeStep].button1) &&
									Array.isArray(data.parenttip.step[activeStep].button2) &&
									data.parenttip.step[activeStep].text === '' ? null : (
										<div className="stepButtonWrapper">
											{data.parenttip.step[activeStep].text && (
												<div
													dangerouslySetInnerHTML={{
														__html: data.parenttip.step[activeStep].text
													}}
												/>
											)}

											{data.parenttip.step[activeStep].button1 &&
												!Array.isArray(
													data.parenttip.step[activeStep].button1
												) && (
													<ParentTipButton
														data={data.parenttip.step[activeStep].button1}
														lessonName={lessonName}
														lessonPicture={lessonName}
														showVideoPopup={showVideoPopup}
														closePopup={closePopup}
													/>
												)}

											{data.parenttip.step[activeStep].button2 &&
												!Array.isArray(
													data.parenttip.step[activeStep].button2
												) && (
													<ParentTipButton
														data={data.parenttip.step[activeStep].button2}
														lessonName={lessonName}
														lessonPicture={lessonName}
														showVideoPopup={showVideoPopup}
														closePopup={closePopup}
													/>
												)}
										</div>
									)}
								</div>

								{data.parenttip.parenttipMedia1Type === 'link' && (
									<img
										src={data.parenttip.parenttipMedia1Url.replace(
											/(\.[^.]+)$/,
											'-400x250$1'
										)}
										srcSet={`${data.parenttip.parenttipMedia1Url.replace(
											/(\.[^.]+)$/,
											'-800x500$1'
										)} 2x`}
										alt=""
									/>
								)}

								{data.parenttip.parenttipMedia1Type === 'video' && (
									<iframe
										src={data.parenttip.parenttipMedia1Url.replace(
											'watch?v=',
											'embed/'
										)}
										title="onderbouw online"
										allowFullScreen
										frameBorder="0"
									/>
								)}
							</div>
						</div>
					</div>
				) : (
					<Loader />
				)}
			</div>
			<span className="overlayBackground" />
		</div>
	);
};

export default ParentRoutineLessonPopup;

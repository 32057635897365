// @import packages
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleBar from 'simplebar-react';
import ReactToPrint from 'react-to-print';
// @import actions
import {
	setStudentList,
	changeLessonStatus,
	changeRouteenLessonStatus,
	sendErrorToHOC
} from '../../actions/actions';
// @import constants
import { annualplan_endpoint } from '../constants/endpoints';
// @import components
import Student from '../components/student';
// @import styles
import '../../css/containers/annualPlan.scss';
// @import images
import { ReactComponent as PrintIcon } from '../../images/svg/printer.svg';
import { ReactComponent as FullscreenIcon } from 'images/svg/fullscreen.svg';
import { ReactComponent as ExitscreenIcon } from 'images/svg/exitscreen.svg';
import { ReactComponent as Tick } from 'images/svg/tick.svg';
// @import utils
import { axiosPost } from '../utils/axiosCall';
import { toggleFullScreen } from 'js/utils/triggerFullscreen';

class AnnualPlan extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isFullScreen: false
		};

		this.handleFullScreenChange = this.handleFullScreenChange.bind(this);
	}

	componentDidMount() {
		if (this.props.groupId) {
			this.setInitialStates();
		}
		document
			.querySelector('.annualplanContainer')
			.addEventListener('fullscreenchange', this.handleFullScreenChange);
	}

	componentDidUpdate(prevProps) {
		if (this.props.lessonTree !== prevProps.lessonTree) {
			this.setInitialStates();
		}
	}

	componentWillUnmount() {
		document
			.querySelector('.annualplanContainer')
			.removeEventListener('fullscreenchange', this.handleFullScreenChange);
	}

	handleFullScreenChange() {
		if (document.fullscreenElement) {
			this.setState({
				isFullScreen: true
			});
			console.log(
				`Element: ${document.fullscreenElement.id} entered fullscreen mode.`
			);
		} else {
			console.log('Leaving full-screen mode.');
			this.setState({
				isFullScreen: false
			});
		}
	}

	setInitialStates = () => {
		let { groupname } = this.props.match.params;
		groupname = groupname.replace(/_/g, ' ');

		const getGroup = this.props.groupList.groups.find(
			x =>
				x.groupName.replace(/_/g, ' ').replace(/\//g, ' ').toLowerCase() ===
				groupname
		);

		setTimeout(() => {
			this.props.setStudentList(
				getGroup.groupId,
				getGroup.groupName,
				getGroup.students
			);
		}, 20);

		const currentSeason = this.props.lessonTree.filter(
			x => x.seasonId === this.props.seasonId.toString()
		);
		const currentPeriod = currentSeason[0].periods?.find(
			x => x.periodId === this.props.periodId.toString()
		);
		const currentTopic = currentPeriod.topics[0];

		this.setState({
			currentPeriod,
			topicName: currentTopic.topicName,
			topicId: currentTopic.topicId
		});

		this.getAnnualPlan(getGroup.groupId, currentTopic.topicId);
	};

	changeTopic = (id, name) => {
		this.setState(
			{
				isLoading: true,
				lessons: null,
				topicName: name,
				topicId: id
			},
			() => {
				this.getAnnualPlan(this.props.groupId, id, this.props.token);
			}
		);
	};

	getAnnualPlan = (groupId, topicId) => {
		const dataForm = new FormData();
		dataForm.append('groupId', groupId);
		dataForm.append('topicId', topicId);

		axiosPost(annualplan_endpoint, dataForm, this.props.token, axiosResult => {
			if (axiosResult.status === 'success') {
				this.setState({
					isLoading: false,
					headers: axiosResult.data.headers.filter(e => e.periods && e.periods.length),
					rows: axiosResult.data.rows
				});
			}
		});
	};

	handleCallback = () => {
		this.getAnnualPlan(this.props.groupId, this.state.topicId);
	};

	render() {
		return (
			<div className="mainContainer annualplanContainer">
				<div>
					{this.state.currentPeriod && (
						<ul className="tabsStyle">
							{this.state.currentPeriod.topics.map((item, i) => (
								<li
									key={i}
									className={`${item.topicName
										.replace(/ /g, '-')
										.toLowerCase()}_tab`}
								>
									<button
										type="button"
										onClick={() =>
											this.changeTopic(item.topicId, item.topicName)
										}
									>
										{item.topicName}
									</button>
								</li>
							))}
						</ul>
					)}
				</div>

				<div
					ref={el => (this.componentRef = el)}
					className={`tabBorderStyle ${
						this.state.topicName
							? this.state.topicName
									.replace(/ /g, '-')
									.toLowerCase()
									.concat('_wrapper')
							: null
					}`}
				>
					<div className="pageContainer">
						<div
							className={`annualPlanWrapper ${
								this.state.topicName &&
								this.state.topicName.replace(/ /g, '-').toLowerCase()
							}_wrap`}
						>
							{this.state.headers && (
								<h1>
									{`${this.props.groupName} - Jaarplanning ${this.state.topicName}`}
								</h1>
							)}

							<button
								type="button"
								className="printButton"
								onClick={() => toggleFullScreen('.annualplanContainer')}
							>
								{this.state.isFullScreen ? (
									<ExitscreenIcon />
								) : (
									<FullscreenIcon />
								)}
							</button>

							<ReactToPrint
								trigger={() => (
									<button
										type="button"
										className="printButton"
										style={{ right: '70px' }}
									>
										<PrintIcon />
									</button>
								)}
								pageStyle={
									'@media print{@page{size: landscape; margin: 6mm 10mm 9mm 10mm;} body{margin:0;} .pageContainer{background: white !important;} .printButton{display: none;}}'
								}
								content={() => this.componentRef}
							/>

							{/* <div className="overflowController"> */}
							{!this.state.isLoading && (
								<SimpleBar
									forceVisible="y"
									autoHide={false}
									style={{
										maxHeight: this.state.isFullScreen
											? 'calc(100vh - 180px)'
											: 'max-content'
									}}
								>
									{this.state.headers && (
										<div className="row headerRow">
											<div className="studentColumn" />
											<div className="tableColumn">
												{this.state.headers.map((item, i) => {
													return (
														<div
															className="groupColumn"
															key={i}
															data-seasonid={item.seasonId}
														>
															<div className="goalRow">
																<h2 className="goalName">{item.seasonName}</h2>
															</div>
															<div className="row">
																{item.periods?.map((res, i) => (
																	<div className="step" key={i}>
																		<Link
																			to={res.periodSlug}
																			className="lessonName"
																			data-periodid={res.periodId}
																		>
																			{res.periodName}
																		</Link>
																	</div>
																))}
															</div>
														</div>
													);
												})}
											</div>
										</div>
									)}

									{this.state.rows &&
										this.state.rows.map((item, i) => (
											<div className="row" key={i}>
												<div className="studentColumn">
													<Student
														imagepath={item.lessonPicture}
														name={item.lessonName}
														size={40}
													/>
												</div>
												<div className="tableColumn">
													{this.state.headers.map((headerItem, i) => (
														<div className="groupColumn" key={i}>
															<div className="row">
																{headerItem.periods?.map((res, i) => {
																	let showBox = false;
																	if (res.periodId === item.lessonPeriod) {
																		showBox = true;
																	}
																	let periods;
																	if (item.routinelessons) {
																		const results = item.routinelessons.filter(
																			x =>
																				x.routinelessonPeriodId === res.periodId
																		);
																		if (results) {
																			periods = results;
																		}
																	}
																	return (
																		<div className="step" key={i}>
																			<span
																				onClick={e => {
																					if (showBox) {
																						// console.log(
																						//   item.lessonPeriod,
																						//   res.periodId
																						// );
																						this.props.changeLessonStatus(
																							res.periodId,
																							item.lessonId,
																							this.props.groupId,
																							item.lessonStatus === 'done'
																								? 0
																								: 1,
																							this.state.topicId,
																							this.props.token,
																							this.handleCallback
																						);
																					}
																					if (item.lessonStatus === 'done') {
																						e.target.classList.add('lightBox');
																						e.target.classList.remove(
																							'darkBox'
																						);
																					} else {
																						e.target.classList.add('darkBox');
																						e.target.classList.remove(
																							'lightBox'
																						);
																					}
																				}}
																				className={`currentStep ${
																					showBox
																						? item.lessonStatus === 'done'
																							? 'addboxStyle darkBox'
																							: 'addboxStyle lightBox'
																						: ''
																				}`}
																			/>
																			<div className="dotWrapper">
																				{periods &&
																					periods.map((period, i) => {
																						return (
																							<span
																								key={i}
																								onClick={e => {
																									// console.log(
																									//   period.routinelessonPeriodId,
																									//   res.periodId
																									// );
																									this.props.changeRouteenLessonStatus(
																										res.periodId,
																										period.routinelessonId,
																										this.props.groupId,
																										period.routinelessonStatus === 'todo' ? 'planned'
																										: period.routinelessonStatus === 'planned' ? 'done' : 'todo',
																										this.state.topicId,
																										this.props.token,
																										this.handleCallback
																									);
																									if (period.routinelessonStatus === 'done') {
																										e.target.classList.remove( 'lightdot');
																										e.target.classList.remove( 'darkdot');
																									} else if (period.routinelessonStatus === 'planned') {
																										e.target.classList.add( 'darkdot');
																										e.target.classList.remove( 'lightdot');
																									} else {
																										e.target.classList.add( 'lightdot');
																										e.target.classList.remove( 'darkdot');
																										e.target.classList.add( 'darkdot');
																										e.target.classList.remove( 'lightdot');
																									}
																								}}
																								className={`${
																									period.routinelessonStatus === 'todo' 
																										? 'dots lightdot' : period.routinelessonStatus === 'planned' 
																										? 'dots darkdot' : 'dots checkdot'
																								}`}
																								title={period.routinelessonName}
																							>
																								{period.routinelessonStatus === 'done' ? <Tick /> : ''}
																							</span>
																						);
																					})}
																			</div>
																		</div>
																	);
																})}
															</div>
														</div>
													))}
												</div>
											</div>
										))}
								</SimpleBar>
							)}
							{/* </div> */}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
	groupName: state.initialData.groupName,
	groupId: state.initialData.groupId,
	groupList: state.initialData.groupList,
	seasonId: state.initialData.seasonId,
	periodId: state.initialData.periodId,
	topicId: state.initialData.topicId,
	lessonTree: state.initialData.lessonTree
});

export default connect(mapStateToProps, {
	setStudentList,
	changeLessonStatus,
	changeRouteenLessonStatus,
	sendErrorToHOC
})(AnnualPlan);

import React, { useState } from 'react';
// @import Components
import Loader from 'js/models/loader';
// @import svgs
import { ReactComponent as Cross } from '../../../../../images/svg/cross.svg';
// @import styles
import './index.scss';

const ProductPopup = ({
	closePopup,
	data,
	onClick,
	userData,
	schoolDisplayname
}) => {
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		customerName: userData.schoolName,
		customerContactperson: userData.userDisplayname,
		customerStreet: userData.schoolStreet,
		customerHousenr: userData.schoolHousenr,
		customerHousenrAddition: userData.schoolHousenrAddition,
		customerZip: userData.schoolZip,
		customerCity: userData.schoolCity
	});

	const staticData = {
		customerName: userData.schoolName,
		customerContactperson: userData.userDisplayname,
		customerStreet: userData.schoolStreet,
		customerHousenr: userData.schoolHousenr,
		customerHousenrAddition: userData.schoolHousenrAddition,
		customerZip: userData.schoolZip,
		customerCity: userData.schoolCity
	};

	const [editAddress, setEditAddress] = useState(false);
	const [confirmDownload, setConfirmDownload] = useState(false);

	// data.shopitemType = 1 = Digital
	// data.shopitemType = 2 = Externbal
	// data.shopitemType = 3 = Physical

	const equals = (a, b) => {
		if (a === b) return true;
		if (a instanceof Date && b instanceof Date)
			return a.getTime() === b.getTime();
		if (!a || !b || (typeof a !== 'object' && typeof b !== 'object'))
			return a === b;
		if (a.prototype !== b.prototype) return false;
		const keys = Object.keys(a);
		if (keys.length !== Object.keys(b).length) return false;
		return keys.every(k => equals(a[k], b[k]));
	};

	const handleInputChange = event => {
		setFormData({
			...formData,
			[event.target.name]: event.target.value
		});
	};

	return (
		<div className="productPopup popupWrapper">
			<div className="popup" style={{ maxWidth: '650px' }}>
				{!loading || data.shopitemPurchaseStatus === 'purchased' ? (
					<>
						<button
							type="button"
							className="closePopup"
							onClick={() => closePopup()}
						>
							<Cross width={18} />
						</button>
						<div>
							<h2 className="headingStyle">
								{data.shopitemPriceExclTax == '0.00' ? 'Activeer ' : 'Koop '}
								{data.shopitemName}
							</h2>

							{data.shopitemPurchaseStatus === 'purchased' && (
								<>
									{data.shopitemType === '1' && data.shopitemDownload && (
										<p>
											{data.shopitemPriceExclTax == '0.00'
												? `Bedankt dat je dit product van ${data.shopitemVendorName} hebt geactiveerd.`
												: `Bedankt dat je dit product van ${data.shopitemVendorName} hebt aangeschaft.`}
											Je kunt het product direct downloaden en gebruiken.
										</p>
									)}
									{data.shopitemType === '3' && (
										<p>
											Bedankt voor je aankoop! Het product wordt zo snel
											mogelijk naar je opgestuurd.
										</p>
									)}
								</>
							)}

							{!loading && (
								<>
									{data.shopitemType === '1' && (
										<p>
											{data.shopitemPriceExclTax == '0.00'
												? `Activeer dit product nu direct bij ${data.shopitemVendorName}. Na activering kun je het product direct downloaden en gebruiken.`
												: `Je koopt dit product direct bij ${data.shopitemVendorName}.
											Hoewel Klassewerk dit product aanbeveelt als lesmateriaal
											dat goed past bij de lesmethode Onderbouwd Online is
											Klassewerk niet betrokken bij of verantwoordelijk voor de
											aanschaf en levering van dit product.`}
										</p>
									)}

									{data.shopitemType === '2' && (
										<p>
											Koop dit digitale product nu direct bij Klassewerk. Na
											aanschaf kun je het product direct downloaden en
											gebruiken. Jouw school ontvangt een factuur hiervoor.
										</p>
									)}

									{data.shopitemType === '3' && (
										<>
											<p>
												Koop dit fysieke product nu direct bij Klassewerk. Na
												aanschaf wordt het product zo snel mogelijk naar je
												opgestuurd. Jouw school ontvangt een factuur voor dit
												product.
											</p>
											<div className="customerDetails">
												<div className="buttonsWrapper">
													<strong>Bezorgadres</strong>
													<button
														type="button"
														onClick={() => setEditAddress(!editAddress)}
													>
														{editAddress ? 'Annuleren' : 'Bewerk'}
													</button>
												</div>
												<p>Dit product wordt bezorgd op het volgende adres: </p>
												{editAddress ? (
													<div className="formWrapper">
														<label>Organisatie</label>
														<input
															required
															type="text"
															name="customerName"
															className="inputField"
															value={formData.customerName}
															onChange={handleInputChange}
														/>
														<label>Naam contactpersoon</label>
														<input
															required
															type="text"
															name="customerContactperson"
															className="inputField"
															value={formData.customerContactperson}
															onChange={handleInputChange}
														/>
														<label>Straat</label>
														<input
															required
															type="text"
															name="customerStreet"
															className="inputField"
															value={formData.customerStreet}
															onChange={handleInputChange}
														/>
														<label>Huisnummer</label>
														<input
															required
															type="text"
															name="customerHousenr"
															className="inputField"
															value={formData.customerHousenr}
															onChange={handleInputChange}
														/>
														<label>Huisnummer toevoeging</label>
														<input
															required
															type="text"
															name="customerHousenrAddition"
															className="inputField"
															value={formData.customerHousenrAddition}
															onChange={handleInputChange}
														/>
														<label>Postcode</label>
														<input
															required
															type="text"
															name="customerZip"
															className="inputField"
															value={formData.customerZip}
															onChange={handleInputChange}
														/>
														<label>Plaats</label>
														<input
															required
															type="text"
															name="customerCity"
															className="inputField"
															value={formData.customerCity}
															onChange={handleInputChange}
														/>
													</div>
												) : (
													<p>
														<span>Organisatie: {userData.schoolName}</span>
														<span>
															Naam contactpersoon: {userData.userDisplayname}
														</span>
														<span>Adres: {userData.schoolAddress}</span>
														<span>
															Postcode: {userData.schoolZip} Plaats:{' '}
															{userData.schoolCity}
														</span>
													</p>
												)}
											</div>
										</>
									)}
								</>
							)}

							{!loading && data.shopitemType !== '2' && (
								<div className="checkBoxWrapper">
									<input
										type="checkbox"
										id="confirmDownload"
										name="confirmDownload"
										checked={confirmDownload}
										onChange={() => {
											setConfirmDownload(!confirmDownload);
										}}
									/>
									<label htmlFor="confirmDownload">
										<p>
											Ja, ik ga namens {schoolDisplayname} akkoord met deze
											aankoop en ik verklaar dat ik gemachtigd ben om deze
											aankoop namens {schoolDisplayname} te doen. Ook ga ik
											akkoord met de algemene voorwaarden van Klassewerk.
										</p>
									</label>
								</div>
							)}

							{error && (
								<p className="errorMsg">
									Om dit product te kopen dien je akkoord te gaan met
									bovenstaande voorwaarden
								</p>
							)}

							{!loading &&
								(data.shopitemType !== '2' ? (
									<button
										type="button"
										onClick={() => {
											if (confirmDownload) {
												if (data.shopitemType === '3') {
													const isEquals = equals(formData, staticData);
													const shipping = isEquals ? 'school' : 'customer';
													onClick(shipping, isEquals ? null : formData);
												} else {
													onClick();
												}
												setLoading(true);
											}
											setError(!confirmDownload);
										}}
										className="outlineButton planButton"
									>
										{data.shopitemPriceExclTax == '0.00'
											? 'Activeer nu'
											: 'Koop nu'}
									</button>
								) : (
									<a
										href={data.shopitemVendorUrl}
										target="_blank"
										rel="noopener noreferrer"
										className="outlineButton inlineBlock"
										onClick={closePopup}
									>
										{data.shopitemPriceExclTax == '0.00'
											? `Activeer bij ${data.shopitemVendorName}`
											: `Kopen bij ${data.shopitemVendorName}`}
									</a>
								))}

							{data.shopitemPurchaseStatus === 'purchased' &&
								data.shopitemType === '1' &&
								data.shopitemDownload && (
									<a
										target="_blank"
										onClick={closePopup}
										rel="noopener noreferrer"
										className="outlineButton inlineBlock"
										href={data.shopitemDownload}
									>
										downloaden
									</a>
								)}
						</div>
					</>
				) : (
					<Loader />
				)}
			</div>
			<span className="overlayBackground" />
		</div>
	);
};

export default ProductPopup;

import React, { Fragment } from 'react';
// @import packages
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// @import components
import Student from './student';
import DevelopmentSteps from './developmentSteps';
// @import utils
import { axiosPost } from '../utils/axiosCall';
import {
	lessonseen_endpoint,
	routine_lessonseen_endpoint
} from '../constants/endpoints';
// @import images
import { ReactComponent as Eye } from '../../images/svg/eye.svg';
import { ReactComponent as Pencil } from '../../images/svg/edit.svg';
import { ReactComponent as CloseEye } from '../../images/svg/close_eye.svg';

const LessonDetail = ({
	token,
	className = null,
	stepdescriptions,
	studentsList,
	lessonName,
	lessonId,
	onDragEnd,
	handleRefresh,
	handleStudentClick,
	type = 'normal'
}) => {
	const handleEyeStatus = studentId => {
		const dataForm = new FormData();
		if (type === 'routeen') {
			dataForm.append('routinelessonId', lessonId);
		} else {
			dataForm.append('lessonId', lessonId);
		}
		dataForm.append('studentId', studentId);

		axiosPost(
			type === 'routeen' ? routine_lessonseen_endpoint : lessonseen_endpoint,
			dataForm,
			token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					handleRefresh();
				}
			}
		);
	};

	return (
		<div
			id={type === 'routeen' ? 'routeenLessonPlan' : null}
			className={`observationWrapper ${className ? className : ''}`}
		>
			{stepdescriptions && (
				<Fragment>
					<h3 className="sectionTitle">{lessonName}</h3>

					<section className="developmentSteps">
						{stepdescriptions &&
							stepdescriptions.map((item, i) => (
								<div className="column" key={i}>
									<DevelopmentSteps
										name={
											item.step === 'E'
												? 'Ontwikkelstappen afgerond'
												: `Ontwikkelstap ${item.step}`
										}
										description={item.description.replace(
											/#KIND/gi,
											'Het kind'
										)}
									/>
								</div>
							))}
					</section>

					<section className="groupLayout">
						<div className="flexWrapper">
							<DragDropContext onDragEnd={onDragEnd} type="BOARD">
								{studentsList &&
									Object.keys(studentsList).map((items, i) => (
										<Droppable
											droppableId={
												type === 'routeen'
													? `routeen_${items}`
													: `normal_${items}`
											}
											key={i}
											type="COLUMN"
										>
											{(provided, snapshot) => (
												<div
													className="column"
													ref={provided.innerRef}
													{...provided.droppableProps}
													style={{
														backgroundColor: snapshot.isDraggingOver
															? '#e1e1e1'
															: '#fff'
													}}
												>
													{studentsList[items] &&
														studentsList[items].map((item, i) => {
															return (
																<Draggable
																	draggableId={item.studentId}
																	index={i}
																	key={item.studentId}
																>
																	{provided => (
																		<div
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}
																			onClick={e => {
																				if (e.target.closest('.iconWrap')) {
																					return;
																				}
																				handleStudentClick(
																					items,
																					item.studentId,
																					type === 'routeen'
																						? 'routeen'
																						: 'normal'
																				);
																			}}
																			className="draggableWrapper"
																		>
																			<Student
																				key={i}
																				size={40}
																				age={item.studentDisplayage}
																				studentId={item.studentId}
																				name={item.studentDisplayname}
																				imagepath={item.studentPicture}
																				studentPerformance={
																					item.studentPerformance
																				}
																				studentPercentageInStep={
																					item.studentPercentageInStep
																				}
																			/>

																			<div className="iconWrap">
																				{item.eyestatus == '1' ? (
																					<Eye
																						width={17}
																						height={17}
																						onClick={() => {
																							handleEyeStatus(item.studentId);
																						}}
																					/>
																				) : (
																					<CloseEye
																						width={17}
																						height={17}
																						className="eyeclose"
																						onClick={() => {
																							handleEyeStatus(item.studentId);
																						}}
																					/>
																				)}

																				{item.studentNotification ===
																					'commented' && (
																					// <div className="iconWrap">
																					<Pencil width={12} height={12} />
																					// </div>
																				)}
																			</div>
																		</div>
																	)}
																</Draggable>
															);
														})}
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									))}
							</DragDropContext>
						</div>
					</section>
				</Fragment>
			)}
		</div>
	);
};

export default LessonDetail;

import React from 'react';
// @import packages
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
// @import components
import App from './App';
import routes, { coordinatorRoutes, parentsRoutes } from './config/routes';
import * as serviceWorker from './serviceWorker';
// @import styles
import './index.scss';
import './css/components/breadcrumbs/index.scss';
import store from './store';
ReactDOM.render(
	<CookiesProvider>
		<Provider store={store}>
			<Router>
				<App
					routes={routes}
					coordinatorRoutes={coordinatorRoutes}
					parentsRoutes={parentsRoutes}
				/>
			</Router>
		</Provider>
	</CookiesProvider>,
	document.getElementById('root')
);

if (module.hot) {
	module.hot.accept();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import axios from 'axios';
import * as tus from 'tus-js-client';

const auth_token = '5899233f577deb0b0ee9a9a1bdb05db9';

export const headerConfig = (accessToken = auth_token) => {
	return { Authorization: `Bearer ${accessToken}` };
};

const patchConfig = (accessToken = auth_token, offset = 0) => {
	return {
		Authorization: `Bearer ${accessToken}`,
		'Tus-Resumable': '1.0.0',
		'Upload-Offset': offset,
		'Content-Type': 'application/offset+octet-stream',
		Accept: 'application/vnd.vimeo.*+json;version=3.4'
	};
};

export const createVideoBody = (size, name) => ({
	upload: {
		approach: 'tus',
		size: size
	},
	name: name
});

// get list of videos
export const getVimeoList = (
	callback,
	// folderId = '3392617',
	folderId = null,
	fields = 'link,uri,name,pictures',
	accessToken = auth_token,
	config = headerConfig(accessToken)
) => {
	// these end points can be use to fetch videos from folder path: 'me/projects/3392617/videos', 'me/folders/3392617/videos'
	const url = folderId
		? `https://api.vimeo.com/me/folders/${folderId}/videos`
		: 'https://api.vimeo.com/me/videos';

	axios
		.get(url, {
			headers: config,
			params: {
				fields: fields
			}
		})
		.then(response => {
			callback(response.data);
		})
		.catch(error => {
			console.log('getvimeolist', error);
		});
};

// get list of videos against list of video ids
export const getVimeoVideos = (
	idList,
	callback,
	fields = 'link,uri,name',
	accessToken = auth_token,
	config = headerConfig(accessToken)
) => {
	const uriList = [];
	idList &&
		idList.length > 0 &&
		idList.forEach(id => {
			uriList.push(`/videos/${id}`);
		});
	const url = `https://api.vimeo.com/videos?uris=${uriList}`;
	axios
		.get(url, {
			headers: config,
			params: {
				fields: fields
			}
		})
		.then(response => {
			callback(response.data);
		})
		.catch(error => {
			console.log('getvimeolist', error);
		});
};

// get specific video
export const getVimeoVideo = (
	video_id,
	callback,
	fields = 'link,uri,name',
	accessToken = auth_token,
	config = headerConfig(accessToken)
) => {
	const url = `https://api.vimeo.com/videos/${video_id}`;

	axios
		.get(url, {
			headers: config,
			params: {
				fields: fields
			}
		})
		.then(response => {
			callback(response.data);
		})
		.catch(error => {
			console.log('getvimeolist', error);
		});
};

// upload video thumbnail:
export const uploadVimeoThumbnail = (video_id, body, callback) => {
	// https://developer.vimeo.com/api/upload/thumbnails#creating-a-thumbnail
	const reader = new FileReader();
	reader.readAsArrayBuffer(body);
	reader.onload = r => {
		getVimeoVideo(
			video_id,
			getResponse => {
				const {
					metadata: {
						connections: {
							pictures: { uri }
						}
					}
				} = getResponse;
				axios({
					method: 'post',
					url: `https://api.vimeo.com${uri}`,
					headers: {
						Authorization: `Bearer ${auth_token}`,
						Accept: 'application/vnd.vimeo.*+json;version=3.4'
					}
				}).then(createResponse => {
					const { link, uri } = createResponse.data;
					axios({
						method: 'put',
						url: link,
						data: body,
						headers: {
							'Content-Type': 'image/png',
							Accept: 'application/vnd.vimeo.*+json;version=3.4'
						}
					}).then(() => {
						axios({
							method: 'patch',
							url: `https://api.vimeo.com${uri}`,
							data: { active: true },
							headers: {
								Authorization: `Bearer ${auth_token}`,
								'Content-Type': 'application/json',
								Accept: 'application/vnd.vimeo.*+json;version=3.4'
							}
						}).then(patchResponse => {
							callback(patchResponse);
						});
					});
				});
			},
			'metadata'
		);
	};
};

// create video thumbnail: not in use
export const createVimeoThumbnail = (video_id, callback) => {
	const url = `https://api.vimeo.com/videos/${video_id}/pictures`;
	axios({
		method: 'post',
		url: url,
		data: { time: '1', active: true },
		headers: {
			Authorization: `Bearer ${auth_token}`,
			'Content-Type': 'application/json',
			Accept: 'application/vnd.vimeo.*+json;version=3.4'
		}
	})
		.then(response => {
			callback(response);
		})
		.catch(error => {
			console.log('Error: create thumbnail error', error.response);
		});
};

// create video request on vimeo
export const createVimeoVideo = (url, body, callback) => {
	axios
		.post(url, body, {
			headers: headerConfig()
		})
		.then(response => {
			callback(response);
		})
		.catch(error => {
			console.log('create video', error);
		});
};

// patch video to vimeo: not in use
export const patchVimeoVideo = (url, body, progressCallback, callback) => {
	axios({
		method: 'patch',
		url: url,
		data: body,
		headers: patchConfig()
	})
		.then(response => {
			callback(response);
		})
		.catch(error => {
			console.log('Error: patch video error', error.response);
		});
	const getProgress = () => {
		calculateUploadProgess(url, progressResponse => {
			console.log(
				progressResponse,
				progressResponse.headers['upload-length'],
				progressResponse.headers['upload-offset']
			);
			if (
				progressResponse.headers['upload-length'] !==
				progressResponse.headers['upload-offset']
			) {
				getProgress();
			}
		});
	};
	getProgress();
};

// calculate upload progress: not in use
export const calculateUploadProgess = (url, callback) => {
	axios({
		method: 'head',
		url: url,
		headers: {
			Authorization: `Bearer ${auth_token}`,
			'Tus-Resumable': '1.0.0',
			Accept: 'application/vnd.vimeo.*+json;version=3.4'
		}
	})
		.then(response => {
			callback(response);
		})
		.catch(error => {
			console.log('Error: head video', error.response);
		});
};

// move video to specific folder
export const moveVimeoVideo = (videoId, folderId = '3392617', callback) => {
	const url = `https://api.vimeo.com/me/projects/${folderId}/videos/${videoId}`;
	axios
		.put(url, null, {
			headers: headerConfig()
		})
		.then(res => {
			callback(res);
		})
		.catch(error => {
			console.log('move video', error.response);
		});
};

// replace video in vimeo
export const replaceVimeoVideo = (video_id, filename, size, callback) => {
	const url = `https://api.vimeo.com/videos/${video_id}/versions?fields=link,uri,upload`;
	axios
		.post(
			url,
			{
				file_name: filename,
				upload: { status: 'in_progress', approach: 'tus', size: size }
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/vnd.vimeo.*+json;version=3.4',
					Authorization: `Bearer ${auth_token}`
				}
			}
		)
		.then(response => {
			callback(response);
		})
		.catch(error => {
			console.log('create video', error);
		});
};

// upload video to vimeo folder
export const uploadVideoToVimeo = (
	url,
	body,
	file,
	progressCallback,
	successCallback
) => {
	createVimeoVideo(url, body, createResponse => {
		const {
			link,
			uri,
			upload: { upload_link }
		} = createResponse.data;
		const uriLink = link.split('/');
		console.log('uriLink', uriLink);
		const videoHash = uriLink[uriLink.length - 1];
		const videoId = uriLink[uriLink.length - 2];
		console.log('uriLink', videoHash);
		moveVimeoVideo(videoId, '3392617', moveResponse => {
			let success = false;
			const client = new tus.Upload(file, {
				uploadUrl: upload_link,
				uploadSize: file.size,
				headers: {
					Authorization: `Bearer ${auth_token}`,
					Accept: 'application/vnd.vimeo.*+json;version=3.4'
				},
				onError: function (error) {
					console.log('Failed because: ' + error);
				},
				onProgress: function (bytesUploaded, bytesTotal) {
					var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(0);
					if (percentage === '100' && !success) {
						success = true;
						progressCallback(percentage);
					} else if (!success && percentage !== '100') {
						progressCallback(percentage);
					}
				},
				onSuccess: () => {
					successCallback(videoId, videoHash);
				}
			});
			client.start();
		});
	});
};

// edit vimeo video process
export const editVimeoVideo = (
	video_id,
	video_hash,
	filename,
	file,
	thumbnail,
	progressCallback,
	successCallback
) => {
	uploadVimeoThumbnail(video_id, thumbnail, () => {
		replaceVimeoVideo(video_id, filename, file.size, replaceResponse => {
			const {
				upload: { upload_link }
			} = replaceResponse.data;
			let success = false;
			const client = new tus.Upload(file, {
				uploadUrl: upload_link,
				uploadSize: file.size,
				headers: {
					Authorization: `Bearer ${auth_token}`,
					Accept: 'application/vnd.vimeo.*+json;version=3.4'
				},
				onError: function (error) {
					console.log('Failed because: ' + error);
				},
				onProgress: function (bytesUploaded, bytesTotal) {
					var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(0);
					if (percentage === '100' && !success) {
						success = true;
						progressCallback(percentage);
					} else if (!success && percentage !== '100') {
						// console.log(percentage);
						progressCallback(percentage);
					}
				},
				onSuccess: () => {
					// createVimeoThumbnail(video_id, res => {
					successCallback(video_id, video_hash);
					// });
				}
			});
			client.start();
		});
	});
};

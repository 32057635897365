// @import packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { TweenMax } from 'gsap/TweenMax';
import { isMobile } from 'react-device-detect';
// @import constants
import { grouplist_endpoint } from '../../constants/endpoints';
// @import Components
import GroupAddEditPopup from '../../components/popup/groupAddEditPopup';
// @import Styles
import '../../../css/containers/manageStudents.scss';
// @import svgs
import { ReactComponent as Edit } from '../../../images/svg/edit.svg';
import { ReactComponent as Add } from '../../../images/svg/add.svg';
// @import actions
import { sendErrorToHOC } from '../../../actions/actions';
// @import utils
import { axiosGet } from '../../utils/axiosCall';

export class ManageGroups extends Component {
	constructor(props) {
		super(props);
		this.state = {
			teacherList: null,
			isLoading: true,
			openPopup: false
		};
	}

	componentDidMount() {
		if (this.props.token) {
			this.getGroupList();
		}
	}

	getGroupList = () => {
		axiosGet(grouplist_endpoint, this.props.token, axiosResult => {
			this.setState({
				groupsList:
					axiosResult.status === 'no such data'
						? 'nodata'
						: axiosResult.data.groups,
				isLoading: false
			});
		});
	};

	handleEdit = data => {
		this.setState(
			{
				openPopup: true,
				popupname: 'edit',
				data
			},
			() => {
				TweenMax.to('.popupWrapper', 0.3, {
					autoAlpha: 1
				});
			}
		);
	};

	openAddStudentPopup = () => {
		this.setState(
			{
				openPopup: true,
				popupname: 'add',
				data: null
			},
			() => {
				TweenMax.to('.popupWrapper', 0.3, {
					autoAlpha: 1
				});
			}
		);
	};

	closePopup = () => {
		TweenMax.to('.popupWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setTimeout(() => {
					this.setState({
						openPopup: false
					});
				}, 200);
			}
		});
	};

	render() {
		return (
			<Fragment>
				<div className="pageContainer manageStudents">
					<div className="studentListWrapper">
						{this.state.groupsList &&
							this.state.groupsList !== 'nodata' &&
							this.state.groupsList.map((item, i) => (
								<div
									className={`studentBox ${isMobile ? 'showIcon' : ''}`}
									key={i}
								>
									{item.groupSelectable !== '0' && (
										<button
											className="editButton"
											onClick={() => this.handleEdit(item)}
										>
											<Edit />
										</button>
									)}
									<div className="aspectContainer">
										<div className="addStudent groupWrap">
											<strong>{item.groupName}</strong>
											<span>{item.studentCount} leerlingen</span>
										</div>
									</div>
								</div>
							))}
						{!this.state.isLoading && (
							<div className="studentBox">
								<div className="aspectContainer">
									<button
										className="addStudent"
										onClick={this.openAddStudentPopup}
									>
										<Add />
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
				{this.state.openPopup && (
					<GroupAddEditPopup
						name={this.state.popupname}
						data={this.state.data}
						hidepopup={this.closePopup}
						token={this.props.token}
						updateGroup={this.getGroupList}
						userMode={this.props.userData.userMode}
					/>
				)}
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
	userData: state.initialData.userData
});

export default connect(mapStateToProps, { sendErrorToHOC })(ManageGroups);

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// @import components
import BreadCrumb from './breadcrumb';
import Rating from '../../components/rating';
import PlaceHolderLoader from '../../components/placeHolderLoader';
// @import endpoints
import { academycourse_endpoint } from '../../constants/endpoints';
// @import utils
import { axiosPost } from '../../utils/axiosCall';
import { currency } from '../../utils/currency';
import { courseColor } from '../../utils/courseColor';
// @import styles
import '../../../css/containers/academy/courseOverview.scss';

export class AcademyCourseOverview extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			courses: null
		};
	}
	componentDidMount() {
		this.getInitialData();
	}
	getInitialData = () => {
		const dataForm = new FormData();
		axiosPost(
			academycourse_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState({
						isLoading: false,
						courses: axiosResult.data.courses
					});
				}
			}
		);
	};
	render() {
		return (
			<div className="mainContainer academyWrapper">
				<BreadCrumb
					routes={[
						{
							name: 'Overzicht opleidingen',
							path: '/academie/opleiding'
						}
					]}
				/>
				<div className="pageContainer">
					{this.state.isLoading ? (
						<PlaceHolderLoader
							type="lesson"
							rows={1}
							columns={8}
							outerClass="lessonLayout"
							innerClass="flexWrapper"
						/>
					) : (
						<div className="academyOverview">
							{this.state.courses.length > 0 &&
								this.state.courses.map(course => {
									const courseCategory = courseColor(course.acourseColourcode);
									return (
										<div
											key={`course_${course.acourseId}`}
											className={`courseWrapper ${courseCategory}`}
										>
											<div className="wrapper">
												<img
													src={course.acourseImage}
													srcSet={`${course.acourseImage} 2x`}
													alt={course.acourseName}
												/>
												<div className="contentSection">
													<Link to={course.acourseDetailsSlug}>
														<h2 className="headingStyle">
															{course.acourseName}
														</h2>
													</Link>
													<div className="columns">
														<span>
															<b>Studietijd:</b> {course.acourseEffort}
														</span>
														<span>
															<b>Doelgroep:</b> {course.acourseAudience}
														</span>
														<span>
															<b>Niveau:</b> {course.acourseLevel}
														</span>
														<span>
															<b>Kosten:</b>{' '}
															{course.acourseCost === '0'
																? 'gratis'
																: currency.format(course.acourseCost)}
														</span>
													</div>
													<div className="ratingWrapper">
														<div></div>
														<Link
															to={course.acourseDetailsSlug}
															className="outlineButton inlineBlock"
														>
															{course.acourseStatus === 'not started' &&
																'Bekijk'}
															{course.acourseStatus === 'in progress' &&
																'Ga verder'}
															{course.acourseStatus === 'done' && 'Al afgerond'}
														</Link>
													</div>
												</div>
											</div>
										</div>
									);
								})}
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token
});

export default connect(mapStateToProps, null)(AcademyCourseOverview);

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { TweenMax } from 'gsap/TweenMax';
import { connect } from 'react-redux';
// @import components
import BreadCrumb from './breadcrumb';
import CommentSection from './commentSection';
import EmbedCanvaYoutube from '../../components/EmbedCanvaYoutube';
import YoutubeVideoPopup from '../../components/popup/youtubeVideoPopup';
// @import endpoints
import {
	academychapterdetails_endpoint,
	academychapterstatus_endpoint
} from '../../constants/endpoints';
// @import utils
import { axiosPost } from '../../utils/axiosCall';
import { courseColor } from '../../utils/courseColor';
// @import actions
import { getChaptersHistory } from '../../../actions/academyActions';
// @import styles
import '../../../css/containers/academy/chapterDetails.scss';

const ChapterDetails = props => {
	const {
		match: { params },
		token
	} = props;

	const [course, setCourse] = useState(null);
	const [chapter, setChapter] = useState(null);
	const [showPopup, setPopupVisibility] = useState(false);
	const [youtubeTubeUrl, setyoutubeTubeUrl] = useState(null);

	useEffect(() => {
		if (course === null) {
			getChapter();
		}
	}, [course === null]);

	const getChapter = () => {
		const dataForm = new FormData();
		dataForm.append('courseSlug', params.coursename);
		dataForm.append('chapterNumber', params.chapter);
		axiosPost(academychapterdetails_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				setCourse(axiosResult.data.course);
				setChapter(axiosResult.data.course.chapter[0]);
			}
		});
	};

	const showYoutubePopup = url => {
		setPopupVisibility(true);
		setyoutubeTubeUrl(url);
		setTimeout(() => {
			TweenMax.to('.popupWrapper', 0.3, {
				autoAlpha: 1
			});
		}, 200);
	};

	const hideYoutubePopup = () => {
		TweenMax.to('.popupWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setTimeout(() => {
					setPopupVisibility(false);
					setyoutubeTubeUrl(null);
				}, 200);
			}
		});
	};

	const handleChapterStatus = value => {
		const dataForm = new FormData();
		dataForm.append('courseId', course.acourseId);
		dataForm.append('chapterNumber', chapter.chapterNumber);
		dataForm.append('newStatus', value);
		axiosPost(academychapterstatus_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				props.getChaptersHistory(token);
				props.history.push(axiosResult.data.redirectto);
			}
		});
	};

	const breadcrumbRoutes = [
		{
			name: 'Opleiding',
			path: '/academie/opleiding'
		},
		{
			name: course ? course.acourseName : params.coursename,
			path: `/academie/opleiding/${params.coursename}`
		},
		{
			name: `Hoofdstuk ${params.chapter}`,
			path: `/academie/opleiding/${params.coursename}/${params.chapter}`
		}
	];

	// console.log('chapter: ', chapter, 'course: ', course);

	return (
		<div className="mainContainer academyChapter">
			<BreadCrumb routes={breadcrumbRoutes} />
			<div className="pageContainer">
				{course && (
					<div
						key={`course_${course.acourseId}`}
						className={`chapterDetailsWrapper ${courseColor(
							course.acourseColourcode
						)}`}
					>
						<div className="wrapper">
							{chapter && (
								<>
									<div className="flexBlock">
										<img
											className="chapterImage"
											src={course.acourseImage}
											srcSet={`${course.acourseImage} 2x`}
											alt={chapter.achapterTitle}
										/>
										<div className="contentSection">
											<h1 className="headingStyle">{`${chapter.chapterNumber}. ${chapter.achapterTitle}`}</h1>
											<div
												className="introContent"
												dangerouslySetInnerHTML={{
													__html: chapter.achapterIntroduction
												}}
											/>
										</div>
									</div>
									<div className="chapterSection">
										{(chapter.achapterIntroMedia1Url ||
											chapter.achapterIntroMedia2Url) && (
											<div className="chapterIntroAttachments">
												{chapter.achapterIntroMedia1Type === 'file' &&
													chapter.achapterIntroMedia1Url && (
														<div className="item">
															<img
																src={chapter.achapterIntroMedia1Url.replace(
																	/(\.[^.]+)$/,
																	'-1000x500$1'
																)}
																srcSet={`${chapter.achapterIntroMedia1Url.replace(
																	/(\.[^.]+)$/,
																	'-2000x1000$1'
																)} 2x`}
																alt={'chapterIntroMedia1'}
															/>
														</div>
													)}
												{chapter.achapterIntroMedia1Type === 'link' &&
													chapter.achapterIntroMedia1Url && (
														<div className="item">
															<EmbedCanvaYoutube
																source={chapter.achapterIntroMedia1Url}
															/>
														</div>
													)}
												{chapter.achapterIntroMedia2Type === 'file' &&
													chapter.achapterIntroMedia2Url && (
														<div className="item">
															<img
																src={chapter.achapterIntroMedia2Url.replace(
																	/(\.[^.]+)$/,
																	'-1000x500$1'
																)}
																srcSet={`${chapter.achapterIntroMedia2Url.replace(
																	/(\.[^.]+)$/,
																	'-2000x1000$1'
																)} 2x`}
																alt={'chapterIntroMedia2'}
															/>
														</div>
													)}
												{chapter.achapterIntroMedia2Type === 'link' &&
													chapter.achapterIntroMedia2Url && (
														<div className="item">
															<EmbedCanvaYoutube
																source={chapter.achapterIntroMedia2Url}
															/>
														</div>
													)}
											</div>
										)}
										{chapter.chapterMedia && (
											<div className="flexBlock flexColumn">
												{chapter.chapterMedia.map((item, i) => {
													let attr;
													if (
														item.mediaType === 'file' ||
														item.mediaType === 'url'
													) {
														attr = {
															target: '__blank'
														};
													}
													if (item.mediaType === 'youtube') {
														attr = {
															onClick: e => {
																e.preventDefault();
																showYoutubePopup(item.mediaUrl);
															}
														};
													}
													return (
														<div className="descRow">
															<div
																className="introContent"
																dangerouslySetInnerHTML={{
																	__html: item.mediaDescription
																}}
															/>
															<a
																href={item.mediaUrl}
																key={i}
																{...attr}
																className="pillButton"
															>
																{item.mediaTitle}
															</a>
														</div>
													);
												})}
											</div>
										)}
									</div>
								</>
							)}
							{chapter && chapter.achapterCommentsEnabled === '1' && (
								<CommentSection
									token={token}
									updateChapter={getChapter}
									courseId={course.acourseId}
									data={chapter.chapterMessages}
									chapterNumber={chapter.chapterNumber}
									status={chapter.achapterstatusStatus}
								/>
							)}
							{chapter && chapter.achapterstatusStatus === 'done' && (
								<button
									type="button"
									onClick={() => handleChapterStatus('in progress')}
									className="outlineButton statusBtn"
								>
									Hoofdstuk heropenen
								</button>
							)}
							{chapter && chapter.achapterstatusStatus === 'in progress' && (
								<div className="rowWrapper">
									<h3>Hoofdstuk afsluiten</h3>
									<p>
										Om je voortgang vast te leggen kun je een hoofdstuk dat je
										hebt afgerond afsluiten. Je sluit dan eventuele communicatie
										met je opleidingsfacilitator af, maar je kunt het
										lesmateriaal van het hoofdstuk nog gewoon zien en gebruiken.
										Mocht je je later bedenken dan kun je het hoofdstuk weer
										heropenen.
									</p>
									<button
										type="done"
										onClick={() => handleChapterStatus('done')}
										className="outlineButton handleStatus"
									>
										Hoofdstuk afgerond
									</button>
								</div>
							)}
						</div>
					</div>
				)}
			</div>
			{showPopup && (
				<YoutubeVideoPopup closePopup={hideYoutubePopup} url={youtubeTubeUrl} />
			)}
		</div>
	);
};

const mapStateToProps = state => ({
	token: state.initialData.token
});

export default withRouter(
	connect(mapStateToProps, { getChaptersHistory })(ChapterDetails)
);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
// @import constants
import { app_name } from 'js/constants/endpoints';
// @import components
import BreadCrumb from './breadcrumb';
// @import styles
import '../../../css/containers/academy/main.scss';
export class AcademyIntroduction extends Component {
	render() {
		const { showintroduction } = this.props;
		// console.log(showintroduction);
		if (showintroduction === 0) {
			return <Redirect to="/academie/opleiding" />;
		}
		return (
			<div className="mainContainer academyWrapper">
				<BreadCrumb />
				<div className="pageContainer">
					<div className="academyIntro">
						<h1 className="headingStyle">Leren wanneer het jou uitkomt!</h1>
						{app_name === 'onderbouwdonline' && (
							<p>
								Heel veel scholen gebruiken Onderbouwd Online al enige tijd. De
								ontwikkelingen gaan echter razendsnel. Volg je eigen
								studietraject via de Onderbouwd Academie:
							</p>
						)}
						{app_name === 'kleuterwijzer' && (
							<p>
								De ontwikkelingen in je vakgebied gaan razendsnel. Blijf bij en
								volg je eigen studietraject via de Kleuterwijzer Academie:
							</p>
						)}
						<ul>
							<li>Startersopleidingen</li>
							<li>Verrijkingsopleidingen</li>
							<li>Masteropleiding</li>
						</ul>
						<p>
							Elke opleiding wordt begeleid door gecertificeerde docenten en
							heeft zijn eigen studiebelasting. Wanneer de opleiding met goed
							gevolg is afgerond wordt er een certificaat verstrekt. De lerende
							heeft per opleiding recht op een telefonisch consult en kan
							eventuele andere vragen stellen via de academie. In principe kun
							je elk moment van het jaar starten met de opleiding van je keuze.
							De betaling is voorafgaand aan je opleiding. Na inschrijving heb
							je 10 maanden de tijd om je opleiding af te ronden. Al je
							ingeleverde werk en filmmateriaal wordt vertrouwelijk behandeld en
							valt onder de AVG-wetgeving.
						</p>

						<div className="buttonsWrapper">
							<Link
								to="/academie/opleiding"
								className="inlineBtn outlineButton"
							>
								Start
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	showintroduction: state.initialData.showintroduction
});

export default connect(mapStateToProps, null)(AcademyIntroduction);

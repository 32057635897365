import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
// @import utils
import { courseColor } from '../../utils/courseColor';
// @import styles
import '../../../css/containers/academy/chapterProgress.scss';

const ChapterProgress = ({ course }) => {
	const courseCategory = courseColor(course.acourseColourcode);
	return (
		<div className={`courseChapter courseWrapper ${courseCategory}`}>
			<div className="wrapper">
				<div className="intro">
					<img
						src={course.acourseImage.replace(/(\.[^.]+)$/, '-90x90$1')}
						srcSet={`${course.acourseImage.replace(
							/(\.[^.]+)$/,
							'-180x180$1'
						)} 2x`}
						alt={course.acourseName}
					/>
					<div className="flexColumn">
						<Link to={course.acourseDetailsSlug}>
							<strong>{course.acourseName}</strong>
						</Link>
						<span className="progress">
							<span
								className="fill"
								style={{ width: `${course.acourseProgress}%` }}
							>
								{course.acourseProgress > 10 && `${course.acourseProgress}%`}
							</span>
						</span>
					</div>
				</div>
				<ol>
					{course.achapters
						.filter(chapter => chapter.achapterActive === '1')
						.map(chapter => (
							<li key={`chapter_${chapter.chapterNumber}`}>
								{chapter.chapterStatus === 'done' ||
								chapter.chapterStatus === 'in progress' ? (
									<Link to={chapter.achapterSlug}>{chapter.chapterTitle}</Link>
								) : (
									<span>{chapter.chapterTitle}</span>
								)}
							</li>
						))}
				</ol>
				<p>
					Deze opleiding verloopt op{' '}
					{moment(new Date(course.acourseExpirydate)).format('DD MMMM YYYY')}.
				</p>
			</div>
		</div>
	);
};

export default ChapterProgress;

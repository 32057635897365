// @import packages
import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { TweenMax } from 'gsap/TweenMax';
import { NavLink } from 'react-router-dom';
import localization from 'moment/locale/nl';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// @import constants
import {
	reportlist_endpoint,
	createreport_endpoint,
	deletereport_endpoint,
	downloadreport_endpoint,
	getTeacherComment_endpoint,
	saveTeacherComment_endpoint,
	reportparentvisible_endpoint
} from 'js/constants/endpoints';
// @import actions
import { setStudentList, sendErrorToHOC } from 'actions/actions';
// @import components
import MediaListPopup from 'js/models/mediaListPopup';
import ErrorPopup from 'js/components/popup/ErrorPopup';
// @import placeholder
import TextLinePlaceholder from 'js/components/placeholder/textLine';
// @import svgs
import { ReactComponent as Add } from 'images/svg/add.svg';
import { ReactComponent as Trash } from 'images/svg/trash.svg';
import { ReactComponent as OpenEye } from 'images/svg/eye.svg';
import { ReactComponent as CloseEye } from 'images/svg/close_eye.svg';
import { ReactComponent as Cross } from 'images/svg/cross.svg';
import Loader from 'images/loader.gif';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
import { breadCrumbRoute } from 'js/utils/studentBreadCrumbs';
// @import styles
import 'css/containers/studentReportWrapper.scss';

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};
export class StudentGenerateReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			downloadRequested: null,
			currentStudent: null,
			isWaiting: false,
			loaderRequested: false,
			teacherComment: '',
			generateReport: false,
			success: '',
			errorMessage: '',
			mediaPopup: false,
			selectedPictures: [],
			teacherstodisplay: null,
			selectedTeachers: [],
			downloadError: ''
		};

		this.popupref = null;
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	componentDidMount() {
		if (this.props.studentList) {
			this.setInitialStates('didmount');
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.studentList !== this.props.studentList) {
			this.setInitialStates('didupdate-student');
		}

		if (
			prevState.generateReport !== this.state.generateReport &&
			!this.state.generateReport &&
			this.reportgenerated &&
			this.reportgenerated.length > 0
		) {
			this.setInitialStates('didupdate');
		}
	}

	setInitialStates = calledfrom => {
		let { groupname } = this.props.match.params;
		groupname = groupname.replace(/_/g, ' ');

		const getGroup = this.props.groupList.groups.find(
			x =>
				x.groupName.replace(/_/g, ' ').replace(/\//g, ' ').toLowerCase() ===
				groupname
		);

		this.props.setStudentList(
			getGroup.groupId,
			getGroup.groupName,
			getGroup.students
		);

		const getStudent = getGroup.students.find(
			x =>
				x.studentDisplayname.replace(/_/g, ' ').toLowerCase() ===
				this.props.match.params.studentname.replace(/_/g, ' ')
		);

		this.setState({
			currentStudent: getStudent,
			studentDisplayname: getStudent.studentDisplayname,
			studentFirstname: getStudent.studentFirstname
		});

		this.getTeacherComment(getStudent.studentId);

		this.getReportOverviewData(getStudent.studentId, this.props.token);
	};

	getReportOverviewData = (studentId, token) => {
		this.setState({
			isLoading: true,
			lessons: null
		});

		const dataForm = new FormData();
		dataForm.append('studentId', studentId);
		axiosPost(reportlist_endpoint, dataForm, this.props.token, axiosResult => {
			if (axiosResult.status === 'success') {
				const {
					reporttitle,
					results,
					lessonstepcomments,
					lessonstepall,
					socialemotional,
					motorical,
					learningstyles,
					textslider,
					teachercomments,
					teacherstodisplay
				} = axiosResult.data.newreportchapters;

				this.setState({
					isLoading: false,
					newreportchapters: axiosResult.data
						? axiosResult.data.newreportchapters
						: 'nodata',
					reporttitle,
					results,
					lessonstepcomments,
					lessonstepall,
					socialemotional,
					motorical,
					learningstyles,
					textslider,
					teachercomments,
					teacherstodisplay,
					reportList: axiosResult.data.reports
				});

				if (!this.state.selectedTeachers.length > 0) {
					const teachersList = teacherstodisplay.map(function (val) {
						return val.teacherId;
					});
					this.setState({
						selectedTeachers: teachersList
					});
				}

				const { reports } = axiosResult.data;
				let reportgenerated = reports.filter(x => {
					return x.studentreportStatus === 'generate';
				});
				if (reportgenerated.length > 0) {
					this.reportgenerated = reportgenerated;
					this.timeout = setTimeout(() => {
						this.setInitialStates('timeout');
					}, 20000);
				} else {
					this.reportgenerated = null;
				}
			}
		});
	};

	handleReportTitleChange = event => {
		this.setState({
			reporttitle: event.target.value
		});
	};

	handleResultsChange = event => {
		this.setState({
			results: event.target.checked ? 'checked' : 'unchecked'
		});
	};

	handleLessonStepCommentsChange = event => {
		this.setState({
			lessonstepcomments: event.target.checked ? 'checked' : 'unchecked'
		});
	};

	handleLessonStepCommentsAllChange = event => {
		this.setState({
			lessonstepcommentsall: event.target.checked ? 'checked' : 'unchecked'
		});
	};

	handleSocialEmotionalChange = event => {
		this.setState({
			socialemotional: event.target.checked ? 'checked' : 'unchecked'
		});
	};

	handleLearningStylesChange = event => {
		this.setState({
			learningstyles: event.target.checked ? 'checked' : 'unchecked'
		});
	};

	handleMotoricalChange = event => {
		this.setState({
			motorical: event.target.checked ? 'checked' : 'unchecked'
		});
	};

	handleTextSliderChange = event => {
		this.setState({
			textslider: event.target.checked ? 'checked' : 'unchecked'
		});
	};

	handleTeacherCommentsChange = event => {
		this.setState({
			teachercomments: event.target.checked ? 'checked' : 'unchecked'
		});
	};

	handleTeacherList = event => {
		let tempArray = [...this.state.selectedTeachers];
		if (tempArray.includes(event.target.value)) {
			tempArray = tempArray.filter(item => item !== event.target.value);
		} else {
			tempArray = [...this.state.selectedTeachers, event.target.value];
		}
		this.setState({
			selectedTeachers: tempArray
		});
	};

	submitrequest = () => {
		const dataForm = new FormData();

		dataForm.append('studentId', this.state.currentStudent.studentId);
		dataForm.append('studentreportTitle', this.state.reporttitle);
		if (this.state.results === 'checked') {
			dataForm.append('results', this.state.results);
		}
		if (this.state.results === 'checked') {
			if (this.state.lessonstepcomments === 'checked') {
				dataForm.append('lessonstepcomments', this.state.lessonstepcomments);
			}
			if (this.state.lessonstepcommentsall === 'checked') {
				dataForm.append(
					'lessonstepcommentsall',
					this.state.lessonstepcommentsall
				);
			}
			if (this.state.textslider === 'checked') {
				dataForm.append('textslider', this.state.textslider);
			}
		}
		if (this.state.socialemotional === 'checked') {
			dataForm.append('socialemotional', this.state.socialemotional);
		}
		if (this.state.learningstyles === 'checked') {
			dataForm.append('learningstyles', this.state.learningstyles);
		}
		if (this.state.motorical === 'checked') {
			dataForm.append('motorical', this.state.motorical);
		}
		if (this.state.teachercomments === 'checked') {
			dataForm.append('teachercomments', this.state.teachercomments);
		}
		if (this.state.selectedPictures.length > 0) {
			const mediaArray = [];
			for (let index = 0; index < this.state.selectedPictures.length; index++) {
				mediaArray.push(this.state.selectedPictures[index].mediaId);
			}
			dataForm.append('media', JSON.stringify(mediaArray));
		}
		dataForm.append(
			'teacherstodisplay',
			JSON.stringify(this.state.selectedTeachers)
		);
		this.setState({
			isWaiting: true,
			loaderRequested: true
		});
		axiosPost(
			createreport_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState(
						{
							isWaiting: false
						},
						() => {
							const loader = document.querySelector('.circleLoader');
							const check = loader.querySelector('.draw');
							loader.classList.add('loadComplete');
							check.classList.add('checkmark');
							TweenMax.to('.formWrapper', 0.3, {
								autoAlpha: 0,
								onComplete: () => {
									this.setState({
										generateReport: false,
										loaderRequested: false,
										selectedPictures: []
									});
									this.setInitialStates('submit');
								}
							});
						}
					);
				} else {
					this.setState({
						isWaiting: false,
						loaderRequested: false,
						selectedPictures: [],
						errorMessage: axiosResult.failreason
					});
				}
			}
		);
	};

	deleteReport = () => {
		const dataForm = new FormData();
		dataForm.append('studentreportId', this.state.studentreportId);

		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		axiosPost(
			deletereport_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState(
						{
							isWaiting: false
						},
						() => {
							const loader = document.querySelector('.circleLoader');
							const check = loader.querySelector('.draw');
							loader.classList.add('loadComplete');
							check.classList.add('checkmark');
							this.setInitialStates('delete');
							this.hideDeletePopup();
						}
					);
				} else {
					this.setState({
						isWaiting: false,
						loaderRequested: false
					});
				}
			}
		);
	};

	downloadReport = studentreportId => {
		const dataForm = new FormData();
		dataForm.append('studentreportId', studentreportId);

		this.setState({
			downloadRequested: studentreportId
		});

		axiosPost(
			downloadreport_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status == 'success') {
					const link = `data:application/pdf;base64,${axiosResult.download.filedata}`;
					var a = document.createElement('a');
					a.setAttribute('download', axiosResult.download.filename);
					a.setAttribute('href', link);
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
				} else {
					this.setState({
						downloadError: axiosResult.failreason
					});
				}
				this.setState({
					downloadRequested: null
				});
			}
		);
	};

	hideDeletePopup = () => {
		TweenMax.to('.deletePopup', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				TweenMax.set('.deleteWrapper', { display: 'none' });
				this.setState({
					loaderRequested: false
				});
			}
		});
	};

	getTeacherComment = studentId => {
		const dataForm = new FormData();
		dataForm.append('studentId', studentId);
		axiosPost(
			getTeacherComment_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState({
						teacherComment: axiosResult.teachercommentsText
					});
				}
			}
		);
	};

	saveTeacherComment = studentId => {
		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		const dataForm = new FormData();
		dataForm.append('studentId', studentId);
		dataForm.append('teachercommentsText', this.state.teacherComment);
		axiosPost(
			saveTeacherComment_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState(
						{
							isWaiting: false
						},
						() => {
							const loader = document.querySelector('.circleLoader');
							const check = loader.querySelector('.draw');
							loader.classList.add('loadComplete');
							check.classList.add('checkmark');
							setTimeout(() => {
								this.setState({
									loaderRequested: false
								});
							}, 500);
						}
					);
				} else {
					this.setState({
						isWaiting: false,
						loaderRequested: false
					});
				}
			}
		);
	};

	handleTeacherComent = event => {
		const teacherComment = event.target.value;
		this.setState({
			teacherComment
		});
	};

	handleParentReportVisibility = reportId => {
		const dataForm = new FormData();
		dataForm.append('studentreportId', reportId);
		axiosPost(
			reportparentvisible_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.getReportOverviewData(
						this.state.currentStudent.studentId,
						this.props.token
					);
				}
			}
		);
	};

	onDragEnd(result) {
		if (!result.destination) {
			return;
		}

		const items = reorder(
			this.state.selectedPictures,
			result.source.index,
			result.destination.index
		);

		this.setState({
			selectedPictures: items
		});
	}

	closePopup = () => {
		this.setState({
			mediaPopup: false
		});
	};

	render() {
		return (
			<div className="mainContainer">
				{this.state.currentStudent ? (
					<nav className="breadCrumbMenu">
						<ul id="breadCrumb">
							<li>
								<span>{this.state.currentStudent.studentDisplayname}</span>
							</li>
							<li>
								{breadCrumbRoute.map((item, i) => (
									<NavLink
										to={`/leerling/${this.props.groupName
											.replace(/ /g, '_')
											.replace(/\//g, '_')
											.toLowerCase()}/${this.state.currentStudent.studentDisplayname
											.replace(/ /g, '_')
											.toLowerCase()}${item.slug}`}
										key={i}
										exact
										className={
											this.props.location.pathname.includes(
												item.name.replace(/ /g, '-').toLowerCase()
											)
												? 'active'
												: ''
										}
									>
										{item.name}
									</NavLink>
								))}
								<NavLink
									to={`/${this.props.groupName
										.replace(/ /g, '_')
										.replace(/\//g, '_')
										.toLowerCase()}/${this.state.currentStudent.studentDisplayname
										.replace(/ /g, '_')
										.toLowerCase()}/leerlingportfolio`}
									exact
									className={
										this.props.location.pathname.includes('leerlingportfolio')
											? 'active'
											: ''
									}
								>
									Leerlingportfolio
								</NavLink>
							</li>
						</ul>
					</nav>
				) : (
					<TextLinePlaceholder margin="0 0 10px" height="39px" />
				)}

				<div className="pageContainer studentReportWrapper">
					{this.state.currentStudent && (
						<div className="studentIntroSec">
							<img
								src={this.state.currentStudent.studentPicture.replace(
									/(\.[^.]+)$/,
									'-100x100$1'
								)}
								srcSet={`${this.state.currentStudent.studentPicture.replace(
									/(\.[^.]+)$/,
									'-200x200$1'
								)} 2x`}
								alt={this.state.currentStudent.studentDisplayname}
							/>
							<div>
								<h1>
									{this.state.currentStudent.studentDisplayname} -
									Leerlingportfolio
								</h1>
								{this.state.currentStudent.studentDisplayage && (
									<span>{this.state.currentStudent.studentDisplayage}</span>
								)}
							</div>
						</div>
					)}
					<h2 className="headingStyle">Leerlingportfolio</h2>
					{!this.state.generateReport ? (
						<div className="flexWrapper">
							<section className="reportListWrapper">
								<div className="titleBar">
									<h2 className="headingStyle">Overzicht</h2>
									<button
										type="button"
										className="defaultBtn addReport"
										onClick={() => {
											this.setState(
												{
													generateReport: true
												},
												() => {
													clearTimeout(this.timeout);
												}
											);
										}}
									>
										<Add />
									</button>
								</div>

								<div className="row header">
									<div className="name">Naam leerlingportfolio</div>
									<div className="date">Datum</div>
									<div className="parentVisible">
										Onderbouwd Online voor Thuis
									</div>
									<div className="delete">Verwijder</div>
								</div>

								{this.state.reportList &&
									this.state.reportList.map((item, i) => {
										let { studentreportDatetime } = item;
										studentreportDatetime = moment(
											new Date(studentreportDatetime.replace(/-/g, '/'))
										)
											.locale('nl', localization)
											.format('DD-MM-YYYY, HH:mm');
										return (
											<div
												key={`reportid_${item.studentreportId}`}
												className="row"
											>
												<div className="name">
													{this.state.downloadRequested ==
													item.studentreportId ? (
														<img
															alt=""
															width={30}
															src={Loader}
															className="reportLoading"
														/>
													) : (
														<a
															target="_blank"
															rel="noopener noreferrer"
															onClick={() =>
																this.downloadReport(
																	item.studentreportId,
																	item.studentreportTitle
																)
															}
															style={{
																pointerEvents:
																	item.studentreportStatus === 'ready'
																		? 'all'
																		: 'none'
															}}
														>
															{item.studentreportTitle}
														</a>
													)}
												</div>

												<div className="date">
													{item.studentreportStatus === 'ready' ? (
														studentreportDatetime
													) : (
														<img
															alt=""
															src={Loader}
															className="reportLoading"
														/>
													)}
												</div>

												<div className="parentVisible">
													<button
														type="button"
														onClick={() => {
															this.handleParentReportVisibility(
																item.studentreportId
															);
														}}
													>
														{item.studentreportParentsEnabled === 0 && (
															<CloseEye />
														)}
														{item.studentreportParentsEnabled === 1 && (
															<OpenEye />
														)}
													</button>
												</div>

												<div className="delete">
													<button
														className="dltButton"
														onClick={() => {
															this.setState({
																studentreportId: item.studentreportId
															});
															TweenMax.to('.deletePopup', 0.3, {
																autoAlpha: 1,
																onComplete: () => {
																	TweenMax.set('.deleteWrapper', {
																		display: 'block',
																		autoAlpha: 0
																	});
																	TweenMax.to('.deleteWrapper', 0.3, {
																		autoAlpha: 1
																	});
																}
															});
														}}
													>
														<Trash />
													</button>
												</div>
											</div>
										);
									})}
							</section>
							<section className="teacherCommentWrapper">
								<h2 className="headingStyle">
									Toelichting op leerlingportfolio
								</h2>
								<textarea
									value={this.state.teacherComment}
									onChange={this.handleTeacherComent}
								/>
								<div className="row buttonsWrapper">
									{this.state.loaderRequested && (
										<div className="circleLoader">
											<div className="draw" />
										</div>
									)}
									<button
										type="button"
										className={`defaultBtn`}
										onClick={() =>
											this.saveTeacherComment(
												this.state.currentStudent.studentId
											)
										}
									>
										Opslaan
									</button>
								</div>
							</section>
						</div>
					) : (
						<section className="reportFormContainer">
							{this.state.newreportchapters && (
								<div className="formWrapper">
									<label>Naam leerlingportfolio</label>
									<input
										type="text"
										className="inputField"
										value={this.state.reporttitle}
										onChange={this.handleReportTitleChange}
									/>
									<label>Kies opties</label>
									<p>Wat wil je laten zien in het leerlingportfolio?</p>
									<div className="row checkboxWrapper">
										<div className="column">
											<div className="checkBox">
												<strong className="checkboxTitle">
													Toon Observaties
												</strong>
											</div>
											<div className="checkBox">
												<input
													type="checkbox"
													value={this.state.results}
													onChange={event => this.handleResultsChange(event)}
													checked={this.state.results === 'checked'}
												/>
												Observaties
											</div>
											<div className={'subCheckboxWrap'}>
												<div className="checkBox">
													<input
														type="checkbox"
														value={this.state.lessonstepcomments}
														onChange={event =>
															this.handleLessonStepCommentsChange(event)
														}
														checked={
															this.state.lessonstepcomments === 'checked'
														}
														disabled={this.state.results !== 'checked'}
													/>
													Toon de opmerkingen van de leerkracht bij de meest
													recente observatie
												</div>

												<div className="checkBox">
													<input
														type="checkbox"
														value={this.state.lessonstepcommentsall}
														onChange={event =>
															this.handleLessonStepCommentsAllChange(event)
														}
														checked={
															this.state.lessonstepcommentsall === 'checked'
														}
														disabled={this.state.results !== 'checked'}
													/>
													Toon de opmerkingen van de leerkracht ook bij de
													observaties uit het verleden
												</div>

												<div className="checkBox">
													<input
														type="checkbox"
														value={this.state.textslider}
														onChange={event =>
															this.handleTextSliderChange(event)
														}
														checked={
															this.state.textslider === 'checked' &&
															this.state.lessonstepcommentsall !== 'checked'
														}
														disabled={
															this.state.results !== 'checked' ||
															this.state.lessonstepcommentsall === 'checked'
														}
													/>
													Visuele weergave van de resultaten (gekleurde lijnen
													in plaats van tekst)
												</div>
											</div>

											<div className="checkBox">
												<strong className="checkboxTitle">Profielen</strong>
											</div>
											<div className="checkBox">
												<input
													type="checkbox"
													value={this.state.socialemotional}
													onChange={event =>
														this.handleSocialEmotionalChange(event)
													}
													checked={this.state.socialemotional === 'checked'}
												/>
												Sociaal-emotioneel
											</div>
											<div className="checkBox">
												<input
													type="checkbox"
													value={this.state.learningstyles}
													onChange={event =>
														this.handleLearningStylesChange(event)
													}
													checked={this.state.learningstyles === 'checked'}
												/>
												Onderwijsbehoeften
											</div>
											<div className="checkBox">
												<input
													type="checkbox"
													value={this.state.motorical}
													onChange={event => this.handleMotoricalChange(event)}
													checked={this.state.motorical === 'checked'}
												/>
												Motoriek
											</div>
										</div>

										<div className="column">
											<div className="checkBox">
												<strong className="checkboxTitle">Toelichting</strong>
											</div>
											<div className="checkBox">
												<input
													type="checkbox"
													value={this.state.teachercomments}
													onChange={event =>
														this.handleTeacherCommentsChange(event)
													}
													checked={this.state.teachercomments === 'checked'}
												/>
												Persoonlijke toelichting van de leerkracht op het
												leerlingportfolio
											</div>

											{this.props.schoolParentsEnabled === '1' && (
												<>
													<div className="checkBox">
														<strong className="checkboxTitle">
															Foto's (max. 4)
														</strong>
													</div>
													<div className="photosWrapper flexBlock">
														{this.state.selectedPictures.length > 0 && (
															<DragDropContext onDragEnd={this.onDragEnd}>
																<Droppable
																	droppableId="droppable"
																	direction="horizontal"
																>
																	{provided => (
																		<div
																			className="flexBlock"
																			ref={provided.innerRef}
																			{...provided.droppableProps}
																		>
																			{this.state.selectedPictures.map(
																				(item, i) => {
																					return (
																						<Draggable
																							index={i}
																							key={`item_${item.mediaId}`}
																							draggableId={item.mediaId}
																						>
																							{provided => (
																								<div
																									ref={provided.innerRef}
																									{...provided.draggableProps}
																									{...provided.dragHandleProps}
																									className="photoBox"
																								>
																									<button
																										type="button"
																										onClick={() => {
																											let tempArray = [
																												...this.state
																													.selectedPictures
																											];
																											tempArray =
																												tempArray.filter(
																													x =>
																														x.mediaId !==
																														item.mediaId
																												);
																											this.setState({
																												selectedPictures: [
																													...tempArray
																												]
																											});
																										}}
																										className="closeBtn"
																									>
																										<Cross />
																									</button>
																									<div className="aspectContainer flexCenter">
																										{item.mediaType ===
																										'video' ? (
																											<video muted playsInline>
																												<source
																													type="video/mp4"
																													src={`${item.mediaFile}#t=0.5`}
																												/>
																											</video>
																										) : (
																											<img
																												src={item.mediaFile}
																												alt={item.mediaTitle}
																											/>
																										)}
																									</div>
																								</div>
																							)}
																						</Draggable>
																					);
																				}
																			)}
																			{provided.placeholder}
																		</div>
																	)}
																</Droppable>
															</DragDropContext>
														)}
														<button
															type="button"
															disabled={
																this.state.selectedPictures.length === 4
															}
															className="defaultBtn addPhoto flexCenter"
															onClick={() => {
																this.setState({
																	mediaPopup: true
																});
															}}
														>
															<Add />
														</button>
													</div>
												</>
											)}

											<div className="checkBox">
												<strong className="checkboxTitle">
													Groepsleerkrachten
												</strong>
											</div>
											{this.state.teacherstodisplay &&
												this.state.teacherstodisplay.length > 0 &&
												this.state.teacherstodisplay.map((teacher, index) => {
													return (
														<div
															className="checkBox"
															key={`teacherid_${index}`}
														>
															<input
																type="checkbox"
																value={teacher.teacherId}
																onChange={event =>
																	this.handleTeacherList(event)
																}
																checked={this.state.selectedTeachers.includes(
																	teacher.teacherId
																)}
															/>
															{teacher.teacherDisplayname}
														</div>
													);
												})}
										</div>
									</div>

									<p className="details">
										Klik op 'Verder' om het leerlingportfolio op te slaan. Het
										leerlingportfolio wordt vervolgens aangemaakt. Je ziet het
										direct in de lijst met leerlingportfolio's verschijnen, maar
										het kan enkele minuten duren voordat het leerlingportfolio
										daadwerkelijk is gemaakt en je het kunt bekijken.
									</p>
									{this.state.errorMessage && (
										<div className="error">* {this.state.errorMessage}</div>
									)}
									<div className="row buttonsWrapper">
										<button
											type="button"
											className="outlineButton inlineBlock"
											onClick={() => {
												this.setState({
													generateReport: false
												});
											}}
										>
											Annuleren
										</button>
										<button
											type="button"
											className="defaultBtn inlineBlock"
											onClick={this.submitrequest}
										>
											Verder
										</button>
										{this.state.loaderRequested && (
											<div className="circleLoader">
												<div className="draw" />
											</div>
										)}
									</div>
								</div>
							)}
						</section>
					)}
				</div>

				{this.state.mediaPopup && (
					<MediaListPopup
						closePopup={this.closePopup}
						selectedPictures={this.state.selectedPictures}
						callback={pictures => {
							this.setState({
								selectedPictures: pictures
							});
						}}
					/>
				)}

				<div className="popupWrapper deletePopup">
					<div className="popup deleteWrapper">
						<p>
							Weet je zeker dat je dit leerlingportfolio wilt verwijderen? Dit
							kan niet ongedaan gemaakt worden.
						</p>

						<div className="row">
							<div />
							<div>
								<button
									type="button"
									className="inlineBlock outlineButton "
									onClick={this.hideDeletePopup}
								>
									Annuleren
								</button>

								<button
									type="button"
									className="inlineBlock defaultBtn"
									onClick={this.deleteReport}
									style={{ margin: '0 15px' }}
								>
									Ok
								</button>

								{this.state.loaderRequested && (
									<div className="circleLoader">
										<div className="draw" />
									</div>
								)}
							</div>
						</div>
					</div>
					<span className="overlayBackground" />
				</div>

				{this.state.downloadError && (
					<ErrorPopup
						content={this.state.downloadError}
						closeCallback={() => this.setState({ downloadError: '' })}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
	studentList: state.initialData.studentList,
	groupList: state.initialData.groupList,
	groupName: state.initialData.groupName,
	schoolParentsEnabled: state.initialData.userData.schoolParentsEnabled
});

export default connect(mapStateToProps, { setStudentList, sendErrorToHOC })(
	StudentGenerateReport
);

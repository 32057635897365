// @import packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { TweenMax } from 'gsap/TweenMax';
import { isMobile } from 'react-device-detect';
// @import constants
import {
	grouplist_endpoint,
	teacherlist_endpoint
} from '../../constants/endpoints';
// @import Components
import TeacherAddEditPopup from '../../components/popup/teacherAddEditPopup';
// @import Styles
import '../../../css/containers/manageStudents.scss';
// @import svgs
import { ReactComponent as Edit } from '../../../images/svg/edit.svg';
import { ReactComponent as Add } from '../../../images/svg/add.svg';
// @import actions
import { sendErrorToHOC } from '../../../actions/actions';
// @import utils
import { axiosPost, axiosGet } from '../../utils/axiosCall';

export class ManageTeacher extends Component {
	constructor(props) {
		super(props);
		this.state = {
			teacherList: null,
			errorMessage: null,
			groupsList: 'nodata',
			isLoading: true,
			openPopup: false
		};
	}

	componentDidMount() {
		if (this.props.token) {
			this.getTeachersList();
			this.getGroupList();
		}
	}

	getGroupList = () => {
		axiosGet(grouplist_endpoint, this.props.token, axiosResult => {
			this.setState({
				groupsList:
					axiosResult.status === 'no such data'
						? 'nodata'
						: axiosResult.data.groups,
				errorMessage:
					axiosResult.status === 'no such data'
						? 'Om een leerkracht toe te voegen moet er eerst een groep worden aangemaakt.'
						: null
			});
		});
	};

	getTeachersList = () => {
		axiosPost(teacherlist_endpoint, null, this.props.token, axiosResult => {
			this.setState({
				teacherList:
					axiosResult.status === 'no such data'
						? 'nodata'
						: axiosResult.data.teachers,
				isLoading: false
			});
		});
	};

	handleEdit = data => {
		this.setState(
			{
				openPopup: true,
				popupname: 'edit',
				data
			},
			() => {
				TweenMax.to('.popupWrapper', 0.3, {
					autoAlpha: 1
				});
			}
		);
	};

	openAddStudentPopup = () => {
		this.setState(
			{
				openPopup: true,
				popupname: 'add',
				data: null
			},
			() => {
				TweenMax.to('.popupWrapper', 0.3, {
					autoAlpha: 1
				});
			}
		);
	};

	closePopup = () => {
		TweenMax.to('.popupWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setTimeout(() => {
					this.setState({
						openPopup: false
					});
				}, 200);
			}
		});
	};

	render() {
		// console.log(this.state.teacherList);
		return (
			<Fragment>
				<div className="pageContainer manageStudents">
					<div className="studentListWrapper">
						{this.state.teacherList &&
							this.state.teacherList !== 'nodata' &&
							this.state.teacherList.map((item, i) => (
								<div
									className={`studentBox ${isMobile ? 'showIcon' : ''}`}
									key={i}
								>
									<div className="aspectContainer">
										<img
											src={item.teacherPicture.replace(
												/(\.[^.]+)$/,
												'-260x260$1'
											)}
											srcSet={`${item.teacherPicture.replace(
												/(\.[^.]+)$/,
												'-520x520$1'
											)} 2x`}
											alt={item.studentDisplayname}
										/>
									</div>
									<button
										className="editButton"
										onClick={() => this.handleEdit(item)}
									>
										<Edit />
									</button>
									<p>
										<strong>
											{item.teacherFirstname}{' '}
											{item.teacherMiddlename && item.teacherMiddlename}{' '}
											{item.teacherLastname}
										</strong>
									</p>
									<span>{item.teacherInviteStatus}</span>
								</div>
							))}
						{!this.state.isLoading && this.state.groupsList !== 'nodata' && (
							<div className="studentBox">
								<div className="aspectContainer">
									<button
										className="addStudent"
										onClick={this.openAddStudentPopup}
									>
										<Add />
									</button>
								</div>
							</div>
						)}
					</div>
					{this.state.errorMessage && (
						<p className="noContent">{this.state.errorMessage}</p>
					)}
				</div>
				{this.state.openPopup && (
					<TeacherAddEditPopup
						name={this.state.popupname}
						data={this.state.data}
						hidepopup={this.closePopup}
						token={this.props.token}
						groupList={this.state.groupsList}
						updateTeachers={this.getTeachersList}
						userMode={this.props.userData.userMode}
					/>
				)}
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
	userData: state.initialData.userData
});

export default connect(mapStateToProps, { sendErrorToHOC })(ManageTeacher);

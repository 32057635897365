import { appname, apiurl } from 'js/utils/getEnv';

export const app_name = appname;
export const api_url = apiurl;

console.log('apiurl', api_url);
console.log('window.location.hostname', window.location.hostname);

export const cors_url = 'https://cors-anywhere.herokuapp.com/';

// export const api_url = cors_url + geturl;

export const signin_endpoint = `${api_url}auth/signin`;
export const screeninit_endpoint = `${api_url}generic/screeninit`;
// export const infoPopup_endpoint = `${api_url}generic/helptext`;
export const error_endpoint = `${api_url}generic/error`;
export const getsettings_endpoint = `${api_url}generic/settings`;
export const postsettings_endpoint = `${api_url}generic/settings`;

export const user_endpoint = `${api_url}user/`;
export const changepassword_endpoint = `${api_url}user/changepassword`;
export const edituser_endpoint = `${api_url}user/edit`;
export const delteuserpicture_endpoint = `${api_url}user/deletepicture`;

export const perioddashboard_endpoint = `${api_url}lesson/perioddashboard`;
export const startobservation_endpoint = `${api_url}lesson/startobservation`;
export const grouplan_endpoint = `${api_url}lesson/groupplan`;
export const lessonseen_endpoint = `${api_url}lesson/seen`;
export const routine_lessonseen_endpoint = `${api_url}routinelesson/seen`;
export const actionplan_endpoint = `${api_url}lesson/actionplan`;
export const lessonstatus_endpoint = `${api_url}lesson/lessonstatus`;
export const routinelessonstatus_endpoint = `${api_url}lesson/routinelessonstatus`;
export const parentslessonstatus_endpoint = `${api_url}lesson/parentlessonstatus`;
export const parentroutinelessonstatus_endpoint = `${api_url}lesson/parentroutinelessonstatus`;

export const grouplist_endpoint = `${api_url}group/grouplist/`;
export const students_endpoint = `${api_url}group/students/`;
export const deletegroup_endpoint = `${api_url}group/delete/`;
export const addgroup_endpoint = `${api_url}group/add/`;
export const editgroup_endpoint = `${api_url}group/edit/`;
export const annualplan_endpoint = `${api_url}group/annualplan/`;
export const groupresults_endpoint = `${api_url}group/results/`;
export const groupnotes_endpoint = `${api_url}/group/groupnotes`;
export const groupbehandelplan_endpoint = `${api_url}group/treatmentplan/`;

// shop
export const getshopitemlist_endpoint = `${api_url}shopitem/list`;
export const getpurchaseditems_endpoint = `${api_url}shopitem/listpurchases`;
export const getshopitempurchase_endpoint = `${api_url}shopitem/purchase`;

export const getstudentperformance_endpoint = `${api_url}student/predictstep`;
export const studentstep_endpoint = `${api_url}student/step`;
export const studentstephistory_endpoint = `${api_url}student/stephistory`;
export const stephistoryundo_endpoint = `${api_url}student/stephistoryundo`;
export const stephistoryupdate_endpoint = `${api_url}student/stephistoryupdate`;

export const studentdashboard_endpoint = `${api_url}student/dashboard`;
export const deletestudent_endpoint = `${api_url}student/delete`;
export const addstudent_endpoint = `${api_url}student/add`;
export const editstudent_endpoint = `${api_url}student/edit`;

export const reportlist_endpoint = `${api_url}student/reportoverview`;
export const createreport_endpoint = `${api_url}student/reportcreate`;
export const deletereport_endpoint = `${api_url}student/reportdelete`;
export const downloadreport_endpoint = `${api_url}student/reportdownload`;
export const reportparentvisible_endpoint = `${api_url}student/reportparentsvisible`;

export const saveTeacherComment_endpoint = `${api_url}student/reportteachercommentssave`;
export const getTeacherComment_endpoint = `${api_url}student/reportteachercommentsget`;

export const socialprofile_endpoint = `${api_url}student/socialprofile`;
export const socialprofileupdate_endpoint = `${api_url}student/socialprofileupdate`;

export const learningstyle_endpoint = `${api_url}student/learningstyle`;
export const learningstyleupdate_endpoint = `${api_url}student/learningstyleupdate`;

export const studentmergesource_endpoint = `${api_url}student/mergesource`;
export const studentmergedestination_endpoint = `${api_url}student/mergedestination`;
export const studentmergeproceed_endpoint = `${api_url}student/mergeproceed`;

export const teacherlist_endpoint = `${api_url}teacher/list`;
export const deleteteacher_endpoint = `${api_url}teacher/delete`;
export const addteacher_endpoint = `${api_url}teacher/add`;
export const editteacher_endpoint = `${api_url}teacher/edit`;
export const resetteacherpass_endpoint = `${api_url}teacher/resetteacherpassword`;

// media endpoints
export const medialist_endpoint = `${api_url}media/list`;
export const mediaadd_endpoint = `${api_url}media/add`;
export const mediaedit_endpoint = `${api_url}media/edit`;
export const mediadelete_endpoint = `${api_url}media/delete`;
export const mediadisplaylist_endpoint = `${api_url}media/displaylist`;

export const topiclist_endpoint = `${api_url}topic/gettopics`;

export const faqlist_endpoint = `${api_url}faq/list`;

export const documentlist_endpoint = `${api_url}documents/list`;

export const newslist_endpoint = `${api_url}news/listperrole`;

export const resultsdashboard_endpoint = `${api_url}results/dashboard`;

export const search_endpoint = `${api_url}generic/search`;

// parents endpoints
export const parentdashboard_endpoint = `${api_url}lesson/parentdashboard`;
export const parentstipstatus_endpoint = `${api_url}parentmy/parenttipstatus`;
export const parentslist_endpoint = `${api_url}parentmy/list`;
export const addparents_endpoint = `${api_url}parentmy/add`;
export const editparents_endpoint = `${api_url}parentmy/edit`;
export const deleteparents_endpoint = `${api_url}parentmy/delete`;
export const resetparentpass_endpoint = `${api_url}parentmy/resetparentpassword`;
export const parentroutinelessonpreview_endpoint = `${api_url}parentmy/lessonpreview`;

// academy endpoints
export const academycourse_endpoint = `${api_url}academy/courses`;
export const academymessage_endpoint = `${api_url}academy/message`;
export const academycoursestart_endpoint = `${api_url}academy/start`;
export const academycourseintro_endpoint = `${api_url}academy/course`;
export const academyconsumevoucher_endpoint = `${api_url}academy/consumevoucher`;
export const academyvalidatevoucher_endpoint = `${api_url}academy/validatevoucher`;
export const academycoursefeedback_endpoint = `${api_url}academy/coursefeedback`;
export const academycoursehistory_endpoint = `${api_url}academy/coursehistory`;
export const academychapterdetails_endpoint = `${api_url}academy/coursechapter`;
export const academychapterstatus_endpoint = `${api_url}academy/coursechapterstatus`;
export const academyrequestcoursecertifcate_endpoint = `${api_url}academy/requestcoursecertifcate`;

// assessments endpoints
export const updatestudentstatement_endpoint = `${api_url}assessment/updatestudentstatement`;
export const studentchapterstatements_endpoint = `${api_url}assessment/studentchapterstatements`;
export const updatestudentchapterremarks_endpoint = `${api_url}assessment/updatestudentchapterremarks`;

// import endpoints
export const panasysgetschooldata_endpoint = 'import/parnassys_getschooldata';
export const proceedparnasysimport_endpoint = `${api_url}import/parnassys_proceed`;

// period endpoints
export const periodlist_endpoint = `${api_url}period/list`;
export const periodadd_endpoint = `${api_url}period/add`;
export const periodedit_endpoint = `${api_url}period/edit`;
export const periodarchive_endpoint = `${api_url}period/archive`;
export const perioddelete_endpoint = `${api_url}period/delete`;
export const periodreorder_endpoint = `${api_url}period/reorder`;

// goal endpoints
export const goallist_endpoint = `${api_url}goal/goalhidelist`;
export const goalhideShow_endpoint = `${api_url}goal/goalhideshow`;

// lessons endpoints
export const lessonlist_endpoint = `${api_url}lesson/list`;
export const lessoninfo_endpoint = `${api_url}lesson/lessoninfo`;
export const lessonadd_endpoint = `${api_url}lesson/add`;
export const lessonedit_endpoint = `${api_url}lesson/edit`;
export const lessonarchive_endpoint = `${api_url}lesson/archive`;
export const lessondelete_endpoint = `${api_url}lesson/delete`;

// routinelessons endpoints
export const routinelessonlist_endpoint = `${api_url}routinelesson/list`;
export const routinelessonadd_endpoint = `${api_url}routinelesson/add`;
export const routinelessonedit_endpoint = `${api_url}routinelesson/edit`;
export const routinelessonarchive_endpoint = `${api_url}routinelesson/archive`;
export const routinelessondelete_endpoint = `${api_url}routinelesson/delete`;

import React, { useState, useEffect, useCallback } from 'react';
import ReactCrop from 'react-image-crop';
// @import styles
import './index.scss';
// import utils
import { createRotatedImage, getCroppedImg } from 'js/utils/canvasUtils';
// @import icons
import { ReactComponent as RotateIcon } from 'images/svg/rotate.svg';

let imageRef,
	actualImage = null;

function ImageEditor({
	src,
	fileName,
	handleClose,
	aspectRatio = 1,
	callback = () => {}
}) {
	const widthConst = 80;

	const [crop, setCrop] = useState({
		unit: '%',
		width: widthConst,
		x: 20,
		y: 20,
		aspect: aspectRatio
	});

	const [rotation, setRotation] = useState(0);
	const [imageSrc, setImageSrc] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null);

	useEffect(() => {
		actualImage = new Image();
		actualImage.src = src;
		setImageSrc(actualImage.src);
	}, []);

	const onLoad = useCallback(img => {
		imageRef = img;

		const aspect = aspectRatio;
		const width =
			img.width / aspect < img.height * aspect
				? widthConst
				: ((img.height * aspect) / img.width) * widthConst;
		const height =
			img.width / aspect > img.height * aspect
				? widthConst
				: (img.width / aspect / img.height) * widthConst;
		const y = (100 - height) / 2;
		const x = (100 - width) / 2;

		setCrop({
			unit: '%',
			width,
			height,
			x,
			y,
			aspect
		});

		const newWidth =
			img.width / aspect < img.height * aspect
				? img.width * (widthConst / 100)
				: img.width * (((img.height * aspect) / img.width) * widthConst * 0.01);

		const newHeight =
			img.width / aspect > img.height * aspect
				? img.width * (widthConst / 100)
				: img.height * (img.width / aspect / img.height) * widthConst * 0.01;

		const imageCorp = {
			unit: 'px',
			width: newWidth,
			height: newHeight,
			x: (img.width - newWidth) / 2,
			y: (img.height - newHeight) / 2,
			aspect
		};

		getCroppedImg(imageRef, imageCorp, fileName).then(image => {
			setCroppedImage(image);
		});

		return false;
	}, []);

	const onCropComplete = crop => {
		makeClientCrop(crop);
	};

	const makeClientCrop = async crop => {
		if (imageRef && crop.width && crop.height) {
			getCroppedImg(imageRef, crop, fileName).then(image => {
				setCroppedImage(image);
			});
		}
	};

	const handleRotation = () => {
		const value = rotation === 360 ? 90 : rotation + 90;
		setRotation(value);
		createRotatedImage(actualImage, value).then(image => {
			setImageSrc(image);
		});
	};

	return (
		<div className="popupWrapper imageEditorPopup">
			<div className="popup">
				<div className="row flexBlock flexSpaceCenter">
					<div>
						<button
							className="outlineButton rotateIcon"
							onClick={handleRotation}
						>
							<RotateIcon />
							<span>draaien</span>
						</button>
					</div>
					<div>
						<button
							type="button"
							className="outlineButton inlineBlock"
							onClick={() => handleClose()}
						>
							Annuleren
						</button>
						<button
							type="button"
							className="defaultBtn inlineBlock"
							onClick={() => callback(croppedImage)}
							style={{ marginLeft: '15px' }}
						>
							Opslaan
						</button>
					</div>
				</div>
				<div>
					{imageSrc && (
						<ReactCrop
							crop={crop}
							src={imageSrc}
							onImageLoaded={onLoad}
							onComplete={onCropComplete}
							onChange={newCrop => setCrop(newCrop)}
						/>
					)}
				</div>
			</div>
			<span className="overlayBackground" />
		</div>
	);
}

export default ImageEditor;

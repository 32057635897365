import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { connect } from 'react-redux';
import { TweenMax } from 'gsap/TweenMax';
// @import constants
import {
	routinelessonarchive_endpoint,
	routinelessonadd_endpoint,
	routinelessonedit_endpoint,
	routinelessondelete_endpoint,
	lessoninfo_endpoint
} from 'js/constants/endpoints';
// @import components
import ImageEditor from 'js/models/ImageEditor';
// @import svgs
import { ReactComponent as Cross } from 'images/svg/cross.svg';
// @import actions
import { sendErrorToHOC } from '../../../actions/actions';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
// @import styles
import 'css/components/popup/lessonAddEditPopup.scss';

class LessonAddEdit extends Component {
	constructor(props) {
		super(props);
		let displayDate;
		if (this.props.popupdata) {
			const date =
				this.props.popupdata.routinelesson?.general
					.routinelessonPublicationdate;
			displayDate = moment(date ? new Date(date) : new Date()).format(
				'DD-MM-YYYY'
			);
		}
		this.state = {
			activeStep: 0,
			routinelessonName:
				this.props.popupdata.routinelesson?.general.routinelessonName,
			routinelessonIntroduction:
				this.props.popupdata.routinelesson?.general.routinelessonIntroduction ||
				'',
			routinelessonPublicationdate: displayDate,
			lessonId:
				this.props.popupdata.routinelesson?.general.relatedlessonId || '',
			goalId:
				this.props.popupdata.routinelesson?.general.routinelessonGoal || '',
			topicId: this.props.popupdata?.topicId || '',
			periodId: this.props.popupdata?.periodId || '',
			seasonId: this.props.popupdata?.seasonId || '',
			routinelessonType1:
				this.props.popupdata.routinelesson?.material.media1.type || 'link',
			routinelessonMedia1Delete: '',
			routinelessonMedia1:
				this.props.popupdata.routinelesson?.material.media1.url || '',
			routinelessonMedia1File: '',
			routinelessonMedia1Title: 'Open les',
			routinelessonType2:
				this.props.popupdata.routinelesson?.material.media2.type || 'link',
			routinelessonMedia2Delete: '',
			routinelessonMedia2:
				this.props.popupdata.routinelesson?.material.media2.url || '',
			routinelessonMedia2File: '',
			routinelessonMedia2Title:
				this.props.popupdata.routinelesson?.material.media2.title || '',
			routinelessonMedia3Delete: '',
			routinelessonParentMediaType:
				this.props.popupdata.routinelesson?.material.parentmedia.type || 'file',
			routinelessonParentMediaFile: '',
			routinelessonParentMediaUrl:
				this.props.popupdata.routinelesson?.material.parentmedia.url || '',
			routinelessonType3:
				this.props.popupdata.routinelesson?.material.media3.type || 'link',
			routinelessonMedia3:
				this.props.popupdata.routinelesson?.material.media3.url || '',
			routinelessonMedia3File: '',
			routinelessonMedia3Title:
				this.props.popupdata.routinelesson?.material.media3.title || '',
			routinelessonWorksheet1Delete: '',
			routinelessonWorksheet1:
				this.props.popupdata.routinelesson?.worksheet.worksheet1.url || '',
			routinelessonWorksheet1File: '',
			routinelessonWorksheet1Title:
				this.props.popupdata.routinelesson?.worksheet.worksheet1.title || '',
			routinelessonWorksheet2Delete: '',
			routinelessonWorksheet2:
				this.props.popupdata.routinelesson?.worksheet.worksheet2.url || '',
			routinelessonWorksheet2Title:
				this.props.popupdata.routinelesson?.worksheet.worksheet2.title || '',
			routinelessonVideo1Url:
				this.props.popupdata.routinelesson?.videos.video1.url || '',
			routinelessonVideo1Text:
				this.props.popupdata.routinelesson?.videos.video1.title || '',
			routinelessonVideo2Url:
				this.props.popupdata.routinelesson?.videos.video2.url || '',
			routinelessonVideo2Text:
				this.props.popupdata.routinelesson?.videos.video2.title || '',
			routinelessonVideo3Url:
				this.props.popupdata.routinelesson?.videos.video3.url || '',
			routinelessonVideo3Text:
				this.props.popupdata.routinelesson?.videos.video3.title || '',
			periodsArray: null,
			topicGoals: null,
			lessonsArray: [],
			loaderRequested: false
		};

		this.escFunction = this.escFunction.bind(this);
	}

	componentDidMount() {
		this.filterPeriods(this.state.seasonId);
		this.filterGoals(this.props.popupdata.topicId);
		document.addEventListener('keydown', this.escFunction, false);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.escFunction, false);
	}

	handleTabClick = index => {
		this.setState({
			activeStep: index
		});
		TweenMax.set('.stepsWrapper', { display: 'none' });
		TweenMax.set(`.step_${index}`, { display: 'block' });
	};

	filterGoals = topicId => {
		this.setState(
			{
				topicGoals: this.props.goals.filter(item => {
					return item.topicId === topicId;
				})[0].goals
			},
			() => {
				if (this.state.goalId) {
					this.filterLessons(this.state.goalId);
				}
			}
		);
	};

	filterPeriods = seasonId => {
		this.setState({
			periodsArray: this.props.data.filter(item => {
				return item.seasonId === seasonId;
			})[0].periods
		});
	};

	filterLessons = goalId => {
		if (typeof this.state.topicGoals.find(x => x.goalId === goalId) === 'undefined') {
			return;
		}
		this.setState({
			lessonsArray: this.state.topicGoals.find(x => x.goalId === goalId).lessons
		});
	};

	fetchLessonDefaults = lessonId => {
		const dataForm = new FormData();
		dataForm.append('lessonId', lessonId);

		axiosPost(
			lessoninfo_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					console.log(axiosResult);
					this.setState({
							routinelessonName: axiosResult.data.lesson.lessonName,
							routinelessonIntroduction: axiosResult.data.lesson.lessonIntroduction,
					});
				} else {
					this.setState({ errorMessage: axiosResult.failreason });
				}
			}
		);
	}

	escFunction(event) {
		if (event.keyCode === 27) {
			this.props.hidepopup();
		}
	}

	handleInputChange = event => {
		// console.log('selectchange: ', event.target.name);
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleFileChange = event => {
		this.setState({
			[event.target.name]: event.target.files[0]
		});
	};

	handleDateChange = event => {
		this.setState({
			date: event.target.value
		});
	};

	openDeletePopup = () => {
		TweenMax.to('.addEditWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				TweenMax.set('.addEditWrapper', { display: 'none' });
				TweenMax.set('.deleteWrapper', { display: 'block', autoAlpha: 0 });
				TweenMax.to('.deleteWrapper', 0.3, { autoAlpha: 1 });
			}
		});
	};

	hideDeletePopup = () => {
		TweenMax.to('.deleteWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				TweenMax.set('.deleteWrapper', { display: 'none' });
				TweenMax.set('.addEditWrapper', { display: 'block', autoAlpha: 0 });
				TweenMax.to('.addEditWrapper', 0.3, { autoAlpha: 1 });
			}
		});
	};

	handleArchive = status => {
		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		const dataForm = new FormData();
		dataForm.append(
			'routinelessonId',
			this.props.popupdata.routinelesson?.routinelessonId
		);
		dataForm.append('newStatus', status);

		axiosPost(
			routinelessonarchive_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState(
						{
							isWaiting: false
						},
						() => {
							const loader = document.querySelector('.circleLoader');
							const check = loader.querySelector('.draw');

							loader.classList.add('loadComplete');
							check.classList.add('checkmark');
						}
					);
					this.props.updateData();
					setTimeout(() => {
						this.props.hidepopup();
					}, 500);
				} else {
					this.setState({
						isWaiting: false,
						loaderRequested: false,
						errorMessage: axiosResult.failreason
					});
				}
			}
		);
	};

	handleDelete = () => {
		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		const dataForm = new FormData();
		dataForm.append(
			'routinelessonId',
			this.props.popupdata.routinelesson?.routinelessonId
		);
		axiosPost(
			routinelessondelete_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState(
						{
							isWaiting: false
						},
						() => {
							const loader = document.querySelector('.circleLoader');
							const check = loader.querySelector('.draw');

							loader.classList.add('loadComplete');
							check.classList.add('checkmark');
						}
					);
					this.props.updateData();
					setTimeout(() => {
						this.props.hidepopup();
					}, 500);
				} else {
					this.setState({
						isWaiting: false,
						loaderRequested: false,
						errorMessage: axiosResult.failreason
					});
				}
			}
		);
	};

	submitrequest = () => {
		if (!this.state.goalId || !this.state.routinelessonName) {
			this.setState({
				isWaiting: false,
				loaderRequested: false,
				errorMessage: 'please fill all required fields'
			});
			return;
		}

		let date;
		if (this.state.routinelessonPublicationdate) {
			date = this.state.routinelessonPublicationdate.replace(/\//g, '-');
			var res = date.split('-', 3);
			// date = `${res[1]}-${res[0]}-${res[2]}`;
			// fix for firefox
			date = `${res[1]}/${res[0]}/${res[2]}`;
			date = moment(new Date(date)).format('YYYY-MM-DD');
		}

		let url;
		if (this.props.name === 'add') {
			url = routinelessonadd_endpoint;
		}
		if (this.props.name === 'edit') {
			url = routinelessonedit_endpoint;
		}

		const dataForm = new FormData();
		if (this.props.name === 'edit') {
			dataForm.append(
				'routinelessonId',
				this.props.popupdata.routinelesson?.routinelessonId
			);
			dataForm.append(
				'routinelessonActive',
				this.props.popupdata.routinelesson?.general.routinelessonActive
			);
		}

		dataForm.append('goalId', this.state.goalId);
		dataForm.append('topicId', this.state.topicId);
		dataForm.append('periodId', this.state.periodId);
		dataForm.append('seasonId', this.state.seasonId);
		dataForm.append('lessonId', this.state.lessonId);

		dataForm.append('routinelessonName', this.state.routinelessonName);
		dataForm.append(
			'routinelessonIntroduction',
			this.state.routinelessonIntroduction
		);
		dataForm.append('routinelessonPublicationdate', date);

		dataForm.append('routinelessonType1', this.state.routinelessonType1);
		dataForm.append(
			'routinelessonMedia1',
			this.state.routinelessonType1 === 'link'
				? this.state.routinelessonMedia1
				: this.state.routinelessonMedia1File
		);
		dataForm.append(
			'routinelessonMedia1Delete',
			this.state.routinelessonMedia1Delete
		);

		dataForm.append(
			'routinelessonMedia2Title',
			this.state.routinelessonMedia2Title
		);
		dataForm.append('routinelessonType2', this.state.routinelessonType2);
		dataForm.append(
			'routinelessonMedia2',
			this.state.routinelessonType2 === 'link'
				? this.state.routinelessonMedia2
				: this.state.routinelessonMedia2File
		);
		dataForm.append(
			'routinelessonMedia2Delete',
			this.state.routinelessonMedia2Delete
		);

		dataForm.append(
			'routinelessonMedia3Title',
			this.state.routinelessonMedia3Title
		);
		dataForm.append('routinelessonType3', this.state.routinelessonType3);
		dataForm.append(
			'routinelessonMedia3',
			this.state.routinelessonType3 === 'link'
				? this.state.routinelessonMedia3
				: this.state.routinelessonMedia3File
		);
		dataForm.append(
			'routinelessonMedia3Delete',
			this.state.routinelessonMedia3Delete
		);

		dataForm.append('routinelessonParentMediaType', this.state.routinelessonParentMediaType);
		dataForm.append('routinelessonParentMediaFile', this.state.routinelessonParentMediaFile);
		dataForm.append('routinelessonParentMediaUrl', this.state.routinelessonParentMediaUrl);

		dataForm.append(
			'routinelessonWorksheet1',
			this.state.routinelessonWorksheet1File ||
				this.state.routinelessonWorksheet1
		);
		dataForm.append(
			'routinelessonWorksheet1Title',
			this.state.routinelessonWorksheet1Title
		);
		dataForm.append(
			'routinelessonWorksheet1Delete',
			this.state.routinelessonWorksheet1Delete
		);
		dataForm.append(
			'routinelessonWorksheet2',
			this.state.routinelessonWorksheet2File ||
				this.state.routinelessonWorksheet2
		);
		dataForm.append(
			'routinelessonWorksheet2Title',
			this.state.routinelessonWorksheet2Title
		);
		dataForm.append(
			'routinelessonWorksheet2Delete',
			this.state.routinelessonWorksheet2Delete
		);

		dataForm.append(
			'routinelessonVideo1Url',
			this.state.routinelessonVideo1Url
		);
		dataForm.append(
			'routinelessonVideo1Text',
			this.state.routinelessonVideo1Text
		);
		dataForm.append(
			'routinelessonVideo2Url',
			this.state.routinelessonVideo2Url
		);
		dataForm.append(
			'routinelessonVideo2Text',
			this.state.routinelessonVideo2Text
		);
		dataForm.append(
			'routinelessonVideo3Url',
			this.state.routinelessonVideo3Url
		);
		dataForm.append(
			'routinelessonVideo3Text',
			this.state.routinelessonVideo3Text
		);

		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		axiosPost(url, dataForm, this.props.token, axiosResult => {
			console.log('add edit routinelesson:', axiosResult);
			if (axiosResult.status === 'success') {
				this.setState(
					{
						isWaiting: false
					},
					() => {
						const loader = document.querySelector('.circleLoader');
						const check = loader.querySelector('.draw');
						loader.classList.add('loadComplete');
						check.classList.add('checkmark');
					}
				);
				this.props.updateData();
				setTimeout(() => {
					this.props.hidepopup();
				}, 500);
			} else {
				this.setState({
					isWaiting: false,
					loaderRequested: false,
					errorMessage: axiosResult.failreason
				});
			}
		});
	};

	render() {
		return (
			<div
				className="LessonAddEditWrapper popupWrapper studentPopupWrapper"
				style={{ opacity: 1, visibility: 'visible' }}
			>
				<div className="popup addEditWrapper">
					<button
						type="button"
						className="closePopup"
						onClick={() => {
							this.props.hidepopup();
						}}
					>
						<Cross width={18} />
					</button>

					{this.props.name === 'add' ? (
						<h2 className="headingStyle">Routineles toevoegen</h2>
					) : (
						<h2 className="headingStyle">Bewerk Routineles</h2>
					)}

					<ul className="popupTabsWrapper">
						{[
							'Algemeen',
							'Lesmateriaal',
							'Werkbladen',
							'Video’s en liedjes'
						].map((item, i) => (
							<li
								key={`tab_${i}`}
								onClick={() => this.handleTabClick(i)}
								className={i === this.state.activeStep ? 'active' : ''}
							>
								{item}
							</li>
						))}
					</ul>

					{/* general info step */}
					<div className="stepsWrapper step_0">
						<div className="selectboxWrapper">
							<div>
								<label>Vak *</label>
								<select
									name="topicId"
									value={this.state.topicId}
									onChange={event => {
										this.handleInputChange(event);
										this.filterGoals(event.target.value);
									}}
								>
									<option value="0">Kies vak</option>
									{this.props.topics.map((item, i) => {
										return (
											<option key={`topic_${i}`} value={item.topicId}>
												{item.topicName}
											</option>
										);
									})}
								</select>
							</div>
							<div>
								<label>Seizoen *</label>
								<select
									name="seasonId"
									value={this.state.seasonId}
									onChange={event => {
										this.handleInputChange(event);
										this.filterPeriods(event.target.value);
									}}
								>
									<option value="0">Kies seizoen</option>
									{this.props.data.map((item, i) => {
										return (
											<option key={`season_${i}`} value={item.seasonId}>
												{item.seasonName}
											</option>
										);
									})}
								</select>
							</div>
							{this.state.periodsArray && (
								<div>
									<label>Thema *</label>
									<select
										name="periodId"
										value={this.state.periodId}
										onChange={this.handleInputChange}
									>
										<option value="0">Kies thema</option>
										{this.state.periodsArray.map((item, i) => {
											return (
												<option key={`period_${i}`} value={item.periodId}>
													{item.periodName}
												</option>
											);
										})}
									</select>
								</div>
							)}

							{this.state.topicGoals && (
								<div>
									<label>Doel *</label>
									<select
										name="goalId"
										value={this.state.goalId}
										onChange={event => {
											this.handleInputChange(event);
											this.filterLessons(event.target.value);
										}}
									>
										<option value="0">Kies doel</option>
										{this.state.topicGoals.map((item, i) => {
											return (
												<option key={`goal_${i}`} value={item.goalId}>
													{item.goalName}
												</option>
											);
										})}
									</select>
								</div>
							)}

							{this.state.lessonsArray?.length > 0 && (
								<div>
									<label>Lessen</label>
									<select
										name="lessonId"
										value={this.state.lessonId}
										onChange={(e) => {
											const lessonId = e.target.value;
											this.handleInputChange(e);
											if (lessonId !== '0' && lessonId !== this.state.lessonId) {
												this.fetchLessonDefaults(lessonId);
											}
										}}
									>
										<option value="0">Kies lessen</option>
										{this.state.lessonsArray.map((item, i) => {
											return (
												<option key={`lesson_${i}`} value={item.lessonId}>
													{item.lessonName}
												</option>
											);
										})}
									</select>
								</div>
							)}
						</div>
						<label>Naam routineles *</label>
						<input
							required
							type="text"
							name="routinelessonName"
							className="inputField"
							placeholder="Vul hier de naam van de routineles in"
							value={this.state.routinelessonName}
							onChange={this.handleInputChange}
						/>

						<label>Omschrijving *</label>
						<textarea
							required
							type="text"
							className="inputField"
							name="routinelessonIntroduction"
							placeholder="Vul hier de omschrijving van de routineles in"
							value={this.state.routinelessonIntroduction}
							onChange={this.handleInputChange}
						/>

						<label>Publicatiedatum *</label>
						<InputMask
							mask="99-99-9999"
							className="inputField"
							placeholder="dd-mm-jjjj"
							value={this.state.routinelessonPublicationdate}
							onChange={this.handleDateChange}
						/>

					</div>

					{/* materials step */}
					<div className="stepsWrapper step_1">
						<label>Materiaal 1</label>
						<div className="row" style={{ marginBottom: 24 }}>
							Dit materiaal wordt geleverd door de les
						</div>

						<label>Materiaal 2</label>
						<div className="row">
							<div
								className={`btnWrapper translateButton ${
									this.state.routinelessonMedia2 ||
									this.state.routinelessonMedia2File
										? 'disableButton'
										: ''
								}`}
							>
								<button
									className={
										this.state.routinelessonType2 === 'link' ? 'active' : ''
									}
									onClick={() => {
										this.setState({
											routinelessonType2: 'link'
										});
									}}
								>
									link
								</button>
								<button
									className={
										this.state.routinelessonType2 !== 'link' ? 'active' : ''
									}
									onClick={() => {
										this.setState({
											routinelessonType2: 'file'
										});
									}}
								>
									bestand
								</button>
							</div>

							<div className="inputWrapper">
								{this.state.routinelessonType2 === 'link' ? (
									<>
										<label className="subLabel">
											Plak hier de url van een YouTube-video of een Canva-link. Gebruik hiervoor de 'insluiten' / 'embed' link.
										</label>
										<input
											type="url"
											name="routinelessonMedia2"
											className="inputField"
											placeholder="https://www.youtube.com/..."
											value={this.state.routinelessonMedia2}
											onChange={this.handleInputChange}
										/>
									</>
								) : (
									<>
										<label className="subLabel">
											Upload bijlage (pdf, powerpoint)
										</label>
										{this.state.routinelessonMedia2 ? (
											<input
												readOnly
												type="text"
												className="inputField"
												value="Er is een bestand aanwezig"
											/>
										) : (
											<input
												type="file"
												className="inputField"
												accept=".ppt,.pptx,.pdf"
												name="routinelessonMedia2File"
												onChange={this.handleFileChange}
											/>
										)}
									</>
								)}
								<label className="subLabel">Titel knop</label>
								<input
									type="text"
									name="routinelessonMedia2Title"
									className="inputField"
									placeholder="Open les"
									value={this.state.routinelessonMedia2Title}
									onChange={this.handleInputChange}
								/>
							</div>

							<div className="translateButton">
								<button
									type="button"
									className="defaultBtn"
									disabled={!this.state.routinelessonMedia2}
									onClick={() => {
										window.open(this.state.routinelessonMedia2, '_blank');
									}}
								>
									bekijk
								</button>
								<button
									type="button"
									className="outlineButton clearButton"
									disabled={
										!this.state.routinelessonMedia2 &&
										!this.state.routinelessonMedia2File
									}
									onClick={() => {
										this.setState({
											routinelessonMedia2: '',
											routinelessonType2: 'link',
											routinelessonMedia2File: '',
											routinelessonMedia2Title: '',
											routinelessonMedia2Delete: true
										});
										const input = document.querySelector(
											'input[name=routinelessonMedia2File]'
										);
										if (input) {
											input.value = '';
										}
									}}
								>
									<Cross width={14} />
								</button>
							</div>
						</div>

						<label>Materiaal 3</label>
						<div className="row">
							<div
								className={`btnWrapper translateButton ${
									this.state.routinelessonMedia3 ||
									this.state.routinelessonMedia3File
										? 'disableButton'
										: ''
								}`}
							>
								<button
									className={
										this.state.routinelessonType3 === 'link' ? 'active' : ''
									}
									onClick={() => {
										this.setState({
											routinelessonType3: 'link'
										});
									}}
								>
									link
								</button>
								<button
									className={
										this.state.routinelessonType3 !== 'link' ? 'active' : ''
									}
									onClick={() => {
										this.setState({
											routinelessonType3: 'file'
										});
									}}
								>
									bestand
								</button>
							</div>
							<div className="inputWrapper">
								{this.state.routinelessonType3 === 'link' ? (
									<>
										<label className="subLabel">
											Plak hier de url van een YouTube-video of een Canva-link. Gebruik hiervoor de 'insluiten' / 'embed' link.
										</label>
										<input
											type="url"
											name="routinelessonMedia3"
											className="inputField"
											placeholder="https://www.youtube.com/..."
											value={this.state.routinelessonMedia3}
											onChange={this.handleInputChange}
										/>
									</>
								) : (
									<>
										<label className="subLabel">
											Upload bijlage (pdf, powerpoint)
										</label>
										{this.state.routinelessonMedia3 ? (
											<input
												readOnly
												type="text"
												className="inputField"
												value="Er is een bestand aanwezig"
											/>
										) : (
											<input
												type="file"
												className="inputField"
												accept=".ppt,.pptx,.pdf"
												name="routinelessonMedia3File"
												onChange={this.handleFileChange}
											/>
										)}
									</>
								)}
								<label className="subLabel">Titel knop</label>
								<input
									type="text"
									name="routinelessonMedia3Title"
									className="inputField"
									placeholder="Open les"
									value={this.state.routinelessonMedia3Title}
									onChange={this.handleInputChange}
								/>
							</div>
							<div className="translateButton">
								<button
									type="button"
									className="defaultBtn"
									disabled={!this.state.routinelessonMedia3}
									onClick={() => {
										window.open(this.state.routinelessonMedia3, '_blank');
									}}
								>
									bekijk
								</button>
								<button
									type="button"
									className="outlineButton clearButton"
									disabled={
										!this.state.routinelessonMedia3 &&
										!this.state.routinelessonMedia3File
									}
									onClick={() => {
										this.setState({
											routinelessonMedia3: '',
											routinelessonType3: 'link',
											routinelessonMedia3File: '',
											routinelessonMedia3Title: '',
											routinelessonMedia3Delete: true
										});
										const input = document.querySelector(
											'input[name=routinelessonMedia3File]'
										);
										if (input) {
											input.value = '';
										}
									}}
								>
									<Cross width={14} />
								</button>
							</div>
						</div>

						<label>Materiaal Ouders</label>
						<div className="row">
							<div
								className={`btnWrapper ${
									this.state.routinelessonParentMediaUrl || this.state.routinelessonParentMediaFile
										? 'disableButton'
										: ''
								}`}
							>
								<button
									className={
										this.state.routinelessonParentMediaType === 'link' ? 'active' : ''
									}
									onClick={() => {
										this.setState({
											routinelessonParentMediaType: 'link'
										});
									}}
								>
									link
								</button>
								<button
									className={
										this.state.routinelessonParentMediaType !== 'link' ? 'active' : ''
									}
									onClick={() => {
										this.setState({
											routinelessonParentMediaType: 'file'
										});
									}}
								>
									bestand
								</button>
							</div>
							<div className="inputWrapper">
								{this.state.routinelessonParentMediaType === 'link' ? (
									<input
										type="url"
										name="routinelessonParentMediaUrl"
										className="inputField"
										onChange={this.handleInputChange}
										value={this.state.routinelessonParentMediaUrl}
									/>
								) : (
									<>
										{this.state.routinelessonParentMediaUrl ? (
											<input
												readOnly
												type="text"
												className="inputField"
												value="Er is een bestand aanwezig"
											/>
										) : (
											<input
												type="file"
												className="inputField"
												accept=".ppt,.pptx,.pdf"
												name="routinelessonParentMediaFile"
												onChange={this.handleFileChange}
											/>
										)}
									</>
								)}
							</div>
							<div>
								<button
									type="button"
									className="defaultBtn"
									disabled={!this.state.routinelessonParentMediaUrl}
									onClick={() => {
										window.open(this.state.routinelessonParentMediaUrl, '_blank');
									}}
								>
									bekijk
								</button>

								<button
									type="button"
									className="outlineButton clearButton"
									disabled={
										!this.state.routinelessonParentMediaUrl &&
										!this.state.routinelessonParentMediaFile
									}
									onClick={() => {
										this.setState({
											routinelessonParentMediaUrl: '',
											routinelessonParentMediaFile: ''
										});
										const input = document.querySelector(
											'input[name=routinelessonParentMediaFile]'
										);
										if (input) {
											input.value = '';
										}
									}}
								>
									<Cross width={14} />
								</button>
							</div>
						</div>

					</div>

					{/* worksheet step */}
					<div className="stepsWrapper step_2">
						<label>Werkblad 1</label>
						<div className="row">
							<div className="inputWrapper">
								{this.state.routinelessonWorksheet1 ? (
									<input
										readOnly
										type="text"
										className="inputField"
										value="Er is een bestand aanwezig"
									/>
								) : (
									<>
										<label className="subLabel">
											Upload bijlage (pdf, powerpoint)
										</label>
										<input
											type="file"
											className="inputField"
											accept=".ppt,.pptx,.pdf"
											name="routinelessonWorksheet1File"
											onChange={this.handleFileChange}
										/>
									</>
								)}
								<label className="subLabel">Titel</label>
								<input
									type="text"
									className="inputField"
									name="routinelessonWorksheet1Title"
									placeholder="Download werkblad"
									value={this.state.routinelessonWorksheet1Title}
									onChange={this.handleInputChange}
								/>
							</div>
							<div className="">
								<button
									type="button"
									className="defaultBtn"
									disabled={!this.state.routinelessonWorksheet1}
									onClick={() => {
										window.open(this.state.routinelessonWorksheet1, '_blank');
									}}
								>
									bekijk
								</button>
								<button
									type="button"
									className="outlineButton clearButton"
									disabled={
										!this.state.routinelessonWorksheet1 &&
										!this.state.routinelessonWorksheet1File
									}
									onClick={() => {
										this.setState({
											routinelessonWorksheet1: '',
											routinelessonWorksheet1File: '',
											routinelessonWorksheet1Title: '',
											routinelessonWorksheet1Delete: true
										});
										const input = document.querySelector(
											'input[name=routinelessonWorksheet1File]'
										);
										if (input) {
											input.value = '';
										}
									}}
								>
									<Cross width={14} />
								</button>
							</div>
						</div>

						<label>Werkblad 2</label>
						<div className="row">
							<div className="inputWrapper">
								{this.state.routinelessonWorksheet2 ? (
									<>
										<input
											readOnly
											type="text"
											className="inputField"
											value="Er is een bestand aanwezig"
										/>
									</>
								) : (
									<>
										<label className="subLabel">
											Upload bijlage (pdf, powerpoint)
										</label>
										<input
											type="file"
											className="inputField"
											accept=".ppt,.pptx,.pdf"
											name="routinelessonWorksheet2File"
											onChange={this.handleFileChange}
										/>
									</>
								)}
								<label className="subLabel">Titel</label>
								<input
									type="text"
									className="inputField"
									name="routinelessonWorksheet2Title"
									placeholder="Download werkblad"
									value={this.state.routinelessonWorksheet2Title}
									onChange={this.handleInputChange}
								/>
							</div>
							<div className="">
								<button
									type="button"
									className="defaultBtn"
									disabled={!this.state.routinelessonWorksheet2}
									onClick={() => {
										window.open(this.state.routinelessonWorksheet2, '_blank');
									}}
								>
									bekijk
								</button>
								<button
									type="button"
									className="outlineButton clearButton"
									disabled={
										!this.state.routinelessonWorksheet2 &&
										!this.state.routinelessonWorksheet2File
									}
									onClick={() => {
										this.setState({
											routinelessonWorksheet2: '',
											routinelessonWorksheet2File: '',
											routinelessonWorksheet2Title: '',
											routinelessonWorksheet2Delete: true
										});
										const input = document.querySelector(
											'input[name=routinelessonWorksheet2File]'
										);
										if (input) {
											input.value = '';
										}
									}}
								>
									<Cross width={14} />
								</button>
							</div>
						</div>
					</div>

					{/* videos step */}
					<div className="stepsWrapper step_3">
						<label>Video / liedje 1</label>
						<label className="subLabel">
							Plak hier de url van een YouTube-video of een Canva-link. Gebruik hiervoor de 'insluiten' / 'embed' link.
						</label>
						<input
							type="text"
							name="routinelessonVideo1Url"
							className="inputField"
							placeholder="https://www.youtube.com/..."
							value={this.state.routinelessonVideo1Url}
							onChange={this.handleInputChange}
						/>
						<label className="subLabel">Titel</label>
						<input
							type="text"
							name="routinelessonVideo1Text"
							className="inputField"
							placeholder="Titel video / liedje"
							value={this.state.routinelessonVideo1Text}
							onChange={this.handleInputChange}
						/>

						<label>Video / liedje 2</label>
						<label className="subLabel">
							Plak hier de url van een YouTube-video of een Canva-link. Gebruik hiervoor de 'insluiten' / 'embed' link.
						</label>
						<input
							type="text"
							name="routinelessonVideo2Url"
							className="inputField"
							placeholder="https://www.youtube.com/..."
							value={this.state.routinelessonVideo2Url}
							onChange={this.handleInputChange}
						/>
						<label className="subLabel">Titel</label>
						<input
							type="text"
							name="routinelessonVideo2Text"
							className="inputField"
							placeholder="Titel video / liedje"
							value={this.state.routinelessonVideo2Text}
							onChange={this.handleInputChange}
						/>

						<label>Video / liedje 3</label>
						<label className="subLabel">
							Plak hier de url van een YouTube-video of een Canva-link. Gebruik hiervoor de 'insluiten' / 'embed' link.
						</label>
						<input
							type="text"
							name="routinelessonVideo3Url"
							className="inputField"
							placeholder="https://www.youtube.com/..."
							value={this.state.routinelessonVideo3Url}
							onChange={this.handleInputChange}
						/>
						<label className="subLabel">Titel</label>
						<input
							type="text"
							name="routinelessonVideo3Text"
							className="inputField"
							placeholder="Titel video / liedje"
							value={this.state.routinelessonVideo3Text}
							onChange={this.handleInputChange}
						/>
					</div>

					{this.state.errorMessage && (
						<div className="error">* {this.state.errorMessage}</div>
					)}

					<div className="row buttonsWrapper">
						{this.props.name === 'edit' && this.props.data ? (
							<div>
								<button
									type="button"
									className="outlineButton inlineBlock"
									onClick={() =>
										this.handleArchive(
											this.props.popupdata.routinelesson?.general
												.routinelessonActive == '1'
												? 'archive'
												: 'unarchive'
										)
									}
								>
									{this.props.popupdata.routinelesson?.general
										.routinelessonActive == '1'
										? 'Archiveer'
										: 'Activeer'}
								</button>
							</div>
						) : (
							<div />
						)}
						<div>
							{this.state.loaderRequested && (
								<div className="circleLoader">
									<div className="draw" />
								</div>
							)}
							<button
								type="button"
								className="outlineButton inlineBlock"
								onClick={this.props.hidepopup}
							>
								Annuleren
							</button>
							<button
								type="button"
								className="defaultBtn inlineBlock"
								onClick={this.submitrequest}
								style={{ marginLeft: '15px' }}
							>
								Opslaan
							</button>
						</div>
					</div>
				</div>

				<div className="popup deleteWrapper" style={{ maxWidth: '540px' }}>
					<p>
						Weet je zeker dat je deze routineles wilt verwijderen? Dit kan niet
						ongedaan gemaakt worden.
					</p>

					<div className="row">
						<div />
						<div>
							{this.state.loaderRequested && (
								<div className="circleLoader">
									<div className="draw" />
								</div>
							)}
							<button
								type="button"
								className="inlineBlock outlineButton "
								onClick={this.hideDeletePopup}
							>
								Annuleren
							</button>

							<button
								type="button"
								className="inlineBlock defaultBtn"
								onClick={this.handleDelete}
								style={{ marginLeft: '15px' }}
							>
								Ok
							</button>
						</div>
					</div>
				</div>

				{this.state.imagePath && (
					<ImageEditor
						fileName={this.state.imagePath.name}
						src={URL.createObjectURL(this.state.imagePath)}
						handleClose={() => {
							this.setState({
								imagePath: null
							});
							this.inputFile.value = '';
						}}
						callback={this.croppedImageCallback}
					/>
				)}
				<span className="overlayBackground" />
			</div>
		);
	}
}

const mapStateToProps = state => ({
	topics: state.initialData.topics,
});

export default connect(mapStateToProps, {
	sendErrorToHOC
})(LessonAddEdit);

export const courseColor = colorNumber => {
	let courseCategory;
	switch (colorNumber) {
		case '0':
			courseCategory = 'generic';
			break;
		case '1':
			courseCategory = 'taal';
			break;
		case '2':
			courseCategory = 'rekenen';
			break;
		case '3':
			courseCategory = 'motoriek';
			break;
		case '4':
			courseCategory = 'grote-motoriek';
			break;
		default:
			courseCategory = 'generic';
	}
	return courseCategory;
};

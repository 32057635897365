import React, { Component } from 'react';
import { TweenMax } from 'gsap/TweenMax';
// @import constants
import {
	addparents_endpoint,
	editparents_endpoint,
	deleteparents_endpoint,
	resetparentpass_endpoint
} from '../../constants/endpoints';
// @import components
import Student from '../student';
import ImageEditor from 'js/models/ImageEditor';
// @import utils
import { axiosPost } from '../../utils/axiosCall';
// @import images
import picture from '../../../images/avatar.png';
import { ReactComponent as Add } from '../../../images/svg/add.svg';
import { ReactComponent as Cross } from '../../../images/svg/cross.svg';

class ParentsPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			groupId: '0',
			studentId: '0',
			userId: this.props.data
				? this.props.data.userId && this.props.data.userId
				: '',
			firstName: this.props.data
				? this.props.data.userFirstname && this.props.data.userFirstname
				: '',
			middleName: this.props.data
				? this.props.data.userMiddlename && this.props.data.userMiddlename
				: '',
			lastName: this.props.data
				? this.props.data.userLastname && this.props.data.userLastname
				: '',
			email: this.props.data
				? this.props.data.userEmail && this.props.data.userEmail
				: '',
			file: this.props.data
				? this.props.data.userPicture && this.props.data.userPicture
				: null,
			kids: this.props.data ? this.props.data.kids && this.props.data.kids : [],
			notificationSuccess: '',
			isWaiting: false,
			loaderRequested: false
		};

		this.inputFile = null;
		this.escFunction = this.escFunction.bind(this);
	}

	componentDidMount() {
		document.addEventListener('keydown', this.escFunction, false);
	}
	componentWillUnmount() {
		document.removeEventListener('keydown', this.escFunction, false);
	}

	escFunction(event) {
		if (event.keyCode === 27) {
			console.log('student edit system');
			this.props.hidepopup();
		}
	}

	handleFirstNameChange = event => {
		const firstName = event.target.value.replace(/\\|\//g, '');
		this.setState({
			firstName
		});
	};

	handleMiddleNameChange = event => {
		const middleName = event.target.value.replace(/\\|\//g, '');
		this.setState({
			middleName
		});
	};

	handleLastNameChange = event => {
		const lastName = event.target.value.replace(/\\|\//g, '');
		this.setState({
			lastName
		});
	};

	handleEmailChange = event => {
		this.setState({
			email: event.target.value
		});
	};

	handleDateChange = event => {
		this.setState({
			date: event.target.value
		});
	};

	handleGroupChange = event => {
		const groupId = event.target.value;
		this.setState({
			groupId,
			studentId: '0'
		});
	};

	handleChildrenSelect = event => {
		const studentId = event.target.value;
		this.setState({
			studentId
		});
	};

	addKid = () => {
		const createStudent = this.props.groupList
			.find(x => x.groupId === this.state.groupId)
			.students.find(x => x.studentId === this.state.studentId);
		this.setState({
			groupId: '0',
			studentId: '0',
			kids: [...this.state.kids, createStudent]
		});
	};

	removeKid = kidId => {
		const index = this.state.kids.findIndex(x => x.studentId === kidId);

		var array = [...this.state.kids];

		array.splice(index, 1);
		this.setState({ kids: array });
	};

	handlePictureChange = event => {
		this.setState({
			imagePath: event.target.files[0]
		});
	};

	croppedImageCallback = file => {
		this.setState({
			imagePath: null,
			croppedImagePath: file,
			file: URL.createObjectURL(file)
		});
	};

	openDeletePopup = () => {
		TweenMax.to('.addEditWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				TweenMax.set('.addEditWrapper', { display: 'none' });
				TweenMax.set('.deleteWrapper', { display: 'block', autoAlpha: 0 });
				TweenMax.to('.deleteWrapper', 0.3, { autoAlpha: 1 });
			}
		});
	};

	hideDeletePopup = () => {
		TweenMax.to('.deleteWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				TweenMax.set('.deleteWrapper', { display: 'none' });
				TweenMax.set('.addEditWrapper', { display: 'block', autoAlpha: 0 });
				TweenMax.to('.addEditWrapper', 0.3, { autoAlpha: 1 });
			}
		});
	};

	deleteStudent = () => {
		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		const dataForm = new FormData();
		dataForm.append('parentId', this.state.userId);
		axiosPost(
			deleteparents_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState(
						{
							isWaiting: false
						},
						() => {
							const loader = document.querySelector('.circleLoader');
							const check = loader.querySelector('.draw');

							loader.classList.add('loadComplete');
							check.classList.add('checkmark');
						}
					);
					this.props.updateCallback(this.props.groupId);
					setTimeout(() => {
						this.props.hidepopup();
					}, 500);
				} else {
					this.setState({
						isWaiting: false,
						loaderRequested: false,
						errorMessage: axiosResult.failreason
					});
				}
			}
		);
	};

	submitrequest = () => {
		let url;
		if (this.props.name === 'add') {
			url = addparents_endpoint;
		}
		if (this.props.name === 'edit') {
			url = editparents_endpoint;
		}

		const dataForm = new FormData();
		if (this.props.name === 'edit') {
			dataForm.append('parentId', this.state.userId);
		}
		dataForm.append('parentFirstname', this.state.firstName);
		if (this.state.middleName) {
			dataForm.append('parentMiddlename', this.state.middleName);
		}
		dataForm.append('parentLastname', this.state.lastName);
		if (this.state.croppedImagePath) {
			dataForm.append('parentPicture', this.state.croppedImagePath);
		}
		dataForm.append('parentEmail', this.state.email);

		let kids = [];
		for (let index = 0; index < this.state.kids.length; index++) {
			const element = this.state.kids[index];
			kids.push(element.studentId);
		}
		dataForm.append('parentKids', JSON.stringify(kids));

		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		axiosPost(url, dataForm, this.props.token, axiosResult => {
			if (axiosResult.status === 'success') {
				this.setState(
					{
						isWaiting: false
					},
					() => {
						const loader = document.querySelector('.circleLoader');
						const check = loader.querySelector('.draw');
						loader.classList.add('loadComplete');
						check.classList.add('checkmark');
					}
				);
				this.props.updateCallback(this.props.groupId);
				setTimeout(() => {
					this.props.hidepopup();
				}, 500);
			} else {
				this.setState({
					isWaiting: false,
					loaderRequested: false,
					errorMessage: axiosResult.failreason
				});
			}
		});
	};

	openResetPassPopup = () => {
		TweenMax.to('.addEditWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				TweenMax.set('.addEditWrapper', { display: 'none' });
				TweenMax.set('.resetPassWrapper', { display: 'block', autoAlpha: 0 });
				TweenMax.to('.resetPassWrapper', 0.3, { autoAlpha: 1 });
			}
		});
	};

	hideReserPassPopup = () => {
		TweenMax.to('.resetPassWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				TweenMax.set('.resetPassWrapper', { display: 'none' });
				TweenMax.set('.addEditWrapper', { display: 'block', autoAlpha: 0 });
				TweenMax.to('.addEditWrapper', 0.3, { autoAlpha: 1 });
			}
		});
	};
	sendResetPassNotification = () => {
		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		const dataForm = new FormData();
		dataForm.append('parentId', this.state.userId);
		axiosPost(
			resetparentpass_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState(
						{
							isWaiting: false,
							notificationSuccess:
								'De ouder heeft op het bij ons bekende e-mailadres een uitnodiging ontvangen om het wachtwoord opnieuw in te stellen.'
						},
						() => {
							const loader = document
								.querySelector('.resetPassWrapper')
								.querySelector('.circleLoader');
							const check = loader.querySelector('.draw');

							loader.classList.add('loadComplete');
							check.classList.add('checkmark');
						}
					);
					setTimeout(() => {
						this.props.hidepopup();
					}, 1500);
				} else {
					this.setState({
						isWaiting: false,
						loaderRequested: false,
						errorMessage: axiosResult.failreason
					});
				}
			}
		);
	};

	render() {
		return (
			<div className="popupWrapper studentPopupWrapper">
				<div className="popup addEditWrapper">
					<button
						type="button"
						className="closePopup"
						onClick={() => {
							this.props.hidepopup();
						}}
					>
						<Cross width={18} />
					</button>

					{this.props.name === 'add' ? (
						<h2 className="headingStyle">Ouder toevoegen</h2>
					) : (
						<h2 className="headingStyle">
							Gegevens {this.props.data.userDisplayname} wijzigen
						</h2>
					)}
					<label>Naam</label>
					<input
						type="text"
						placeholder="Voornaam"
						className="inputField"
						value={this.state.firstName}
						onChange={this.handleFirstNameChange}
						required
					/>
					<input
						type="text"
						placeholder="Tussenvoegsel"
						className="inputField"
						value={this.state.middleName}
						onChange={this.handleMiddleNameChange}
					/>
					<input
						type="text"
						placeholder="Achternaam"
						className="inputField"
						value={this.state.lastName}
						onChange={this.handleLastNameChange}
						required
					/>
					<label>E-mailadres</label>
					<input
						type="text"
						placeholder="E-mailadres"
						className="inputField"
						value={this.state.email}
						onChange={this.handleEmailChange}
						required
						readOnly={
							this.props.userMode &&
							this.props.userMode === 'read' &&
							this.props.name !== 'add'
						}
					/>
					<label>Foto</label>
					<div className="row">
						<input
							ref={elem => (this.inputFile = elem)}
							type="file"
							accept="image/*"
							onChange={this.handlePictureChange}
							onClick={event => (event.target.value = '')}
						/>
						{this.state.file ? (
							<img src={this.state.file} alt="" className="profilePicture" />
						) : (
							<img src={picture} alt="" className="profilePicture" />
						)}
					</div>
					<label>Kinderen van deze ouder</label>
					<label className="small">Leerling selecteren</label>
					<div className="selectboxWrapper">
						<select
							value={this.state.groupId}
							onChange={this.handleGroupChange}
						>
							<option value="0">Groep</option>
							{this.props.groupList.map((item, i) => {
								return (
									<option key={i} value={item.groupId}>
										{item.groupName}
									</option>
								);
							})}
						</select>

						<select
							value={this.state.studentId}
							onChange={this.handleChildrenSelect}
							disabled={this.state.groupId === '0'}
							className={this.state.groupId === '0' ? 'disable' : ''}
						>
							<option value="0">Leerling</option>
							{this.state.groupId !== '0' &&
								this.props.groupList.find(x => x.groupId === this.state.groupId)
									.students &&
								this.props.groupList
									.find(x => x.groupId === this.state.groupId)
									.students.map(std => {
										return (
											<option
												key={`std${std.studentId}`}
												disabled={this.state.kids.some(
													el => el.studentId === std.studentId
												)}
												value={std.studentId}
											>
												{std.studentDisplayname}
											</option>
										);
									})}
						</select>

						<button
							className={`defaultBtn inlineBlock ${
								this.state.studentId === '0' ? 'disable' : ''
							}`}
							onClick={e => {
								if (this.state.studentId !== '0') {
									this.addKid();
								}
							}}
							type="button"
						>
							<Add />
						</button>
					</div>

					{this.state.kids && this.state.kids.length > 0 && (
						<>
							<label className="small">Geselecteerde leerling(en)</label>
							<div className="kidsRow">
								{this.state.kids.map(kid => (
									<div key={`kid_${kid.studentId}`} className="stdWrp">
										<button
											type="button"
											onClick={() => this.removeKid(kid.studentId)}
										>
											<Cross />
										</button>
										<Student
											size={40}
											studentId={kid.studentId}
											name={kid.studentDisplayname}
											imagepath={kid.studentPicture}
										/>
									</div>
								))}
							</div>
						</>
					)}
					{this.state.errorMessage && (
						<div className="error">* {this.state.errorMessage}</div>
					)}
					<div className="row buttonsWrapper">
						{this.props.name === 'edit' ? (
							<div>
								<button
									type="button"
									className="outlineButton inlineBlock"
									onClick={this.openDeletePopup}
								>
									Verwijderen
								</button>
								<button
									type="button"
									className="outlineButton inlineBlock"
									style={{ marginLeft: '15px' }}
									onClick={this.openResetPassPopup}
								>
									Reset wachtwoord
								</button>
							</div>
						) : (
							<div />
						)}
						<div>
							{this.state.loaderRequested && (
								<div className="circleLoader">
									<div className="draw" />
								</div>
							)}
							<button
								type="button"
								className="outlineButton inlineBlock"
								onClick={this.props.hidepopup}
							>
								Annuleren
							</button>
							<button
								type="button"
								className="defaultBtn inlineBlock"
								onClick={this.submitrequest}
								style={{ marginLeft: '15px' }}
							>
								{this.props.name === 'add' ? 'Verstuur uitnodiging' : 'Opslaan'}
							</button>
						</div>
					</div>
				</div>

				<div className="popup deleteWrapper">
					<p>
						Weet je zeker dat je de ouder wilt verwijderen? Dit kan niet
						ongedaan gemaakt worden.
					</p>

					<div className="row">
						<div />
						<div>
							{this.state.loaderRequested && (
								<div className="circleLoader">
									<div className="draw" />
								</div>
							)}
							<button
								type="button"
								className="inlineBlock outlineButton "
								onClick={this.hideDeletePopup}
							>
								Annuleren
							</button>

							<button
								type="button"
								className="inlineBlock defaultBtn"
								onClick={this.deleteStudent}
								style={{ marginLeft: '15px' }}
							>
								Ok
							</button>
						</div>
					</div>
				</div>

				<div className="popup resetPassWrapper">
					<p>
						Wil je een e-mail laten sturen waarmee de ouder het eigen wachtwoord
						opnieuw kan instellen?
					</p>

					<div className="row">
						<div />
						<div>
							{this.state.loaderRequested && (
								<div className="circleLoader">
									<div className="draw" />
								</div>
							)}
							<button
								type="button"
								className="inlineBlock outlineButton "
								onClick={this.hideReserPassPopup}
							>
								Annuleren
							</button>

							<button
								type="button"
								className="inlineBlock defaultBtn"
								onClick={this.sendResetPassNotification}
								style={{ marginLeft: '15px' }}
							>
								Ok
							</button>
						</div>
					</div>
					{this.state.notificationSuccess && (
						<p className="success">{this.state.notificationSuccess}</p>
					)}
				</div>

				{this.state.imagePath && (
					<ImageEditor
						fileName={this.state.imagePath.name}
						src={URL.createObjectURL(this.state.imagePath)}
						handleClose={() => {
							this.setState({
								imagePath: null
							});
							this.inputFile.value = '';
						}}
						callback={this.croppedImageCallback}
					/>
				)}
				<span className="overlayBackground" />
			</div>
		);
	}
}

export default ParentsPopup;

// TODO Nouman: create stateless input handle component and use in all popup

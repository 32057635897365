// @import dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
// @import constants
import {
	stephistoryundo_endpoint,
	studentstephistory_endpoint
} from 'js/constants/endpoints';
// @import actions
import { changeLessonStep, sendErrorToHOC } from 'actions/actions';
// @import components
import StudentPopup from 'js/models/studentPopupCompoent';
import LessonHistory from 'js/models/historyPopupComponent';
// @import images
import { ReactComponent as Cross } from 'images/svg/cross.svg';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
// @import styles
import './index.scss';

class ObservationPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loadingPopup: true,
			studentPerformance: props.studentPerformance && props.studentPerformance
		};
		this.escFunction = this.escFunction.bind(this);
	}

	componentDidMount() {
		this.getHistoryData();
		document.addEventListener('keydown', this.escFunction, false);
	}

	componentDidUpdate(prevProps) {
		if (this.props.studentPerformance !== prevProps.studentPerformance) {
			this.setState({
				studentPerformance: this.props.studentPerformance
			});
		}
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.escFunction, false);
	}

	escFunction(event) {
		if (event.keyCode === 27) {
			this.handleClose();
		}
	}

	getHistoryData = () => {
		const { studentId } = this.props.draggedStudent;

		const dataForm = new FormData();
		dataForm.append('studentId', studentId);
		dataForm.append('lessonId', this.props.lessonId);

		axiosPost(
			studentstephistory_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState({
						loadingPopup: false,
						lessonHistory: axiosResult.data.stephistory,
						stepLegend: axiosResult.data.steplegend
					});
				}
			}
		);
	};

	deleteStepHistory = (studentId, stepId, lessonId) => {
		const dataForm = new FormData();
		dataForm.append('studentId', studentId);
		dataForm.append('studentlessonstephistoryid', stepId);

		this.setState({
			loadingPopup: true
		});

		axiosPost(
			stephistoryundo_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.getHistoryData();

					setTimeout(() => {
						this.props.hidepopup();
					}, 1500);

					if (this.props.calledfrom === 'action plan') {
						this.props.updateActionPlan(
							this.props.lessonId,
							this.props.routinelessonId
						);
					}

					if (this.props.calledfrom === 'group plan') {
						this.lastAction = 'delete';
						this.props.changeLessonStep(
							this.props.lessonId,
							this.props.groupId,
							this.props.token
						);
					}

					if (this.props.callback) {
						this.props.callback();
					}

					setTimeout(() => {
						this.setState({
							loadingPopup: false
						});
					}, 1000);
				}
			}
		);
	};

	handleClose = () => {
		if (this.props.stepTo !== this.props.stepFrom) {
			if (this.props.cancelDrag) {
				this.props.cancelDrag();
			}

			if (this.props.callback) {
				this.props.callback();
			}
		}

		this.props.hidepopup();
	};

	render() {
		return (
			<div className="popupWrapper">
				<div className="obsPopup">
					<button
						type="button"
						className="closePopup"
						onClick={this.handleClose}
					>
						<Cross width={18} />
					</button>
					<div className="popup">
						<StudentPopup
							lastAction={this.lastAction}
							readOnly={this.props.readOnly}
							stepFrom={this.props.stepFrom}
							stepTo={this.props.stepTo}
							message={this.props.message}
							lessonId={this.props.lessonId}
							lesson={this.props.clickedLesson}
							student={this.props.draggedStudent}
							routinelessonId={this.props.routinelessonId}
							calledfrom={this.props.calledfrom}
							topicName={this.props.topicName}
							studentPerformance={this.state.studentPerformance}
							stepLegend={this.state.stepLegend}
							handleClose={this.handleClose}
							callback={this.props.callback}
							hidepopup={this.props.hidepopup}
							cancelDrag={this.props.cancelDrag}
							updateActionPlan={this.props.updateActionPlan}
						/>
					</div>

					<div className="popup">
						<LessonHistory
							callback={this.props.callback}
							data={this.state.lessonHistory}
							lesson={this.props.clickedLesson}
							student={this.props.draggedStudent}
							loadingPopup={this.state.loadingPopup}
							updateHistorydata={this.getHistoryData}
							deleteStepHistory={this.deleteStepHistory}
						/>
					</div>
				</div>
				<span className="overlayBackground" />
			</div>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
	groupId: state.initialData.groupId
});

export default connect(mapStateToProps, {
	changeLessonStep,
	sendErrorToHOC
})(ObservationPopup);

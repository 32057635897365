import React from 'react';
// @import packages
// @import components
// @import styles
import '../../css/components/developmentSteps.scss';
const DevelopmentSteps = ({ name, description }) => {
	let age;
	switch (name) {
		case 'Ontwikkelstap A':
			age = '3 - 4 jaar';
			break;
		case 'Ontwikkelstap B':
			age = '4 - 5 jaar';
			break;
		case 'Ontwikkelstap C':
			age = '5 - 6 jaar';
			break;
		case 'Ontwikkelstap D':
			age = '6 - 7 jaar';
			break;
		case 'Ontwikkelstappen afgerond':
			age = '';
			break;
		default:
			age = '3 - 4 jaar';
	}
	return (
		<div className="step">
			<h2 className="sectionTitle">
				{name} <span style={{ opacity: '0.6', fontSize: '14px' }}>{age}</span>
			</h2>
			<p>{description}</p>
		</div>
	);
};

export default DevelopmentSteps;

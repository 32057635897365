// @import packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { TweenMax } from 'gsap/TweenMax';
import { isMobile } from 'react-device-detect';
// @import constants
import {
	students_endpoint,
	deletestudent_endpoint,
	addstudent_endpoint,
	editstudent_endpoint
} from '../constants/endpoints';
// @import actions
import {
	fetchStudentList,
	fetchInitialData,
	sendErrorToHOC
} from '../../actions/actions';
// @import styles
import '../../css/containers/studentEidSystem.scss';
// @import svgs
import ImageEditor from 'js/models/ImageEditor';
import { ReactComponent as Edit } from '../../images/svg/edit.svg';
import { ReactComponent as Add } from '../../images/svg/add.svg';
// @import images
import picture from '../../images/avatar.png';
import { ReactComponent as Cross } from '../../images/svg/cross.svg';
// @import utils
import { axiosPost } from '../utils/axiosCall';

class StudentPopup extends Component {
	constructor(props) {
		super(props);
		let displayDate;
		if (this.props.data) {
			displayDate = moment(new Date(this.props.data.studentBirthdate)).format(
				'DD-MM-YYYY'
			);
		}

		this.state = {
			studentId: this.props.data
				? this.props.data.studentId && this.props.data.studentId
				: '',
			firstName: this.props.data
				? this.props.data.studentFirstname && this.props.data.studentFirstname
				: '',
			middleName: this.props.data
				? this.props.data.studentMiddlename && this.props.data.studentMiddlename
				: '',
			lastName: this.props.data
				? this.props.data.studentLastname && this.props.data.studentLastname
				: '',
			vve: this.props.data
				? this.props.data.studentVVE && this.props.data.studentVVE
				: '0',
			file: this.props.data
				? this.props.data.studentPicture && this.props.data.studentPicture
				: null,
			date: this.props.data
				? this.props.data.studentBirthdate && displayDate
				: '',
			isWaiting: false,
			loaderRequested: false
		};

		this.inputFile = null;
		this.escFunction = this.escFunction.bind(this);
	}

	componentDidMount() {
		document.addEventListener('keydown', this.escFunction, false);
	}
	componentWillUnmount() {
		document.removeEventListener('keydown', this.escFunction, false);
	}

	escFunction(event) {
		if (event.keyCode === 27) {
			console.log('student edit system');
			this.props.hidepopup();
		}
	}

	handleFirstNameChange = event => {
		const firstName = event.target.value.replace(/\\|\//g, '');
		this.setState({
			firstName
		});
	};

	handleMiddleNameChange = event => {
		const middleName = event.target.value.replace(/\\|\//g, '');
		this.setState({
			middleName
		});
	};

	handleLastNameChange = event => {
		const lastName = event.target.value.replace(/\\|\//g, '');
		this.setState({
			lastName
		});
	};

	handleVVEChange = event => {
		this.setState({
			vve: this.state.vve === '0' ? '1' : '0',
		});
	};

	handleDateChange = event => {
		this.setState({
			date: event.target.value
		});
	};

	handlePictureChange = event => {
		this.setState({
			imagePath: event.target.files[0]
		});
	};

	croppedImageCallback = file => {
		this.setState({
			imagePath: null,
			croppedImagePath: file,
			file: URL.createObjectURL(file)
		});
	};

	openDeletePopup = () => {
		TweenMax.to('.addEditWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				TweenMax.set('.addEditWrapper', { display: 'none' });
				TweenMax.set('.deleteWrapper', { display: 'block', autoAlpha: 0 });
				TweenMax.to('.deleteWrapper', 0.3, { autoAlpha: 1 });
			}
		});
	};

	hideDeletePopup = () => {
		TweenMax.to('.deleteWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				TweenMax.set('.deleteWrapper', { display: 'none' });
				TweenMax.set('.addEditWrapper', { display: 'block', autoAlpha: 0 });
				TweenMax.to('.addEditWrapper', 0.3, { autoAlpha: 1 });
			}
		});
	};

	deleteStudent = () => {
		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		const dataForm = new FormData();
		dataForm.append('studentId', this.state.studentId);
		axiosPost(
			deletestudent_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.props.fetchStudentList(
						this.props.groupId,
						this.props.groupName,
						this.props.token
					);
					this.props.fetchInitialData(this.props.token);
					this.setState(
						{
							isWaiting: false
						},
						() => {
							const loader = document.querySelector('.circleLoader');
							const check = loader.querySelector('.draw');

							loader.classList.add('loadComplete');
							check.classList.add('checkmark');
						}
					);
					const { groupId, token } = this.props;
					this.props.updateStudents(groupId, token);
					setTimeout(() => {
						this.props.hidepopup();
					}, 500);
				} else {
					this.setState({
						isWaiting: false,
						loaderRequested: false,
						errorMessage: axiosResult.failreason
					});
				}
			}
		);
	};

	submitrequest = () => {
		let date;
		if (this.state.date) {
			date = this.state.date.replace(/\//g, '-');
			var res = date.split('-', 3);
			// fix for firefox
			date = `${res[1]}/${res[0]}/${res[2]}`;
			date = moment(new Date(date)).format('YYYY-MM-DD');
		}

		let url;
		if (this.props.name === 'add') {
			url = addstudent_endpoint;
		}
		if (this.props.name === 'edit') {
			url = editstudent_endpoint;
		}

		const dataForm = new FormData();
		if (this.props.name === 'edit') {
			dataForm.append('studentId', this.state.studentId);
		}
		dataForm.append('studentFirstname', this.state.firstName);
		if (this.state.middleName) {
			dataForm.append('studentMiddlename', this.state.middleName);
		}
		dataForm.append('studentLastname', this.state.lastName);
		if (this.state.croppedImagePath) {
			dataForm.append('studentPicture', this.state.croppedImagePath);
		}
		dataForm.append('studentBirthdate', date);
		dataForm.append('groupId', this.props.groupId);
		dataForm.append('studentVVE', this.state.vve);

		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		axiosPost(url, dataForm, this.props.token, axiosResult => {
			if (axiosResult.status === 'success') {
				this.props.fetchStudentList(
					this.props.groupId,
					this.props.groupName,
					this.props.token
				);
				this.props.fetchInitialData(this.props.token);
				this.setState(
					{
						isWaiting: false
					},
					() => {
						const loader = document.querySelector('.circleLoader');
						const check = loader.querySelector('.draw');
						loader.classList.add('loadComplete');
						check.classList.add('checkmark');
					}
				);
				const { groupId, token } = this.props;
				this.props.updateStudents(groupId, token);
				setTimeout(() => {
					this.props.hidepopup();
				}, 500);
			} else {
				this.setState({
					isWaiting: false,
					loaderRequested: false,
					errorMessage: axiosResult.failreason
				});
			}
		});
	};

	render() {
		// console.log(this.props.data);
		return (
			<div className="popupWrapper studentPopupWrapper">
				<div className="popup addEditWrapper">
					<button
						type="button"
						className="closePopup"
						onClick={() => {
							this.props.hidepopup();
						}}
					>
						<Cross width={18} />
					</button>

					{this.props.name === 'add' ? (
						<h2 className="headingStyle">Leerling toevoegen</h2>
					) : (
						<h2 className="headingStyle">
							Gegevens {this.props.data.studentDisplayname} wijzigen
						</h2>
					)}
					<label>Naam</label>
					<input
						type="text"
						placeholder="Voornaam"
						className="inputField"
						value={this.state.firstName}
						onChange={this.handleFirstNameChange}
						required
					/>
					<input
						type="text"
						placeholder="Tussenvoegsel"
						className="inputField"
						value={this.state.middleName}
						onChange={this.handleMiddleNameChange}
					/>
					<input
						type="text"
						placeholder="Achternaam"
						className="inputField"
						value={this.state.lastName}
						onChange={this.handleLastNameChange}
						required
					/>
					<label>Geboortedatum</label>
					<InputMask
						mask="99-99-9999"
						placeholder="dd-mm-jjjj"
						className="inputField"
						onChange={this.handleDateChange}
						value={this.state.date}
					/>
					<label>Foto</label>
					<div className="row">
						<input
							ref={elem => (this.inputFile = elem)}
							type="file"
							accept="image/*"
							onChange={this.handlePictureChange}
							onClick={event => (event.target.value = '')}
						/>
						{this.state.file ? (
							<img src={this.state.file} alt="" className="profilePicture" />
						) : (
							<img src={picture} alt="" className="profilePicture" />
						)}
					</div>
					<div className="checkBox">
						<input
							type="checkbox"
							value={this.state.vve}
							checked={this.state.vve === '1'}
							onChange={this.handleVVEChange}
							style={{ marginRight: 8 }}
						/>
						VVE
					</div>

					{this.state.errorMessage && (
						<div className="error">* {this.state.errorMessage}</div>
					)}
					<div className="row buttonsWrapper">
						{this.props.name === 'edit' ? (
							<button
								type="button"
								className="outlineButton inlineBlock"
								onClick={this.openDeletePopup}
							>
								Verwijderen
							</button>
						) : (
							<div />
						)}
						<div>
							{this.state.loaderRequested && (
								<div className="circleLoader">
									<div className="draw" />
								</div>
							)}
							<button
								type="button"
								className="outlineButton inlineBlock"
								onClick={this.props.hidepopup}
							>
								Annuleren
							</button>
							<button
								type="button"
								className="defaultBtn inlineBlock"
								onClick={this.submitrequest}
								style={{ marginLeft: '15px' }}
							>
								Opslaan
							</button>
						</div>
					</div>
				</div>

				<div className="popup deleteWrapper">
					<p>
						Weet je zeker dat je de leerling met zijn persoonlijke gegevens en
						scores wilt verwijderen? Dit kan niet ongedaan gemaakt worden.
					</p>

					<div className="row">
						<div />
						<div>
							{this.state.loaderRequested && (
								<div className="circleLoader">
									<div className="draw" />
								</div>
							)}
							<button
								type="button"
								className="inlineBlock outlineButton "
								onClick={this.hideDeletePopup}
							>
								Annuleren
							</button>

							<button
								type="button"
								className="inlineBlock defaultBtn"
								onClick={this.deleteStudent}
								style={{ marginLeft: '15px' }}
							>
								Ok
							</button>
						</div>
					</div>
				</div>
				<span className="overlayBackground" />

				{this.state.imagePath && (
					<ImageEditor
						fileName={this.state.imagePath.name}
						src={URL.createObjectURL(this.state.imagePath)}
						handleClose={() => {
							this.setState({
								imagePath: null
							});
							this.inputFile.value = '';
						}}
						callback={this.croppedImageCallback}
					/>
				)}
			</div>
		);
	}
}

class StudentEditSystem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			students: null,
			openPopup: false,
			data: null
		};
	}

	componentDidMount() {
		if (this.props.groupId) {
			this.getStudents(this.props.groupId, this.props.token);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.groupId !== prevProps.groupId) {
			this.getStudents(this.props.groupId, this.props.token);
		}
	}

	getStudents = (groupId, token) => {
		this.setState({
			isLoading: true
		});

		const dataForm = new FormData();
		dataForm.append('groupId', groupId);

		axiosPost(students_endpoint, dataForm, this.props.token, axiosResult => {
			if (axiosResult.status === 'success') {
				this.setState({
					isLoading: false,
					students: axiosResult.data.students
				});
			}
		});
	};

	handleEdit = data => {
		this.setState(
			{
				openPopup: true,
				popupname: 'edit',
				data
			},
			() => {
				TweenMax.to('.popupWrapper', 0.3, {
					autoAlpha: 1
				});
			}
		);
	};

	openAddStudentPopup = () => {
		this.setState(
			{
				openPopup: true,
				popupname: 'add',
				data: null
			},
			() => {
				TweenMax.to('.popupWrapper', 0.3, {
					autoAlpha: 1
				});
			}
		);
	};

	closePopup = () => {
		TweenMax.to('.popupWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setTimeout(() => {
					this.setState({
						openPopup: false
					});
				}, 200);
			}
		});
	};

	getTopicName = topicName => {
		this.setState({
			topicName
		});
	};

	render() {
		// console.log('group name', this.props.groupName);
		return (
			<div className="mainContainer">
				<div className="pageContainer">
					<Fragment>
						<h1 className="headingStyle">
							{this.props.groupName} - Leerlingen
						</h1>
						<div className="studentListWrapper">
							{this.state.students &&
								this.state.students.map((item, i) => (
									<div
										className={`studentBox ${isMobile ? 'showIcon' : ''}`}
										key={i}
									>
										<div className="aspectContainer">
											<img
												src={item.studentPicture.replace(
													/(\.[^.]+)$/,
													'-260x260$1'
												)}
												srcSet={`${item.studentPicture.replace(
													/(\.[^.]+)$/,
													'-520x520$1'
												)} 2x`}
												alt={item.studentDisplayname}
											/>
										</div>
										{item.studentEditable === '1' && (
											<button
												className="editButton"
												onClick={() => this.handleEdit(item)}
											>
												<Edit />
											</button>
										)}
										<strong>{item.studentDisplayname}</strong>
										{item.studentDisplayage && (
											<span>{item.studentDisplayage}</span>
										)}
									</div>
								))}
							<div className="studentBox">
								<div className="aspectContainer">
									<button
										className="addStudent"
										onClick={this.openAddStudentPopup}
									>
										<Add />
									</button>
								</div>
							</div>
						</div>
					</Fragment>
				</div>
				{this.state.openPopup && (
					<StudentPopup
						name={this.state.popupname}
						data={this.state.data}
						hidepopup={this.closePopup}
						groupId={this.props.groupId}
						groupName={this.props.groupName}
						token={this.props.token}
						updateStudents={this.getStudents}
						fetchStudentList={this.props.fetchStudentList}
						fetchInitialData={this.props.fetchInitialData}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
	studentList: state.initialData.studentList,
	groupId: state.initialData.groupId,
	groupName: state.initialData.groupName
});

export default connect(mapStateToProps, {
	fetchStudentList,
	fetchInitialData,
	sendErrorToHOC
})(StudentEditSystem);

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { TweenMax } from 'gsap/TweenMax';
import localization from 'moment/locale/nl';
import { withRouter, Link } from 'react-router-dom';
// @import components
import BreadCrumb from './breadcrumb';
import Rating from '../../components/rating';
import EmbedCanvaYoutube from '../../components/EmbedCanvaYoutube';
import VoucherPopup from '../../components/popup/voucherPopup';
// @import endpoints
import {
	academycourseintro_endpoint,
	academycoursestart_endpoint,
	academycoursefeedback_endpoint,
	academyconsumevoucher_endpoint,
	academyrequestcoursecertifcate_endpoint
} from '../../constants/endpoints';
// @import utils
import { currency } from '../../utils/currency';
import { axiosPost } from '../../utils/axiosCall';
import { courseColor } from '../../utils/courseColor';
import { data_url } from '../../utils/getEnv';
// @import actions
import { getChaptersHistory } from '../../../actions/academyActions';
// @import styles
import '../../../css/containers/academy/courseIntroduction.scss';
// @import icons
import { ReactComponent as Edit } from '../../../images/svg/edit.svg';

const AcademyCourseIntroduction = props => {
	const {
		match: { params },
		token
	} = props;

	const [loading, setLoading] = useState(false);
	const [rating, setRating] = useState(0);
	const [flag, setFlag] = useState(false);
	const [course, setCourse] = useState(null);
	const [certificateMessage, setCertificateMessage] = useState(null);
	const [feedback, setFeedback] = useState('');
	const [certificateRequested, setCertificateRequested] = useState(false);
	const [tempRating, setTempRating] = useState(0);
	const [voucherLink, setVoucherLink] = useState(false);
	const [voucherCode, setVoucherCode] = useState('');
	const [voucherValidated, setVoucherValidated] = useState(false);
	const [isConfirmationChecked, setConfirmationChecked] = useState({
		one: false,
		two: false
	});

	const closeVoucherPopup = (vCode, vValidated) => {
		setVoucherCode(vCode);
		setVoucherValidated(vValidated);
		if (vValidated) {
			discardFirstCondition();
		}
		TweenMax.to('.voucherPopup', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setVoucherLink(false);
			}
		});
	};

	useEffect(() => {
		if (voucherLink) {
			TweenMax.to('.voucherPopup', 0.3, {
				autoAlpha: voucherLink ? 1 : 0
			});
		}
	}, [voucherLink]);

	useEffect(() => {
		const dataForm = new FormData();
		dataForm.append('courseSlug', params.coursename);
		axiosPost(academycourseintro_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				setCourse(axiosResult.data.course);
				updateCertificateMessage(
					axiosResult.data.course.acourseuserCertificateStatus
				);
				if (axiosResult.data.course.acourseYourRating) {
					setRating(axiosResult.data.course.acourseYourRating.currentRating);
					setTempRating(
						axiosResult.data.course.acourseYourRating.currentRating
					);
					setFeedback(
						axiosResult.data.course.acourseYourRating.currentFeedback
					);
				}
				if (axiosResult.data.course.acourseCost === '0') {
					setConfirmationChecked(true);
				}
			}
		});
	}, [course === null]);

	const updateCertificateMessage = status => {
		let message = '';
		switch (status) {
			case '1':
				message =
					'Het certificaat wordt aangemaakt, je ontvangt het binnenkort per e-mail.';
				break;
			case '2':
				message =
					'De opleidingsfacilitator gaat beoordelen of je in aanmerking komt voor het certificaat, je ontvangt het certificaat daarna per e-mail';
				break;
			case '3':
				message = 'Hier is je certificaat:';
				break;
			default:
				message = 'Je kunt nu je certificaat aanvragen';
		}
		setCertificateMessage(message);
	};

	const handleCheckbox = id => {
		if (id === 'one') {
			setConfirmationChecked({
				one: !isConfirmationChecked.one,
				two: isConfirmationChecked.two
			});
		}
		if (id === 'two') {
			setConfirmationChecked({
				one: isConfirmationChecked.one,
				two: !isConfirmationChecked.two
			});
		}
	};

	const discardFirstCondition = () => {
		setConfirmationChecked({
			one: true,
			two: isConfirmationChecked.two
		});
	};

	const handleCourseStart = () => {
		const dataForm = new FormData();
		dataForm.append('courseId', course.acourseId);
		dataForm.append('voucherCode', voucherCode);
		axiosPost(academycoursestart_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				props.getChaptersHistory(token);
				props.history.push(axiosResult.data.redirectto);
			}
		});
	};

	const breadcrumbRoutes = [
		{
			name: 'Opleiding',
			path: '/academie/opleiding'
		},
		{
			name: course ? course.acourseName : params.coursename,
			path: `/academie/opleiding/${params.coursename}`
		}
	];

	const numbers = [1, 2, 3, 4, 5];

	function rate(rating) {
		setRating(rating);
		setTempRating(rating);
	}

	function star_over(rating) {
		setRating(rating);
	}

	function star_out() {
		setRating(tempRating);
	}

	function handleFeedback(event) {
		setFeedback(event.target.value);
	}

	function requestCertificate() {
		setLoading(true);
		const dataForm = new FormData();
		dataForm.append('courseId', course.acourseId);
		axiosPost(
			academyrequestcoursecertifcate_endpoint,
			dataForm,
			token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					setLoading(false);
					setCertificateRequested(true);
				} else {
					setLoading(false);
				}
			}
		);
	}

	function handleFeedbackSubmit() {
		setLoading(true);
		const dataForm = new FormData();
		dataForm.append('courseId', course.acourseId);
		dataForm.append('rating', rating);
		dataForm.append('feedback', feedback);
		axiosPost(academycoursefeedback_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				const loader = document.querySelector('.circleLoader');
				const check = loader.querySelector('.draw');
				loader.classList.add('loadComplete');
				check.classList.add('checkmark');
				setTimeout(() => {
					setLoading(false);
					TweenMax.to('.feedbackWrapper', 0.3, {
						autoAlpha: 0,
						onComplete: () => {
							TweenMax.set('.feedbackWrapper', { display: 'none' });
							TweenMax.set('.afterSubmit', { display: 'block' });
							TweenMax.to('.afterSubmit', 0.2, { autoAlpha: 1 });
						}
					});
				}, 600);
			} else {
				setLoading(false);
			}
		});
	}

	// console.log(course);

	return (
		<div className="mainContainer academyWrapper">
			<BreadCrumb routes={breadcrumbRoutes} />
			<div className="pageContainer courseIntroduction">
				{course && (
					<div
						key={`course_${course.acourseId}`}
						className={`courseWrapper ${courseColor(course.acourseColourcode)}`}
					>
						<div className="wrapper">
							<div className="flexBlock">
								<img
									src={course.acourseImage}
									srcSet={`${course.acourseImage} 2x`}
									alt={course.acourseName}
								/>
								<div className="contentSection">
									<h2 className="headingStyle">{course.acourseName}</h2>
									<div className="columns">
										<span>
											<b>Studietijd:</b> {course.acourseEffort}
										</span>
										<span>
											<b>Doelgroep:</b> {course.acourseAudience}
										</span>
										<span>
											<b>Niveau:</b> {course.acourseLevel}
										</span>
										<span>
											<b>Kosten:</b>
											{course.acourseCost === '0'
												? 'gratis'
												: currency.format(course.acourseCost)}
										</span>
									</div>
									<div
										className="introContent"
										dangerouslySetInnerHTML={{
											__html: course.acourseIntroduction
										}}
									/>
								</div>
							</div>

							{(course.acourseMedia1Url || course.acourseMedia2Url) && (
								<div className="courseAttachments">
									{course.acourseMedia1Type === 'file' &&
										course.acourseMedia1Url && (
											<div className="item">
												<img
													src={course.acourseMedia1Url.replace(
														/(\.[^.]+)$/,
														'-1000x500$1'
													)}
													srcSet={`${course.acourseMedia1Url.replace(
														/(\.[^.]+)$/,
														'-1000x500$1'
													)} 2x`}
													alt={'courseMedia1'}
												/>
											</div>
										)}
									{course.acourseMedia1Type === 'link' &&
										course.acourseMedia1Url && (
											<div className="item">
												<EmbedCanvaYoutube source={course.acourseMedia1Url} />
											</div>
										)}
									{course.acourseMedia2Type === 'file' &&
										course.acourseMedia2Url && (
											<div className="item">
												<img
													src={course.acourseMedia2Url.replace(
														/(\.[^.]+)$/,
														'-1000x500$1'
													)}
													srcSet={`${course.acourseMedia2Url.replace(
														/(\.[^.]+)$/,
														'-1000x500$1'
													)} 2x`}
													alt={'courseMedia2'}
												/>
											</div>
										)}
									{course.acourseMedia2Type === 'link' &&
										course.acourseMedia2Url && (
											<div className="item">
												<EmbedCanvaYoutube source={course.acourseMedia2Url} />
											</div>
										)}
								</div>
							)}

							{course.acourseIntroduction2 &&
								course.acourseIntroduction2.length > 0 && (
									<div
										className="introContent"
										dangerouslySetInnerHTML={{
											__html: course.acourseIntroduction2
										}}
									/>
								)}

							{course.chapter && course.chapter.length > 0 && (
								<div className="courseChapters">
									<div className="sectionTitle">Hoofdstukken</div>
									<ol>
										{course.chapter
											.filter(chapter => chapter.achapterActive === '1')
											.map(chapter => (
												<li key={`chapter_${chapter.chapterNumber}`}>
													{chapter.chapterStatus === 'done' ||
													chapter.chapterStatus === 'in progress' ? (
														<Link to={chapter.achapterSlug}>
															{chapter.chapterTitle}
														</Link>
													) : (
														<span>{chapter.chapterTitle}</span>
													)}
												</li>
											))}
									</ol>
								</div>
							)}

							{course.acourseStatus === 'not started' && (
								<p>
									De opleiding blijft {course.acourseDuration} maanden
									beschikbaar voor je. Dat betekent dat je de opleiding moet
									afronden voor{' '}
									{moment()
										.add(course.acourseDuration, 'M')
										.locale('nl', localization)
										.format('D MMMM YYYY')}
									.
								</p>
							)}

							{course.acourseStatus === 'not started' && (
								<div className="confirmation">
									{course.acourseCost !== '0' && (
										<>
											{course.acourseQuotation && (
												<>
													<div className="sectionTitle">Offerte</div>
													<p>
														Mocht je op jouw school een offerte nodig hebben
														voordat je akkoord kunt gaan met de opleidingskosten
														dan kun je die hieronder downloaden.
													</p>
													<a
														target="_blank"
														className="pillButton quotationBtn"
														rel="noopener noreferrer"
														href={course.acourseQuotation}
													>
														Download offerte
													</a>
												</>
											)}

											<div className="sectionTitle">Vouchercode</div>
											<p>
												Heb je een vouchercode gekregen voor deze opleiding? Vul
												deze dan nu in.
											</p>
											<div className="voucherLink">
												{!voucherValidated && (
													<p
														className="link"
														onClick={() => setVoucherLink(!voucherLink)}
													>
														Heb je een vouchercode?
													</p>
												)}
												{voucherValidated && (
													<div className="validated">
														<p>
															Vouchercode
															<span className="code">{voucherCode}</span> is
															toegepast
														</p>
														<button
															type="button"
															className="pillButton danger inlineBlock"
															onClick={() => {
																setVoucherValidated(false);
															}}
														>
															Vouchercode verwijderen
														</button>
													</div>
												)}
											</div>

											<div className="sectionTitle">Start je opleiding</div>
											{!voucherValidated && (
												<div className="flexBlock">
													<input
														id="confirmation_1"
														type="checkbox"
														checked={isConfirmationChecked.one}
														onChange={() => {
															handleCheckbox('one');
														}}
													/>
													<label htmlFor="confirmation_1">
														Ja, ik begrijp dat ik een betaalde opleiding ga doen
														bij Klassewerk Onderwijstraining en ga namens mijn
														school akkoord met de opleidingskosten van{' '}
														{currency.format(course.acourseCost)}.
													</label>
												</div>
											)}

											<div className="flexBlock">
												<input
													id="confirmation_2"
													type="checkbox"
													checked={isConfirmationChecked.two}
													onChange={() => {
														handleCheckbox('two');
													}}
												/>
												<label htmlFor="confirmation_2">
													Ik heb de{' '}
													<a
														href={`${data_url}/staticfiles/koopovereenkomst-onderbouwdacademie.pdf`}
														download={
															'koopovereenkomst van Onderbouwd Academie'
														}
														target="_blank"
														rel="noopener noreferrer"
													>
														koopovereenkomst
													</a>{' '}
													gelezen, begrepen en ga hiermee namens mijn school
													akkoord
												</label>
											</div>
										</>
									)}

									<button
										type="button"
										disabled={
											course.acourseCost !== '0' &&
											(!isConfirmationChecked.one || !isConfirmationChecked.two)
										}
										className="outlineButton"
										onClick={handleCourseStart}
									>
										Start opleiding
									</button>
								</div>
							)}

							{course.acourseStatus === 'done' && (
								<div className="courseCertificate">
									<h3 className="headingStyle">
										Certificaat voor deze opleiding
									</h3>
									<p>
										{!certificateRequested
											? certificateMessage
											: 'Het certificaat is aangevraagd'}
									</p>
									{course.acourseuserCertificateStatus !== '3' && (
										<button
											type="button"
											disabled={
												course.acourseuserCertificateStatus !== '0' ||
												certificateRequested
											}
											className="outlineButton"
											onClick={requestCertificate}
										>
											{course.acourseuserCertificateStatus === '0' &&
												!certificateRequested &&
												'Vraag certificaat aan'}
											{(course.acourseuserCertificateStatus !== '0' ||
												certificateRequested) &&
												'Certificaat wordt gemaakt'}
										</button>
									)}
									{course.acourseuserCertificateStatus === '3' && (
										<a
											target="_blank"
											rel="noopener noreferrer"
											className="pillButton"
											href={course.acourseuserCertificateFile}
										>
											Bekijk certificaat
										</a>
									)}
								</div>
							)}

							{course.acourseStatus === 'done' &&
								course.acourseYourRating.ratingEditable === 1 && (
									<div className="courseFeedback">
										{course.acourseYourRating.currentRating !== null && !flag && (
											<div>
												<h3 className="headingStyle">
													Jouw beoordeling
													<button type="button" onClick={() => setFlag(true)}>
														<Edit />
													</button>
												</h3>
												<div className="ratingWrapper">
													<div>
														<Rating
															rating={course.acourseYourRating.currentRating}
														/>
													</div>
												</div>
												{feedback && <p>{feedback}</p>}
											</div>
										)}
										{flag && (
											<>
												<h3 className="headingStyle">
													Beoordeel deze opleiding
												</h3>
												<p>Hoe vond je deze opleiding?</p>
												<div className="feedbackWrapper">
													<div className="row">
														<label>Beoordeel deze opleiding</label>
														<div className="editableRating">
															{numbers.map(item => {
																var starclass = 'star-rating__star';

																if (rating >= item) {
																	starclass += ' is-selected';
																}

																return (
																	<label
																		key={item}
																		className={starclass}
																		onClick={() => rate(item)}
																		onMouseOver={() => star_over(item)}
																		onMouseOut={star_out}
																	>
																		★
																	</label>
																);
															})}
														</div>
													</div>

													<label>Eventuele opmerkingen</label>

													<textarea
														value={feedback === null ? '' : feedback}
														onChange={handleFeedback}
													/>

													<div>
														<button
															className="outlineButton"
															onClick={() => setFlag(false)}
														>
															Annuleren
														</button>
														<button
															className="outlineButton"
															onClick={handleFeedbackSubmit}
														>
															Opslaan
														</button>

														{loading && (
															<div className="circleLoader">
																<div className="draw" />
															</div>
														)}
													</div>
												</div>
												<div className="afterSubmit">
													<p>Bedankt voor het doorgeven van je beoordeling</p>
												</div>
											</>
										)}
									</div>
								)}
						</div>
					</div>
				)}
			</div>
			{voucherLink && (
				<VoucherPopup
					token={token}
					acourseId={course.acourseId}
					closePopup={closeVoucherPopup}
				/>
			)}
		</div>
	);
};

const mapStateToProps = state => ({
	token: state.initialData.token,
	userDisplayName: state.initialData.userDisplayName
});

export default withRouter(
	connect(mapStateToProps, { getChaptersHistory })(AcademyCourseIntroduction)
);

import React, { forwardRef, useImperativeHandle, useState } from 'react';

const ConfirmationPopup = forwardRef(({ content, handleSubmit }, ref) => {
	const [loaderRequested, setLoaderRequested] = useState(false);

	const handleClosePopup = () => {
		TweenMax.to('#confirmationPopup', 0.2, {
			autoAlpha: 0,
			onComplete: () => {}
		});
	};

	useImperativeHandle(ref, () => ({
		showPopup() {
			TweenMax.to('#confirmationPopup', 0.2, {
				autoAlpha: 1
			});
		},
		hidePopup() {
			handleClosePopup();
		},
		setLoader() {
			setLoaderRequested(!loaderRequested);
		},
		getLoader() {
			return loaderRequested;
		}
	}));

	return (
		<div id="confirmationPopup" className="popupWrapper">
			<div className="popup confirmationPopup">
				<p>{content}</p>

				<div className="row">
					<div />
					<div>
						{loaderRequested && (
							<div className="circleLoader">
								<div className="draw" />
							</div>
						)}
						<button
							type="button"
							className="inlineBlock outlineButton "
							onClick={() => handleClosePopup()}
						>
							Annuleren
						</button>

						<button
							type="button"
							className="inlineBlock defaultBtn"
							onClick={handleSubmit}
							style={{ marginLeft: '15px' }}
						>
							Ok
						</button>
					</div>
				</div>
			</div>
			<span className="overlayBackground" />
		</div>
	);
});

export default ConfirmationPopup;

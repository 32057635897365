// @import packages
import React, { Component } from 'react';
import { Redirect, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
// @import components
import ManageStudent from './manageStudent';
import ManageTeacher from './manageTeacher';
import ManageGroups from './manageGroups';
import ManageParents from './manageParents';
import ManageSettings from './manageSettings';
import MergeableStudents from './mergeableStudents';
import ManageParnasSysImport from './manageParnassysImport';
// @import styles
import '../../../css/containers/management.scss';

export class Management extends Component {
	state = {
		redirect: false
	};
	componentWillMount() {
		if (
			this.props.location.pathname === '/' ||
			(this.props.location.pathname === '/beheer/ouders' &&
				this.props.schoolParentsEnabled === '0')
		) {
			this.setState({
				redirect: true
			});
		}
	}
	render() {
		const { redirect } = this.state;
		if (redirect) {
			return <Redirect to={this.props.initialData.defaultUrl} />;
		}
		return (
			<div className="mainContainer">
				<div className="managementContainer">
					<div className="breadCrumb">
						<ul>
							<li>
								<NavLink to="/beheer/leerlingen" activeClassName="active">
									Leerlingen
								</NavLink>
							</li>
							<li>
								<NavLink to="/beheer/leerkrachten" activeClassName="active">
									Leerkrachten
								</NavLink>
							</li>
							<li>
								<NavLink to="/beheer/groepen" activeClassName="active">
									Groepen
								</NavLink>
							</li>
							{this.props.schoolParentsEnabled !== '0' && (
								<li>
									<NavLink to="/beheer/ouders" activeClassName="active">
										Ouders
									</NavLink>
								</li>
							)}
							{this.props.userMode === 'read' && (
								<li>
									<NavLink to="/beheer/parnassys" activeClassName="active">
										ParnasSys
									</NavLink>
								</li>
							)}
							<li>
								<NavLink to="/beheer/instellingen" activeClassName="active">
									Instellingen
								</NavLink>
							</li>
							{this.props.initialData.group0studentsmergeable && (
								<li>
									<NavLink
										to="/beheer/overgang-instroomleerling"
										activeClassName="active"
									>
										Overgang instroomleerling
									</NavLink>
								</li>
							)}
						</ul>
					</div>

					<section>
						{this.props.match.params.managetype === 'leerlingen' && (
							<ManageStudent />
						)}
						{this.props.match.params.managetype === 'leerkrachten' && (
							<ManageTeacher />
						)}
						{this.props.match.params.managetype === 'groepen' && (
							<ManageGroups />
						)}
						{this.props.match.params.managetype === 'ouders' && (
							<ManageParents />
						)}
						{this.props.userMode === 'read' &&
							this.props.match.params.managetype === 'parnassys' && (
								<ManageParnasSysImport />
							)}
						{this.props.match.params.managetype === 'instellingen' && (
							<ManageSettings token={this.props.token} />
						)}
						{this.props.initialData.group0studentsmergeable &&
							this.props.match.params.managetype ===
								'overgang-instroomleerling' && <MergeableStudents />}
					</section>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	initialData: state.initialData,
	token: state.initialData.token,
	schoolParentsEnabled: state.initialData.userData.schoolParentsEnabled,
	userMode: state.initialData.userData.userMode
});

export default connect(mapStateToProps, null)(Management);

// TODO Nouman: on all management screen show proper loader on group change

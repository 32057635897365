import React, { useEffect } from 'react';
import { ReactComponent as Cross } from '../../../images/svg/cross.svg';
import '../../../css/components/popup/parentLessonPopup.scss';

const ParentLessonPopup = ({
	data,
	closePopup,
	lessonName,
	lessonPicture,
	showVideoPopup,
	parentButtonStatus
}) => {
	useEffect(() => {
		const handleEsc = event => {
			if (event.keyCode === 27) {
				closePopup();
			}
		};
		window.addEventListener('keydown', handleEsc);

		return () => {
			window.removeEventListener('keydown', handleEsc);
		};
	}, []);

	return (
		<div className="popupWrapper parentLessonPopup">
			<div className="popup" style={{ maxWidth: '650px' }}>
				<button
					type="button"
					className="closePopup"
					onClick={() => closePopup()}
				>
					<Cross width={18} />
				</button>
				<div>
					<h2>Thuisles {lessonName}</h2>
					<p>
						Voor deze les is een thuisles beschikbaar. Deze thuisles is
						ontwikkeld voor leerlingen in de verschillende leerstappen. Bekijk
						hieronder de thuisles voor de verschillende leerstappen:
					</p>
					<div className="subAnchors">
						{data['A'] && data['A'].lessonMedia && (
							<a
								href={data['A'].lessonMedia}
								download={data['A'].lessonMediatype === 'file'}
								target={
									data['A'].lessonMediatype === 'file' ||
									data['A'].lessonMediatype === 'link'
										? '_blank'
										: '_self'
								}
								rel="noopener noreferrer"
								className="outlineButton inlineBlock"
								onClick={e => {
									if (data['A'].lessonMediatype === 'video') {
										e.preventDefault();
										showVideoPopup(
											'Leerstap A',
											data['A'].lessonMedia,
											lessonName,
											lessonPicture
										);
									}
								}}
							>
								Leerstap A
							</a>
						)}

						{data['B'] && data['B'].lessonMedia && (
							<a
								href={data['B'].lessonMedia}
								download={data['B'].lessonMediatype === 'file'}
								target={
									data['B'].lessonMediatype === 'file' ||
									data['B'].lessonMediatype === 'link'
										? '_blank'
										: '_self'
								}
								rel="noopener noreferrer"
								className="outlineButton inlineBlock"
								onClick={e => {
									if (data['B'].lessonMediatype === 'video') {
										e.preventDefault();
										showVideoPopup(
											'Leerstap B',
											data['B'].lessonMedia,
											lessonName,
											lessonPicture
										);
									}
								}}
							>
								Leerstap B
							</a>
						)}

						{data['C'] && data['C'].lessonMedia && (
							<a
								href={data['C'].lessonMedia}
								download={data['C'].lessonMediatype === 'file'}
								target={
									data['C'].lessonMediatype === 'file' ||
									data['C'].lessonMediatype === 'link'
										? '_blank'
										: '_self'
								}
								rel="noopener noreferrer"
								className="outlineButton inlineBlock"
								onClick={e => {
									if (data['C'].lessonMediatype === 'video') {
										e.preventDefault();
										showVideoPopup(
											'Leerstap C',
											data['C'].lessonMedia,
											lessonName,
											lessonPicture
										);
									}
								}}
							>
								Leerstap C
							</a>
						)}

						{data['D'] && data['D'].lessonMedia && (
							<a
								href={data['D'].lessonMedia}
								download={data['D'].lessonMediatype === 'file'}
								target={
									data['D'].lessonMediatype === 'file' ||
									data['D'].lessonMediatype === 'link'
										? '_blank'
										: '_self'
								}
								rel="noopener noreferrer"
								className="outlineButton inlineBlock"
								onClick={e => {
									if (data['D'].lessonMediatype === 'video') {
										e.preventDefault();
										showVideoPopup(
											'Leerstap D',
											data['D'].lessonMedia,
											lessonName,
											lessonPicture
										);
									}
								}}
							>
								Leerstap D
							</a>
						)}
					</div>
					<p>
						De thuisles staat op dit moment{' '}
						{parentButtonStatus === 0 ? 'uit' : 'aan'} en is dus{' '}
						{parentButtonStatus === 0 ? 'niet' : 'wel'} zichtbaar voor ouders.
					</p>
				</div>
			</div>
			<span className="overlayBackground" />
		</div>
	);
};

export default ParentLessonPopup;

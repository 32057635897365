// @import dependencies
import React from 'react';
// @import placeholder
import TextLinePlaceholder from './textLine';
import ImagePlaceholder from './Image';

const HistoryLoader = () => {
	return (
		<>
			<div
				className="pageIntroSec_placeholder"
				style={{ padding: 0, margin: 0 }}
			>
				<ImagePlaceholder width="100px" height="100px" margin="0 10px 0 0" />
				<div className="textWrapper">
					<TextLinePlaceholder width="80%" />
					<TextLinePlaceholder width="30%" height="10px" />
				</div>
			</div>

			<div className="stepHistory" style={{ background: 'white' }}>
				<div>
					<TextLinePlaceholder width="50%" height="15px" />
					<span>
						<TextLinePlaceholder width="100px" height="10px" />
					</span>
					<TextLinePlaceholder width="100%" height="10px" />
				</div>
			</div>
			<div className="stepHistory" style={{ background: 'white' }}>
				<div>
					<TextLinePlaceholder width="50%" height="15px" />
					<span>
						<TextLinePlaceholder width="100px" height="10px" />
					</span>
					<TextLinePlaceholder width="100%" height="10px" />
				</div>
			</div>
			<div className="stepHistory" style={{ background: 'white' }}>
				<div>
					<TextLinePlaceholder width="50%" height="15px" />
					<span>
						<TextLinePlaceholder width="100px" height="10px" />
					</span>
					<TextLinePlaceholder width="100%" height="10px" />
				</div>
			</div>
		</>
	);
};

export default HistoryLoader;

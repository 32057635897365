import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TweenMax } from 'gsap/TweenMax';
// @import constants
import {
	studentmergesource_endpoint,
	studentmergedestination_endpoint,
	studentmergeproceed_endpoint
} from '../../constants/endpoints';
// @import actions
import { sendErrorToHOC } from '../../../actions/actions';
// @import utils
import { axiosPost } from '../../utils/axiosCall';
// @import icons
import { ReactComponent as Convert } from '../../../images/svg/right-arrow.svg';

class MergeableStudents extends Component {
	constructor(props) {
		super(props);
		this.state = {
			step1Students: null,
			step2Students: null,
			sourceStudent: null,
			destinationStudent: null,
			isLoading: true,
			mergeConfirmation: false,
			mergeSucceed: false,
			isWaiting: false,
			loaderRequested: false
		};
	}

	componentDidMount() {
		this.getStep1Students();
	}

	getStep1Students = () => {
		const dataForm = new FormData();
		axiosPost(
			studentmergesource_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				this.setState({
					step1Students:
						axiosResult.status === 'no such data'
							? 'nodata'
							: axiosResult.data.students,
					isLoading: false
				});
			}
		);
	};

	getStep2Students = () => {
		const dataForm = new FormData();
		dataForm.append('sourceStudentId', this.state.sourceStudent.studentId);
		axiosPost(
			studentmergedestination_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				const destinationStudent = axiosResult.data.students.find(
					student => student.studentSelected === 1
				);
				TweenMax.to('.step-1', 0.2, {
					autoAlpha: 0,
					onComplete: () => {
						this.setState(
							{
								step2Students:
									axiosResult.status === 'no such data'
										? 'nodata'
										: axiosResult.data.students,
								destinationStudent: destinationStudent
									? destinationStudent.studentId
									: null,
								isLoading: false
							},
							() => {
								TweenMax.set('.step-1', { display: 'none' });
								TweenMax.set('.step-2', { display: 'flex' });
								TweenMax.to('.step-2', 0.2, { autoAlpha: 1 });
							}
						);
					}
				});
			}
		);
	};

	handleStep3 = () => {
		TweenMax.to('.step-2', 0.2, {
			autoAlpha: 0,
			onComplete: () => {
				this.setState(
					{
						mergeConfirmation: true
					},
					() => {
						TweenMax.set('.step-2', { display: 'none' });
						TweenMax.set('.step-3', { display: 'flex' });
						TweenMax.to('.step-3', 0.2, { autoAlpha: 1 });
					}
				);
			}
		});
	};

	handleStudentMerge = () => {
		this.setState({
			isWaiting: true,
			loaderRequested: true
		});
		const dataForm = new FormData();
		dataForm.append('sourceStudentId', this.state.sourceStudent.studentId);
		dataForm.append(
			'destinationStudentId',
			this.state.destinationStudent.studentId
		);
		axiosPost(
			studentmergeproceed_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					TweenMax.to('.step-3', 0.2, {
						autoAlpha: 0,
						onComplete: () => {
							this.setState(
								{
									mergeSucceed: true,
									isWaiting: false,
									loaderRequested: false
								},
								() => {
									TweenMax.set('.step-3', { display: 'none' });
									TweenMax.set('.step-4', { display: 'block' });
									TweenMax.to('.step-4', 0.2, { autoAlpha: 1 });
								}
							);
						}
					});
				}
			}
		);
	};

	render() {
		return (
			<div className="pageContainer mergeableStudents">
				<h1 className="headingStyle">Overgang instroomleerling</h1>
				<div className="step-1">
					<p>
						Als een instroomleerling 4 geworden is kun je hem toevoegen aan
						ParnasSys. Zodra je dat hebt gedaan wordt de leerling twee keer
						zichtbaar in ParnasSys: als instroomleerling en als leerling van de
						groep waar je hem in ParnasSys in hebt geplaatst. Via deze functie
						verplaats je eenmalig de resultaten van de instroomleerling naar de
						ParnasSys-leerling, zodat de doorgaande leerlijn vanaf instroom
						zichtbaar blijft. Kies hieronder de leerling van wie je de
						resultaten wilt verplaatsen en klik op Verder.
					</p>
					<div className="wrapper">
						<div className="studentListWrapper">
							{this.state.step1Students &&
								this.state.step1Students !== 'nodata' &&
								this.state.step1Students.map((item, i) => (
									<button
										type="button"
										className={`studentBox ${
											this.state.sourceStudent &&
											item.studentId === this.state.sourceStudent.studentId
												? 'active'
												: ''
										}`}
										key={item.studentId}
										onClick={() => {
											this.setState({
												sourceStudent: item
											});
										}}
									>
										<div className="aspectContainer">
											<img
												src={item.studentPicture.replace(
													/(\.[^.]+)$/,
													'-260x260$1'
												)}
												srcSet={`${item.studentPicture.replace(
													/(\.[^.]+)$/,
													'-520x520$1'
												)} 2x`}
												alt={item.studentDisplayname}
											/>
										</div>
										<strong>{item.studentDisplayname}</strong>
										{item.studentDisplayage && (
											<span>{item.studentDisplayage}</span>
										)}
									</button>
								))}
						</div>
					</div>
					<div className="buttonsWrapper">
						<button
							type="button"
							className={`outlineButton ${
								!this.state.sourceStudent ? 'disable' : ''
							}`}
							disabled={!this.state.sourceStudent}
							onClick={this.getStep2Students}
						>
							Verder
						</button>
					</div>
				</div>

				<div className="step-2">
					<p>
						Kies nu de ParnasSys-leerling waar de resultaten naartoe verplaatst
						moeten worden.
					</p>
					<div className="wrapper">
						<div className="studentListWrapper">
							{this.state.step2Students &&
								this.state.step2Students !== 'nodata' &&
								this.state.step2Students.map((item, i) => (
									<button
										type="button"
										className={`studentBox ${
											this.state.destinationStudent &&
											item.studentId === this.state.destinationStudent.studentId
												? 'active'
												: ''
										}`}
										key={item.studentId}
										onClick={() => {
											this.setState({
												destinationStudent: item
											});
										}}
									>
										<div className="aspectContainer">
											<img
												src={item.studentPicture.replace(
													/(\.[^.]+)$/,
													'-260x260$1'
												)}
												srcSet={`${item.studentPicture.replace(
													/(\.[^.]+)$/,
													'-520x520$1'
												)} 2x`}
												alt={item.studentDisplayname}
											/>
										</div>
										<strong>{item.studentDisplayname}</strong>
										{item.studentDisplayage && (
											<span>{item.studentDisplayage}</span>
										)}
									</button>
								))}
						</div>
					</div>
					<div className="buttonsWrapper">
						<button
							type="button"
							className="outlineButton"
							onClick={() => {
								TweenMax.to('.step-2', 0.2, {
									autoAlpha: 0,
									onComplete: () => {
										TweenMax.set('.step-2', { display: 'none' });
										TweenMax.set('.step-1', { display: 'flex' });
										TweenMax.to('.step-1', 0.2, { autoAlpha: '1' });
									}
								});
							}}
						>
							Terug
						</button>
						<button
							type="button"
							className={`outlineButton ${
								!this.state.destinationStudent ? 'disable' : ''
							}`}
							disabled={!this.state.destinationStudent}
							onClick={this.handleStep3}
						>
							Verder
						</button>
					</div>
				</div>

				{this.state.mergeConfirmation && (
					<div className="step-3">
						<p>Je gaat nu de gegevens van de volgende leerling verplaatsen:</p>
						<div className="wrapper">
							<div className="studentListWrapper">
								<div className="studentBox active">
									<span className="fromto">Van:</span>
									<div className="aspectContainer">
										<img
											src={this.state.sourceStudent.studentPicture.replace(
												/(\.[^.]+)$/,
												'-260x260$1'
											)}
											srcSet={`${this.state.sourceStudent.studentPicture.replace(
												/(\.[^.]+)$/,
												'-520x520$1'
											)} 2x`}
											alt={this.state.sourceStudent.studentDisplayname}
										/>
									</div>
									<strong>{this.state.sourceStudent.studentDisplayname}</strong>
									{this.state.sourceStudent.studentDisplayage && (
										<span>{this.state.sourceStudent.studentDisplayage}</span>
									)}
								</div>

								<Convert className="convert" />

								<div className="studentBox active">
									<span className="fromto">Naar:</span>
									<div className="aspectContainer">
										<img
											src={this.state.destinationStudent.studentPicture.replace(
												/(\.[^.]+)$/,
												'-260x260$1'
											)}
											srcSet={`${this.state.destinationStudent.studentPicture.replace(
												/(\.[^.]+)$/,
												'-520x520$1'
											)} 2x`}
											alt={this.state.destinationStudent.studentDisplayname}
										/>
									</div>
									<strong>
										{this.state.destinationStudent.studentDisplayname}
									</strong>
									{this.state.destinationStudent.studentDisplayage && (
										<span>
											{this.state.destinationStudent.studentDisplayage}
										</span>
									)}
								</div>
							</div>
						</div>
						<div className="buttonsWrapper">
							<button
								type="button"
								className="outlineButton"
								onClick={() => {
									this.setState({
										mergeConfirmation: false
									});
									TweenMax.to('.step-3', 0.2, {
										autoAlpha: 0,
										onComplete: () => {
											TweenMax.set('.step-3', { display: 'none' });
											TweenMax.set('.step-2', { display: 'flex' });
											TweenMax.to('.step-2', 0.2, { autoAlpha: '1' });
										}
									});
								}}
							>
								Terug
							</button>
							<button
								type="button"
								className={`outlineButton ${
									!this.state.destinationStudent ? 'disable' : ''
								}`}
								disabled={!this.state.destinationStudent}
								onClick={this.handleStudentMerge}
							>
								Afronden
							</button>
							{this.state.loaderRequested && (
								<div className="circleLoader">
									<div className="draw" />
								</div>
							)}
						</div>
					</div>
				)}

				{this.state.mergeSucceed && (
					<div className="step-4">
						<p>
							De resultaten van de instroomleerling zijn verplaatst en de
							instroomleerling is niet meer zichtbaar. Alle bestaande resultaten
							zijn nu zichtbaar bij de ParnasSys-leerling.
						</p>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
	userData: state.initialData.userData
});

export default connect(mapStateToProps, { sendErrorToHOC })(MergeableStudents);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TweenMax } from 'gsap/TweenMax';
// @import constants
import {
	perioddelete_endpoint,
	periodadd_endpoint,
	periodedit_endpoint,
	periodarchive_endpoint
} from '../../constants/endpoints';
// @import components
import ImageEditor from 'js/models/ImageEditor';
// @import svgs
import { ReactComponent as Cross } from '../../../images/svg/cross.svg';
// @import images
import picture from '../../../images/avatar.png';
// @import actions
import { sendErrorToHOC } from '../../../actions/actions';
// @import utils
import { axiosPost } from '../../utils/axiosCall';

var monthList = [
	'jan',
	'feb',
	'maa',
	'apr',
	'mei',
	'jun',
	'jul',
	'aug',
	'sep',
	'okt',
	'nov',
	'dec'
];
var monthToSeasonMap = {
	'1': 2,
	'2': 2,
	'3': 3,
	'4': 3,
	'5': 3,
	'6': 4,
	'7': 4,
	'8': 4,
	'9': 1,
	'10': 1,
	'11': 1,
	'12': 2,
};

class PeriodAddEditPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			periodId: this.props.data
				? this.props.data.periodId && this.props.data.periodId
				: '',
			periodName: this.props.data
				? this.props.data.periodName && this.props.data.periodName
				: '',
			periodStartMonth: this.props.data
				? this.props.data.periodStartMonth && this.props.data.periodStartMonth
				: '',
			periodStartDay: this.props.data
				? this.props.data.periodStartDay && this.props.data.periodStartDay
				: '',
			hidden: this.props.data ? this.props.data.attributes.hidden : 0,
			file: this.props.data
				? this.props.data.periodPicture &&
				  this.props.data.periodPicture.replace(/(\.[^.]+)$/, '-150x150$1')
				: null,
			seasonId: this.props.seasonId ? this.props.seasonId : '',
			isWaiting: false,
			loaderRequested: false
		};
		this.inputFile = null;
		this.editable = this.props.data ? this.props.data.attributes.editable : true;
		this.escFunction = this.escFunction.bind(this);
	}

	componentDidMount() {
		document.addEventListener('keydown', this.escFunction, false);
	}
	componentWillUnmount() {
		document.removeEventListener('keydown', this.escFunction, false);
	}

	escFunction(event) {
		if (event.keyCode === 27) {
			console.log('Period add edit');
			this.props.hidepopup();
		}
	}

	handleFirstNameChange = event => {
		const periodName = event.target.value.replace(/\\|\//g, '');
		this.setState({
			periodName
		});
	};

	handleStartDayChange = event => {
		const periodStartDay = event.target.value;
		this.setState({
			periodStartDay
		});
	};

	handleStartMonthChange = event => {
		const periodStartMonth = event.target.value;
		this.setState({
			periodStartMonth
		});
	};

	handlePictureChange = event => {
		this.setState({
			imagePath: event.target.files[0]
		});
	};

	croppedImageCallback = file => {
		this.setState({
			imagePath: null,
			croppedImagePath: file,
			file: URL.createObjectURL(file)
		});
	};

	openDeletePopup = () => {
		TweenMax.to('.addEditWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				TweenMax.set('.addEditWrapper', { display: 'none' });
				TweenMax.set('.deleteWrapper', { display: 'block', autoAlpha: 0 });
				TweenMax.to('.deleteWrapper', 0.3, { autoAlpha: 1 });
			}
		});
	};

	hideDeletePopup = () => {
		TweenMax.to('.deleteWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				TweenMax.set('.deleteWrapper', { display: 'none' });
				TweenMax.set('.addEditWrapper', { display: 'block', autoAlpha: 0 });
				TweenMax.to('.addEditWrapper', 0.3, { autoAlpha: 1 });
			}
		});
	};

	handleArchive = status => {
		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		const dataForm = new FormData();
		dataForm.append('periodId', this.state.periodId);
		dataForm.append('newStatus', status);
		axiosPost(
			periodarchive_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState(
						{
							isWaiting: false
						},
						() => {
							const loader = document.querySelector('.circleLoader');
							const check = loader.querySelector('.draw');

							loader.classList.add('loadComplete');
							check.classList.add('checkmark');
						}
					);
					this.props.updateStudents();
					setTimeout(() => {
						this.props.hidepopup();
					}, 500);
				} else {
					this.setState({
						isWaiting: false,
						loaderRequested: false,
						errorMessage: axiosResult.failreason
					});
				}
			}
		);
	};

	handleDelete = () => {
		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		const dataForm = new FormData();
		dataForm.append('periodId', this.state.periodId);
		axiosPost(
			perioddelete_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState(
						{
							isWaiting: false
						},
						() => {
							const loader = document.querySelector('.circleLoader');
							const check = loader.querySelector('.draw');

							loader.classList.add('loadComplete');
							check.classList.add('checkmark');
						}
					);
					this.props.updateStudents();
					setTimeout(() => {
						this.props.hidepopup();
					}, 500);
				} else {
					this.setState({
						isWaiting: false,
						loaderRequested: false,
						errorMessage: axiosResult.failreason
					});
				}
			}
		);
	};

	submitrequest = () => {
		let date = '2021-10-13';
		let url;
		if (this.props.name === 'add') {
			url = periodadd_endpoint;
		}
		if (this.props.name === 'edit') {
			url = periodedit_endpoint;
		}

		const dataForm = new FormData();
		if (this.props.name === 'edit') {
			dataForm.append('periodId', this.state.periodId);
		}
		dataForm.append('periodName', this.state.periodName);
		if (this.state.croppedImagePath) {
			dataForm.append('periodPicture', this.state.croppedImagePath);
		}
		//if (this.props.name === 'add') {
			dataForm.append('seasonId', monthToSeasonMap[this.state.periodStartMonth.toString()]);
		//}
		dataForm.append('periodStartDay', this.state.periodStartDay);
		dataForm.append('periodStartMonth', this.state.periodStartMonth);

		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		axiosPost(url, dataForm, this.props.token, axiosResult => {
			if (axiosResult.status === 'success') {
				this.setState(
					{
						isWaiting: false
					},
					() => {
						const loader = document.querySelector('.circleLoader');
						const check = loader.querySelector('.draw');
						loader.classList.add('loadComplete');
						check.classList.add('checkmark');
					}
				);
				const { seasonId } = this.state;
				this.props.updateStudents(seasonId);
				setTimeout(() => {
					this.props.hidepopup();
				}, 500);
			} else {
				this.setState({
					isWaiting: false,
					loaderRequested: false,
					errorMessage: axiosResult.failreason
				});
			}
		});
	};


	render() {
		return (
			<div className="themeAddEditWrapper popupWrapper studentPopupWrapper">
				<div className="popup addEditWrapper">
					<button
						type="button"
						className="closePopup"
						onClick={() => {
							this.props.hidepopup();
						}}
					>
						<Cross width={18} />
					</button>

					{this.props.name === 'add' ? (
						<h2 className="headingStyle">Thema toevoegen</h2>
					) : (
						<h2 className="headingStyle">Bewerk thema</h2>
					)}
					{this.editable && <>
						<label>Naam</label>
						<input
							type="text"
							className="inputField"
							value={this.state.periodName}
							onChange={this.handleFirstNameChange}
							required
						/>
					</>}
					<div className="startDateWrap">
						<label>Startdatum</label>
						<div className="dropDownWrap">
							<select
								value={this.state.periodStartDay}
								onChange={this.handleStartDayChange}
							>
								<option value="" disabled>
									Dag...
								</option>
								{[...Array(31)].map((item, i) => (
									<option key={i} value={i + 1}>
										{i + 1}
									</option>
								))}
							</select>
							<select
								value={this.state.periodStartMonth}
								onChange={this.handleStartMonthChange}
							>
								<option value="" disabled>
									Maand...
								</option>
								{monthList.map((item, i) => (
									<option key={i} value={i + 1}>
										{item}
									</option>
								))}
							</select>
						</div>
					</div>

					{this.editable && <>
					<label>Afbeelding</label>
					<div className="row">
						<input
							ref={elem => (this.inputFile = elem)}
							type="file"
							accept="image/*"
							onChange={this.handlePictureChange}
							onClick={event => (event.target.value = '')}
						/>

						{this.state.file ? (
							<img src={this.state.file} alt="" className="profilePicture" />
						) : (
							<img
								src={picture.replace(/(\.[^.]+)$/, '-150x150$1')}
								srcSet={`${picture.replace(/(\.[^.]+)$/, '-300x300$1')} 2x`}
								alt=""
								className="profilePicture"
							/>
						)}
					</div>
					</>}
					{this.state.errorMessage && (
						<div className="error">* {this.state.errorMessage}</div>
					)}
					<div className="row buttonsWrapper">
						{this.props.name === 'edit' ? (
							<div>
								{this.props.data.attributes?.deletable == '1' && (
									<button
										type="button"
										className="outlineButton inlineBlock"
										onClick={this.openDeletePopup}
										style={{ marginRight: '15px' }}
									>
										Verwijderen
									</button>
								)}
								<button
									type="button"
									className="outlineButton inlineBlock"
									onClick={() =>
										this.handleArchive(
											this.props.data.attributes.hidden == 1
												? 'unarchive'
												: 'archive'
										)
									}
								>
									{this.props.data.attributes.hidden == 1
										? 'Activeer'
										: 'Archiveer'}
								</button>
							</div>
						) : <div />}
						<div>
							{this.state.loaderRequested && (
								<div className="circleLoader">
									<div className="draw" />
								</div>
							)}
							<button
								type="button"
								className="outlineButton inlineBlock"
								onClick={this.props.hidepopup}
							>
								Annuleren
							</button>
							<button
								type="button"
								className="defaultBtn inlineBlock"
								onClick={this.submitrequest}
								style={{ marginLeft: '15px' }}
							>
								Opslaan
							</button>
						</div>
					</div>
				</div>

				<div className="popup deleteWrapper">
					<p>
						Weet je zeker dat je dit thema wilt verwijderen? Dit kan niet
						ongedaan gemaakt worden.
					</p>

					<div className="row">
						<div />
						<div>
							{this.state.loaderRequested && (
								<div className="circleLoader">
									<div className="draw" />
								</div>
							)}
							<button
								type="button"
								className="inlineBlock outlineButton "
								onClick={this.hideDeletePopup}
							>
								Annuleren
							</button>

							<button
								type="button"
								className="inlineBlock defaultBtn"
								onClick={this.handleDelete}
								style={{ marginLeft: '15px' }}
							>
								Ok
							</button>
						</div>
					</div>
				</div>

				{this.state.imagePath && (
					<ImageEditor
						fileName={this.state.imagePath.name}
						src={URL.createObjectURL(this.state.imagePath)}
						handleClose={() => {
							this.setState({
								imagePath: null
							});
							this.inputFile.value = '';
						}}
						callback={this.croppedImageCallback}
					/>
				)}
				<span className="overlayBackground" />
			</div>
		);
	}
}

export default connect(null, {
	sendErrorToHOC
})(PeriodAddEditPopup);

import React from 'react';
import Lottie from 'lottie-react-web';
// @import animation
import animationData from './loader.json';
// @import styles
import './index.scss';

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice'
	}
};

const Loader = () => {
	return (
		<div className="loaderWrapper">
			<Lottie options={defaultOptions} speed={2} />
		</div>
	);
};

export default Loader;

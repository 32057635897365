import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { TweenLite } from 'gsap/TweenMax';
import moment from 'moment';
import localization from 'moment/locale/nl';
import debounce from 'lodash.debounce';
// @import constants
import { newslist_endpoint } from '../constants/endpoints';
// @import styles
import '../../css/components/news.scss';
// @import actions
import { sendErrorToHOC } from '../../actions/actions';
// @import utils
import { axiosPost } from '../utils/axiosCall';

export class News extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			hasMore: true,
			newsList: [],
			offset: 0
		};
	}

	componentDidMount() {
		this.getNews();
		window.addEventListener('scroll', this.handleScroll(), { passive: false });
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll(), {
			passive: false
		});
	}

	handleScroll = () =>
		debounce(() => {
			if (
				document.documentElement.scrollTop + window.innerHeight ===
				document.documentElement.scrollHeight
			) {
				// get more news
				if (this.state.hasMore) {
					this.getNews();
				}
			}
		}, 100);

	getNews = () => {
		this.setState({
			isLoading: true
		});
		const dataForm = new FormData();
		dataForm.append('offset', this.state.offset);
		axiosPost(newslist_endpoint, dataForm, this.props.token, axiosResult => {
			if (axiosResult.status === 'success') {
				const localNewsId = localStorage.getItem('newsId');
				if (
					localNewsId !== axiosResult.data.news[0].newsId &&
					this.state.offset === 0
				) {
					const navMenu = document.querySelector('a[data-name="Nieuws"]');
					navMenu.classList.remove('notify');
					localStorage.setItem('notify', 'false');
					localStorage.setItem('newsId', axiosResult.data.news[0].newsId);
				}

				const newsList = this.state.newsList;
				for (let i = 0; i < axiosResult.data.news.length; i++) {
					newsList.push(axiosResult.data.news[i]);
					if (i === axiosResult.data.news.length - 1) {
						this.setState({
							isLoading: false,
							newsList: newsList,
							offset: Number(axiosResult.data.offset) + 10
						});
					}
				}
			}
			if (axiosResult.status === 'no such data') {
				this.setState({
					isLoading: false,
					hasMore: false
				});
			}
		});
	};

	handleReadMore = event => {
		const button = event.currentTarget;
		const readMoreWrapper = button.previousElementSibling;
		if (readMoreWrapper.classList.contains('closed')) {
			TweenLite.set(readMoreWrapper, { height: 'auto' });
			TweenLite.from(readMoreWrapper, 0.2, {
				height: 0,
				onComplete: () => {
					readMoreWrapper.classList.remove('closed');
					readMoreWrapper.classList.add('opened');
					button.textContent = 'Lees minder';
				}
			});
		} else {
			TweenLite.to(readMoreWrapper, 0.2, {
				height: 0,
				onComplete: () => {
					readMoreWrapper.classList.remove('opened');
					readMoreWrapper.classList.add('closed');
					button.textContent = 'Lees verder';
				}
			});
		}
	};

	render() {
		// console.log(this.state.newsList);
		return (
			<div className="mainContainer">
				<div className="pageContainer nieuwsScreen">
					<Helmet>
						<link
							href="https://fonts.googleapis.com/css?family=Schoolbell&display=swap"
							rel="stylesheet"
						/>
					</Helmet>

					{this.state.newsList && (
						<div className="innerContainer nieuwsScreen">
							{this.state.newsList.map((item, i) => {
								let { newsContent, newsIntro } = item;
								if (newsContent) {
									newsContent = newsContent
										.replace(/(<p>&nbsp;<\/p>)+$/, '')
										.replace(/(<p><br>&nbsp;<\/p>)+$/, '');
								}
								if (newsIntro) {
									newsIntro = newsIntro
										.replace(/(<p>&nbsp;<\/p>)+$/, '')
										.replace(/(<p><br>&nbsp;<\/p>)+$/, '');

									// canva
									newsIntro = newsIntro.replace(
										'<oembed url="https://www.canva.com/design/',
										'<div style="position: relative; width: 100%; height: 0; padding-top: 56.2500%; padding-bottom: 48px; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden; border-radius: 8px; will-change: transform;"><iframe style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;" src="https://www.canva.com/design/'
									);
									newsIntro = newsIntro.replace(
										'/view"></oembed>',
										'/view?embed"></iframe></div>'
									);

									// youtube
									newsIntro = newsIntro.replace(
										'<oembed url="https://www.youtube.com/watch?v=',
										'<iframe allowfullscreen allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" src="https://www.youtube-nocookie.com/embed/'
									);
									newsIntro = newsIntro.replace('</oembed>', '</iframe>');
								}

								return (
									<section className="newsComponent" key={i}>
										<div className="contentSec">
											<h2>{item.newsTitle}</h2>
											<strong>
												{moment(new Date(item.newsDate))
													.locale('nl', localization)
													.format('DD MMMM YYYY')}
											</strong>
											<div dangerouslySetInnerHTML={{ __html: newsIntro }} />

											<div className="inlineContainer">
												{item.actionlink1Text && (
													<a
														href={item.actionlink1Url}
														className="actionlink"
														target="_blank"
														rel="noopener noreferrer"
													>
														{item.actionlink1Text}
													</a>
												)}

												{item.actionlink2Text && (
													<a
														href={item.actionlink2Url}
														className="actionlink"
														target="_blank"
														rel="noopener noreferrer"
													>
														{item.actionlink2Text}
													</a>
												)}
											</div>
										</div>
										{item.newsImage && (
											<div className="imageBox">
												<h2>{item.newsTitle}</h2>
												<strong>
													{moment(item.newsDate)
														.locale('nl', localization)
														.format('DD MMM YYYY')}
												</strong>
												<img
													src={item.newsImage.replace(
														/(\.[^.]+)$/,
														'-520x350$1'
													)}
													srcSet={`${item.newsImage.replace(
														/(\.[^.]+)$/,
														'-1100x700$1'
													)} 2x`}
													alt={item.newsTitle}
													title={item.newsTitle}
												/>
											</div>
										)}
									</section>
								);
							})}
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token
});

export default connect(mapStateToProps, { sendErrorToHOC })(News);

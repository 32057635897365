export const FETCH_USER = 'FETCH_USER';
export const SIGN_IN = 'SIGN_IN';
export const FETCH_INITIAL_DATA = 'FETCH_INITIAL_DATA';
export const FETCH_PERIOD_DATA = 'FETCH_PERIOD_DATA';
export const FETCH_LESSON = 'FETCH_LESSON';
export const FETCH_GROUP_LIST = 'FETCH_GROUP_LIST';
export const FETCH_STUDENT_LIST = 'FETCH_STUDENT_LIST';
export const STUDENT_STEP = 'STUDENT_STEP';
export const LESSON_STEP = 'LESSON_STEP';
export const START_OBSERVATION = 'START_OBSERVATION';
export const CHANGE_ROUTEENLESSON = 'CHANGE_ROUTEENLESSON';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_GROUP_ID = 'SET_GROUP_ID';
export const SET_STUDENT_LIST = 'SET_STUDENT_LIST';
export const SET_STUDENT_STEP_SUCCESS = 'SET_STUDENT_STEP_SUCCESS';
export const SHOW_INFO_POPUP = 'SHOW_INFO_POPUP';
export const HIDE_INFO_POPUP = 'HIDE_INFO_POPUP';
export const SEND_ERROR_TO_HOC = 'SEND_ERROR_TO_HOC';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// @import constants
import { getpurchaseditems_endpoint } from 'js/constants/endpoints';
// @import components
import ProductWrapper from 'js/containers/Home/components/ProductWrapper';
// @import styles
import 'css/containers/home.scss';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';

const MyPurchases = props => {
	const [data, setData] = useState(null);
	useEffect(() => {
		fetchList();
	}, []);

	const fetchList = () => {
		axiosPost(getpurchaseditems_endpoint, null, props.token, response => {
			if (response.status === 'success') {
				setData(response.data.shopitems || null);
			}
		});
	};

	return (
		<div className="mainContainer">
			<div className="pageContainer">
				<div className="contentSec">
					<h1 className="headingStyle">Mijn Aankopen</h1>
				</div>
				{data && (
					<>
						{data.length > 0 ? (
							<ProductWrapper
								purchased={true}
								shopItemsList={data}
								schoolDisplayname={props.userData.schoolDisplayname}
							/>
						) : (
							<p>
								Hier zie je de aankopen die je hebt gedaan bij KlasseWerk in de
								Materialenwinkel. Je hebt nog geen aankopen gedaan.
							</p>
						)}
					</>
				)}
			</div>
		</div>
	);
};

// export default MyPurchases;

const mapStateToProps = state => ({
	token: state.initialData.token,
	userData: state.initialData.userData
});

export default connect(mapStateToProps, null)(MyPurchases);

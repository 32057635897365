import axios from 'axios';
import { sendErrorToHOClocal } from '../../actions/actions';
import store from '../../store';

export function axiosPost(endpoint, dataForm, token, callback) {
  axios
    .post(endpoint, dataForm, {
      headers: {
        Authtoken: token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(res => {
      callback(res.data);
    })
    .catch(function(error) {
      const errorDetails = `api endpoint: ${endpoint} , error: ${error}  , pagename: ${window &&
        window.location}`;
      store.dispatch(sendErrorToHOClocal(errorDetails));
    });
}

export function axiosGet(endpoint, token, callback) {
  axios
    .get(endpoint, {
      headers: {
        Authtoken: token
      }
    })
    .then(res => {
      callback(res.data);
    })
    .catch(function(error) {
      const errorDetails = `api endpoint: ${endpoint} , error: ${error}  , pagename: ${window &&
        window.location}`;
      store.dispatch(sendErrorToHOClocal(errorDetails));
    });
}

import {
	FETCH_INITIAL_DATA,
	FETCH_USER,
	SET_STUDENT_STEP_SUCCESS,
	FETCH_STUDENT_LIST,
	FETCH_PERIOD_DATA,
	START_OBSERVATION,
	SIGN_IN,
	STUDENT_STEP,
	LESSON_STEP,
	CHANGE_ROUTEENLESSON,
	SET_TOKEN,
	SET_GROUP_ID,
	SET_STUDENT_LIST,
	SEND_ERROR_TO_HOC
} from '../actions/types';

const initialState = {
	token: null,
    topics: null,
	defaultUrl: null,
	userData: null,
	groupList: null,
	studentList: null,
	periodData: null,
	lessons: null,
	shopItemsList: [],
	routinelessons: null,
	periodFail: null,
	students: null,
	lessonTree: null,
	seasonId: null,
	periodId: null,
	topicId: null,
	groupId: null,
	activeLessonId: null,
	stepdescriptions: null,
	groupName: null,
	isLoading: true,
	studentStepSuccess: null,
	routeenLessonStepSuccess: null,
	showInfoPopup: false,
	informationData: null,
	group0studentsmergeable: false,
	HOCerror: null,
	schoolParentsEnabled: null,
	showintroduction: null,
	genericSettings: null,
	signoutUrl: null
};

// export const authReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case SIGN_IN:
//       return {
//         ...state,
//         token: action.payload.auth.token,
//         groupId: action.payload.data.defaultgroup.groupId,
//         groupName: action.payload.data.defaultgroup.groupName,
//         seasonId: action.payload.data.defaultlessontreestatus.defaultSeason,
//         periodId: action.payload.data.defaultlessontreestatus.efaultPeriod,
//         topicId: action.payload.data.defaultlessontreestatus.defaultTopic
//       };
//     default:
//       return {
//         ...state
//       };
//   }
// };

export const initialReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_TOKEN:
			return {
				...state,
				token: action.payload
			};
		case SET_GROUP_ID:
			return {
				...state,
				groupId: action.groupId,
				groupName: action.groupName
			};
		case SIGN_IN:
			return {
				...state,
				token: action.payload.auth.token,
				topics: action.payload.data.topics,
				defaultUrl:
					action.payload.data.defaultUrl && action.payload.data.defaultUrl,
				groupId:
					action.payload.data.defaultgroup &&
					action.payload.data.defaultgroup.groupId,
				groupName:
					action.payload.data.defaultgroup &&
					action.payload.data.defaultgroup.groupName,
				seasonId:
					action.payload.data.defaultlessontreestatus &&
					action.payload.data.defaultlessontreestatus.defaultSeason,
				periodId:
					action.payload.data.defaultlessontreestatus &&
					action.payload.data.defaultlessontreestatus.defaultPeriod,
				topicId:
					action.payload.data.defaultlessontreestatus &&
					action.payload.data.defaultlessontreestatus.defaultTopic,
			};
		case FETCH_INITIAL_DATA:
			return {
				...state,
				defaultUrl: action.payload.defaultUrl
					? action.payload.defaultUrl
					: state.defaultUrl,
				signoutUrl: action.payload.signout?.redirect,
				userData: action.payload.user && action.payload.user,
				groupList: action.payload.grouplist && action.payload.grouplist,
				// studentList: action.payload.studentlist && action.payload.studentlist,
				showintroduction:
					action.payload.academy && action.payload.academy.showintroduction,
				schoolParentsEnabled:
					action.payload.user.schoolParentsEnabled &&
					action.payload.user.schoolParentsEnabled,
				schoolAcademyEnabled:
					action.payload.user.schoolAcademyEnabled &&
					action.payload.user.schoolAcademyEnabled,
				group0studentsmergeable:
					action.payload.group0studentsmergeable &&
					action.payload.group0studentsmergeable,
				lessonTree: action.payload.lessontree && action.payload.lessontree,
				groupId:
					action.payload.defaultgroup && action.payload.defaultgroup.groupId,
				groupName:
					action.payload.defaultgroup && action.payload.defaultgroup.groupName,
				seasonId:
					action.payload.defaultlessontreestatus &&
					action.payload.defaultlessontreestatus.defaultSeason,
				periodId:
					action.payload.defaultlessontreestatus &&
					action.payload.defaultlessontreestatus.defaultPeriod,
				topicId:
					action.payload.defaultlessontreestatus &&
					action.payload.defaultlessontreestatus.defaultTopic,
				topics: action.payload.topics,
				genericSettings: action.payload.settings,
				isLoading: false
			};
		case FETCH_PERIOD_DATA:
			return {
				...state,
				lessons:
					action.status === 'success'
						? action.payload.lessons
							? action.payload.lessons
							: 'nodata'
						: 'nodata',
				routinelessons:
					action.status === 'success'
						? action.payload.routinelessons
							? action.payload.routinelessons
							: 'nodata'
						: 'nodata',
				shopItemsList: action.payload.shopitems || [],
				periodFail: action.failreason ? action.failreason : null
				// students:
				// 	action.status === 'success' ? action.payload.students : 'nodata'
			};
		case LESSON_STEP:
			return {
				...state,
				students: action.payload.students,
				stepdescriptions: action.payload.stepdescriptions,
				activeLessonId:
					action.status === 'success'
						? action.payload.relatedlesson.lessonId
						: 'nodata'
			};
		case STUDENT_STEP:
			return {
				...state,
				studentStepSuccess: action.payload
			};
		case SET_STUDENT_STEP_SUCCESS:
			return {
				...state,
				studentStepSuccess: action.payload
			};
		case CHANGE_ROUTEENLESSON:
			return {
				...state,
				routeenLessonStepSuccess: action.payload
			};
		case START_OBSERVATION:
			return {
				...state,
				lessons: action.payload.lessons,
				routinelessons: action.payload.routinelessons,
				students: action.payload.students
			};
		case FETCH_STUDENT_LIST:
			return {
				...state,
				studentList:
					action.payload.status === 'success'
						? action.payload.data.students
						: 'nodata'
				// groupId:
				//   action.payload.status === 'success'
				//     ? action.payload.data.group.groupId
				//     : action.groupId,
				// groupName:
				//   action.payload.status === 'success'
				//     ? action.payload.data.group.groupName
				//     : action.groupName
			};
		case SET_STUDENT_LIST:
			return {
				...state,
				studentList: action.studentList,
				groupId: action.groupId,
				groupName: action.groupName
			};
		case SEND_ERROR_TO_HOC:
			return {
				...state,
				HOCerror: action.payload
			};
		default:
			return {
				...state
			};
	}
};

export const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_USER:
			return {
				...state,
				userData: action.payload.data
			};
		default:
			return {
				...state
			};
	}
};

// export const groupReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case FETCH_GROUP_LIST:
//       return {
//         ...state,
//         groupList: action.payload.data
//       };
//     case FETCH_STUDENT_LIST:
//       return {
//         ...state,
//         studentList: action.payload
//       };
//     default:
//       return {
//         ...state
//       };
//   }
// };

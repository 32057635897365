import React from 'react';
// @import placeholder
import Image from './Image';
import TextLine from './textLine';
// @import styles
import '../../../css/components/placeholder/listItem.scss';

const ListItem = ({ width, height, margin }) => {
  return (
    <div className="listItem">
      <Image width="45px" height="45px" margin="0 10px 0 0" />
      <TextLine height="10px" width="75%" />
    </div>
  );
};

export default ListItem;

import React from 'react';
// @import styles
import '../../../css/components/placeholder/image.scss';

const Image = ({ width, height, margin }) => {
  return (
    <div
      className="image"
      style={{
        width: width ? width : '100%',
        height: height ? height : '100%',
        margin: margin ? margin : '0'
      }}
    />
  );
};

export default Image;

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
// @import components
import AcademyCourseOverview from './courseOverview.js';
import AcademyIntroduction from './academy-introduction.js';
import AcademyCourseIntroduction from './courseIntroduction';
import AcademyChapterDetails from './chapterDetails';
// @import actions
import { getChaptersHistory } from '../../../actions/academyActions';
const index = props => {
	if (!props.coursehistory) {
		props.getChaptersHistory(props.token);
	}
	return (
		<Switch>
			<Route path={`/academie/intro`} exact component={AcademyIntroduction} />
			<Route
				exact
				path={`/academie/opleiding`}
				component={AcademyCourseOverview}
			/>
			<Route
				exact
				path={`/academie/opleiding/:coursename`}
				component={AcademyCourseIntroduction}
			/>
			<Route
				exact
				path={`/academie/opleiding/:coursename/:chapter`}
				component={AcademyChapterDetails}
			/>
		</Switch>
	);
};

const mapStateToProps = state => ({
	token: state.initialData.token,
	coursehistory: state.academy.coursehistory
});

export default connect(mapStateToProps, { getChaptersHistory })(index);

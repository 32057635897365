// @import packages
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { TweenMax } from 'gsap/TweenMax';
import { Redirect, Link } from 'react-router-dom';
// @import endpoints
import {
	parentdashboard_endpoint,
	parentstipstatus_endpoint
} from '../../constants/endpoints';
// @import utils
import { axiosPost } from '../../utils/axiosCall';
import { topicColor } from '../../utils/topicColor';
// @import components
import LessonComponent from '../../components/LessonComponent';
import RoutineLessonSection from '../../components/routineLessonSection.js';
import YoutubeVideoPopup from '../../components/popup/youtubeVideoPopup';
import IframeLessonPopup from '../../components/popup/iframeLessonPopup';
// @import styles
import '../../../css/containers/parents/home.scss';
// @import icons
import { ReactComponent as Close } from '../../../images/svg/up-arrow.svg';

const ParentTips = ({ data, setVideoUrl, studentId, token }) => {
	// console.log(data, studentId, token);
	const [openDetails, setOpenDetails] = useState(
		data.parenttipStatus === 'todo'
	);

	function handleParentLessonStatus() {
		setOpenDetails(!openDetails);
		const dataForm = new FormData();
		dataForm.append('parenttipId', data.parenttipId);
		dataForm.append('studentId', studentId);
		dataForm.append('newstatus', openDetails ? 'done' : 'todo');
		axiosPost(parentstipstatus_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
			}
		});
	}

	return (
		<div
			className={`parentTipSection ${topicColor(data.parenttipColourcode)} ${
				openDetails ? 'open' : 'closed'
			}`}
		>
			<button
				type="button"
				className={`closeIcon ${openDetails ? 'active' : ''}`}
				onClick={handleParentLessonStatus}
			>
				<Close />
			</button>
			<div className="goalSec">
				<h3>{data.goal.goalName}</h3>
				<p>{data.goal.lessonIntroduction}</p>
				<img
					src={data.goal.goalPicture.replace(/(\.[^.]+)$/, '-150x150$1')}
					srcSet={`${data.goal.goalPicture.replace(
						/(\.[^.]+)$/,
						'-300x300$1'
					)} 2x`}
					alt={data.goal.goalName}
				/>
			</div>
			<div className="tipSec">
				<h3>{data.parenttipTitle}</h3>
				<div className="row">
					<div>
						<div
							dangerouslySetInnerHTML={{
								__html: data.parenttipText
							}}
						/>
						<div className="subAnchors">
							{data.parenttipMedia2Url && (
								<a
									href={data.parenttipMedia2Url}
									download={data.parenttipMedia2Type === 'file'}
									target={
										data.parenttipMedia2Type === 'file' ||
										data.parenttipMedia2Type === 'link'
											? '_blank'
											: '_self'
									}
									className="outlineButton inlineBtn"
									rel="noopener noreferrer"
									onClick={e => {
										if (data.parenttipMedia2Type === 'video') {
											e.preventDefault();
											setVideoUrl(
												data.parenttipMedia2Url.replace('watch?v=', 'embed/')
											);
											setTimeout(() => {
												TweenMax.to('.popupWrapper', 0.3, {
													autoAlpha: 1
												});
											}, 500);
										}
									}}
								>
									{data.parenttipMedia2Title}
								</a>
							)}

							{data.parenttipMedia3Url && (
								<a
									href={data.parenttipMedia3Url}
									download={data.parenttipMedia3Type === 'file'}
									target={
										data.parenttipMedia3Type === 'file' ||
										data.parenttipMedia3Type === 'link'
											? '_blank'
											: '_self'
									}
									className="outlineButton inlineBtn"
									rel="noopener noreferrer"
									onClick={e => {
										if (data.parenttipMedia3Type === 'video') {
											e.preventDefault();
											setVideoUrl(
												data.parenttipMedia3Url.replace('watch?v=', 'embed/')
											);
											setTimeout(() => {
												TweenMax.to('.popupWrapper', 0.3, {
													autoAlpha: 1
												});
											}, 500);
										}
									}}
								>
									{data.parenttipMedia3Title}
								</a>
							)}

							{data.parenttipMediaStepDependentUrl1 && (
								<a
									href={data.parenttipMediaStepDependentUrl1}
									download={data.parenttipMediaStepDependentType1 === 'file'}
									target={
										data.parenttipMediaStepDependentType1 === 'file' ||
										data.parenttipMediaStepDependentType1 === 'link'
											? '_blank'
											: '_self'
									}
									className="outlineButton inlineBtn"
									rel="noopener noreferrer"
									onClick={e => {
										if (data.parenttipMediaStepDependentType1 === 'video') {
											e.preventDefault();
											setVideoUrl(
												data.parenttipMediaStepDependentUrl1.replace(
													'watch?v=',
													'embed/'
												)
											);
											setTimeout(() => {
												TweenMax.to('.popupWrapper', 0.3, {
													autoAlpha: 1
												});
											}, 500);
										}
									}}
								>
									{data.parenttipMediaStepDependentTitle1}
								</a>
							)}

							{data.parenttipMediaStepDependentUrl2 && (
								<a
									href={data.parenttipMediaStepDependentUrl2}
									download={data.parenttipMediaStepDependentType2 === 'file'}
									target={
										data.parenttipMediaStepDependentType2 === 'file' ||
										data.parenttipMediaStepDependentType2 === 'link'
											? '_blank'
											: '_self'
									}
									className="outlineButton inlineBtn"
									rel="noopener noreferrer"
									onClick={e => {
										if (data.parenttipMediaStepDependentType2 === 'video') {
											e.preventDefault();
											setVideoUrl(
												data.parenttipMediaStepDependentUrl2.replace(
													'watch?v=',
													'embed/'
												)
											);
											setTimeout(() => {
												TweenMax.to('.popupWrapper', 0.3, {
													autoAlpha: 1
												});
											}, 500);
										}
									}}
								>
									{data.parenttipMediaStepDependentTitle2}
								</a>
							)}
						</div>
					</div>
					{data.parenttipMedia1Type === 'link' && (
						<img
							src={data.parenttipMedia1Url.replace(/(\.[^.]+)$/, '-400x250$1')}
							srcSet={`${data.parenttipMedia1Url.replace(
								/(\.[^.]+)$/,
								'-800x500$1'
							)} 2x`}
							alt=""
						/>
					)}
					{data.parenttipMedia1Type === 'video' && (
						<iframe
							src={data.parenttipMedia1Url.replace('watch?v=', 'embed/')}
							title="onderbouw online"
							allowFullScreen
							frameBorder="0"
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const Home = props => {
	const [data, setData] = useState(null);
	// const [student, setStudent] = useState(null);
	const [videoUrl, setVideoUrl] = useState(null);
	const [iframeUrl, setIframeUrl] = useState(null);

	if (props.location.pathname === '/') {
		return <Redirect to={props.parentdata.defaultUrl} />;
	}

	const seasonObject = props.parentdata.lessonTree.find(
		x => x.seasonName.toLowerCase() === props.match.params.season.toLowerCase()
	);
	const periodsObject = seasonObject.periods.find(
		x =>
			x.periodName.toLowerCase() ===
			props.match.params.period.replace(/_/g, ' ').toLowerCase()
	);
	const studentObject = props.parentdata.childrenlist.find(
		x =>
			x.studentDisplayname.toLowerCase().replace(/_/g, ' ') ===
			props.match.params.student.replace(/_/g, ' ').toLowerCase()
	);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		if (!data) {
			const dataForm = new FormData();
			dataForm.append('periodId', periodsObject.periodId);
			dataForm.append('studentId', studentObject.studentId);
			axiosPost(
				parentdashboard_endpoint,
				dataForm,
				props.token,
				axiosResult => {
					if (axiosResult.status === 'success') {
						setData(axiosResult.data);
					}
				}
			);
		}
	}, [!data]);

	// console.log('parents data', data);

	return (
		<div className={`seasonWrapper ${props.match.params.season}_Season`}>
			{periodsObject && (
				<div className="periodWrapper">
					<ul className="periodsTab">
						{seasonObject.periods.map((item, i) => (
							<li
								key={i}
								className={`${
									props.match.params.period.includes('_')
										? props.match.params.period
												.replace(/_/g, ' ')
												.toLowerCase() === item.periodName.toLowerCase() &&
										  'active'
										: props.match.params.period.toLowerCase() ===
												item.periodName.toLowerCase() && 'active'
								}`}
							>
								<Link
									to={`/${
										props.match.params.student
									}/${props.match.params.season.toLowerCase()}/${
										item.periodName.includes(' ')
											? item.periodName.replace(/ /g, '_').toLowerCase()
											: item.periodName.toLowerCase()
									}/`}
								>
									{item.periodName}
								</Link>
							</li>
						))}
					</ul>
					<section>
						{data !== null && (
							<>
								<div className="header">
									<div className="periodImage">
										<img
											src={periodsObject.periodPicture.replace(
												/(\.[^.]+)$/,
												'-180x180$1'
											)}
											srcSet={`${periodsObject.periodPicture.replace(
												/(\.[^.]+)$/,
												'-360x360$1'
											)} 2x`}
											alt=""
										/>
									</div>
									<div className="periodDetails">
										<h2 className="headingStyle">{data.period.periodTitle}</h2>
										<div
											dangerouslySetInnerHTML={{
												__html: data.period.periodDescription
											}}
										/>
									</div>
								</div>
								<section className="contentSection">
									<h2 className="headingStyle">Lessen voor thuisonderwijs</h2>
									<p>
										Met de lessen hieronder kun je met je kind het leren nog
										leuker maken. De lestips zijn herhalingen van doelen die je
										kind op school al heeft gedaan of helpen je kind juist om al
										kennis te maken met toekomstige doelen.
									</p>
								</section>
								{data.parenttips && (
									<div className="tipsWrapper">
										{data.parenttips.map(item => (
											<ParentTips
												data={item}
												token={props.token}
												setVideoUrl={setVideoUrl}
												key={`parenttipId_${item.parenttipId}`}
												studentId={studentObject.studentId}
											/>
										))}
									</div>
								)}
							</>
						)}
					</section>
				</div>
			)}
			{videoUrl && (
				<YoutubeVideoPopup
					closePopup={() => {
						TweenMax.to('.popupWrapper', 0.3, {
							autoAlpha: 0,
							onComplete: () => {
								setTimeout(() => {
									setVideoUrl(null);
								}, 200);
							}
						});
					}}
					url={videoUrl}
				/>
			)}
			{iframeUrl && (
				<IframeLessonPopup
					closePopup={() => {
						TweenMax.to('#iframePopup', 0.3, {
							autoAlpha: 0,
							onComplete: () => {
								setTimeout(() => {
									setIframeUrl(null);
								}, 200);
							}
						});
					}}
					url={iframeUrl}
				/>
			)}
		</div>
	);
};

const mapStateToProps = state => ({
	parentdata: state.parents,
	token: state.initialData.token
});

export default connect(mapStateToProps, null)(Home);

// @import packages
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import localization from 'moment/locale/nl';
// import { TweenMax } from 'gsap/TweenMax';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// @import constants
import {
	medialist_endpoint,
	mediadisplaylist_endpoint
} from 'js/constants/endpoints';
// @import Components
import Loader from 'js/models/loader';
import MediaPopup from 'js/models/mediaPopup';
import MediaPreviewPopup from 'js/models/ImageVideoPopup';
// @import svgs
import { ReactComponent as Add } from 'images/svg/add.svg';
import { ReactComponent as Edit } from 'images/svg/edit.svg';
import { ReactComponent as Play } from 'images/svg/play.svg';
import { ReactComponent as Zoom } from 'images/svg/zoom_in.svg';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
import { getVimeoVideo, getVimeoVideos } from 'js/utils/vimeoCall';
import { slugtoGroup, grouptoSlug } from 'js/utils/groupUtility';
import { getCurrentGroup } from 'js/utils/filterArray';
// @import styles
import './index.scss';

const Media = () => {
	const {
		initialData: {
			token,
			groupList,
			userData: { userRole, schoolParentsEnabled }
		},
		parents
	} = useSelector(state => state);

	const isParent = userRole === 'parent';

	let { groupname, student } = useParams();
	groupname = slugtoGroup(groupname);

	let groupId;
	if (isParent) {
		groupId = parents.childrenlist.find(x => {
			return (
				grouptoSlug(x.group.groupdetails.groupName) === grouptoSlug(groupname)
			);
		}).group.groupdetails.groupId;
	} else {
		groupId = getCurrentGroup(groupList.groups, groupname).groupId;
	}

	const [data, setData] = useState(null);
	const [popup, setPopup] = useState(null);
	const [videoHash, setVideoHash] = useState(null);
	const [isLoading, setLoading] = useState(true);
	const [mediaPreview, setMediaPreview] = useState(null);
	const [videoTranscodedList, setVideoTranscodedList] = useState([]);

	useEffect(() => {
		getMedia();
	}, []);

	const getMedia = () => {
		setData(null);
		const dataForm = new FormData();
		dataForm.append('groupId', groupId);

		let url = isParent ? mediadisplaylist_endpoint : medialist_endpoint;

		if (isParent) {
			const studentId = parents.childrenlist.find(
				x =>
					x.studentDisplayname.toLowerCase().replace(/_/g, ' ') ===
					student.replace(/_/g, ' ').toLowerCase()
			).studentId;
			dataForm.append('studentId', studentId);
			dataForm.append('mediaType', 'all');
		}

		axiosPost(url, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				const { data } = axiosResult;
				setData(data.media.length > 0 ? data.media : 'nodata');
				if (data.media.length > 0) {
					getTranscodeStatus(data);
				}
			}
			setLoading(false);
		});
	};

	const getTranscodeStatus = data => {
		const videoList =
			data &&
			data.media &&
			data.media
				.filter(item => item.mediaType === 'video')
				.map(item => item.mediaExternalVideoId);

		if (videoList) {
			getVimeoVideos(
				videoList,
				res => {
					const list = [];
					res.data.forEach(item => {
						if (
							item.transcode &&
							item.transcode.status === 'complete' &&
							item.upload.status === 'complete'
						) {
							const uriLink = item.link.split('/');
							list.push({
								videoId: uriLink[uriLink.length - 2],
								videoHash: uriLink[uriLink.length - 1]
							});
						} else if (
							item.transcode &&
							(item.transcode.status !== 'complete' ||
								item.upload.status !== 'complete')
						) {
							fetchVideoStatus(item, list);
						}
					});
					setVideoTranscodedList([...videoTranscodedList, ...list]);
				},
				'link,uri,upload.status,transcode.status'
			);
		}
	};

	const fetchVideoStatus = (item, list) => {
		const fetchStatusInterval = setInterval(() => {
			const uriLink = item.link.split('/');
			getVimeoVideo(
				uriLink[uriLink.length - 2],
				res => {
					if (
						res &&
						res.transcode.status === 'complete' &&
						res.upload.status === 'complete'
					) {
						setTimeout(() => {
							setVideoTranscodedList([
								...videoTranscodedList,
								...list,
								{
									videoId: uriLink[uriLink.length - 2],
									videoHash: uriLink[uriLink.length - 1]
								}
							]);
						}, 5000);
						clearInterval(fetchStatusInterval);
					}
				},
				'link,uri,upload.status,transcode.status'
			);
		}, 5000);
	};

	const handleMediaPreview = (item, videoHash = '') => {
		if (isParent) {
			setMediaPreview({
				src: item.mediaFile,
				type: item.mediaType,
				title: item.mediaTitle,
				description: item.mediaDescription,
				videoId: item.mediaExternalVideoId,
				videoHash
			});
		} else {
			setMediaPreview({
				src: item.mediaFile,
				type: item.mediaType,
				videoId: item.mediaExternalVideoId,
				videoHash
			});
		}
	};

	const closePopup = () => {
		setPopup(null);
	};

	return (
		<div className="mainContainer">
			<div
				className={`pageContainer mediaPageContainer ${
					isLoading ? 'flexCenter' : ''
				}`}
			>
				{schoolParentsEnabled === '0' ? (
					<>
						<h1 className="headingStyle">{groupname} - Foto's en video's</h1>
						<p className="emptyData">
							Het ouderportaal is door jullie school uitgeschakeld, het is
							daarom niet mogelijk om foto's / video's toe te voegen.
						</p>
					</>
				) : (
					<>
						{isLoading ? (
							<Loader />
						) : (
							<>
								<h1 className="headingStyle">
									{groupname} - Foto's en video's
								</h1>
								{data === 'nodata' || !data ? (
									<p className="emptyData">
										Er zijn nog geen foto’s of video’s van deze groep toegevoegd
									</p>
								) : null}

								<div className="studentListWrapper">
									{data &&
										data !== 'nodata' &&
										data.map(item => {
											const date = moment(item.mediaDate)
												.locale('nl', localization)
												.format('D MMMM YY');
											const iframeVideo = videoTranscodedList.find(
												elem => elem.videoId === item.mediaExternalVideoId
											);
											return (
												<div
													className="studentBox mediaPreviewBox"
													key={
														iframeVideo
															? `transcoded-${item.mediaId}`
															: item.mediaId
													}
												>
													<div className="aspectContainer flexCenter">
														{item.mediaType === 'video' ? (
															<>
																{iframeVideo && (
																	<button
																		type="button"
																		className="previewButton"
																		onClick={() =>
																			handleMediaPreview(
																				item,
																				iframeVideo.videoHash
																			)
																		}
																	>
																		<Play width={30} height={30} />
																	</button>
																)}
																{iframeVideo ? (
																	<iframe
																		frameBorder="0"
																		allow="autoplay; fullscreen; picture-in-picture"
																		src={`https://player.vimeo.com/video/${item.mediaExternalVideoId}?h=${iframeVideo.videoHash}&background=1&muted=1&autoplay=0`}
																	/>
																) : (
																	<div className="loaderPlaceholder">
																		<Loader />
																	</div>
																)}
															</>
														) : (
															<>
																<button
																	type="button"
																	className="previewButton"
																	onClick={() => handleMediaPreview(item)}
																>
																	<Zoom width={30} height={30} />
																</button>
																<img
																	src={item.mediaFile.replace(
																		/(\.[^.]+)$/,
																		'-250x140$1'
																	)}
																	srcSet={`${item.mediaFile.replace(
																		/(\.[^.]+)$/,
																		'-500x280$1'
																	)} 2x`}
																	alt={item.mediaTitle}
																/>
															</>
														)}
													</div>
													{!isParent && (
														<button
															className="editButton"
															onClick={() => {
																setPopup(item);
																if (iframeVideo && iframeVideo.videoHash) {
																	setVideoHash(iframeVideo.videoHash);
																}
															}}
														>
															<Edit />
														</button>
													)}

													<strong>{item.mediaTitle}</strong>
													<span>{date}</span>
												</div>
											);
										})}

									{!isParent && (
										<div className="studentBox mediaPreviewBox">
											<div className="aspectContainer">
												<button
													className="addStudent"
													onClick={() => setPopup('add')}
												>
													<Add />
												</button>
											</div>
										</div>
									)}
								</div>
							</>
						)}
					</>
				)}
			</div>
			{popup && (
				<MediaPopup
					groupId={groupId}
					callback={getMedia}
					closePopup={closePopup}
					videoHash={videoHash}
					data={popup === 'add' ? null : popup}
					type={popup === 'add' ? 'add' : 'edit'}
				/>
			)}
			{mediaPreview && (
				<MediaPreviewPopup
					src={mediaPreview.src}
					type={mediaPreview.type}
					videoId={mediaPreview.videoId}
					videoHash={mediaPreview.videoHash}
					title={isParent ? mediaPreview.title : null}
					description={isParent ? mediaPreview.description : null}
					closePopup={() => {
						setMediaPreview(null);
					}}
				/>
			)}
		</div>
	);
};

export default Media;

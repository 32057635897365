// @import action types
import { FETCH_INPROGRESS_CHAPTERS } from '../actions/academyTypes';

const initialState = {
	coursehistory: null
};

export const academyReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_INPROGRESS_CHAPTERS:
			return {
				...state,
				coursehistory: action.payload.coursehistory
			};
		default:
			return {
				...state
			};
	}
};

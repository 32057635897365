import React from 'react';
import { ratingPercentage } from '../utils/rating';

const Rating = ({ rating }) => {
	return (
		<div className="star-ratings-css">
			<div
				className="star-ratings-css-top"
				style={{
					width: `${ratingPercentage(rating, 0, 5)}%`
				}}
			>
				<span>★</span>
				<span>★</span>
				<span>★</span>
				<span>★</span>
				<span>★</span>
			</div>
			<div className="star-ratings-css-bottom">
				<span>★</span>
				<span>★</span>
				<span>★</span>
				<span>★</span>
				<span>★</span>
			</div>
		</div>
	);
};

export default Rating;

let gethost;
let getApiurl;
let getAppUrl;
let getPublicUrl;
let getDataUrl;
let getappname;

switch (window.location.hostname) {
	case 'localhost':
		gethost = 'localhost';
		getappname = process.env.REACT_APP_SITE;
		getAppUrl = 'http://localhost:3000';
		getPublicUrl = 'http://localhost:3000';
		getDataUrl = 'https://data.onderbouwdonline.site';
		getApiurl = `https://api.${getappname}.site/`; // nouman's local
		break;
	case 'dashboard.onderbouwd.develop':
		gethost = 'localhost';
		getappname = 'onderbouwdonline';
		getAppUrl = 'http://localhost:3000';
		getPublicUrl = 'http://localhost:3000';
		getDataUrl = 'https://data.onderbouwd.develop';
		getApiurl = 'https://api.onderbouwd.develop/'; // develop
	case 'dashboard.onderbouwdonline.site':
		gethost = 'demo';
		getappname = 'onderbouwdonline';
		getAppUrl = 'https://dashboard.onderbouwdonline.site';
		getPublicUrl = 'https://www.onderbouwdonline.site';
		getDataUrl = 'https://data.onderbouwdonline.site';
		getApiurl = 'https://api.onderbouwdonline.site/'; // demo
		break;
	case 'dashboard.onderbouwdonline.nl':
		gethost = 'production';
		getappname = 'onderbouwdonline';
		getAppUrl = 'https://dashboard.onderbouwdonline.nl';
		getPublicUrl = 'https://www.onderbouwdonline.nl';
		getDataUrl = 'https://data.onderbouwdonline.nl';
		getApiurl = 'https://api.onderbouwdonline.nl/'; // prod
		break;
	case 'dashboard.kleuterwijzer.develop':
		gethost = 'localhost';
		getappname = 'kleuterwijzer';
		getAppUrl = 'http://localhost:3000';
		getPublicUrl = 'http://localhost:3000';
		getDataUrl = 'https://data.onderbouwdonline.site';
		getApiurl = 'http://api.kleuterwijzer.develop/'; // develop
		break;
	case 'dashboard.kleuterwijzer.site':
		gethost = 'demo';
		getappname = 'kleuterwijzer';
		getAppUrl = 'https://dashboard.kleuterwijzer.site';
		getPublicUrl = 'https://www.kleuterwijzer.site';
		getDataUrl = 'https://data.kleuterwijzer.site';
		getApiurl = 'https://api.kleuterwijzer.site/'; // demo
		break;
	case 'dashboard.kleuterwijzer.nl':
		gethost = 'production';
		getappname = 'kleuterwijzer';
		getAppUrl = 'https://dashboard.kleuterwijzer.nl';
		getPublicUrl = 'https://www.kleuterwijzer.nl';
		getDataUrl = 'https://data.kleuterwijzer.nl';
		getApiurl = 'https://api.kleuterwijzer.nl/'; // prod
		break;
	case 'dashboard.wijzneus.site':
		gethost = 'demo';
		getappname = 'wijzneus';
		getAppUrl = 'https://dashboard.wijzneus.site';
		getPublicUrl = 'https://www.wijzneus.site';
		getDataUrl = 'https://data.wijzneus.site';
		getApiurl = 'https://api.wijzneus.site/'; // demo
		break;
	case 'dashboard.wijzneus.nl':
		gethost = 'production';
		getappname = 'wijzneus';
		getAppUrl = 'https://dashboard.wijzneus.nl';
		getPublicUrl = 'https://www.wijzneus.nl';
		getDataUrl = 'https://data.wijzneus.nl';
		getApiurl = 'https://api.wijzneus.nl/'; // prod
		break;
	default:
		gethost = 'localhost';
		getappname = process.env.REACT_APP_SITE;
		getAppUrl = 'http://localhost:3000';
		getPublicUrl = 'http://localhost:3000';
		getDataUrl = 'https://data.onderbouwdonline.site';
		getApiurl = `https://api.${getappname}.site/`; // nouman's local
}

export const apiurl = getApiurl;
export const host_name = gethost;
export const app_url = getAppUrl;
export const appname = getappname;
export const data_url = getDataUrl;
export const public_url = getPublicUrl;

import React from "react";
// @import styles
import "../../../css/components/placeholder/textLine.scss";

const TextLine = ({ width, height, margin }) => {
  return (
    <div
      className="textLine"
      style={{
        height: height ? height : "15px",
        width: width ? width : "100%",
        margin: margin ? margin : "10px 0"
      }}
    />
  );
};

export default TextLine;

import React, { Component } from "react";
// @import images
import Image from "../../images/404.jpg";
export class PageNotFound extends Component {
  render() {
    return (
      <div className="mainContainer">
        <div className="pageContainer pagenotfound">
          <img src={Image} alt="Pagina niet gevonden" />
        </div>
      </div>
    );
  }
}

export default PageNotFound;

// @import packages
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// @import constants
import {
	updatestudentchapterremarks_endpoint,
	updatestudentstatement_endpoint,
	studentchapterstatements_endpoint
} from '../constants/endpoints';
// @import actions
import { setStudentList, sendErrorToHOC } from '../../actions/actions';
// @import styles
import '../../css/containers/assessments.scss';
// @import utils
import { axiosPost } from '../utils/axiosCall';
import { breadCrumbRoute } from '../utils/studentBreadCrumbs';

export class Assessments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			topics: null,
			remarks: null,
			isLoading: true,
			currentStudent: null,
			loaderRequested: false
		};
	}

	componentDidMount() {
		if (this.props.studentList) {
			this.setInitialStates();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.studentList !== this.props.studentList) {
			this.setInitialStates();
		}
	}

	setInitialStates = () => {
		this.chapterName = this.props.match.params.chapterName;
		let { groupname } = this.props.match.params;
		groupname = groupname.replace(/_/g, ' ');
		const getGroup = this.props.groupList.groups.find(
			x =>
				x.groupName.replace(/_/g, ' ').replace(/\//g, ' ').toLowerCase() ===
				groupname
		);

		this.props.setStudentList(
			getGroup.groupId,
			getGroup.groupName,
			getGroup.students
		);

		const getStudent = getGroup.students.find(
			x =>
				x.studentDisplayname.replace(/ /g, ' ').toLowerCase() ===
				this.props.match.params.studentname.replace(/_/g, ' ')
		);

		// const getStudent = this.props.studentList.students.find(
		//   x =>
		//     x.studentDisplayname.toLowerCase() ===
		//     this.props.match.params.studentname.replace(/-/g, ' ')
		// );

		this.setState({
			currentStudent: getStudent,
			studentDisplayname: getStudent.studentDisplayname,
			studentFirstname: getStudent.studentFirstname
		});

		this.getOverviewData(getStudent.studentId, this.props.token);
	};

	getOverviewData = (studentId, token) => {
		this.setState({
			isLoading: true
		});

		const dataForm = new FormData();
		dataForm.append('studentId', studentId);
		dataForm.append('chapterName', this.chapterName);

		this.props.location.pathname.includes('leerlingportfolio');
		axiosPost(
			studentchapterstatements_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState({
						isLoading: false,
						topics: axiosResult.data && axiosResult.data.topics,
						remarks: axiosResult.data && axiosResult.data.remarks
					});
				}
			}
		);
	};

	updateStudentStatement = (studentId, statementId, answer, token) => {
		const dataForm = new FormData();
		dataForm.append('studentId', studentId);
		dataForm.append('statementId', statementId);
		dataForm.append('answer', answer);
		axiosPost(
			updatestudentstatement_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.getOverviewData(studentId, this.props.token);
				}
			}
		);
	};

	saveRemarks = () => {
		this.setState({ loaderRequested: true });
		const { studentId } = this.state.currentStudent;
		const dataForm = new FormData();
		dataForm.append('studentId', studentId);
		dataForm.append('chapterName', this.chapterName);
		dataForm.append('remarks', this.state.remarks);
		axiosPost(
			updatestudentchapterremarks_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState({ loaderRequested: false });
					this.getOverviewData(studentId, this.props.token);
				}
			}
		);
	};

	handleChange(value, id) {
		this.updateStudentStatement(
			this.state.currentStudent.studentId,
			id,
			value,
			this.props.token
		);
	}

	getTopicName = topicName => {
		this.setState({
			topicName
		});
	};

	updateRemarks = remarks => {
		this.setState({
			remarks
		});
	};

	render() {
		return (
			<div className="mainContainer">
				<Helmet>
					<title>Mijn groep</title>
				</Helmet>
				{this.state.currentStudent && (
					<nav className="breadCrumbMenu">
						<ul id="breadCrumb">
							<li>
								<span>{this.state.currentStudent.studentDisplayname}</span>
							</li>
							<li>
								{breadCrumbRoute.map((item, i) => (
									<NavLink
										to={`/leerling/${this.props.groupName
											.replace(/ /g, '_')
											.replace(/\//g, '_')
											.toLowerCase()}/${this.state.currentStudent.studentDisplayname
											.replace(/ /g, '_')
											.toLowerCase()}${item.slug}`}
										key={i}
										exact
										className={
											this.props.location.pathname.includes(
												item.name.replace(/ /g, '_').toLowerCase()
											)
												? 'active'
												: ''
										}
									>
										{item.name}
									</NavLink>
								))}
								<NavLink
									to={`/${this.props.groupName
										.replace(/ /g, '_')
										.replace(/\//g, '_')
										.toLowerCase()}/${this.state.currentStudent.studentDisplayname
										.replace(/ /g, '_')
										.toLowerCase()}/leerlingportfolio`}
									exact
									className={
										this.props.location.pathname.includes('leerlingportfolio')
											? 'active'
											: ''
									}
								>
									Leerlingportfolio
								</NavLink>
							</li>
						</ul>
					</nav>
				)}
				{this.state.topics && (
					<div className="pageContainer flexContainer">
						<div className="contentContainer">
							<h1 className="headingStyle">{this.chapterName}</h1>
							{this.state.topics
								.filter(topic => topic && topic.statements.length > 0)
								.map((topic, i) => {
									const getCheckedLength = topic.statements.filter(
										x => x.assessstatementAnswer === '1'
									);
									return (
										<div key={i} className="socialWrapper">
											<img
												src={topic.assesstopicPicture.replace(
													/(\.[^.]+)$/,
													'-200x200$1'
												)}
												srcSet={`${topic.assesstopicPicture.replace(
													/(\.[^.]+)$/,
													'-400x400$1'
												)} 2x`}
												alt=""
											/>
											<div className={'socialContent'}>
												<h2>
													{topic.assesstopicTitle}{' '}
													{getCheckedLength.length > 0 &&
														`(${getCheckedLength.length})`}
												</h2>
												{topic.statements && (
													<ul>
														{topic.statements.map((statement, i) => (
															<li key={i}>
																<div>
																	<input
																		type="checkbox"
																		name={statement.assessstatementId}
																		value={statement.assessstatementAnswer}
																		checked={
																			statement.assessstatementAnswer === '1'
																		}
																		onChange={event =>
																			this.handleChange(
																				statement.assessstatementAnswer === '1'
																					? '0'
																					: '1',
																				statement.assessstatementId
																			)
																		}
																	/>
																	<span>
																		{statement.assessstatementTitle.replace(
																			/#kind/gi,
																			this.state.studentFirstname
																		)}
																	</span>
																</div>
															</li>
														))}
													</ul>
												)}
											</div>
										</div>
									);
								})}
						</div>
						<div className="remarksContainer">
							<ReactQuill
								theme="snow"
								defaultValue=""
								placeholder="Toelichting"
								value={this.state.remarks}
								onChange={option => this.updateRemarks(option)}
							/>
							<div className="row buttonsWrapper">
								{this.state.loaderRequested && (
									<div className="circleLoader">
										<div className="draw" />
									</div>
								)}
								<button
									type="button"
									className="defaultBtn"
									onClick={this.saveRemarks}
								>
									Opslaan
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
	students: state.initialData.students,
	studentList: state.initialData.studentList,
	groupId: state.initialData.groupId,
	groupName: state.initialData.groupName,
	groupList: state.initialData.groupList,
	seasonId: state.initialData.seasonId,
	periodId: state.initialData.periodId,
	topicId: state.initialData.topicId,
	lessonTree: state.initialData.lessonTree
});

export default connect(mapStateToProps, { setStudentList, sendErrorToHOC })(
	Assessments
);

// @import packages
import React from 'react';
// @import styles
import '../../css/components/student.scss';

const Student = ({
  age,
  size,
  name,
  studentId,
  imagepath,
  groupdetails,
  studentPerformance,
  studentPercentageInStep
}) => {
  let icon = null;
  if (studentPerformance === 'ahead') {
    //icon = <div className="icon ahead" />;
    icon = (
      <div
        className="icon ahead"
        style={{
          background: `linear-gradient(to right, #2db1ff 0%,#2db1ff ${studentPercentageInStep -
            10}%,#43da41 ${studentPercentageInStep + 10}%,#43da41 100%)`
        }}
      />
    );
  }
  if (studentPerformance === 'behind') {
    icon = <div className="icon behind" />;
  }
  if (studentPerformance === 'on track' || studentPerformance === 'unknown') {
    icon = (
      <div
        className="icon ontrack"
        style={{
          background: `linear-gradient(to right, #43da41 0%,#43da41 ${studentPercentageInStep -
            10}%,#eb6308 ${studentPercentageInStep + 10}%,#eb6308 100%)`
        }}
      />
    );
  }
  return (
    <div className="studentWrap">
      <img
        src={imagepath.replace(/(\.[^.]+)$/, `-${size}x${size}$1`)}
        srcSet={`${imagepath.replace(
          /(\.[^.]+)$/,
          `-${size * 2}x${size * 2}$1`
        )} 2x`}
        alt=""
      />

      <div className="textWrap">
        <span className="name">{name}</span>
        {age && <span className="age">{age}</span>}
        {groupdetails && (
          <span className="groupname">{groupdetails.groupName}</span>
        )}
      </div>

      {icon && icon}
    </div>
  );
};

export default Student;

export const readFile = file => {
	return new Promise(resolve => {
		const reader = new FileReader();
		reader.addEventListener('load', () => resolve(reader.result), false);
		reader.readAsDataURL(file);
	});
};

export const dataURLtoFile = (dataurl, filename) => {
	let arr = dataurl.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, { type: mime });
};

export const createRotatedImage = (image, degrees) => {
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	const orientationChanged =
		degrees === 90 || degrees === -90 || degrees === 270 || degrees === -270;

	if (orientationChanged) {
		canvas.width = image.height;
		canvas.height = image.width;
	} else {
		canvas.width = image.width;
		canvas.height = image.height;
	}

	if (orientationChanged) {
		ctx.translate(image.height / 2, image.width / 2);
	} else {
		ctx.translate(image.width / 2, image.height / 2);
	}

	ctx.rotate((degrees * Math.PI) / 180);
	ctx.drawImage(image, -image.width / 2, -image.height / 2);

	return new Promise(resolve => {
		canvas.toBlob(blob => {
			if (!blob) {
				console.error('Canvas is empty');
				return;
			}
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				resolve(reader.result);
			};
		});
	});
};

export const getCroppedImg = (image, crop, fileName) => {
	const canvas = document.createElement('canvas');
	const scaleX = image.naturalWidth / image.width;
	const scaleY = image.naturalHeight / image.height;
	canvas.width = Math.ceil(crop.width * scaleX);
	canvas.height = Math.ceil(crop.height * scaleY);

	const ctx = canvas.getContext('2d');
	ctx.imageSmoothingQuality = 'high';

	ctx.drawImage(
		image,
		crop.x * scaleX,
		crop.y * scaleY,
		crop.width * scaleX,
		crop.height * scaleY,
		0,
		0,
		crop.width * scaleX,
		crop.height * scaleY
	);

	return new Promise((resolve, reject) => {
		canvas.toBlob(blob => {
			if (!blob) {
				console.error('Canvas is empty');
				return;
			}
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				resolve(dataURLtoFile(reader.result, fileName));
			};
		});
	});
};

export const captureVideoFrame = video => {
	var canvas = document.createElement('canvas');
	canvas.width = video.videoWidth;
	canvas.height = video.videoHeight;
	var canvasContext = canvas.getContext('2d');
	canvasContext.drawImage(video, 0, 0);
	// return canvas.toDataURL('image/png');
	return new Promise((resolve, reject) => {
		canvas.toBlob(blob => {
			if (!blob) {
				console.error('Canvas is empty');
				return;
			}
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				resolve(dataURLtoFile(reader.result));
			};
		});
	});
};

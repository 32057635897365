import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// @import utils
import { grouptoSlug } from 'js/utils/groupUtility';

const BehandelplanTabs = ({ groupName, season = null, period = null }) => {
	const { userRole } = useSelector(state => state.initialData.userData);

	const behandelplanArray = [
		{
			name: 'In ontwikkeling',
			classname: 'behind_tab',
			path:
				userRole === 'teacher'
					? `/groep/${grouptoSlug(
							groupName
					  )}/groepsoverzicht/behandelplan/in-ontwikkeling${
							season && period ? `/${season}/${period}` : ''
					  }`
					: `/groepsoverzicht/${grouptoSlug(
							groupName
					  )}/behandelplan/in-ontwikkeling`
		},
		{
			name: 'Leervoorsprong',
			classname: 'ahead_tab',
			path:
				userRole === 'teacher'
					? `/groep/${grouptoSlug(
							groupName
					  )}/groepsoverzicht/behandelplan/leervoorsprong${
							season && period ? `/${season}/${period}` : ''
					  }`
					: `/groepsoverzicht/${grouptoSlug(
							groupName
					  )}/behandelplan/leervoorsprong`
		}
	];
	return behandelplanArray.map((item, i) => (
		<li key={`behandelplanTab_${i}`} className={item.classname}>
			<Link to={item.path}>{item.name}</Link>
		</li>
	));
};

export default BehandelplanTabs;

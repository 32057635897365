// @import packages
import React, { Component } from 'react';
import { connect } from 'react-redux';
// @import constants
import { changepassword_endpoint } from '../constants/endpoints';
// @import styles
import '../../css/containers/changePassword.scss';
// @import actions
import { sendErrorToHOC } from '../../actions/actions';
// @import utils
import { axiosPost } from '../utils/axiosCall';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '',
      newPassword: '',
      repeatPassword: '',
      successMessage: null
    };
  }
  handleCurrentPassword = event => {
    const currentPassword = event.target.value;
    this.setState({
      currentPassword
    });
  };
  handleNewPassword = event => {
    const newPassword = event.target.value;
    this.setState({
      newPassword
    });
  };
  handleRepeatPassword = event => {
    const repeatPassword = event.target.value;
    this.setState({
      repeatPassword
    });
  };

  submitrequest = () => {
    this.setState({
      isWaiting: true,
      loaderRequested: true
    });

    const dataForm = new FormData();
    dataForm.append('currentPassword', this.state.currentPassword);
    dataForm.append('newPassword', this.state.newPassword);
    dataForm.append('repeatNewPassword', this.state.repeatPassword);

    axiosPost(
      changepassword_endpoint,
      dataForm,
      this.props.token,
      axiosResult => {
        if (axiosResult.status === 'success') {
          this.setState(
            {
              isWaiting: false,
              currentPassword: '',
              newPassword: '',
              repeatPassword: '',
              successMessage: 'Het wachtwoord is veranderd.',
              errorMessage: ''
            },
            () => {
              const loader = document.querySelector('.circleLoader');
              const check = loader.querySelector('.draw');
              loader.classList.add('loadComplete');
              check.classList.add('checkmark');
            }
          );
        } else {
          this.setState({
            isWaiting: false,
            loaderRequested: false,
            errorMessage: axiosResult.failreason,
            successMessage: ''
          });
        }
      }
    );
  };
  render() {
    return (
      <div className="mainContainer">
        <div className="pageContainer">
          <h1 className="headingStyle">Mijn wachtwoord</h1>
          <div className="changePassWrapper">
            <label>Huidig wachtwoord</label>
            <input
              type="password"
              placeholder="Huidig wachtwoord"
              className="inputField"
              value={this.state.currentPassword}
              onChange={this.handleCurrentPassword}
              required
            />
            <label>Nieuw wachtwoord</label>
            <input
              type="password"
              placeholder="Nieuw wachtwoord"
              className="inputField"
              value={this.state.newPassword}
              onChange={this.handleNewPassword}
              required
            />
            <label>Herhaal nieuw wachtwoord</label>
            <input
              type="password"
              placeholder="Herhaal nieuw wachtwoord"
              className="inputField"
              value={this.state.repeatPassword}
              onChange={this.handleRepeatPassword}
              required
            />
            {this.state.errorMessage && (
              <div className="error">* {this.state.errorMessage}</div>
            )}
            {this.state.successMessage && (
              <div className="success">* {this.state.successMessage}</div>
            )}
            <div className="row">
              <button
                type="button"
                className="outlineButton"
                onClick={this.submitrequest}
              >
                Opslaan
              </button>
              {this.state.loaderRequested && (
                <div className="circleLoader">
                  <div className="draw" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.initialData.token
});

export default connect(
  mapStateToProps,
  { sendErrorToHOC }
)(ChangePassword);

// @import packages
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TweenMax } from 'gsap/TweenMax';
import { isMobile } from 'react-device-detect';
// @import constants
import {
	grouplist_endpoint,
	parentslist_endpoint
} from '../../constants/endpoints';
// @import Components
import ParentsPopup from '../../components/popup/parentsAddEditPopup';
// @import Styles
import '../../../css/containers/manageStudents.scss';
// @import svgs
import { ReactComponent as Edit } from '../../../images/svg/edit.svg';
import { ReactComponent as Add } from '../../../images/svg/add.svg';
// @import actions
import { sendErrorToHOC } from '../../../actions/actions';
// @import utils
import { axiosPost, axiosGet } from '../../utils/axiosCall';

export class ManageParents extends Component {
	constructor(props) {
		super(props);
		this.state = {
			groupsList: null,
			errorMessage: null,
			isLoading: true,
			openPopup: false
		};
	}
	componentDidMount() {
		this.getGroupList();
	}

	getGroupList = () => {
		axiosGet(grouplist_endpoint, this.props.token, axiosResult => {
			if (axiosResult.status === 'success') {
				this.setState(
					{
						groupsList: axiosResult.data.groups,
						groupId: axiosResult.data.groups[0].groupId
						// groupSelectable: axiosResult.data.groups[0].groupSelectable
					},
					() => {
						this.getParents(this.state.groupsList[0].groupId);
					}
				);
			} else {
				this.setState({
					errorMessage:
						'Om een leerling toe te voegen moet er eerst een groep worden aangemaakt'
				});
			}
		});
	};

	getParents = groupId => {
		this.setState({
			isLoading: true
		});

		const dataForm = new FormData();
		dataForm.append('groupId', groupId);

		axiosPost(parentslist_endpoint, dataForm, this.props.token, axiosResult => {
			this.setState({
				isLoading: false,
				parents:
					axiosResult.status === 'no such data'
						? 'nodata'
						: axiosResult.data.parents
			});
		});
	};

	handleGroupChange = event => {
		const groupId = event.target.value;
		// const currentGroup = this.state.groupsList.find(
		// 	item => item.groupId === groupId
		// );

		this.setState(
			{
				groupId
				// groupSelectable: currentGroup.groupSelectable
			},
			() => {
				this.getParents(groupId);
			}
		);
	};

	handleEdit = data => {
		this.setState(
			{
				openPopup: true,
				popupname: 'edit',
				data
			},
			() => {
				TweenMax.to('.popupWrapper', 0.3, {
					autoAlpha: 1
				});
			}
		);
	};

	openAddStudentPopup = () => {
		this.setState(
			{
				openPopup: true,
				popupname: 'add',
				data: null
			},
			() => {
				TweenMax.to('.popupWrapper', 0.3, {
					autoAlpha: 1
				});
			}
		);
	};

	closePopup = () => {
		TweenMax.to('.popupWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setTimeout(() => {
					this.setState({
						openPopup: false
					});
				}, 200);
			}
		});
	};

	render() {
		return (
			<>
				<div className="pageContainer manageStudents">
					{/* {this.state.parents === 'nodata' && (
						<p className="noContent">Dit onderdeel is nog niet in gebruik</p>
					)} */}

					{this.state.groupsList && (
						<select onChange={this.handleGroupChange}>
							{this.state.groupsList.map((item, i) => (
								<option key={i} value={item.groupId}>
									{item.groupName}
								</option>
							))}
							<option value={-1}>Ouders zonder leerling</option>
						</select>
					)}

					<div className="studentListWrapper">
						{this.state.parents &&
							this.state.parents !== 'nodata' &&
							this.state.parents.map(item => (
								<div
									className={`studentBox ${isMobile ? 'showIcon' : ''}`}
									key={`parent_${item.userId}`}
								>
									<div className="aspectContainer">
										<img
											src={item.userPicture.replace(
												/(\.[^.]+)$/,
												'-260x2600$1'
											)}
											srcSet={`${item.userPicture.replace(
												/(\.[^.]+)$/,
												'-520x520$1'
											)} 2x`}
											alt={item.userDisplayname}
										/>
									</div>

									<button
										className="editButton"
										onClick={() => this.handleEdit(item)}
									>
										<Edit />
									</button>

									<strong>{item.userDisplayname}</strong>
									{item.kidsDisplaynames && (
										<span>{item.kidsDisplaynames}</span>
									)}
								</div>
							))}
						{!this.state.isLoading && (
							<div className="studentBox">
								<div className="aspectContainer">
									<button
										className="addStudent"
										onClick={this.openAddStudentPopup}
									>
										<Add />
									</button>
								</div>
							</div>
						)}
					</div>
					{this.state.errorMessage && (
						<p className="noContent">{this.state.errorMessage}</p>
					)}
				</div>
				{this.state.openPopup && (
					<ParentsPopup
						data={this.state.data}
						token={this.props.token}
						name={this.state.popupname}
						hidepopup={this.closePopup}
						groupId={this.state.groupId}
						groupName={this.props.groupName}
						updateCallback={this.getParents}
						groupList={this.props.groupList.groups}
					/>
				)}
			</>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
	userData: state.initialData.userData,
	groupList: state.initialData.groupList
});

// TODO Nouman: use one component to manage parent system

export default connect(mapStateToProps, { sendErrorToHOC })(ManageParents);

// @import dependencies
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { TweenMax } from 'gsap/TweenMax';
import { useSelector } from 'react-redux';
import localization from 'moment/locale/nl';
import { isMobile } from 'react-device-detect';
// @import constants
import { stephistoryupdate_endpoint } from 'js/constants/endpoints';
// @import placeholder
import HistoryLoader from 'js/components/placeholder/historyPlaceholder';
// @import images
import { ReactComponent as Trash } from 'images/svg/trash.svg';
import { ReactComponent as Edit } from 'images/svg/edit.svg';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
// @import styles
import './index.scss';

const AddPerformance = ({ studentPerformance }) => {
	return (
		<div
			className={`icon ${
				studentPerformance === 'on track' || studentPerformance === 'unknown'
					? 'ontrack'
					: studentPerformance
			}`}
		/>
	);
};

const switchPopup = (from, to) => {
	TweenMax.to(`.${from}`, 0.3, {
		autoAlpha: 0,
		onComplete: () => {
			TweenMax.set(`.${from}`, { display: 'none' });
			TweenMax.set(`.${to}`, { display: 'block', autoAlpha: 0 });
			TweenMax.to(`.${to}`, 0.3, { autoAlpha: 1 });
		}
	});
};

const LessonHistory = ({
	data,
	lesson,
	student,
	loadingPopup,
	updateHistorydata,
	deleteStepHistory,
	callback = () => {}
}) => {
	const { token } = useSelector(state => state.initialData);

	const [teacherComment, setTeacherComment] = useState('');
	const [loadingPopupState, setLoadingPopup] = useState(loadingPopup);
	const [stepId, setStepId] = useState();

	useEffect(() => {
		setLoadingPopup(loadingPopup);
	}, [loadingPopup]);

	const handleTeacherComent = event => {
		setTeacherComment(event.target.value);
	};

	const handleDelete = () => {
		deleteStepHistory(student.studentId, stepId, lesson.lessonId);
		switchPopup('deleteWrapper', 'commentsWrapper');
	};

	const updateStepHistory = (studentId, stepId, lessonId, teacherComment) => {
		const dataForm = new FormData();
		dataForm.append('studentId', studentId);
		dataForm.append('lessonId', lessonId);
		dataForm.append('teacherComment', teacherComment);
		dataForm.append('studentlessonstephistoryid', stepId);

		setLoadingPopup(true);

		axiosPost(stephistoryupdate_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				callback();
				updateHistorydata();
				setLoadingPopup(false);
			}
		});
	};

	return (
		<>
			<div className="commentsWrapper">
				<div className="historyWrapper">
					{!loadingPopupState && lesson ? (
						<>
							<div className="studentWrap">
								{lesson.lessonPicture && (
									<img
										src={lesson.lessonPicture.replace(/(\.[^.]+)$/, '-70x70$1')}
										srcSet={`${lesson.lessonPicture.replace(
											/(\.[^.]+)$/,
											'-140x140$1'
										)} 2x`}
										alt={lesson.lessonName}
									/>
								)}
								{lesson.lessonName && (
									<div>
										<h3>{lesson.lessonName}</h3>
										<p>{lesson.lessonIntroduction}</p>
									</div>
								)}
							</div>

							<h2>Ontwikkelgeschiedenis</h2>

							<div className="stepHistoryWrap">
								{data &&
									data.map((item, i) => {
										const date = moment(item.dateentered)
											.locale('nl', localization)
											.format('D MMM YY');
										return (
											<div className="stepHistory" key={i}>
												<div className="stepContent">
													{item.previousStep ? (
														<h4>
															{item.step
																? item.step === 'E'
																	? `Van ontwikkelstap ${item.previousStep} naar ontwikkelstappen afgerond`
																	: item.previousStep === 'E'
																	? `Van ontwikkelstappen afgerond naar ${item.step}`
																	: `Van ontwikkelstap ${item.previousStep} naar ${item.step}`
																: item.previousStep === 'E'
																? `De ontwikkelstappen blijven afgerond`
																: `Geobserveerd in ontwikkelstap ${item.previousStep}`}
															<AddPerformance
																studentPerformance={item.studentPerformance}
															/>
														</h4>
													) : (
														<h4>
															Gestart in ontwikkelstap {item.step}
															<AddPerformance
																studentPerformance={item.studentPerformance}
															/>
														</h4>
													)}
													<span>
														{date}
														{item.teacherDisplayname &&
															` door ${item.teacherDisplayname}`}
													</span>

													{item.studentlessonstephistoryid === stepId ? (
														<div className="editStepWrap">
															<textarea
																value={teacherComment || ''}
																onChange={handleTeacherComent}
															/>
															<div className="editbtnWrap">
																<button
																	type="button"
																	className="outlineButton"
																	onClick={() => {
																		setStepId(null);
																		setTeacherComment(null);
																	}}
																>
																	Annuleren
																</button>
																<button
																	type="button"
																	className="defaultBtn"
																	onClick={() => {
																		setStepId(null);
																		updateStepHistory(
																			student.studentId,
																			item.studentlessonstephistoryid,
																			lesson.lessonId,
																			teacherComment
																		);
																	}}
																>
																	Verder
																</button>
															</div>
														</div>
													) : (
														item.teachercomment && (
															<p
																dangerouslySetInnerHTML={{
																	__html: item.teachercomment.replace(
																		/(?:\r\n|\r|\n)/g,
																		'<br>'
																	)
																}}
															/>
														)
													)}
												</div>
												{!stepId && (
													<>
														{item.deletable || item.editable ? (
															<div
																className={`btnWrap ${
																	isMobile ? 'showIcon' : ''
																}`}
															>
																{item.deletable ? (
																	<button
																		type="button"
																		onClick={() => {
																			switchPopup(
																				'commentsWrapper',
																				'deleteWrapper'
																			);
																			setStepId(
																				item.studentlessonstephistoryid
																			);
																		}}
																	>
																		<Trash className="trash" />
																	</button>
																) : (
																	<span />
																)}
																{item.editable ? (
																	<button
																		type="button"
																		onClick={() => {
																			setTeacherComment(item.teachercomment);
																			setStepId(
																				item.studentlessonstephistoryid
																			);
																		}}
																	>
																		<Edit className="edit" />
																	</button>
																) : (
																	<span />
																)}
															</div>
														) : null}
													</>
												)}
											</div>
										);
									})}
							</div>
						</>
					) : (
						<HistoryLoader />
					)}
				</div>
			</div>

			<div className="deleteWrapper">
				<p>Weet je zeker dat je deze observatie ongedaan wilt maken?</p>

				<div className="row">
					<div />
					<div>
						<button
							type="button"
							className="inlineBlock outlineButton"
							onClick={() => {
								setStepId(null);
								switchPopup('deleteWrapper', 'commentsWrapper');
							}}
						>
							Annuleren
						</button>

						<button
							type="button"
							className="inlineBlock defaultBtn"
							onClick={() => handleDelete()}
							style={{ marginLeft: '15px' }}
						>
							Ok
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default LessonHistory;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
// @import constants
import {
	api_url,
	panasysgetschooldata_endpoint,
	proceedparnasysimport_endpoint
} from '../../../constants/endpoints';
// @import components
import Loader from 'js/models/loader';
// @import utils
import { axiosPost } from '../../../utils/axiosCall';
// @import styles
import './index.scss';

const ManageParnasSysImport = () => {
	const {
		initialData: { token }
	} = useSelector(state => state);

	const [data, setData] = useState({
		groups: null,
		students: null,
		teachers: null
	});
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [successNote, setSuccessNote] = useState(null);
	const [description, setDescription] = useState(
		'Bezig met verbinden met ParnasSys'
	);

	const handleParnasysDataImport = () => {
		setLoading(true);
		const dataForm = new FormData();
		const handlerequest = url => {
			axiosPost(`${api_url}${url}`, dataForm, token, axiosResult => {
				if (axiosResult.status === 'success') {
					if (axiosResult.autonext) {
						setDescription(axiosResult.autonext.description);
						handlerequest(axiosResult.autonext.url);
					}
					if (axiosResult.groups) {
						setData({
							groups: axiosResult.groups,
							students: axiosResult.students,
							teachers: axiosResult.teachers
						});
						setLoading(false);
					}
				} else {
					setLoading(false);
					setError(axiosResult.failreason);
				}
			});
		};
		handlerequest(panasysgetschooldata_endpoint);
	};

	const handleParnasysDataProceed = () => {
		setLoading(true);
		const dataForm = new FormData();
		dataForm.append('whattodo', 'data');
		axiosPost(proceedparnasysimport_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				setLoading(false);
				setSuccessNote(
					'De nieuwste gegevens uit ParnasSys zijn verwerkt in Onderbouwd Online.'
				);
			} else {
				setLoading(false);
			}
		});
	};

	if (successNote) {
		return (
			<div className="manageParnasys">
				<div className="pageContainer">
					<h1 className="headingStyle">Synchroniseren met ParnasSys</h1>
					<p>{successNote}</p>
				</div>
			</div>
		);
	}

	return (
		<div className="manageParnasys">
			<div className="pageContainer">
				{data.groups === null && (
					<>
						<h1 className="headingStyle">Synchronisatie met ParnasSys</h1>
						{error ? (
							<p dangerouslySetInnerHTML={{ __html: error }} />
						) : (
							<p>
								Jullie school is in Onderbouwd Online gekoppeld aan ParnasSys.
								Dat betekent dat de gegevens van jullie leerlingen, groepen en
								leerkrachten uit ParnasSys worden gehaald. Als er in ParnasSys
								door jullie gegevens zijn gewijzigd dan kun je via deze optie
								zelf de gewijzigde gegevens binnenhalen en doorvoeren in
								Onderbouwd Online. Voordat de opgehaalde gegevens worden
								verwerkt krijg je eerst te zien wat er gewijzigd zal gaan worden
								in Onderbouwd Online.
							</p>
						)}
						<div className="buttonsWrapper">
							<button
								type="button"
								className="defaultBtn"
								onClick={handleParnasysDataImport}
							>
								start
							</button>
						</div>
					</>
				)}

				{data.groups !== null && (
					<>
						<h1 className="headingStyle">Synchroniseren met ParnasSys</h1>

						{/* students */}
						{data.students.tobeadded.length > 0 ||
						data.students.tobedeleted.length > 0 ||
						data.students.tobeupdated.length > 0 ? (
							<section className="importWrapper">
								<h2 className="headingStyle">Leerlingen</h2>
								{data.students.tobeadded.length > 0 && (
									<>
										<strong>Toevoegen</strong>
										<p>
											De volgende leerling(en) worden toegevoegd aan Onderbouwd
											online:
										</p>
										<ul>
											{data.students.tobeadded.map((item, i) => {
												return (
													<li key={`tobeaddedstudents_${i}`}>
														{item.studentFirstname} {item.studentMiddlename}{' '}
														{item.studentLastname} (
														{moment(new Date(item.studentBirthdate)).format(
															'DD-MM-YYYY'
														)}
														), leerjaar {item.studentGroup}
													</li>
												);
											})}
										</ul>
									</>
								)}

								{data.students.tobedeleted.length > 0 && (
									<>
										<strong>Verwijderen</strong>
										<p>
											De gegevens van de volgende leerling(en) worden verwijderd
											uit Onderbouwd online:
										</p>
										<ul>
											{data.students.tobedeleted.map((item, i) => {
												return (
													<li key={`tobedeletedstudents_${i}`}>
														{item.studentFirstname} {item.studentMiddlename}{' '}
														{item.studentLastname} (
														{moment(new Date(item.studentBirthdate)).format(
															'DD-MM-YYYY'
														)}
														)
													</li>
												);
											})}
										</ul>
									</>
								)}
								{data.students.tobeupdated.length > 0 && (
									<>
										<strong>Bijwerken</strong>
										<p>
											De gegevens van de volgende leerling(en) worden bijgewerkt
											in Onderbouwd online:
										</p>
										<ul>
											{data.students.tobeupdated.map((item, i) => {
												return (
													<li key={`tobeupdatedstudents_${i}`}>
														{`Huidige: ${item.originalFirstname} ${
															item.originalMiddlename
														} ${item.originalLastname} (${moment(
															new Date(item.originalBirthdate)
														).format('DD-MM-YYYY')}), leerjaar ${
															item.originalGroup
														}.
											Nieuw: ${item.newFirstname} ${item.newMiddlename} ${item.newLastname} (${moment(
															new Date(item.newBirthdate)
														).format('DD-MM-YYYY')}), leerjaar ${
															item.newGroup
														}`}
													</li>
												);
											})}
										</ul>
									</>
								)}
							</section>
						) : null}
						{/* groups */}
						{data.groups.tobeadded.length > 0 ||
						data.groups.tobedeleted.length > 0 ||
						data.groups.tobeupdated.length > 0 ? (
							<section className="importWrapper">
								<h2 className="headingStyle">Groepen</h2>
								{data.groups.tobeadded.length > 0 && (
									<>
										<strong>Toevoegen</strong>
										<p>
											De volgende groep(en) worden toegevoegd aan Onderbouwd
											online:
										</p>
										<ul>
											{data.groups.tobeadded.map((item, i) => {
												return (
													<li key={`tobeaddedgroups_${i}`}>{item.groupName}</li>
												);
											})}
										</ul>
									</>
								)}
								{data.groups.tobedeleted.length > 0 && (
									<>
										<strong>Verwijderen</strong>
										<p>
											De volgende groep(en) worden verwijderd uit Onderbouwd
											online:
										</p>
										<ul>
											{data.groups.tobedeleted.map((item, i) => {
												return (
													<li key={`tobedeletedgroups_${i}`}>
														{item.groupName}
													</li>
												);
											})}
										</ul>
									</>
								)}
								{data.groups.tobeupdated.length > 0 && (
									<>
										<strong>Bijwerken</strong>
										<p>
											De gegevens van de volgende groep(en) worden bijgewerkt in
											Onderbouwd online:
										</p>
										<ul>
											{data.groups.tobeupdated.map((item, i) => {
												return (
													<li
														key={`tobeupdatedgroups_${i}`}
													>{`Huidige: ${item.originalGroupname}. Nieuw: ${item.newGroupname}.`}</li>
												);
											})}
										</ul>
									</>
								)}
							</section>
						) : null}
						{/* teachers */}
						{data.teachers.tobeadded.length > 0 ||
						data.teachers.tobedeleted.length > 0 ||
						data.teachers.tobeupdated.length > 0 ||
						data.teachers.tobemerged.length > 0 ? (
							<section className="importWrapper">
								<h2 className="headingStyle">Leerkrachten</h2>
								{data.teachers.tobeadded.length > 0 && (
									<>
										<strong>Toevoegen</strong>
										<p>
											De volgende leerkracht(en) worden toegevoegd aan
											Onderbouwd online:
										</p>
										<ul>
											{data.teachers.tobeadded.map((item, i) => {
												return (
													<li key={`tobeaddedteachers_${i}`}>
														{item.userFirstname} {item.userMiddlename}{' '}
														{item.userLastname}{' '}
														{item.teacherGroups && `(${item.teacherGroups})`}
													</li>
												);
											})}
										</ul>
									</>
								)}
								{data.teachers.tobedeleted.length > 0 && (
									<>
										<strong>Verwijderen</strong>
										<p>
											De gegevens van de volgende leerkracht(en) worden
											verwijderd uit Onderbouwd online:
										</p>
										<ul>
											{data.teachers.tobedeleted.map((item, i) => {
												return (
													<li key={`tobedeletedteachers_${i}`}>
														{item.userFirstname} {item.userMiddlename}{' '}
														{item.userLastname}
													</li>
												);
											})}
										</ul>
									</>
								)}
								{data.teachers.tobeupdated.length > 0 && (
									<>
										<strong>Bijwerken</strong>
										<p>
											De gegevens van de volgende leerkracht(en) worden
											bijgewerkt in Onderbouwd online:
										</p>
										<ul>
											{data.teachers.tobeupdated.map((item, i) => {
												return (
													<li key={`tobeupdatedteachers_${i}`}>
														Huidig: {item.originalFirstname}{' '}
														{item.originalMiddlename} {item.originalLastname}{' '}
														{item.originalTeachergroups &&
															`(${item.originalTeachergroups})`}
														. Nieuw: {item.newFirstname} {item.newMiddlename}{' '}
														{item.newLastname}{' '}
														{item.newTeachergroups &&
															`(${item.newTeachergroups})`}
													</li>
												);
											})}
										</ul>
									</>
								)}
								{data.teachers.tobemerged.length > 0 && (
									<>
										<strong>Samenvoegen</strong>
										<p>
											De volgende leerkracht(en) worden samengevoegd met hun
											bestaande instroomleerkracht account in Onderbouwd online:
										</p>
										<ul>
											{data.teachers.tobemerged.map((item, i) => {
												return (
													<li key={`tobemerged_${i}`}>
														{item.userFirstname} {item.userMiddlename}{' '}
														{item.userLastname}{' '}
														{item.teacherGroups && `(${item.teacherGroups})`}
													</li>
												);
											})}
										</ul>
									</>
								)}
							</section>
						) : null}

						{data.students.tobeadded.length === 0 &&
						data.students.tobedeleted.length === 0 &&
						data.students.tobeupdated.length === 0 &&
						data.groups.tobeadded.length === 0 &&
						data.groups.tobedeleted.length === 0 &&
						data.groups.tobeupdated.length === 0 &&
						data.teachers.tobeadded.length === 0 &&
						data.teachers.tobedeleted.length === 0 &&
						data.teachers.tobeupdated.length === 0 &&
						data.teachers.tobemerged.length === 0 ? (
							<>
								<p>
									De gegevens van jullie school in Onderbouwd Online zijn al
									gesynchroniseerd met ParnasSys.
								</p>
								<p>
									Mocht je foto's hebben veranderd in ParnasSys dan kunnen we
									die wijziging in Onderbouwd Online niet herkennen. Klik in dat
									geval op 'verder' om de foto's te verversen.
								</p>
								<div className="buttonsWrapper">
									<button
										type="button"
										className="defaultBtn"
										onClick={handleParnasysDataProceed}
									>
										Verder
									</button>
								</div>
							</>
						) : (
							<>
								<p>Zijn deze mutaties juist?</p>
								<div className="buttonsWrapper">
									<button
										type="button"
										className="defaultBtn"
										onClick={handleParnasysDataProceed}
									>
										Doorvoeren
									</button>
								</div>
								<p>
									Als de mutaties niet juist zijn, corrigeer de gegevens in
									ParnasSys en start deze synchronisatie opnieuw.
								</p>
								<div className="buttonsWrapper">
									<button
										type="button"
										className="outlineButton"
										onClick={() => {
											window.location.href = '/';
										}}
									>
										Annuleren
									</button>
								</div>
							</>
						)}
					</>
				)}
			</div>

			{loading && (
				<div className="loaderFixedWrapper flexCenter">
					<div className="loaderContainer">
						<p>{description}</p>
						<Loader />
					</div>
				</div>
			)}
		</div>
	);
};

export default ManageParnasSysImport;

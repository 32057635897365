// @import packages
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { TweenMax } from 'gsap/TweenMax';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
// @import actions
import {
	setStudentList,
	fetchPeriodData,
	sendErrorToHOC
} from 'actions/actions';
// @import constants
import {
	studentstep_endpoint,
	groupresults_endpoint
} from 'js/constants/endpoints';
// @import components
import Loader from 'js/models/loader';
import Student from 'js/components/student';
import ObservationPopup from 'js/models/observationPopup';
// @import placeholder
// @import styles
import 'css/containers/groupResults.scss';
// @import images
import { ReactComponent as Eye } from '../../images/svg/eye.svg';
import { ReactComponent as Pencil } from '../../images/svg/edit.svg';
import { ReactComponent as PrintIcon } from 'images/svg/printer.svg';
import { ReactComponent as NextArrowIcon } from 'images/svg/next.svg';
import { ReactComponent as FullscreenIcon } from 'images/svg/fullscreen.svg';
import { ReactComponent as ExitscreenIcon } from 'images/svg/exitscreen.svg';
// @import utils
import { axiosPost } from '../utils/axiosCall';
import { toggleFullScreen } from 'js/utils/triggerFullscreen';
import { grouptoSlug, slugtoGroup } from 'js/utils/groupUtility';

export class GroupResults extends Component {
	constructor(props) {
		super(props);
		this.idleTime = 0;
		this.maxIdleTime = 60;
		this.checkInactivityInterval = null;
		this.state = {
			isLoading: true,
			refresh: false,
			isFullScreen: false
		};
		this.handleFullScreenChange = this.handleFullScreenChange.bind(this);
	}

	componentDidMount() {
		if (this.props.groupId) {
			this.setInitialStates();
			this.props.fetchPeriodData(
				this.props.token,
				this.props.periodId,
				this.props.groupId,
				this.props.topicId
			);
		}

		document
			.querySelector('.groupOverviewContainer')
			.addEventListener('fullscreenchange', this.handleFullScreenChange);
	}

	componentDidUpdate(prevProps) {
		if (this.props.lessonTree !== prevProps.lessonTree) {
			this.setInitialStates();
		}
	}

	componentWillUnmount() {
		this.untrackActivity();
		document
			.querySelector('.groupOverviewContainer')
			.removeEventListener('fullscreenchange', this.handleFullScreenChange);
	}

	handleFullScreenChange() {
		if (document.fullscreenElement) {
			this.setState({
				isFullScreen: true
			});
			console.log(
				`Element: ${document.fullscreenElement.id} entered fullscreen mode.`
			);
		} else {
			console.log('Leaving full-screen mode.');
			this.setState({
				isFullScreen: false
			});
		}
	}

	trackActivity() {
		document.addEventListener('mousemove', this.handleCursorMove);
		clearInterval(this.checkInactivityInterval);
		this.checkInactivityInterval = setInterval(
			() => this.checkInactivity(),
			1000
		);
	}

	untrackActivity() {
		clearInterval(this.checkInactivityInterval);
		document.removeEventListener('mousemove', this.handleCursorMove);
	}

	checkInactivity() {
		this.idleTime++;
		if (this.idleTime === this.maxIdleTime) {
			this.idleTime = 0;
			clearInterval(this.checkInactivityInterval);
			this.setState({ refresh: true });
		}
	}

	refreshData() {
		this.setState({ refresh: false }, () => {
			this.setInitialStates();
		});
	}

	handleCursorMove = () => {
		this.idleTime = 0;
		if (this.state.refresh) {
			this.refreshData();
		}
	};

	setInitialStates = () => {
		setTimeout(() => {
			let { groupname } = this.props.match.params;
			groupname = slugtoGroup(groupname);

			const getGroup = this.props.groupList.groups.find(
				x => slugtoGroup(x.groupName) === groupname
			);
			if (getGroup?.numberOfTeachers > 1) {
				this.trackActivity();
			}

			this.props.setStudentList(
				getGroup.groupId,
				getGroup.groupName,
				getGroup.students
			);

			const { params } = this.props.match;

			let currentSeason, currentPeriod;

			if (params.season && params.period) {
				currentSeason = this.props.lessonTree.find(
					x => x.seasonName.toLowerCase() === params.season.toLowerCase()
				);

				let getPeriodFromURL = params.period;
				if (getPeriodFromURL.includes('_')) {
					getPeriodFromURL = getPeriodFromURL.replace(/_/g, ' ');
				}

				currentPeriod = currentSeason.periods.find(
					x => x.periodName.toLowerCase() === getPeriodFromURL.toLowerCase()
				);
			} else {
				currentSeason = this.props.lessonTree.filter(
					x => x.seasonId === this.props.seasonId.toString()
				);
				currentPeriod = currentSeason[0].periods.find(
					x => x.periodId === this.props.periodId.toString()
				);
			}

			const topic = this.props.location.state
				? this.props.location.state.topic
				: null;

			const currentTopic = topic ? topic : currentPeriod.topics[0];

			this.setState({
				currentPeriod,
				topicId: topic ? topic.topicId : currentTopic.topicId,
				topicName: topic ? topic.topicName : currentTopic.topicName
			});

			this.getGroupResults();
		}, 200);
	};

	getGroupResults = () => {
		this.setState({
			isLoading: true,
			lessons: null
		});

		const { season, period } = this.props.match.params;

		const dataForm = new FormData();
		dataForm.append('groupId', this.props.groupId);
		dataForm.append('topicId', this.state.topicId);

		if (season && period) {
			dataForm.append('periodId', this.state.currentPeriod.periodId);
		}

		axiosPost(
			groupresults_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState({
						isLoading: false,
						headers: axiosResult.data.headers,
						students: axiosResult.data.students,
						nolessons:
							axiosResult.data.headers === null ||
							axiosResult.data.headers.filter(
								array => array.lessons.length !== 0
							).length === 0
					});
				}
			}
		);
	};

	changeTopic = (id, name) => {
		this.setState(
			{
				topicName: name,
				topicId: id
			},
			() => {
				this.getGroupResults(this.props.groupId, id);
			}
		);
	};

	showObservationPopup = (step, lesson, student, studentPerformance) => {
		let message = lesson.stepdescriptions.find(
			item => item.step === step
		).description;
		message = message.replace(/#kind/gi, student.studentFirstname);

		this.setState(
			{
				draggedStudent: student,
				currentStep: step,
				draggedMessage: message,
				draggedLessonId: lesson.lessonId,
				studentPerformance,
				clickedLesson: lesson,
				calledfrom: 'group overview',
				showStudentPopup: true
			},
			() => {
				TweenMax.to('.popupWrapper', 0.3, {
					autoAlpha: 1
				});
				this.untrackActivity();
			}
		);
	};

	hideObservationPopup = () => {
		TweenMax.to('.popupWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setTimeout(() => {
					this.setState(
						{
							showStudentPopup: false
						},
						() => {
							this.trackActivity();
						}
					);
				}, 200);
			}
		});
	};

	handleArrowStep = (studentId, lessonId, step) => {
		const dataForm = new FormData();
		dataForm.append('studentId', studentId);
		dataForm.append('lessonId', lessonId);
		dataForm.append('newStep', step);
		dataForm.append('teacherComment', '');
		dataForm.append('calledfrom', 'group overview');
		dataForm.append('routinelessonId', '');

		axiosPost(studentstep_endpoint, dataForm, this.props.token, axiosResult => {
			if (axiosResult.status === 'success') {
				this.getGroupResults(this.props.groupId, this.state.topicId);
			}
		});
	};

	render() {
		const { season, period } = this.props.match.params;
		return (
			<div className="mainContainer groupOverviewContainer">
				<div className="flexBlock">
					{season && period && (
						<ul className="verticalTab">
							<li
								className={`${
									this.state.topicName && this.state.topicName.replace(/ /g, '-').toLowerCase()
								} active`}
							>
								<Link
									to={{
										pathname: `/groep/${grouptoSlug(
											this.props.groupName
										)}/groepsoverzicht/${season}/${period}`,
										state: {
											topic: {
												topicId: this.state.topicId,
												topicName: this.state.topicName
											}
										}
									}}
									onClick={e => {
										if (!this.props.location.pathname.includes('notities')) {
											e.preventDefault();
										}
									}}
								>
									Resultaten
								</Link>
							</li>
							<li
								className={`${
									this.state.topicName && this.state.topicName.replace(/ /g, '-').toLowerCase()
								}`}
							>
								<Link
									to={`/groep/${grouptoSlug(
										this.props.groupName
									)}/groepsoverzicht/${season}/${period}/${
										this.state.topicName && this.state.topicName.replace(/ /g, '_').toLowerCase()
									}/notities`}
								>
									Notities
								</Link>
							</li>
						</ul>
					)}

					<div className={`${season && period ? 'reducedWidth' : 'fullWidth'}`}>
						<div className="flexBlock flexSpaceCenter">
							{this.state.currentPeriod && (
								<ul className="tabsStyle">
									{this.state.currentPeriod.topics.map((item, i) => (
										<li
											key={i}
											className={`${item.topicName
												.replace(/ /g, '-')
												.toLowerCase()}_tab`}
										>
											<button
												type="button"
												onClick={() =>
													this.changeTopic(item.topicId, item.topicName)
												}
											>
												{item.topicName}
											</button>
										</li>
									))}
									{!this.state.isFullScreen && (
										<>
											<li className="behind_tab">
												<Link
													to={`/groep/${grouptoSlug(
														this.props.groupName
													)}/groepsoverzicht/behandelplan/in-ontwikkeling${
														season && period ? `/${season}/${period}` : ''
													}`}
												>
													In ontwikkeling
												</Link>
											</li>
											<li className="ahead_tab">
												<Link
													to={`/groep/${grouptoSlug(
														this.props.groupName
													)}/groepsoverzicht/behandelplan/leervoorsprong${
														season && period ? `/${season}/${period}` : ''
													}`}
												>
													Leervoorsprong
												</Link>
											</li>
										</>
									)}
								</ul>
							)}
							<div style={{ display: 'flex' }}>
							{period && (
								<Link
									className="groupLink"
									to={`/lessen/${season}/${period.toLowerCase().replace(' ', '_')}/${this.state.topicName ? this.state.topicName.toLowerCase() : 'taal'}`}
								>
									Naar thema
								</Link>
							)}
							{season && period && (
								<Link
									className="groupLink"
									to={`/groep/${grouptoSlug(
										this.props.groupName
									)}/groepsoverzicht`}
								>
									Volledig Groepsoverzicht
								</Link>
							)}
							</div>
						</div>

						<div
							ref={el => (this.componentRef = el)}
							className={`tabBorderStyle ${
								this.state.topicName
									? this.state.topicName
											.replace(/ /g, '-')
											.toLowerCase()
											.concat('_wrapper')
									: null
							}`}
						>
							<div className="pageContainer">
								<div
									className={`groupResultsWrapper ${
										this.state.isLoading ? 'flexCenter' : ''
									}`}
								>
									{this.state.isLoading ? (
										<Loader />
									) : (
										<>
											{this.state.headers && (
												<h1>
													{`${this.props.groupName} - Groepsoverzicht ${
														this.state.topicName
													} ${
														season && period
															? `- ${this.state.currentPeriod.periodName}`
															: ''
													}`}
												</h1>
											)}
											<button
												type="button"
												className="printButton"
												onClick={() =>
													toggleFullScreen('.groupOverviewContainer')
												}
											>
												{this.state.isFullScreen ? (
													<ExitscreenIcon />
												) : (
													<FullscreenIcon />
												)}
											</button>

											<ReactToPrint
												trigger={() => (
													<button
														type="button"
														className="printButton"
														style={{ right: '70px' }}
													>
														<PrintIcon width="24px" />
													</button>
												)}
												pageStyle={
													'@media print{@page{size: landscape; margin: 5mm 7mm 5mm 7mm;} body{margin:0;} .pageContainer{background: white !important;} .printButton{display: none;}}'
												}
												content={() => this.componentRef}
											/>

											{this.state.nolessons ? (
												<>
													<h1></h1>
													<p className="nodata">
														Er zijn geen lessen of routinelessen ingepland in
														deze periode.
													</p>
												</>
											) : (
												<div className="overflowController">
													<SimpleBar forceVisible="y" autoHide={false}>
														{this.state.headers && (
															<div className="row headerRow">
																<div className="studentColumn" />
																<div className="tableColumn">
																	{this.state.headers.map((item, i) => {
																		if (item.lessons.length === 0) {
																			return null;
																		}
																		return (
																			<div
																				className="groupColumn"
																				key={i}
																				data-goalid={item.goalId}
																			>
																				<div className="goalRow">
																					<h2 className="goalName">
																						{item.goalName}
																					</h2>
																					<img
																						src={item.goalImage.replace(
																							/(\.[^.]+)$/,
																							'-80x80$1'
																						)}
																						srcSet={`${item.goalImage.replace(
																							/(\.[^.]+)$/,
																							'-160x160$1'
																						)} 2x`}
																						alt={item.goalName}
																					/>
																				</div>
																				<div className="row">
																					{item.lessons.map((res, i) => {
																						return (
																							<div className="step" key={i}>
																								<span
																									className="lessonName"
																									data-lessonid={res.lessonId}
																								>
																									{res.lessonName}
																								</span>
																							</div>
																						);
																					})}
																				</div>
																			</div>
																		);
																	})}
																</div>
															</div>
														)}

														{this.state.students &&
															this.state.students.map(item => {
																return (
																	<div className="row" key={item.studentId}>
																		<div className="studentColumn">
																			<Link
																				to={`/leerling/${grouptoSlug(
																					this.props.groupName
																				)}/${item.studentDisplayname
																					.replace(/ /g, '_')
																					.toLowerCase()}/resultaten`}
																			>
																				<Student
																					studentId={item.studentId}
																					name={item.studentDisplayname}
																					imagepath={item.studentPicture}
																					size={40}
																				/>
																			</Link>
																		</div>
																		<div className="tableColumn">
																			{this.state.headers?.map(
																				(headerItem, i) => {
																					if (headerItem.lessons.length === 0) {
																						return null;
																					}
																					return (
																						<div
																							className="groupColumn"
																							key={i}
																						>
																							<div className="row">
																								{headerItem.lessons.map(
																									(res, i) => {
																										let currentStep,
																											nextstep,
																											eyestatus,
																											previousstep,
																											currentPerformance,
																											studentNotification;

																										if (item.scores) {
																											const results =
																												item.scores.find(
																													x =>
																														x.lessonId ===
																														res.lessonId
																												);
																											if (results) {
																												currentStep =
																													results.step;
																												currentPerformance =
																													results.studentPerformance;
																												eyestatus =
																													results.eyestatus;
																												studentNotification =
																													results.studentNotification;
																												nextstep =
																													results.nextstep;
																												previousstep =
																													results.previousstep;
																											}
																										}
																										return (
																											<div
																												className={`step ${
																													currentPerformance
																														? currentPerformance.replace(
																																/ /g,
																																'-'
																														  )
																														: ''
																												} ${
																													currentStep
																														? 'cursor'
																														: ''
																												}`}
																												key={res.lessonId}
																											>
																												<button
																													type="button"
																													onClick={() =>
																														this.handleArrowStep(
																															item.studentId,
																															res.lessonId,
																															previousstep
																														)
																													}
																													disabled={
																														!previousstep
																													}
																													className="stepButton prevArrow"
																												>
																													<NextArrowIcon />
																												</button>

																												<button
																													type="button"
																													disabled={!nextstep}
																													onClick={() =>
																														this.handleArrowStep(
																															item.studentId,
																															res.lessonId,
																															nextstep
																														)
																													}
																													className="stepButton nextArrow"
																												>
																													<NextArrowIcon />
																												</button>

																												<button
																													type="button"
																													className="itemButton"
																													key={res.lessonId}
																													onClick={
																														currentPerformance
																															? () =>
																																	this.showObservationPopup(
																																		currentStep,
																																		res,
																																		item,
																																		currentPerformance
																																	)
																															: e =>
																																	e.preventDefault()
																													}
																												>
																													<span className="currentStep">
																														{eyestatus ===
																															'1' && (
																															<div className="iconWrap eyeIcon">
																																<Eye />
																															</div>
																														)}
																														{currentStep &&
																														currentStep ===
																															'E' ? (
																															<b>•</b>
																														) : (
																															currentStep
																														)}
																														{studentNotification ===
																															'commented' && (
																															<div className="iconWrap">
																																<Pencil />
																															</div>
																														)}
																													</span>
																												</button>
																											</div>
																										);
																									}
																								)}
																							</div>
																						</div>
																					);
																				}
																			)}
																		</div>
																	</div>
																);
															})}
													</SimpleBar>
												</div>
											)}
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				{this.state.showStudentPopup && (
					<ObservationPopup
						draggedStudent={this.state.draggedStudent}
						studentPerformance={this.state.studentPerformance}
						stepFrom={this.state.currentStep}
						stepTo={this.state.currentStep}
						message={this.state.draggedMessage}
						lessonId={this.state.draggedLessonId}
						hidepopup={this.hideObservationPopup}
						calledfrom={this.state.calledfrom}
						clickedLesson={this.state.clickedLesson}
						callback={this.getGroupResults}
						topicName={this.state.topicName}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
	groupName: state.initialData.groupName,
	groupId: state.initialData.groupId,
	groupList: state.initialData.groupList,
	seasonId: state.initialData.seasonId,
	periodId: state.initialData.periodId,
	topicId: state.initialData.topicId,
	lessons: state.initialData.lessons,
	lessonTree: state.initialData.lessonTree
});

export default connect(mapStateToProps, {
	setStudentList,
	fetchPeriodData,
	sendErrorToHOC
})(GroupResults);

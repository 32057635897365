// @import packages
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
// @import constants
import { students_endpoint } from '../constants/endpoints';
// @import styles
import '../../css/containers/studentEidSystem.scss';
// @import images
import { ReactComponent as PrintIcon } from '../../images/svg/printer.svg';
// @import actions
import { sendErrorToHOC } from '../../actions/actions';
// @import utils
import { axiosPost } from '../utils/axiosCall';

class StudentListComponent extends Component {
	render() {
		const { students, groupName } = this.props;
		return (
			<div className="printContainer">
				<h1 className="headingStyle">{groupName} - Leerlingenkaart</h1>
				<div className="studentListWrapper">
					{students === 'nodata' ? (
						<p className="noContent">
							Het groepsoverzicht kan alleen geprint worden als er leerlingen
							zijn
						</p>
					) : (
						students &&
						students.map((item, i) => (
							<div className="studentBox" key={i}>
								<div className="aspectContainer">
									<img
										src={item.studentPicture.replace(
											/(\.[^.]+)$/,
											'-260x260$1'
										)}
										srcSet={`${item.studentPicture.replace(
											/(\.[^.]+)$/,
											'-520x520$1'
										)} 2x`}
										alt={item.studentDisplayname}
									/>
								</div>
								<strong>{item.studentDisplayname}</strong>
								{item.studentDisplayage && (
									<span>{item.studentDisplayage}</span>
								)}
							</div>
						))
					)}
				</div>
			</div>
		);
	}
}

class StudentPrintPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			students: null,
			data: null
		};
	}

	componentDidMount() {
		if (this.props.groupId) {
			this.getStudents(this.props.groupId, this.props.token);
		}
	}

	getStudents = (groupId, token) => {
		this.setState({
			isLoading: true
		});

		const dataForm = new FormData();
		dataForm.append('groupId', groupId);
		axiosPost(students_endpoint, dataForm, this.props.token, axiosResult => {
			this.setState({
				isLoading: false,
				students:
					axiosResult.status === 'no such data'
						? 'nodata'
						: axiosResult.data.students
			});
		});
	};

	getTopicName = topicName => {
		this.setState({
			topicName
		});
	};

	render() {
		return (
			<div className="mainContainer">
				<div className="pageContainer studentPrintWrapper">
					<ReactToPrint
						trigger={() => (
							<button type="button" className="printButton">
								<PrintIcon />
							</button>
						)}
						content={() => this.componentRef}
					/>
					<StudentListComponent
						groupName={this.props.groupName}
						students={this.state.students}
						ref={el => (this.componentRef = el)}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
	studentList: state.initialData.studentList,
	groupId: state.initialData.groupId,
	groupName: state.initialData.groupName
});

export default connect(mapStateToProps, { sendErrorToHOC })(StudentPrintPage);

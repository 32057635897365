import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import localization from 'moment/locale/nl';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// @import components
import EmbedCanvaYoutube from '../../components/EmbedCanvaYoutube';
// @import constants
import { academymessage_endpoint } from '../../constants/endpoints';
// @import utils
import { axiosPost } from '../../utils/axiosCall';
// @import images
// import profilepic from '../../../images/banner1.jpg';
// @import styles
import '../../../css/containers/academy/commentSection.scss';

const CommentSection = ({
	data,
	token,
	status,
	courseId,
	updateChapter,
	chapterNumber,
	...props
}) => {
	// console.log('data', data, 'props', props.userData);

	const [comment, setComment] = useState('');
	const [attachments, setAttachments] = useState({
		attachment1Title: '',
		attachment1File: ''
	});
	const [count, setCount] = useState(1);
	const [error, setError] = useState(false);

	const handleSubmit = evt => {
		setError(false);
		const dataForm = new FormData();
		dataForm.append('message', comment);
		dataForm.append('courseId', courseId);
		dataForm.append('chapterNumber', chapterNumber);

		for (let index = 0; index < count; index++) {
			dataForm.append(
				`attachment${index + 1}Title`,
				attachments[`attachment${index + 1}Title`]
			);
			dataForm.append(
				`attachment${index + 1}File`,
				document.querySelector(
					`.attachmentRow input[name='attachment${index + 1}File']`
				).files[0]
			);
		}

		if (comment !== '') {
			axiosPost(academymessage_endpoint, dataForm, token, axiosResult => {
				if (axiosResult.status === 'success') {
					setComment('');
					setAttachments({
						attachment1Title: '',
						attachment1File: ''
					});
					updateChapter();
				} else {
					setError(true);
				}
			});
		}
	};

	function handleAttachmentChange(evt) {
		const value = evt.target.value;
		setAttachments({
			...attachments,
			[evt.target.name]: value
		});
	}

	const handleAddition = index => {
		setCount(count + 1);
		var keyTitle = `attachment${count + 1}Title`;
		var keyFile = `attachment${count + 1}File`;
		setAttachments({
			...attachments,
			[keyTitle]: '',
			[keyFile]: ''
		});
	};

	return (
		<div className="commentSection">
			<h3>Contact</h3>
			<p>
				Als je een vraag hebt over dit hoofdstuk of iets wilt delen met je
				opleidingsfacilitator dan kun je dit hieronder doen. Je kunt ook
				&eacute;&eacute;n of meerdere bijlagen meesturen.
			</p>

			{data.map((item, i) => {
				const date = moment(item.acoursemessageDatetime)
					.locale('nl', localization)
					.format('D MMMM YYYY');

				const time = moment(item.acoursemessageDatetime).format('HH:mm');

				return (
					<div
						key={`comment_${i}`}
						className={`comment ${
							item.acoursemessageSender ? 'repliedComment' : 'teachersComment'
						}`}
					>
						<img
							src={
								item.acoursemessageSender
									? item.acoursemessageSender.userPicture
									: props.userData.userPicture
							}
							alt={''}
							className="cmntImg"
						/>
						<div className="content">
							<div
								dangerouslySetInnerHTML={{
									__html: item.acoursemessageText
								}}
							/>
							{item.acoursemessageMediaUrl && (
								<div className="media">
									<EmbedCanvaYoutube source={item.acoursemessageMediaUrl} />
								</div>
							)}
							{item.acoursemessageAttachments && (
								<div className="row">
									{item.acoursemessageAttachments.map(attachment => (
										<a
											className="pillButton small"
											target="_blank"
											rel="noopener noreferrer"
											key={attachment.attachmentId}
											href={attachment.attachmentFile}
										>
											{attachment.attachmentTitle}
										</a>
									))}
								</div>
							)}

							<span className="cmntTime">
								{item.acoursemessageSender
									? item.acoursemessageSender.userFirstname
									: 'Jij'}
								, op {date} om {time}
							</span>
						</div>
					</div>
				);
			})}

			{status === 'in progress' && (
				<div className="commentForm">
					<h4>Nieuw bericht</h4>
					<div className="flexBlock commentWrapper">
						<img src={props.userData.userPicture} alt="" className="cmntImg" />
						<ReactQuill
							value={comment}
							theme="snow"
							placeholder="Jouw bericht"
							onChange={option => setComment(option)}
						/>
					</div>

					<div className="flexBlock attachmentRow">
						{Array(count)
							.fill(1)
							.map((el, i) => (
								<>
									<div className="row" key={`attachment_${i + 1}`}>
										<input
											type="text"
											className="inputFeild"
											placeholder="Naam bijlage"
											onChange={handleAttachmentChange}
											name={`attachment${i + 1}Title`}
											value={`${attachments[`attachment${i + 1}Title`]}`}
										/>
										<input
											type="file"
											name={`attachment${i + 1}File`}
											onChange={handleAttachmentChange}
											value={`${attachments[`attachment${i + 1}File`]}`}
										/>
									</div>
									{i + 1 === count && (
										<button
											type="button"
											disabled={
												`${attachments[`attachment${i + 1}Title`]}` === '' ||
												`${attachments[`attachment${i + 1}File`]}` === ''
											}
											className="defaultBtn addbutton"
											onClick={() => handleAddition(i + 1)}
										>
											+
										</button>
									)}
								</>
							))}
					</div>

					<div className="rowWrapper">
						<p className="error">
							{error && 'Er ging iets mis bij het versturen van het bericht.'}
						</p>
						<button
							type="button"
							onClick={handleSubmit}
							disabled={comment === ''}
							className="outlineButton"
						>
							Verstuur bericht
						</button>
					</div>
				</div>
			)}
		</div>
	);
};
const mapStateToprops = state => ({
	userData: state.initialData.userData
});

export default connect(mapStateToprops, null)(CommentSection);

import React, { useRef, useState, useEffect } from 'react';
import { TweenMax } from 'gsap/TweenMax';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
// @import constants
import {
	routinelessonlist_endpoint,
	routinelessonarchive_endpoint,
} from 'js/constants/endpoints';
// @import components
import Loader from 'js/models/loader';
import RoutineLessonAddEditPopup from 'js/components/popup/routineLessonAddEditPopup';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
import { getCurrentTopic } from 'js/utils/filterArray';
// @import styles
import './index.scss';
// @import svgs
import { ReactComponent as Add } from 'images/svg/add.svg';
import { ReactComponent as Edit } from 'images/svg/edit.svg';
import { ReactComponent as UpArrow } from 'images/svg/up-arrow.svg';
import { ReactComponent as Eye } from 'images/svg/eye.svg';
import { ReactComponent as EyeClosed } from 'images/svg/close_eye.svg';

const PeriodList = ({ theme, title, data, handlePopup, defaultOpen, showArchived, onUpdate, token }) => {
	const cachedPeriodId = localStorage.getItem('activeRoutineLessonPeriod');
	console.log('EDGE cachedPeriodId: ', cachedPeriodId);
	const [periodData, setPeriodData] = useState(data && data.periods);
	const [isOpen, setOpen] = useState(defaultOpen);
	// const [openPeriod, setOpenPeriod] = useState(data && data.periods && data.periods.length ? data.periods[0].periodId : null);
	const [openPeriod, setOpenPeriod] = useState(cachedPeriodId);
	const accord = useRef(null);

	function handleAccordian() {
		if (!isOpen) {
			TweenMax.set(accord.current, { height: 'auto' });
			TweenMax.from(accord.current, 0.2, { height: 0 });
			setOpen(!isOpen);
		} else {
			TweenMax.to(accord.current, 0.2, { height: 0 });
			setOpen(!isOpen);
		}
		localStorage.setItem('activeRoutineLessonSeason', title);
	}

	function handlePeriodAccordian(periodId) {
		setOpenPeriod(periodId === openPeriod ? null : periodId);
		if (periodId !== null) {
			localStorage.setItem('activeRoutineLessonPeriod', periodId);
		}
		console.log('EDGE localStorage periodId: ', localStorage.getItem('activeRoutineLessonPeriod'));
	}

	const handleArchive = (status, routinelessonId) => {
		const dataForm = new FormData();
		dataForm.append('routinelessonId', routinelessonId);
		dataForm.append('newStatus', status);

		axiosPost(routinelessonarchive_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				onUpdate();
			}
		});
	};

	useEffect(() => {
		if (data && data.periods) {
			setPeriodData(data.periods);
		}
	}, [data]);

	return (
		<>
			<li className={theme}>
				<button
					type="button"
					onClick={handleAccordian}
					className={isOpen ? 'active' : ''}
				>
					{title}
					<UpArrow />
				</button>
			</li>
			{periodData && periodData.length > 0 && (
				<li
					ref={accord}
					className={`periodContainer flexRow ${isOpen ? 'active' : ''}`}
				>
					{periodData.map((item, index) => {
						let filteredRoutineLessons = item.routinelessons
							.filter((routinelesson) => showArchived ? true : routinelesson.general.routinelessonActive === '1');

						return (
							<div key={`period_${index}`} className="PeriodWrap">
								<div className={`${theme} period`} onClick={() => handlePeriodAccordian(item.periodId)}>
									<span>{item.periodName}</span>
									<div class="period-actions">
										<UpArrow className={`accordion-arrow ${openPeriod === item.periodId && 'accordion-arrow--active'}`}/>
										<button
											className="toggleBtn"
											onClick={() => {
												handlePopup('add', data.seasonId, item.periodId);
											}}
										>
											<Add />
										</button>
									</div>
								</div>
								{(item.routinelessons && openPeriod === item.periodId) && (
									<div className="PeriodWrap">
										{filteredRoutineLessons.map((routinelesson, index) => {
											const {
												general: { routinelessonName, routinelessonActive }
											} = routinelesson;
											return (
												<div
													key={`routinelesson_${index}`}
													className={`lesson ${
														routinelessonActive == 0 ? 'archived' : ''
													}`}
													style={{ marginLeft: 32 }}
												>
													<span>{routinelessonName}</span>
													{routinelesson.editable == true ? (
														<button
															className="toggleBtn"
															onClick={() => {
																handlePopup(
																	'edit',
																	data.seasonId,
																	item.periodId,
																	routinelesson
																);
															}}
														>
															<Edit />
														</button>
													) : (
														<button
															className="toggleBtn"
															onClick={() => handleArchive(routinelessonActive == 0 ? 'unarchive' : 'archive', routinelesson.routinelessonId)}
														>
															{routinelessonActive == 0 ? <EyeClosed /> : <Eye />}
														</button>
													)}
												</div>
											);
										})}
									</div>
								)}
							</div>
						);
					})}
				</li>
			)}
		</>
	);
};

const ManageLessons = ({ match, topics }) => {
	const {
		initialData: { token }
	} = useSelector(state => state);

	const [data, setData] = useState(null);
	const [goals, setGoals] = useState(null);
	const [loading, setLoading] = useState(true);
	const [openPopup, setOpenPopup] = useState();
	const [popupdata, setPopupData] = useState();
	const [showArchived, setShowArchived] = useState(false);

	useEffect(() => {
		getData();
		return () => {};
	}, []);

	const getData = (loader = true) => {
		if (loader) {
			setLoading(true);
		}

		const dataForm = new FormData();
		dataForm.append('topicId', getCurrentTopic(topics, match.params.topicname).topicId);

		axiosPost(routinelessonlist_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				setLoading(false);
				setGoals(axiosResult.topicsgoals);
				setData(axiosResult.seasonperiods);
				console.log('axiosResult', axiosResult);
			} else {
				setLoading(false);
				console.log('axiosResult', axiosResult);
			}
		});
	};

	const reOrderPeriod = (sId, pIds) => {
		const dataForm = new FormData();
		dataForm.append('periodIds', pIds);
		dataForm.append('seasonId', sId);

		axiosPost(periodreorder_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				getData(false);
			} else {
				setLoading(false);
				console.log('axiosResult', axiosResult);
			}
		});
	};

	const handlePopup = (popupType, seasonId, periodId, routineLesson = null) => {
		setOpenPopup(true);
		// console.log('popupType: ',popupType,'seasonId: ',seasonId,'periodId: ',periodId,'lesson: ',lesson);
		setPopupData({
			popupType: popupType,
			topicId: getCurrentTopic(topics, match.params.topicname).topicId,
			seasonId: seasonId,
			periodId: periodId,
			routinelesson: routineLesson
		});
	};

	const closePopup = () => {
		TweenMax.to('.popupWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setTimeout(() => {
					setOpenPopup(false);
				}, 200);
			}
		});
	};

	useEffect(() => {
		if (openPopup) {
			TweenMax.to('.popupWrapper', 0.3, {
				autoAlpha: 1
			});
		}
	}, [openPopup]);

	return (
		<>
			<div className="routineLessonsWrapper">
				<ul className="tabsStyle">
					{topics && topics.map((item, i) => (
						<li
							key={i}
							className={`${item.topicName
								.replace(/ /g, '-')
								.toLowerCase()}_tab`}
						>
							<NavLink
								to={`/curriculum/manageroutinelessons/${item.topicName
									.replace(/ /g, '_')
									.toLowerCase()}`}
							>
								{item.topicName}
							</NavLink>
						</li>
					))}
				</ul>
				<div
					className={`tabBorderStyle ${
						match.params.topicname
							? match.params.topicname.replace(/_/g, '-').concat('_wrapper')
							: null
					}`}
				>
					<div className="pageContainer">
						<div
							className={`groupResultsWrapper ${loading ? 'flexCenter' : ''}`}
						>
							{loading ? (
								<Loader />
							) : (
								<div>
									<h1 className="title">Beheer Routinelessen</h1>
									<p className="pageintro">
										Hier beheer je de routinelessen. Deze zijn gekoppeld aan een
										les die geobserveerd wordt en passen in een thema.
									</p>
									<div>
										<button
											type="button"
											onClick={() => setShowArchived(!showArchived)}
											className={`outlineButton ${ showArchived ? 'disableButton' : 'doneButton' }`}
										>
											{showArchived ? 'Verberg gearchiveerd' : 'Zie gearchiveerd'}
										</button>
									</div>
									<br />
									<ul className="themeList">
										{data &&
											data.map((item, index) => {
												return (
													<PeriodList
														data={item}
														defaultOpen={
															localStorage.getItem('activeRoutineLessonSeason')
																? localStorage.getItem(
																		'activeRoutineLessonSeason'
																  ) === item.seasonName
																: index === 0
														}
														key={`theme_${index}`}
														title={item.seasonName}
														handlePopup={handlePopup}
														reOrderPeriod={reOrderPeriod}
														theme={item.seasonName.toLowerCase()}
														showArchived={showArchived}
														token={token}
														onUpdate={() => getData(false)}
													/>
												);
											})}
									</ul>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			{openPopup && (
				<RoutineLessonAddEditPopup
					data={data}
					token={token}
					goals={goals}
					popupdata={popupdata}
					hidepopup={closePopup}
					updateData={getData}
					name={popupdata.popupType}
				/>
			)}
		</>
	);
};

const mapStateToProps = state => ({
	topics: state.initialData.topics,
});

export default connect(mapStateToProps)(ManageLessons);

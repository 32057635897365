import React from 'react';
// @import styles
import '../../css/components/embed-canva-youtube.scss';

export default function EmbedCanvaYoutube({ source = '' }) {
	return (
		<div className="embedWrapper">
			<iframe
				className="iframe"
				src={
					source.includes('youtube')
						? source.replace('watch?v=', 'embed/')
						: `${source}?embed`
				}
				title="chapterIntroMedia1"
				allowFullScreen
				frameBorder="0"
			/>
		</div>
	);
}

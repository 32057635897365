import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { TweenMax } from 'gsap/TweenMax';
import { useSelector } from 'react-redux';
// @import constants
import {
	students_endpoint,
	mediaadd_endpoint,
	mediaedit_endpoint,
	mediadelete_endpoint
} from 'js/constants/endpoints';
// @import component
import Student from 'js/components/student';
import ImageEditor from 'js/models/ImageEditor';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
import { captureVideoFrame } from 'js/utils/canvasUtils';
import {
	editVimeoVideo,
	createVideoBody,
	uploadVideoToVimeo
} from 'js/utils/vimeoCall';
// @import styles
import './index.scss';
// @import images
import { ReactComponent as Cross } from 'images/svg/cross.svg';
import { ReactComponent as Camera } from 'images/svg/camera.svg';
import { ReactComponent as Download } from 'images/svg/download.svg';

const MediaPopup = ({
	type,
	groupId,
	videoHash,
	closePopup,
	data = null,
	callback = () => {}
}) => {
	const {
		token,
		userData: { userId }
	} = useSelector(state => state.initialData);

	const [isUploaded, setUploaded] = useState(null);
	const [inputImage, setInputImage] = useState(null);
	const [studentList, setStudentList] = useState(null);
	const [isSubmitable, setSubmitable] = useState(true);
	const [errorMessage, setErrorMessage] = useState(null);
	const [isDeletePopup, setDeletePopup] = useState(false);
	const [videoThumbnail, setVideoThumbnail] = useState(false);
	const [loaderRequested, setLoaderRequested] = useState(false);
	const [croppedImagePath, setCroppedImagePath] = useState(null);

	const [state, setState] = useState({
		mediaId: data ? data.mediaId : '',
		mediaTitle: data ? data.mediaTitle : '',
		mediaDescription: data ? data.mediaDescription : '',
		mediaDate: data ? data.mediaDate : '',
		mediaVisibility: data ? data.mediaVisibility : 'some',
		taggedStudents: data ? data.taggedStudents : [],
		mediaType: data ? data.mediaType : '',
		mediaFile: data ? data.mediaFile : '',
		mediaExternalVideoId: data ? data.mediaExternalVideoId : '',
		mediaHash: videoHash ? videoHash : ''
	});

	const videoRef = useRef(null);
	const checkRef = useRef(null);
	const circleRef = useRef(null);
	const progressRef = useRef(null);
	const addEditPopup = useRef(null);
	const inputFileRef = useRef(null);

	const escFunction = event => {
		if (event.keyCode === 27) {
			console.log('media add edit');
			closePopup();
		}
	};

	useEffect(() => {
		const dataForm = new FormData();
		dataForm.append('groupId', groupId);
		axiosPost(students_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				const { data } = axiosResult;
				setStudentList(data.students);
			}
		});
		document.addEventListener('keydown', escFunction, false);
		return () => {
			document.removeEventListener('keydown', escFunction, false);
		};
	}, []);

	const handleTitleChange = event => {
		setState({
			...state,
			mediaTitle: event.target.value
		});
	};

	const handleDescChange = event => {
		setState({
			...state,
			mediaDescription: event.target.value
		});
	};

	const handlePictureChange = event => {
		if (event.target.files[0].type.match('image/*')) {
			setInputImage(event.target.files[0]);
			togglePopup('hide');
			setState({
				...state,
				mediaType: 'picture'
			});
		} else if (event.target.files[0].type.match('video/*')) {
			setState({
				...state,
				mediaType: 'video',
				mediaFile: event.target.files[0]
			});
			handleVideoPreview(window.URL.createObjectURL(event.target.files[0]));
		}
	};

	const handleVideoPreview = src => {
		videoRef.current.src = src;
		videoRef.current.currentTime = 0.5;
		videoRef.current.addEventListener(
			'progress',
			function (evt) {
				videoRef.current.currentTime = 0.5;
				videoRef.current.pause();
			},
			false
		);
	};

	const croppedImageCallback = file => {
		setState({
			...state,
			mediaFile: URL.createObjectURL(file)
		});
		togglePopup('show');
		setInputImage(null);
		setCroppedImagePath(file);
	};

	const handleMediaVisibility = value => {
		setState({
			...state,
			mediaVisibility: value
		});
	};

	const handleTaggedStudent = value => {
		let tempArray = state.taggedStudents;
		if (state.taggedStudents.includes(value)) {
			tempArray = state.taggedStudents.filter(x => x !== value);
		} else {
			tempArray.push(value);
		}

		setState({
			...state,
			taggedStudents: tempArray
		});
	};

	const togglePopup = value => {
		TweenMax.to(addEditPopup.current, 0.3, {
			autoAlpha: value === 'hide' ? 0 : 1,
			onStart: () => {
				if (value !== 'hide')
					TweenMax.set(addEditPopup.current, { display: 'block' });
			},
			onComplete: () => {
				if (value === 'hide')
					TweenMax.set(addEditPopup.current, { display: 'none' });
			}
		});
	};

	const handleSubmitClick = () => {
		setSubmitable(false);
		if (type === 'add' && state.mediaType === 'video') {
			if (errorMessage && isUploaded) {
				handleSubmit(isUploaded.videoId, isUploaded.videoHash);
			} else {
				const size = state.mediaFile.size;
				const url =
					'https://api.vimeo.com/me/videos?fields=link,uri,name,upload';
				uploadVideoToVimeo(
					url,
					createVideoBody(size, `${userId} - ${groupId}`),
					state.mediaFile,
					progress => {
						if (progress < 10) {
							progressRef.current.classList.remove('loading');
						}
						TweenMax.set('.progressbar', {
							width: `${progress}%`
						});
					},
					(videoId, videoHash) => {
						setUploaded({ videoId, videoHash });
						handleSubmit(videoId, videoHash);
					}
				);
			}
		}

		if (type === 'edit' && state.mediaType === 'video') {
			if (state.mediaFile === '' || (errorMessage && isUploaded)) {
				handleSubmit(state.mediaExternalVideoId, state.mediaHash);
			} else {
				editVimeoVideo(
					state.mediaExternalVideoId,
					state.mediaHash,
					`${userId} - ${groupId} - edit`,
					state.mediaFile,
					videoThumbnail,
					progress => {
						if (progress < 10) {
							progressRef.current.classList.remove('loading');
						}
						TweenMax.set('.progressbar', {
							width: `${progress}%`
						});
					},
					(videoId, videoHash) => {
						setUploaded({ videoId, videoHash });
						handleSubmit(videoId, videoHash);
					}
				);
			}
		}

		if (state.mediaType === 'picture') {
			handleSubmit();
		}
	};

	const handleSubmit = (vimeoVideoId, vimeoVideoHash) => {
		const date = state.mediaDate
			? moment(new Date(state.mediaDate)).format('YYYY-MM-DD')
			: moment(new Date()).format('YYYY-MM-DD');
		setLoaderRequested(true);
		let url = type === 'edit' ? mediaedit_endpoint : mediaadd_endpoint;
		const dataForm = new FormData();
		if (type === 'edit') {
			dataForm.append('mediaId', state.mediaId);
		}
		dataForm.append('mediaTitle', state.mediaTitle);
		dataForm.append('mediaDescription', state.mediaDescription);
		dataForm.append('mediaDate', date);
		dataForm.append('mediaVisibility', state.mediaVisibility);
		if (state.mediaVisibility === 'some') {
			dataForm.append('taggedStudents', JSON.stringify(state.taggedStudents));
		}
		dataForm.append('mediaType', state.mediaType);
		if (state.mediaType === 'picture' && croppedImagePath) {
			dataForm.append('mediaFile', croppedImagePath);
		} else {
			dataForm.append('mediaExternalVideoId', vimeoVideoId);
			if (vimeoVideoHash) {
				dataForm.append('mediaHash', vimeoVideoHash);
			}
		}
		dataForm.append('groupId', groupId);

		axiosPost(url, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				callback();
				checkRef.current.classList.add('checkmark');
				circleRef.current.classList.add('loadComplete');
				setTimeout(() => {
					setSubmitable(true);
					setLoaderRequested(false);
					closePopup();
				}, 1000);
			} else {
				setSubmitable(true);
				setLoaderRequested(false);
				setErrorMessage(axiosResult.failreason);
			}
		});
	};

	const deleteMedia = () => {
		setLoaderRequested(true);
		const dataForm = new FormData();
		dataForm.append('groupId', groupId);
		dataForm.append('mediaId', state.mediaId);
		axiosPost(mediadelete_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				callback();
				checkRef.current.classList.add('checkmark');
				circleRef.current.classList.add('loadComplete');
				setTimeout(() => {
					setLoaderRequested(false);
					closePopup();
				}, 1000);
			} else {
				setLoaderRequested(false);
				setErrorMessage(axiosResult.failreason);
			}
		});
	};

	return (
		<div className="popupWrapper mediaPopupWrapper">
			<div className="mediaPopup" ref={addEditPopup}>
				<button type="button" className="closePopup" onClick={closePopup}>
					<Cross width={18} />
				</button>
				<div className="flexBlock">
					<div className="popup mediaInputPopup">
						<div className="aspectContainer">
							<div className="wrapper">
								<input
									type="file"
									ref={inputFileRef}
									accept="video/*,image/*"
									onChange={handlePictureChange}
									onClick={event => (event.target.value = '')}
								/>
								<video
									ref={videoRef}
									muted
									playsInline
									onLoadedData={() => {
										captureVideoFrame(videoRef.current).then(image => {
											setVideoThumbnail(image);
										});
									}}
								>
									<source type="video/mp4" src={`${state.mediaFile}#t=0.5`} />
								</video>
								{state.mediaExternalVideoId && !state.mediaFile && (
									<iframe
										frameBorder="0"
										allow="autoplay; fullscreen; picture-in-picture"
										src={`https://player.vimeo.com/video/${state.mediaExternalVideoId}?h=${videoHash}&background=1&muted=1&autoplay=0#t=1s`}
									/>
								)}
								{state.mediaFile || state.mediaExternalVideoId ? (
									<>
										<Camera width={30} className="updateIcon" />
										{state.mediaType === 'picture' && (
											<>
												<img src={state.mediaFile} />
											</>
										)}
									</>
								) : (
									<div className="picturePlaceholderWrapper">
										<Download className="downloadIcon" />
										<span>
											<b>Kies een foto of video</b> of sleep hem hierheen
										</span>
									</div>
								)}
							</div>
						</div>
						<div
							ref={progressRef}
							className="progressTrack loading"
							style={{ opacity: isSubmitable ? 0 : 1 }}
						>
							<span className="progressbar" />
						</div>
						<input
							type="text"
							placeholder="Titel"
							className="inputField"
							value={state.mediaTitle}
							onChange={handleTitleChange}
						/>
						<textarea
							className="inputField"
							onChange={handleDescChange}
							value={state.mediaDescription}
							placeholder="Beschrijf de foto / video"
						/>
					</div>
					<div className="popup studentListPopup">
						<div className="switchWrapper flexBlock">
							<button
								type="button"
								onClick={() => handleMediaVisibility('all')}
								className={state.mediaVisibility === 'all' ? 'active' : ''}
							>
								Hele groep
							</button>
							<button
								type="button"
								onClick={() => handleMediaVisibility('some')}
								className={state.mediaVisibility === 'some' ? 'active' : ''}
							>
								Sommige leerlingen
							</button>
						</div>
						{studentList && (
							<div className="studentListContainer flexBlock">
								{studentList.map(student => {
									const isTagged = state.taggedStudents.includes(
										student.studentId
									);
									const isAtive =
										state.mediaVisibility === 'all' ? true : isTagged;
									return (
										<button
											type="button"
											disabled={state.mediaVisibility === 'all'}
											className={`flexBlock studentButton ${
												isAtive ? 'active' : ''
											}`}
											onClick={() => {
												handleTaggedStudent(student.studentId);
											}}
											key={student.studentId}
										>
											<span className="checkBox"></span>
											<Student
												size={40}
												studentId={student.studentId}
												name={student.studentDisplayname}
												imagepath={student.studentPicture}
											/>
										</button>
									);
								})}
							</div>
						)}
					</div>
				</div>

				{errorMessage && <div className="error">* {errorMessage}</div>}

				<div className="flexBlock flexSpaceCenter buttonsWrapper">
					{type === 'edit' ? (
						<div>
							<button
								type="button"
								className="outlineButton inlineBlock"
								onClick={() => {
									togglePopup('hide');
									setDeletePopup(true);
								}}
							>
								Verwijderen
							</button>
						</div>
					) : (
						<div />
					)}
					<div>
						{loaderRequested && (
							<div ref={circleRef} className="circleLoader">
								<div ref={checkRef} className="draw" />
							</div>
						)}
						<button
							type="button"
							className="outlineButton inlineBlock"
							onClick={closePopup}
						>
							Annuleren
						</button>
						<button
							type="button"
							disabled={!isSubmitable || !state.mediaType}
							onClick={handleSubmitClick}
							style={{ marginLeft: '15px' }}
							className="defaultBtn inlineBlock"
						>
							Opslaan
						</button>
					</div>
				</div>
			</div>

			{inputImage && (
				<ImageEditor
					aspectRatio={16 / 9}
					fileName={inputImage.name}
					handleClose={() => {
						togglePopup('show');
						setInputImage(null);
						inputFileRef.current.value = '';
					}}
					callback={croppedImageCallback}
					src={URL.createObjectURL(inputImage)}
				/>
			)}

			{isDeletePopup && (
				<div className="popup">
					<p>Weet je zeker dat je deze foto / video wilt verwijderen?</p>
					{errorMessage && <div className="error">* {errorMessage}</div>}
					<div className="flexBlock buttonsWrapper">
						<div />
						<div>
							{loaderRequested && (
								<div ref={circleRef} className="circleLoader">
									<div ref={checkRef} className="draw" />
								</div>
							)}
							<button
								type="button"
								className="inlineBlock outlineButton "
								onClick={() => {
									togglePopup('show');
									setDeletePopup(false);
								}}
							>
								Annuleren
							</button>

							<button
								type="button"
								className="inlineBlock defaultBtn"
								onClick={deleteMedia}
								style={{ marginLeft: '15px' }}
							>
								Ok
							</button>
						</div>
					</div>
				</div>
			)}
			<span className="overlayBackground" />
		</div>
	);
};

export default MediaPopup;

// @import packages
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TweenMax } from 'gsap/TweenMax';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// @import constants
import {
	periodlist_endpoint,
	periodreorder_endpoint
} from 'js/constants/endpoints';
// @import components
import Loader from 'js/models/loader';
import PeriodAddEditPopup from 'js/components/popup/periodAddEditPopup';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
// @import styles
import './index.scss';
// @import svgs
import { ReactComponent as Add } from 'images/svg/add.svg';
import { ReactComponent as Edit } from 'images/svg/edit.svg';

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

function compareStrings( a, b ) {
  if ( a.last_nom < b.last_nom ){
    return -1;
  }
  if ( a.last_nom > b.last_nom ){
    return 1;
  }
  return 0;
}

const ThemeList = ({
	theme,
	title,
	data,
	handleEdit,
	reOrderPeriod,
	openAddStudentPopup
}) => {
	const [periodData, setPeriodData] = useState(data && data.periods);

	const onDragEnd = result => {
		if (!result.destination) {
			return;
		}

		const items = reorder(
			periodData,
			result.source.index,
			result.destination.index
		);
		const periodIds = items.map(item => item.periodId);
		setPeriodData(items);
		reOrderPeriod(data.seasonId, periodIds);
	};

	useEffect(() => {
		if (data && data.periods) {
			setPeriodData(data.periods);
		}
	}, [data]);

	return (
		<>
			<li className={theme}>
				{title}
				<button className="toggleBtn" onClick={openAddStudentPopup}>
					<Add />
				</button>
			</li>
			{periodData && periodData.length > 0 && (
				<li className="periodContainer active">
					<div className="PeriodWrap">
						{periodData.map((item, index) => {
							return (
								<div className={`${theme} period ${item.attributes.hidden === 1 ? 'archived' : ''}`}>
									<div>
										<span>{item.periodName}</span>
										<small>{item.periodDisplayStartdate}</small>
									</div>

									<button
										className="toggleBtn"
										onClick={() => {
											handleEdit(item);
										}}
									>
										<Edit />
									</button>
								</div>
							);
						})}
					</div>
				</li>
			)}
		</>
	);
};

const ManageThemes = () => {
	const {
		initialData: { token }
	} = useSelector(state => state);

	const [openPopup, setOpenPopup] = useState();
	const [popupname, setPopupname] = useState();
	const [seasonId, setSeasonId] = useState();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(null);
	const [periodData, setPeriodData] = useState(null);

	useEffect(() => {
		getData();
	}, []);

	const getData = (loader = true) => {
		if (loader) {
			setLoading(true);
		}
		axiosPost(periodlist_endpoint, '', token, axiosResult => {
			if (axiosResult.status === 'success') {
				setLoading(false);
				setData(axiosResult.seasonperiods);
			} else {
				setLoading(false);
				console.log('axiosResult', axiosResult);
			}
		});
	};

	const reOrderPeriod = (sId, pIds) => {
		const dataForm = new FormData();
		dataForm.append('periodIds', pIds);
		dataForm.append('seasonId', sId);

		axiosPost(periodreorder_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				getData(false);
			} else {
				setLoading(false);
				console.log('axiosResult', axiosResult);
			}
		});
	};

	const handleEdit = data => {
		setPeriodData(data);
		setPopupname('edit');
		setOpenPopup(true);
	};

	const openAddStudentPopup = id => {
		setPeriodData(null);
		setSeasonId(id);
		setPopupname('add');
		setOpenPopup(true);
	};

	const closePopup = () => {
		TweenMax.to('.popupWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setTimeout(() => {
					setOpenPopup(false);
				}, 200);
			}
		});
	};

	useEffect(() => {
		if (openPopup) {
			TweenMax.to('.popupWrapper', 0.3, {
				autoAlpha: 1
			});
		}
	}, [openPopup]);

	return (
		<>
			<div className="managethemesWrapper">
				<section className="pageContainer">
					<div className={`groupResultsWrapper ${loading ? 'flexCenter' : ''}`}>
						{loading ? (
							<Loader />
						) : (
							<div>
								<h1 className="title">Beheer thema's</h1>
								<p className="pageintro">
									Hier beheer je de thema's die jullie gedurende het schooljaar
									aanbieden.
								</p>
								<ul className="themeList">
									{data &&
										data.map((item, index) => {
											return (
												<ThemeList
													data={item}
													key={`theme_${index}`}
													title={item.seasonName}
													handleEdit={handleEdit}
													seasonId={item.seasonId}
													reOrderPeriod={reOrderPeriod}
													theme={item.seasonName.toLowerCase()}
													openAddStudentPopup={() => {
														openAddStudentPopup(item.seasonId);
													}}
												/>
											);
										})}
								</ul>
							</div>
						)}
					</div>
				</section>
			</div>

			{openPopup && (
				<PeriodAddEditPopup
					token={token}
					name={popupname}
					data={periodData}
					seasonId={seasonId}
					hidepopup={closePopup}
					updateStudents={getData}
				/>
			)}
		</>
	);
};

export default ManageThemes;

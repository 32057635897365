// @import utils
import { axiosPost, axiosGet } from '../js/utils/axiosCall';
import { Cookies } from 'react-cookie';

import {
	SIGN_IN,
	FETCH_USER,
	FETCH_INITIAL_DATA,
	FETCH_PERIOD_DATA,
	FETCH_GROUP_LIST,
	FETCH_STUDENT_LIST,
	START_OBSERVATION,
	STUDENT_STEP,
	LESSON_STEP,
	CHANGE_ROUTEENLESSON,
	SET_TOKEN,
	SET_GROUP_ID,
	SET_STUDENT_LIST,
	SET_STUDENT_STEP_SUCCESS,
	SEND_ERROR_TO_HOC
} from '../actions/types';

import { FETCH_PARENTS_DATA } from './parentTypes';

// @import constants
import {
	api_url,
	signin_endpoint,
	// infoPopup_endpoint,
	perioddashboard_endpoint,
	screeninit_endpoint,
	startobservation_endpoint,
	user_endpoint,
	grouplist_endpoint,
	students_endpoint,
	grouplan_endpoint,
	studentstep_endpoint,
	lessonstatus_endpoint,
	routinelessonstatus_endpoint
} from '../js/constants/endpoints';

export const setToken = token => dispatch => {
	// console.log(token);
	dispatch({
		type: SET_TOKEN,
		payload: token
	});
};

export const setStudentStepSuccess = () => dispatch => {
	// console.log(token);
	dispatch({
		type: SET_STUDENT_STEP_SUCCESS,
		payload: ''
	});
};

export const setGroupId = (groupId, groupName) => dispatch => {
	// console.log(groupId, groupName);
	dispatch({
		type: SET_GROUP_ID,
		groupId: groupId,
		groupName: groupName
	});
};

export const setStudentList = (groupId, groupName, studentList) => dispatch => {
	// console.log('action', groupName, groupName);
	dispatch({
		type: SET_STUDENT_LIST,
		groupId: groupId,
		groupName: groupName,
		studentList: studentList
	});
};

export const logIn = () => dispatch => {
	const dataForm = new FormData();

	// teacher login credentials
	// dataForm.append('username', 'me+teacher1@gertjanvanlaar.nl');
	// // dataForm.append('username', 'me+kleuterwijzerib2@gertjanvanlaar.nl'); // teacher kleuterwijzer
	// // dataForm.append('username', 'me+klw+teacher1@gertjanvanlaar.nl'); // teacher kleuterwijzer
	// dataForm.append('password', 'TestTest1!'); // TestTest1!Careful
	// dataForm.append('role', '3');

	// coordinator login credentials
	// dataForm.append('username', 'me+ib@gertjanvanlaar.nl'); // with all blank data: noumankhan48@yahoo.com'
	// dataForm.append('username', 'me+klw+ib@gertjanvanlaar.nl'); // coordinator kleuterwijzer
	// dataForm.append('password', 'TestTest1!'); // password 'March@2020'
	// dataForm.append('role', '2');

	// coordinator ParnasSys login credentials
	// dataForm.append('username', 'me+66b477n82t477.80uld50r@gertjanvanlaar.nl');
	// dataForm.append('username', 'me+dafschbb0bb0l@gertjanvanlaar.nl');
	// dataForm.append('password', '123456');
	// dataForm.append('role', '2');

	// parent login credentials
	// dataForm.append('username', 'me+parent1@gertjanvanlaar.nl');
	// dataForm.append('password', 'TestTest1!');
	// dataForm.append('role', '4');
	
	dataForm.append('username', process.env.REACT_APP_USERNAME);
	dataForm.append('password', process.env.REACT_APP_PASSWORD);
	dataForm.append('role', process.env.REACT_APP_ROLE);


	axiosPost(signin_endpoint, dataForm, null, axiosResult => {
		dispatch({
			type: SIGN_IN,
			payload: axiosResult
		});
	});
};

const dispatchData = (type, data) => dispatch => {
	dispatch({
		type: type,
		payload: data
	});
};

export const fetchInitialData = token => dispatch => {
	axiosGet(screeninit_endpoint, token, axiosResult => {
		if (axiosResult.status === 'success') {
			if (axiosResult.data.user.userRole === 'parent') {
				dispatch(dispatchData(FETCH_PARENTS_DATA, axiosResult.data));
			}
			dispatch(dispatchData(FETCH_INITIAL_DATA, axiosResult.data));

			const cookies = new Cookies();
			var expDate = new Date();
			expDate.setTime(expDate.getTime() + 60 * 60 * 1000);
			cookies.set('defaulturl', axiosResult.data.defaultUrl, {
				domain: 'onderbouwdonline.nl',
				expires: expDate
			});
		}
	});
};

export const fetchPeriodData =
	(token, periodId, groupId, topicId) => dispatch => {
		// const periodId = 8;
		// const groupId = 1014;
		// const topicId = 2;

		if (periodId && groupId && topicId) {
			const dataForm = new FormData();
			dataForm.append('periodId', periodId);
			dataForm.append('groupId', groupId);
			dataForm.append('topicId', topicId);

			axiosPost(perioddashboard_endpoint, dataForm, token, axiosResult => {
				dispatch({
					type: FETCH_PERIOD_DATA,
					payload: axiosResult.data,
					status: axiosResult.status,
					failreason: axiosResult.failreason ? axiosResult.failreason : null
				});
			});
		} else {
			dispatch({
				type: FETCH_PERIOD_DATA,
				payload: '',
				status: ''
			});
		}
	};

export const startObservation =
	(token, lessonId, groupId, periodId, topicId) => dispatch => {
		// console.log(groupId);
		// const lessonId = 12;
		// const groupId = 1014;

		const dataForm = new FormData();
		dataForm.append('lessonId', lessonId);
		dataForm.append('groupId', groupId);

		axiosPost(startobservation_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				const url = `${api_url}lesson/perioddashboard`;
				const dataForm = new FormData();
				dataForm.append('periodId', periodId);
				dataForm.append('topicId', topicId);
				dataForm.append('groupId', groupId);
				axiosPost(url, dataForm, token, axiosResult => {
					dispatch({
						type: START_OBSERVATION,
						payload: axiosResult.data
					});
				});
			}
		});
	};

export const fetchUser = token => dispatch => {
	axiosGet(user_endpoint, token, axiosResult => {
		dispatch({
			type: FETCH_USER,
			payload: axiosResult
		});
	});
};

export const fetchGroupList = token => dispatch => {
	axiosGet(grouplist_endpoint, token, axiosResult => {
		dispatch({
			type: FETCH_GROUP_LIST,
			payload: axiosResult
		});
	});
};

export const fetchStudentList = (id, groupName, token) => dispatch => {
	const dataForm = new FormData();
	dataForm.append('groupId', id);
	axiosPost(students_endpoint, dataForm, token, axiosResult => {
		dispatch({
			type: FETCH_STUDENT_LIST,
			payload: axiosResult,
			groupName: groupName,
			groupId: id
		});
	});
};

export const changeLessonStep = (lessonId, groupId, token) => dispatch => {
	const dataForm = new FormData();
	dataForm.append('lessonId', lessonId);
	dataForm.append('groupId', groupId);

	axiosPost(grouplan_endpoint, dataForm, token, axiosResult => {
		dispatch({
			type: LESSON_STEP,
			payload: axiosResult.data,
			status: axiosResult.status
		});
	});
};

export const changeStudentStep =
	(
		studentId,
		lessonId,
		newStep,
		teacherComment,
		token,
		groupId,
		calledfrom,
		routinelessonId
	) =>
	dispatch => {
		const dataForm = new FormData();
		dataForm.append('studentId', studentId);
		dataForm.append('lessonId', lessonId);
		dataForm.append('newStep', newStep);
		dataForm.append('teacherComment', teacherComment);
		if (calledfrom) {
			dataForm.append('calledfrom', calledfrom);
		}
		if (routinelessonId) {
			dataForm.append('routinelessonId', routinelessonId);
		}

		axiosPost(studentstep_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				if (calledfrom !== 'action plan') {
					const dataForm = new FormData();
					dataForm.append('lessonId', lessonId);
					dataForm.append('groupId', groupId);

					axiosPost(grouplan_endpoint, dataForm, token, axiosResult => {
						dispatch({
							type: LESSON_STEP,
							payload: axiosResult.data,
							status: axiosResult.status
						});
						dispatch({
							type: STUDENT_STEP,
							payload: 'success'
						});
					});
				} else {
					dispatch({
						type: STUDENT_STEP,
						payload: 'success'
					});
				}
			}
		});
	};

export const changeRouteenLessonStatus =
	(
		periodId,
		routinelessonId,
		groupId,
		status,
		topicId,
		token,
		callback = null
	) =>
	dispatch => {
		const dataForm = new FormData();
		dataForm.append('periodId', periodId);
		dataForm.append('routinelessonId', routinelessonId);
		dataForm.append('groupId', groupId);
		dataForm.append('status', status);

		axiosPost(routinelessonstatus_endpoint, dataForm, token, axiosResult => {
			const dataForm = new FormData();
			dataForm.append('periodId', periodId);
			dataForm.append('groupId', groupId);
			dataForm.append('topicId', topicId);

			if (callback) {
				callback();
			}

			axiosPost(perioddashboard_endpoint, dataForm, token, axiosResult => {
				dispatch({
					type: FETCH_PERIOD_DATA,
					payload: axiosResult.data,
					status: axiosResult.status
				});
				dispatch({
					type: CHANGE_ROUTEENLESSON,
					payload: 'success'
				});
			});
		});
	};

export const changeLessonStatus =
	(periodId, lessonId, groupId, status, topicId, token, callback = null) =>
	dispatch => {
		const dataForm = new FormData();
		dataForm.append('periodId', periodId);
		dataForm.append('lessonId', lessonId);
		dataForm.append('groupId', groupId);
		dataForm.append('status', status);

		axiosPost(lessonstatus_endpoint, dataForm, token, axiosResult => {
			const dataForm = new FormData();
			dataForm.append('periodId', periodId);
			dataForm.append('groupId', groupId);
			dataForm.append('topicId', topicId);

			if (callback) {
				callback();
			}

			axiosPost(perioddashboard_endpoint, dataForm, token, axiosResult => {
				dispatch({
					type: FETCH_PERIOD_DATA,
					payload: axiosResult.data,
					status: axiosResult.status
				});
				dispatch({
					type: CHANGE_ROUTEENLESSON,
					payload: 'success'
				});
			});
		});
	};

export const sendErrorToHOC = error => dispatch => {
	dispatch({
		type: SEND_ERROR_TO_HOC,
		payload: error
	});
};

export function sendErrorToHOClocal(error) {
	return {
		type: SEND_ERROR_TO_HOC,
		payload: error
	};
}

// @import packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { TweenMax } from 'gsap/TweenMax';
import { isMobile } from 'react-device-detect';
// @import constants
import {
	grouplist_endpoint,
	students_endpoint
} from '../../constants/endpoints';
// @import Components
import StudentAddEditPopup from '../../components/popup/studentAddEditPopup';
// @import Styles
import '../../../css/containers/manageStudents.scss';
// @import svgs
import { ReactComponent as Edit } from '../../../images/svg/edit.svg';
import { ReactComponent as Add } from '../../../images/svg/add.svg';
// @import actions
import { sendErrorToHOC } from '../../../actions/actions';
// @import utils
import { axiosPost, axiosGet } from '../../utils/axiosCall';

export class ManageStudent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			groupsList: null,
			errorMessage: null,
			isLoading: true,
			openPopup: false
		};
	}
	componentDidMount() {
		this.getGroupList();
	}

	getGroupList = () => {
		axiosGet(grouplist_endpoint, this.props.token, axiosResult => {
			if (axiosResult.status === 'success') {
				this.setState(
					{
						groupsList: axiosResult.data.groups,
						groupId: axiosResult.data.groups[0].groupId,
						groupSelectable: axiosResult.data.groups[0].groupSelectable
					},
					() => {
						this.getStundetList(this.state.groupsList[0].groupId);
					}
				);
			} else {
				this.setState({
					errorMessage:
						'Om een leerling toe te voegen moet er eerst een groep worden aangemaakt'
				});
			}
		});
	};

	getStundetList = groupId => {
		const currentGroup = this.state.groupsList.find(
			item => item.groupId === groupId
		);
		this.setState({
			groupId,
			groupSelectable: groupId === '-1' ? 0 : currentGroup.groupSelectable
		});

		const dataForm = new FormData();
		dataForm.append('groupId', groupId);
		axiosPost(students_endpoint, dataForm, this.props.token, axiosResult => {
			console.log(axiosResult);
			this.setState({
				students:
					axiosResult.status === 'no such data'
						? 'nodata'
						: axiosResult.data.students,
				isLoading: false
			});
		});
	};

	handleGroupChange = event => {
		// const groupName = event.target.options[event.target.selectedIndex].text;
		const groupId = event.target.value;
		const currentGroup = this.state.groupsList.find(
			item => item.groupId === groupId
		);

		this.setState(
			{
				groupId,
				groupSelectable: groupId === '-1' ? 0 : currentGroup.groupSelectable
			},
			() => {
				this.getStundetList(groupId);
			}
		);
	};

	handleEdit = data => {
		// console.log(data);
		this.setState(
			{
				openPopup: true,
				popupname: 'edit',
				data
			},
			() => {
				TweenMax.to('.popupWrapper', 0.3, {
					autoAlpha: 1
				});
			}
		);
	};

	openAddStudentPopup = () => {
		this.setState(
			{
				openPopup: true,
				popupname: 'add',
				data: null
			},
			() => {
				TweenMax.to('.popupWrapper', 0.3, {
					autoAlpha: 1
				});
			}
		);
	};

	closePopup = () => {
		TweenMax.to('.popupWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setTimeout(() => {
					this.setState({
						openPopup: false
					});
				}, 200);
			}
		});
	};

	render() {
		// console.log(this.state.groupSelectable);
		return (
			<Fragment>
				<div className="pageContainer manageStudents">
					{this.state.groupsList && (
						<select
							value={this.state.groupId}
							onChange={this.handleGroupChange}
						>
							{this.state.groupsList.map((item, i) => (
								<option key={i} value={item.groupId}>
									{item.groupName}
								</option>
							))}
							{this.props.studentMergeable && (
								<option value={-1}>Instroomleerlingen zonder groep</option>
							)}
						</select>
					)}

					<div className="studentListWrapper">
						{this.state.students &&
							this.state.students !== 'nodata' &&
							this.state.students.map((item, i) => (
								<div
									className={`studentBox ${isMobile ? 'showIcon' : ''}`}
									key={i}
								>
									<div className="aspectContainer">
										<img
											src={item.studentPicture.replace(
												/(\.[^.]+)$/,
												'-260x260$1'
											)}
											srcSet={`${item.studentPicture.replace(
												/(\.[^.]+)$/,
												'-520x520$1'
											)} 2x`}
											alt={item.studentDisplayname}
										/>
									</div>
									{item.studentEditable === '1' && (
										<button
											className="editButton"
											onClick={() => this.handleEdit(item)}
										>
											<Edit />
										</button>
									)}
									<strong>{item.studentDisplayname}</strong>
									{item.studentDisplayage && (
										<span>{item.studentDisplayage}</span>
									)}
								</div>
							))}
						{!this.state.isLoading && this.state.groupSelectable === '1' && (
							<div className="studentBox">
								<div className="aspectContainer">
									<button
										className="addStudent"
										onClick={this.openAddStudentPopup}
									>
										<Add />
									</button>
								</div>
							</div>
						)}
					</div>
					{this.state.errorMessage && (
						<p className="noContent">{this.state.errorMessage}</p>
					)}
				</div>
				{this.state.openPopup && (
					<StudentAddEditPopup
						name={this.state.popupname}
						data={this.state.data}
						hidepopup={this.closePopup}
						token={this.props.token}
						groupId={this.state.groupId}
						groupList={this.state.groupsList}
						updateStudents={this.getStundetList}
						userMode={this.props.userData.userMode}
					/>
				)}
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
	userData: state.initialData.userData,
	studentMergeable: state.initialData.group0studentsmergeable
});

export default connect(mapStateToProps, { sendErrorToHOC })(ManageStudent);

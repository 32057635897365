import Home from '../js/containers/Home/index';
import Redirect from '../js/containers/Home/index';
import PageNotFound from '../js/containers/pagenotfound';
import StudentResults from '../js/containers/studentResults';
import StudentSocialProfile from '../js/containers/studentSocialProfile';
import Assessments from '../js/containers/assessments';
import StudentLearningStyle from '../js/containers/studentLearningStyle';
import StudentEditSystem from '../js/containers/studentEditSystem';
import ParentsAddEditSystem from '../js/containers/parentEditSystem';
import Media from '../js/containers/media';
import StudentPrintPage from '../js/containers/studentPrintPage';
import StudentGenerateReport from '../js/containers/studentGenerateReport';
import GroupResults from '../js/containers/groupResults';
import Behandelplan from '../js/containers/behandelplan';
import Notities from '../js/containers/notes';
import AnnualPlan from '../js/containers/annualPlan';
import News from '../js/containers/news';
import Contact from '../js/containers/contact';
import Profile from '../js/containers/profile';
import ChangePassword from '../js/containers/changePassword';
import MyPurchases from '../js/containers/myPurchases';
import Faq from '../js/containers/faq';
import Documents from '../js/containers/documents';

import Academy from '../js/containers/academie/index.js';

import Management from '../js/containers/coordinator/management';
import Resultaten from '../js/containers/coordinator/resultaten';
import Curriculum from '../js/containers/coordinator/curriculum';
import OnderbouwdCurriculum from '../js/containers/coordinator/curriculum/onderbouwd/index';
import GroepsOverzichten from '../js/containers/coordinator/groupResults';
// import CoStudentResults from '../js/containers/coordinator/studentResults';
// import Settings from '../js/containers/coordinator/settings';
import ParentsHome from '../js/containers/parents/home';
import WeekPlanner from 'js/containers/weekPlanner';

const academieRoutes = [
	{
		path: '/academie/intro',
		component: Academy,
		name: 'Academie',
		exact: true,
		isInHeader: true
	},
	{
		path: '/academie/opleiding',
		component: Academy,
		name: 'Academie',
		exact: true
	},
	{
		path: '/academie/opleiding/:coursename',
		component: Academy,
		exact: true
	},
	{
		path: '/academie/opleiding/:coursename/:chapter',
		component: Academy,
		name: 'Academie ',
		exact: true
	}
];

const routes = [
	{
		path: '/',
		component: Home,
		name: 'Lessen',
		exact: true
	},
	{
		path: '/lessen/:season/:period/:topic/',
		component: Home,
		name: 'Lessen',
		exact: true
	},
	{
		path: '/lessen/:season/:period/:topic/:shop',
		component: Home,
		name: 'Lessen'
		// exact: true
	},
	{
		path: '/kalender',
		component: WeekPlanner,
		name: 'Week Planner'
	},
	{
		path: '/nieuws',
		component: News,
		name: 'Nieuws',
		exact: true,
		isInHeader: true
	},
	{
		path: '/veelgestelde-vragen',
		component: Faq,
		name: 'Veelgestelde vragen',
		exact: true,
		isInHeader: true
	},
	{
		path: '/documenten',
		component: Documents,
		name: 'Documenten',
		exact: true,
		isInHeader: true
	},
	{
		path: '/contact',
		component: Contact,
		name: 'Contact',
		exact: true,
		isInHeader: true
	},
	{
		path: '/login',
		component: Redirect,
		exact: true
	},
	{
		path: '/leerling/:groupname/:studentname/resultaten',
		component: StudentResults,
		exact: true
	},
	{
		path: '/leerling/:groupname/:studentname/sociaal-emotioneel',
		component: StudentSocialProfile,
		exact: true
	},
	{
		path: '/leerling/:groupname/:studentname/assessments/:chapterName',
		component: Assessments,
		exact: true
	},
	{
		path: '/leerling/:groupname/:studentname/onderwijsbehoeften',
		component: StudentLearningStyle,
		exact: true
	},
	{
		path: '/:groupname/:studentname/leerlingportfolio',
		component: StudentGenerateReport,
		exact: true
	},
	{
		path: '/groep/:groupname/leerlingen',
		component: StudentEditSystem,
		exact: true
	},
	{
		path: '/groep/:groupname/ouders',
		component: ParentsAddEditSystem,
		exact: true
	},
	{
		path: '/groep/:groupname/leerlingenkaart',
		component: StudentPrintPage,
		exact: true
	},
	{
		path: '/groep/:groupname/media',
		component: Media,
		exact: true
	},
	{
		path: '/groep/:groupname/groepsoverzicht/behandelplan/:treatmenttype',
		component: Behandelplan,
		exact: true,
		routeFor: ['onderbouwdonline', 'kleuterwijzer']
	},
	{
		path: '/groep/:groupname/groepsoverzicht/behandelplan/:treatmenttype/:season/:period',
		component: Behandelplan,
		exact: true,
		routeFor: ['onderbouwdonline', 'kleuterwijzer']
	},
	{
		path: '/groep/:groupname/groepsoverzicht',
		component: GroupResults,
		exact: true,
		routeFor: ['onderbouwdonline', 'kleuterwijzer']
	},
	{
		path: '/groep/:groupname/groepsoverzicht/:season/:period',
		component: GroupResults,
		exact: true,
		routeFor: ['onderbouwdonline', 'kleuterwijzer']
	},
	{
		path: '/groep/:groupname/groepsoverzicht/:season/:period/:topic/notities',
		component: Notities,
		exact: true,
		routeFor: ['onderbouwdonline', 'kleuterwijzer'],
	},
	{
		path: '/groep/:groupname/jaarplanning',
		component: AnnualPlan,
		exact: true
	},
	{
		path: '/404',
		component: PageNotFound,
		exact: true
	},
	{
		path: '/mijn-wachtwoord',
		component: ChangePassword,
		exact: true
	},
	{
		path: '/mijn-aankopen',
		component: MyPurchases,
		exact: true,
		routeFor: 'onderbouwdonline'
	},
	{
		path: '/mijn-gegevens',
		component: Profile,
		exact: true
	}
];

routes.splice(
	2,
	0,
	academieRoutes[0],
	academieRoutes[1],
	academieRoutes[2],
	academieRoutes[3]
);

export const coordinatorRoutes = [
	{
		path: '/',
		component: Management,
		exact: true
	},
	{
		path: '/beheer',
		component: Management,
		name: 'Beheer',
		exact: true,
		isInHeader: true
	},
	{
		path: '/beheer/:managetype',
		component: Management,
		name: 'Beheer',
		exact: true
	},
	/*
	{
		path: '/curriculum',
		component: OnderbouwdCurriculum,
		name: 'Curriculum',
		exact: true,
		isInHeader: true,
		routeFor: 'onderbouwdonline'
	},
	*/
	{
		path: '/curriculum',
		component: Curriculum,
		name: 'Curriculum',
		exact: true,
		isInHeader: true,
		routeFor: ['onderbouwdonline', 'kleuterwijzer', 'wijzneus']
	},
	{
		path: '/curriculum/managethemes',
		component: Curriculum,
		name: 'Curriculum',
		exact: true,
		routeFor: ['onderbouwdonline', 'kleuterwijzer', 'wijzneus']
	},
	{
		path: '/curriculum/managelessons/:topicname',
		component: Curriculum,
		name: 'Curriculum',
		exact: true,
		routeFor: ['onderbouwdonline', 'kleuterwijzer', 'wijzneus']
	},
	{
		path: '/curriculum/manageroutinelessons/:topicname',
		component: Curriculum,
		name: 'Curriculum',
		exact: true,
		routeFor: ['onderbouwdonline', 'kleuterwijzer', 'wijzneus']
	},
	{
		path: '/resultaten',
		component: Resultaten,
		name: 'Resultaten',
		exact: true,
		isInHeader: true,
		routeFor: ['onderbouwdonline', 'kleuterwijzer']
	},
	{
		path: '/resultaten/:topicname',
		component: Resultaten,
		name: 'Resultaten',
		exact: true,
		routeFor: ['onderbouwdonline', 'kleuterwijzer']
	},
	{
		path: '/groepsoverzicht/:groupname/behandelplan/:treatmenttype',
		component: Behandelplan,
		exact: true
	},
	{
		path: '/groepsoverzichten/:groupname/:topicname',
		component: GroepsOverzichten,
		name: 'Groepsoverzichten',
		isInHeader: true,
		exact: true,
		routeFor: ['onderbouwdonline', 'kleuterwijzer']
	},
	// {
	//   path: '/leerlingresultaten',
	//   component: CoStudentResults,
	//   name: 'Leerlingresultaten',
	//   exact: true,
	//   isInHeader: true
	// },
	// {
	//   path: '/instellingen',
	//   component: Settings,
	//   name: 'Instellingen',
	//   exact: true,
	//   isInHeader: true
	// },
	{
		path: '/nieuws',
		component: News,
		name: 'Nieuws',
		exact: true,
		isInHeader: true
	},
	{
		path: '/veelgestelde-vragen',
		component: Faq,
		name: 'Veelgestelde Vragen',
		exact: true,
		isInHeader: true
	},
	{
		path: '/documenten',
		component: Documents,
		name: 'Documenten',
		exact: true,
		isInHeader: true
	},
	{
		path: '/contact',
		component: Contact,
		name: 'Contact',
		exact: true,
		isInHeader: true
	},
	{
		path: '/404',
		component: PageNotFound,
		exact: true
	},
	{
		path: '/mijn-wachtwoord',
		component: ChangePassword,
		exact: true
	},
	{
		path: '/mijn-gegevens',
		component: Profile,
		exact: true
	}
];

coordinatorRoutes.splice(
	6,
	0,
	academieRoutes[0],
	academieRoutes[1],
	academieRoutes[2],
	academieRoutes[3]
);

export const parentsRoutes = [
	{
		path: '/',
		component: ParentsHome,
		exact: true
	},
	{
		path: '/:student/:season/:period/',
		component: ParentsHome,
		exact: true
	},
	{
		path: '/:student/groep/:groupname/media',
		component: Media,
		exact: true
	},
	{
		path: '/nieuws',
		component: News,
		name: 'Nieuws',
		exact: true,
		isInHeader: true
	},
	{
		path: '/contact',
		component: Contact,
		name: 'Contact',
		exact: true,
		isInHeader: true
	},
	{
		path: '/404',
		component: PageNotFound,
		exact: true
	},
	{
		path: '/mijn-wachtwoord',
		component: ChangePassword,
		exact: true
	},
	{
		path: '/mijn-gegevens',
		component: Profile,
		exact: true
	}
];

export default routes;

import {
  FETCH_PARENTS_DATA,
  SET_DEFAULT_STUDENT
} from '../actions/parentTypes.js';

const initialState = {
  userData: null,
  seasonId: null,
  periodId: null,
  defaultUrl: null,
  lessonTree: null,
  childrenlist: null,
  defaultStudent: null
};

export const parentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PARENTS_DATA:
      return {
        ...state,
        userData: action.payload.user,
        defaultUrl: action.payload.defaultUrl,
        lessonTree: action.payload.lessontree,
        childrenlist: action.payload.childrenlist,
        defaultStudent: action.payload.defaultUrl.split('/')[1],
        seasonId: action.payload.defaultlessontreestatus.defaultSeason,
        periodId: action.payload.defaultlessontreestatus.defaultPeriod
      };
    case SET_DEFAULT_STUDENT:
      return {
        ...state,
        defaultStudent: action.payload
      };
    default:
      return {
        ...state
      };
  }
};

// @import packages
import React, { PureComponent } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
// @import constants
import { error_endpoint } from '../constants/endpoints';

class ErrorHandler extends PureComponent {
  constructor() {
    super();
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.HOCerror !== prevProps.HOCerror) {
      const error = this.props.HOCerror;
      this.setState({ hasError: true, error });
      this.postError(error);
    }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, errorInfo: info });
    //  post error back to api
    this.postError(error);
  }

  postError = error => {
    const dataForm = new FormData();
    dataForm.append('message', error);
    axios
      .post(error_endpoint, dataForm, {
        headers: {
          Authtoken: this.props.token
        }
      })
      .then(res => {
        console.log(res.data);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  render() {
    if (this.state.hasError) {
      console.log('Error, ', this.state.error);
      return (
        <div className="mainContainer">
          <div className="pageContainer">
            <p>
              Oeps, er ging iets mis... dat ligt niet aan jou. We hebben al een
              bericht gekregen dat wat jij probeerde te doen mis ging en we gaan
              het zo snel mogelijk verhelpen.
            </p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

const mapStateToProps = state => ({
  token: state.initialData.token,
  HOCerror: state.initialData.HOCerror
});

export default connect(
  mapStateToProps,
  null
)(ErrorHandler);

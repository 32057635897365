import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter, Switch } from 'react-router-dom';
// @import constants
import { app_name } from 'js/constants/endpoints';
export class Authentication extends Component {
	shouldComponentUpdate() {
		return false;
	}
	render() {
		const { routes, cordinator, parents, location } = this.props;
		return (
			<div>
				<Switch location={location}>
					{this.props.userData &&
						this.props.userData.userRole === 'teacher' &&
						routes.map((route, index) => {
							if (route.routeFor !== undefined && !route.routeFor.includes(app_name)) {
								return;
							}
							return (
								<Route
									key={index}
									path={route.path}
									exact={route.exact}
									render={props => {
										return React.createElement(route.component, {
											...props
										});
									}}
								/>
							);
						})}

					{this.props.userData &&
						this.props.userData.userRole === 'coordinator' &&
						cordinator.map((route, index) => {
							if (route.routeFor !== undefined && !route.routeFor.includes(app_name)) {
								return;
							}
							return (
								<Route
									key={index}
									path={route.path}
									exact={route.exact}
									render={props =>
										React.createElement(route.component, {
											...props
										})
									}
								/>
							);
						})}

					{this.props.userData &&
						this.props.userData.userRole === 'parent' &&
						parents.map((route, index) => {
							if (route.routeFor !== undefined && !route.routeFor.includes(app_name)) {
								return;
							}
							return (
								<Route
									key={index}
									path={route.path}
									exact={route.exact}
									render={props =>
										React.createElement(route.component, {
											...props
										})
									}
								/>
							);
						})}
					<Redirect from="*" to="/404" />
				</Switch>
			</div>
		);
	}
}

// export default Authentication;
const mapStateToProps = state => ({
	userData: state.initialData.userData
});

export default withRouter(connect(mapStateToProps, null)(Authentication));

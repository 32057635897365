// @import packages
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TweenMax } from 'gsap/TweenMax';
import { isMobile } from 'react-device-detect';
// @import constants
import { parentslist_endpoint } from '../constants/endpoints';
// @import actions
import { setGroupId, setStudentList } from '../../actions/actions';
// @import Components
import ParentsPopup from '../components/popup/parentsAddEditPopup';
// @import svgs
import { ReactComponent as Edit } from '../../images/svg/edit.svg';
import { ReactComponent as Add } from '../../images/svg/add.svg';
// @import utils
import { axiosPost } from '../utils/axiosCall';

class ParentEditSystem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			students: null,
			openPopup: false,
			data: null
		};
	}

	componentDidMount() {
		setTimeout(() => {
			this.getGroupId();
		}, 100);
	}

	getGroupId = () => {
		const currentGroup = this.props.groupList.groups.find(
			item =>
				item.groupName.replace(/ /g, '_').replace(/\//g, '_').toLowerCase() ===
				this.props.match.params.groupname
		);
		this.props.setGroupId(currentGroup.groupId, currentGroup.groupName);
		this.props.setStudentList(
			currentGroup.groupId,
			currentGroup.groupName,
			currentGroup.students
		);
		this.getParents(currentGroup.groupId);
	};

	getParents = groupId => {
		this.setState({
			isLoading: true
		});

		const dataForm = new FormData();
		dataForm.append('groupId', groupId);

		axiosPost(parentslist_endpoint, dataForm, this.props.token, axiosResult => {
			this.setState({
				isLoading: false,
				parents:
					axiosResult.status === 'no such data'
						? 'nodata'
						: axiosResult.data.parents
			});
		});
	};

	handleEdit = data => {
		this.setState(
			{
				openPopup: true,
				popupname: 'edit',
				data
			},
			() => {
				TweenMax.to('.popupWrapper', 0.3, {
					autoAlpha: 1
				});
			}
		);
	};

	openAddParentsPopup = () => {
		this.setState(
			{
				openPopup: true,
				popupname: 'add',
				data: null
			},
			() => {
				TweenMax.to('.popupWrapper', 0.3, {
					autoAlpha: 1
				});
			}
		);
	};

	closePopup = () => {
		TweenMax.to('.popupWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setTimeout(() => {
					this.setState({
						openPopup: false
					});
				}, 200);
			}
		});
	};

	render() {
		return (
			<div className="mainContainer">
				<div className="pageContainer">
					<h1 className="headingStyle">{this.props.groupName} - Ouders</h1>
					{/* {this.state.parents === 'nodata' && (
            <p className="noContent">Dit onderdeel is nog niet in gebruik</p>
          )} */}
					<div className="studentListWrapper">
						{this.state.parents &&
							this.state.parents !== 'nodata' &&
							this.state.parents.map((item, i) => (
								<div
									className={`studentBox ${isMobile ? 'showIcon' : ''}`}
									key={item.userId}
								>
									<div className="aspectContainer">
										<img
											src={item.userPicture.replace(/(\.[^.]+)$/, '-260x260$1')}
											srcSet={`${item.userPicture.replace(
												/(\.[^.]+)$/,
												'-520x520$1'
											)} 2x`}
											alt={item.userDisplayname}
										/>
									</div>

									<button
										className="editButton"
										onClick={() => this.handleEdit(item)}
									>
										<Edit />
									</button>

									<strong>{item.userDisplayname}</strong>
									{item.kidsDisplaynames && (
										<span>{item.kidsDisplaynames}</span>
									)}
								</div>
							))}
						<div className="studentBox">
							<div className="aspectContainer">
								<button
									className="addStudent"
									onClick={this.openAddParentsPopup}
								>
									<Add />
								</button>
							</div>
						</div>
					</div>
				</div>
				{this.state.openPopup && (
					<ParentsPopup
						data={this.state.data}
						token={this.props.token}
						name={this.state.popupname}
						hidepopup={this.closePopup}
						groupId={this.props.groupId}
						groupName={this.props.groupName}
						updateCallback={this.getParents}
						groupList={this.props.groupList.groups}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
	studentList: state.initialData.studentList,
	groupList: state.initialData.groupList,
	groupId: state.initialData.groupId,
	groupName: state.initialData.groupName
});

export default connect(mapStateToProps, {
	setGroupId,
	setStudentList
})(ParentEditSystem);

import React, { useState } from 'react';
import { TweenMax } from 'gsap/TweenMax';
import { connect } from 'react-redux';
// @import components
import ParentLessonPopup from '../components/popup/parentLessonPopup';
// @import utils
import { axiosPost } from '../utils/axiosCall';
import { app_name, parentslessonstatus_endpoint } from '../constants/endpoints';
// @import svgs
import { ReactComponent as Tick } from '../../images/svg/tick.svg';
import { ReactComponent as Eye } from '../../images/svg/eye.svg';
import { ReactComponent as CloseEye } from '../../images/svg/close_eye.svg';

const LessonSection = ({
	item,
	lessonStatus,
	token,
	groupId,
	periodId,
	topicId,
	activeLessonId,
	isLessonGroupPlan,
	showVideoPopup,
	showIframePopup,
	handleObservertion,
	handleLessonSwitch,
	handleLesson,
	...props
}) => {
	const [popup, setPopup] = useState(false);

	const [parentButtonStatus, SetParentButtonStatus] = useState(
		item.parents.parentlessonVisible
	);

	const handleParentLessonPopup = (bool = true) => {
		if (bool) {
			showPopup();
		} else {
			hidePopup();
		}
	};

	const showPopup = () => {
		setPopup(true);
		setTimeout(() => {
			TweenMax.to('.parentLessonPopup', 0.3, {
				autoAlpha: 1
			});
		}, 500);
	};

	const hidePopup = () => {
		TweenMax.to('.parentLessonPopup', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setPopup(null);
			}
		});
	};

	const handleParentLessonStatue = () => {
		const dataForm = new FormData();
		dataForm.append('lessonId', item.lessonId);
		dataForm.append('groupId', groupId);
		dataForm.append(
			'newstatus',
			item.parents.parentlessonVisible === 0 ? 1 : 0
		);
		axiosPost(parentslessonstatus_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				SetParentButtonStatus(parentButtonStatus === 0 ? 1 : 0);
			}
		});
	};
	console.log('RENDER LessonSection');

	return (
		<>
			<section className="lessonSection" key={item.lessonId}>
				<img
					src={item.lessonPicture.replace(/(\.[^.]+)$/, '-120x180$1')}
					srcSet={`${item.lessonPicture.replace(
						/(\.[^.]+)$/,
						'-240x360$1'
					)} 2x`}
					alt={item.lessonName}
				/>
				<div className="lessonWrapper">
					<h1 className="headingStyle">{item.lessonName}</h1>
					<p>{item.lessonIntroduction}</p>
					{item.lessonMedia1 && (
						<a
							href={item.lessonMedia1}
							download={item.lessonMediatype1 === 'file'}
							target={
								item.lessonMediatype1 === 'file' ||
								item.lessonMediatype1 === 'link'
									? '_blank'
									: '_self'
							}
							rel="noopener noreferrer"
							className="outlineButton inlineBlock"
							onClick={e => {
								if (item.lessonMediatype1 === 'video') {
									e.preventDefault();
									showVideoPopup(
										'Observatie',
										item.lessonMedia1,
										item.lessonName,
										item.lessonPicture
									);
								} else if (item.lessonMediatype2 === 'iframe') {
									e.preventDefault();
									showIframePopup(item.lessonMedia1);
								}
							}}
						>
							{app_name === 'onderbouwdonline' ? 'Observatie' : 'Open les'}
						</a>
					)}

					{item.lessonMedia2 && (
						<a
							href={item.lessonMedia2}
							download={item.lessonMediatype2 === 'file'}
							target={
								item.lessonMediatype2 === 'file' ||
								item.lessonMediatype2 === 'link'
									? '_blank'
									: '_self'
							}
							rel="noopener noreferrer"
							className="outlineButton inlineBlock"
							onClick={e => {
								if (item.lessonMediatype2 === 'video') {
									e.preventDefault();
									showVideoPopup(
										item.lessonMedia2Title,
										item.lessonMedia2,
										item.lessonName,
										item.lessonPicture
									);
								} else if (item.lessonMediatype2 === 'iframe') {
									e.preventDefault();
									showIframePopup(item.lessonMedia2);
								}
							}}
						>
							{item.lessonMedia2Title}
						</a>
					)}

					{item.lessonMedia3 && (
						<a
							href={item.lessonMedia3}
							download={item.lessonMediatype3 === 'file'}
							target={
								item.lessonMediatype3 === 'file' ||
								item.lessonMediatype3 === 'link'
									? '_blank'
									: '_self'
							}
							onClick={e => {
								if (item.lessonMediatype3 === 'video') {
									e.preventDefault();
									showVideoPopup(
										item.lessonMedia3Title,
										item.lessonMedia3,
										item.lessonName,
										item.lessonPicture
									);
								} else if (item.lessonMediatype3 === 'iframe') {
									e.preventDefault();
									showIframePopup(item.lessonMedia3);
								}
							}}
							rel="noopener noreferrer"
							className="outlineButton inlineBlock"
						>
							{item.lessonMedia3Title}
						</a>
					)}

					{!item.observationstarted ? (
						<button
							type="button"
							onClick={() =>
								handleObservertion(
									token,
									item.lessonId,
									item.lessonName,
									groupId,
									periodId,
									topicId
								)
							}
							className="outlineButton"
						>
							Start observatie
						</button>
					) : (
						<>
							{props.genericSettings.schoolParentsEnabled === '1' &&
								(item.parents['A'].lessonMedia ||
									item.parents['B'].lessonMedia ||
									item.parents['C'].lessonMedia ||
									item.parents['D'].lessonMedia) && (
									<div className="oudersButton">
										<button type="button" onClick={handleParentLessonPopup}>
											Digitaal spel
										</button>
										<button
											className="ouderStatus"
											onClick={handleParentLessonStatue}
										>
											{parentButtonStatus === 0 ? <CloseEye /> : <Eye />}
										</button>
									</div>
								)}

							<button
								type="button"
								onClick={() =>
									handleLessonSwitch(item.lessonId, item.lessonName)
								}
								className={`outlineButton planButton ${
									activeLessonId === item.lessonId && isLessonGroupPlan
										? 'disableButton'
										: ''
								}`}
							>
								Groepsplan
							</button>

							<button
								type="button"
								className={`outlineButton ${
									lessonStatus === 0 ? 'disableButton' : 'doneButton'
								}`}
								onClick={() =>
									handleLesson(item.lessonId, lessonStatus, 'lesson')
								}
							>
								<Tick /> Ingepland
							</button>
						</>
					)}

					<div className="subAnchors">
						{item.lessonVideo1Url && (
							<button
								type="button"
								onClick={() =>
									showVideoPopup(
										item.lessonVideo1Text,
										item.lessonVideo1Url,
										item.lessonName,
										item.lessonPicture
									)
								}
							>
								{item.lessonVideo1Text}
							</button>
						)}

						{item.lessonVideo2Url && (
							<button
								type="button"
								onClick={() =>
									showVideoPopup(
										item.lessonVideo2Text,
										item.lessonVideo2Url,
										item.lessonName,
										item.lessonPicture
									)
								}
							>
								{item.lessonVideo2Text}
							</button>
						)}

						{item.lessonVideo3Url && (
							<button
								type="button"
								onClick={() =>
									showVideoPopup(
										item.lessonVideo3Text,
										item.lessonVideo3Url,
										item.lessonName,
										item.lessonPicture
									)
								}
							>
								{item.lessonVideo3Text}
							</button>
						)}

						{item.lessonWorksheet1 && (
							<a
								href={item.lessonWorksheet1}
								download
								target="_blank"
								rel="noopener noreferrer"
								className=""
							>
								{item.lessonWorksheet1Title}
							</a>
						)}

						{item.lessonWorksheet2 && (
							<a
								href={item.lessonWorksheet2}
								download
								target="_blank"
								rel="noopener noreferrer"
								className=""
							>
								{item.lessonWorksheet2Title}
							</a>
						)}
					</div>
				</div>
			</section>
			{popup && (
				<ParentLessonPopup
					data={item.parents}
					lessonName={item.lessonName}
					lessonPicture={item.lessonPicture}
					showVideoPopup={showVideoPopup}
					parentButtonStatus={parentButtonStatus}
					closePopup={() => handleParentLessonPopup(false)}
				/>
			)}
		</>
	);
};

const mapStateToProps = state => ({
	genericSettings: state.initialData.genericSettings
});

export default connect(mapStateToProps, null)(LessonSection);

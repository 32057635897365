export const ageGroup = step => {
	let age;
	switch (step) {
		case 'A':
			age = '3 - 4 jaar';
			break;
		case 'B':
			age = '4 - 5 jaar';
			break;
		case 'C':
			age = '5 - 6 jaar';
			break;
		case 'D':
			age = '6 - 7 jaar';
			break;
		case 'afgerond':
			age = '';
			break;
		default:
			age = '3 - 4 jaar';
	}
	return age;
};

export const grouptoSlug = groupname => {
	return groupname.replace(/ /g, '_').replace(/\//g, '_').toLowerCase();
};

export const slugtoGroup = groupname => {
	return groupname.replace(/_/g, ' ').replace(/\//g, ' ').toLowerCase();
};

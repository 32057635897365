import React, { Component } from 'react';
import moment from 'moment';
import { TweenMax } from 'gsap/TweenMax';
import { connect } from 'react-redux';
// @import constants
import {
	deleteteacher_endpoint,
	addteacher_endpoint,
	editteacher_endpoint,
	resetteacherpass_endpoint
} from '../../constants/endpoints';
// @import components
import ImageEditor from 'js/models/ImageEditor';
// @import svgs
import { ReactComponent as Cross } from '../../../images/svg/cross.svg';
// @import images
import picture from '../../../images/avatar.png';
// @import actions
import { sendErrorToHOC } from '../../../actions/actions';
// @import utils
import { axiosPost } from '../../utils/axiosCall';

class TeacherAddEditPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			teacherId: this.props.data
				? this.props.data.teacherId && this.props.data.teacherId
				: '',
			teacherEmail: this.props.data
				? this.props.data.teacherEmail && this.props.data.teacherEmail
				: '',
			firstName: this.props.data
				? this.props.data.teacherFirstname && this.props.data.teacherFirstname
				: '',
			middleName: this.props.data
				? this.props.data.teacherMiddlename && this.props.data.teacherMiddlename
				: '',
			lastName: this.props.data
				? this.props.data.teacherLastname && this.props.data.teacherLastname
				: '',
			file: this.props.data
				? this.props.data.teacherPicture && this.props.data.teacherPicture
				: null,
			teacherGroups: this.props.data
				? this.props.data.teacherGroups && this.props.data.teacherGroups
				: null,
			groupList: this.props.data
				? this.props.data.teacherGroups && this.props.data.teacherGroups
				: this.props.groupList,
			teachingInGroups: [],
			notificationSuccess: '',
			isWaiting: false,
			loaderRequested: false
		};

		this.inputFile = null;
		this.escFunction = this.escFunction.bind(this);
	}

	componentWillMount() {
		if (this.props.data && this.props.data.teacherGroups) {
			const selectedGroups = this.props.data.teacherGroups.filter(
				x => x.IsTeachingThisGroup === '1'
			);
			selectedGroups.forEach(element => {
				const array = this.state.teachingInGroups;
				array.push(element.groupId);
				this.setState({
					teachingInGroups: array
				});
			});
		}
	}

	componentDidMount() {
		document.addEventListener('keydown', this.escFunction, false);
	}
	componentWillUnmount() {
		document.removeEventListener('keydown', this.escFunction, false);
	}

	escFunction(event) {
		if (event.keyCode === 27) {
			console.log('teacher add edit');
			this.props.hidepopup();
		}
	}

	handleFirstNameChange = event => {
		const firstName = event.target.value.replace(/\\|\//g, '');
		this.setState({
			firstName
		});
	};

	handleMiddleNameChange = event => {
		const middleName = event.target.value.replace(/\\|\//g, '');
		this.setState({
			middleName
		});
	};

	handleLastNameChange = event => {
		const lastName = event.target.value.replace(/\\|\//g, '');
		this.setState({
			lastName
		});
	};

	handleEmailChange = event => {
		this.setState({
			teacherEmail: event.target.value
		});
	};

	handlePictureChange = event => {
		this.setState({
			imagePath: event.target.files[0]
		});
	};

	croppedImageCallback = file => {
		this.setState({
			imagePath: null,
			croppedImagePath: file,
			file: URL.createObjectURL(file)
		});
	};

	handleCheckBox = (event, groupId) => {
		const items = this.state.groupList;

		const item = this.state.groupList.find(x => x.groupId === groupId);
		if (item.IsTeachingThisGroup === '1') {
			item.IsTeachingThisGroup = '0';
			const array = this.state.teachingInGroups.filter(x => x !== groupId);
			this.setState({
				teachingInGroups: array
			});
		} else {
			item.IsTeachingThisGroup = '1';
			const array = this.state.teachingInGroups;
			array.push(groupId);
			this.setState({
				teachingInGroups: array
			});
		}

		items.forEach((element, index) => {
			if (element.groupId === item.groupId) {
				items[index] = item;
			}
		});

		this.setState({
			groupList: items
		});
	};

	// handleGroupChange = event => {
	//   // const groupName = event.target.options[event.target.selectedIndex].text;
	//   const groupId = event.target.value;
	//   this.setState({
	//     groupId
	//   });
	// };

	openDeletePopup = () => {
		TweenMax.to('.addEditWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				TweenMax.set('.addEditWrapper', { display: 'none' });
				TweenMax.set('.deleteWrapper', { display: 'block', autoAlpha: 0 });
				TweenMax.to('.deleteWrapper', 0.3, { autoAlpha: 1 });
			}
		});
	};

	hideDeletePopup = () => {
		TweenMax.to('.deleteWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				TweenMax.set('.deleteWrapper', { display: 'none' });
				TweenMax.set('.addEditWrapper', { display: 'block', autoAlpha: 0 });
				TweenMax.to('.addEditWrapper', 0.3, { autoAlpha: 1 });
			}
		});
	};

	openResetPassPopup = () => {
		TweenMax.to('.addEditWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				TweenMax.set('.addEditWrapper', { display: 'none' });
				TweenMax.set('.resetPassWrapper', { display: 'block', autoAlpha: 0 });
				TweenMax.to('.resetPassWrapper', 0.3, { autoAlpha: 1 });
			}
		});
	};

	hideReserPassPopup = () => {
		TweenMax.to('.resetPassWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				TweenMax.set('.resetPassWrapper', { display: 'none' });
				TweenMax.set('.addEditWrapper', { display: 'block', autoAlpha: 0 });
				TweenMax.to('.addEditWrapper', 0.3, { autoAlpha: 1 });
			}
		});
	};

	deleteStudent = () => {
		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		const dataForm = new FormData();
		dataForm.append('teacherId', this.state.teacherId);
		axiosPost(
			deleteteacher_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState(
						{
							isWaiting: false
						},
						() => {
							const loader = document.querySelector('.circleLoader');
							const check = loader.querySelector('.draw');

							loader.classList.add('loadComplete');
							check.classList.add('checkmark');
						}
					);
					this.props.updateTeachers();
					setTimeout(() => {
						this.props.hidepopup();
					}, 500);
				} else {
					this.setState({
						isWaiting: false,
						loaderRequested: false,
						errorMessage: axiosResult.failreason
					});
				}
			}
		);
	};

	sendResetPassNotification = () => {
		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		const dataForm = new FormData();
		dataForm.append('teacherId', this.state.teacherId);
		axiosPost(
			resetteacherpass_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState(
						{
							isWaiting: false,
							notificationSuccess:
								'De leerkracht heeft op het bij ons bekende e-mailadres een uitnodiging ontvangen om het wachtwoord opnieuw in te stellen.'
						},
						() => {
							const loader = document
								.querySelector('.resetPassWrapper')
								.querySelector('.circleLoader');
							const check = loader.querySelector('.draw');

							loader.classList.add('loadComplete');
							check.classList.add('checkmark');
						}
					);
					setTimeout(() => {
						this.props.hidepopup();
					}, 1500);
				} else {
					this.setState({
						isWaiting: false,
						loaderRequested: false,
						errorMessage: axiosResult.failreason
					});
				}
			}
		);
	};

	submitrequest = () => {
		let date;
		if (this.state.date) {
			date = this.state.date.replace(/\//g, '-');
			var res = date.split('-', 3);
			date = `${res[1]}-${res[0]}-${res[2]}`;
			date = moment(new Date(date)).format('YYYY-MM-DD');
		}

		let url;
		if (this.props.name === 'add') {
			url = addteacher_endpoint;
		}
		if (this.props.name === 'edit') {
			url = editteacher_endpoint;
		}

		const dataForm = new FormData();
		if (this.props.name === 'edit') {
			dataForm.append('teacherId', this.state.teacherId);
		}
		dataForm.append('teacherFirstname', this.state.firstName);

		dataForm.append('teacherMiddlename', this.state.middleName);

		dataForm.append('teacherLastname', this.state.lastName);
		dataForm.append('teacherEmail', this.state.teacherEmail);
		if (this.state.croppedImagePath) {
			dataForm.append('teacherPicture', this.state.croppedImagePath);
		}
		dataForm.append(
			'teacherGroups',
			JSON.stringify(this.state.teachingInGroups)
		);

		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		axiosPost(url, dataForm, this.props.token, axiosResult => {
			if (axiosResult.status === 'success') {
				this.setState(
					{
						isWaiting: false
					},
					() => {
						const loader = document.querySelector('.circleLoader');
						const check = loader.querySelector('.draw');
						loader.classList.add('loadComplete');
						check.classList.add('checkmark');
					}
				);
				this.props.updateTeachers();
				setTimeout(() => {
					this.props.hidepopup();
				}, 500);
			} else {
				this.setState({
					isWaiting: false,
					loaderRequested: false,
					errorMessage: axiosResult.failreason
				});
			}
		});
	};

	render() {
		// console.log('props', this.props);
		// console.log(this.state.teachingInGroups);
		const userMode = this.props.userMode;

		return (
			<div className="popupWrapper studentPopupWrapper">
				<div className="popup addEditWrapper">
					<button
						type="button"
						className="closePopup"
						onClick={() => {
							this.props.hidepopup();
						}}
					>
						<Cross width={18} />
					</button>

					{this.props.name === 'add' ? (
						<h2 className="headingStyle">
							{userMode && userMode === 'read'
								? 'Instroomleerkracht toevoegen'
								: 'Leerkracht toevoegen'}
						</h2>
					) : (
						<h2 className="headingStyle">Gegevens wijzigen</h2>
					)}

					{userMode && userMode === 'read' && (
						<p className="warning">
							Let op: het aanmaken van een instroomaccount voor een leerkracht
							zorgt ervoor dat eventuele ParnasSys-accounts met dit e-mailadres
							niet meer in Onderbouwd Online binnenkomen. Maak dus alleen een
							instroomaccount aan als het een leerkracht betreft van een
							instroomgroep die niet in ParnasSys bestaat."
						</p>
					)}

					<label>Naam</label>
					<input
						type="text"
						placeholder="Voornaam"
						className="inputField"
						value={this.state.firstName}
						onChange={this.handleFirstNameChange}
						required
						readOnly={
							userMode && userMode === 'read' && this.props.name !== 'add'
						}
					/>
					<input
						type="text"
						placeholder="Tussenvoegsel"
						className="inputField"
						value={this.state.middleName}
						onChange={this.handleMiddleNameChange}
						readOnly={
							userMode && userMode === 'read' && this.props.name !== 'add'
						}
					/>
					<input
						type="text"
						placeholder="Achternaam"
						className="inputField"
						value={this.state.lastName}
						onChange={this.handleLastNameChange}
						required
						readOnly={
							userMode && userMode === 'read' && this.props.name !== 'add'
						}
					/>
					<label>E-mailadres</label>
					<input
						type="text"
						placeholder="E-mailadres"
						className="inputField"
						value={this.state.teacherEmail}
						onChange={this.handleEmailChange}
						required
						readOnly={
							userMode && userMode === 'read' && this.props.name !== 'add'
						}
					/>
					<label>Foto</label>
					<div className="row">
						<input
							type="file"
							accept="image/*"
							ref={elem => (this.inputFile = elem)}
							onChange={this.handlePictureChange}
							onClick={event => (event.target.value = '')}
							disabled={
								userMode && userMode === 'read' && this.props.name !== 'add'
							}
						/>
						{this.state.file ? (
							<img src={this.state.file} alt="" className="profilePicture" />
						) : (
							<img
								src={picture.replace(/(\.[^.]+)$/, '-150x150$1')}
								srcSet={`${picture.replace(/(\.[^.]+)$/, '-300x300$1')} 2x`}
								alt=""
								className="profilePicture"
							/>
						)}
					</div>
					<label>Groep(en)</label>
					<div className="row checkboxWrapper">
						{this.state.groupList &&
							this.state.groupList.map((item, i) => {
								return (
									<div key={i} className="checkBox">
										<input
											type="checkbox"
											value={item.groupId}
											onChange={event =>
												this.handleCheckBox(event, item.groupId)
											}
											checked={item.IsTeachingThisGroup === '1'}
											disabled={item.groupSelectable === '0'}
										/>
										{item.groupName}
									</div>
								);
							})}
					</div>
					{this.state.errorMessage && (
						<div className="error">* {this.state.errorMessage}</div>
					)}
					<div className="row buttonsWrapper">
						{this.props.name === 'edit' ? (
							<div>
								{this.props.data.teacherEditable === '1' && (
									<button
										type="button"
										className="outlineButton inlineBlock"
										onClick={this.openDeletePopup}
									>
										Verwijderen
									</button>
								)}
								<button
									type="button"
									className="outlineButton inlineBlock"
									style={{
										marginLeft:
											this.props.data.teacherEditable === '1' ? '15px' : '0px'
									}}
									onClick={this.openResetPassPopup}
								>
									Reset wachtwoord
								</button>
							</div>
						) : (
							<div />
						)}
						<div>
							{this.state.loaderRequested && (
								<div className="circleLoader">
									<div className="draw" />
								</div>
							)}
							<button
								type="button"
								className="outlineButton inlineBlock"
								onClick={this.props.hidepopup}
							>
								Annuleren
							</button>
							<button
								type="button"
								className="defaultBtn inlineBlock"
								onClick={this.submitrequest}
								style={{ marginLeft: '15px' }}
							>
								Opslaan
							</button>
						</div>
					</div>
				</div>

				<div className="popup deleteWrapper">
					<p>Weet je zeker dat je deze leerkracht wilt verwijderen?</p>

					<div className="row">
						<div />
						<div>
							{this.state.loaderRequested && (
								<div className="circleLoader">
									<div className="draw" />
								</div>
							)}
							<button
								type="button"
								className="inlineBlock outlineButton "
								onClick={this.hideDeletePopup}
							>
								Annuleren
							</button>

							<button
								type="button"
								className="inlineBlock defaultBtn"
								onClick={this.deleteStudent}
								style={{ marginLeft: '15px' }}
							>
								Ok
							</button>
						</div>
					</div>
				</div>

				<div className="popup resetPassWrapper">
					<p>
						Wil je een e-mail laten sturen waarmee de leerkracht het eigen
						wachtwoord opnieuw kan instellen?
					</p>

					<div className="row">
						<div />
						<div>
							{this.state.loaderRequested && (
								<div className="circleLoader">
									<div className="draw" />
								</div>
							)}
							<button
								type="button"
								className="inlineBlock outlineButton "
								onClick={this.hideReserPassPopup}
							>
								Annuleren
							</button>

							<button
								type="button"
								className="inlineBlock defaultBtn"
								onClick={this.sendResetPassNotification}
								style={{ marginLeft: '15px' }}
							>
								Ok
							</button>
						</div>
					</div>
					{this.state.notificationSuccess && (
						<p className="success">{this.state.notificationSuccess}</p>
					)}
				</div>

				{this.state.imagePath && (
					<ImageEditor
						fileName={this.state.imagePath.name}
						src={URL.createObjectURL(this.state.imagePath)}
						handleClose={() => {
							this.setState({
								imagePath: null
							});
							this.inputFile.value = '';
						}}
						callback={this.croppedImageCallback}
					/>
				)}
				<span className="overlayBackground" />
			</div>
		);
	}
}

export default connect(null, {
	sendErrorToHOC
})(TeacherAddEditPopup);

// @import packages
import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
// @import actions
import { sendErrorToHOC } from 'actions/actions';
// @import constants
import {
	groupresults_endpoint,
	groupnotes_endpoint
} from 'js/constants/endpoints';
// @import components
import ConfirmationPopup from 'js/components/popup/confirmationPopup';
// @import styles
import './index.scss';
// @import images/svgs
import { ReactComponent as PrintIcon } from 'images/svg/printer.svg';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
import { grouptoSlug, slugtoGroup } from 'js/utils/groupUtility';
import {
	getCurrentGroup,
	getCurrentPeriod,
	getCurrentTopic
} from 'js/utils/filterArray';

const Notities = props => {
	let { groupname, season, period, topic } = useParams();
	groupname = slugtoGroup(groupname);

	const [content, setContent] = useState('');
	const [isreadOnly, setReadOnly] = useState(true);
	const [textAreaHeight, setTextAreaHeight] = useState('auto');

	const popupref = useRef(null);
	const textareaRef = useRef(null);
	const componentRef = useRef(null);

	useEffect(() => {
		getGroupNotes();
	}, []);

	useEffect(() => {
		setTextAreaHeight(`${textareaRef.current.scrollHeight}px`);
	}, [content]);

	const getGroupNotes = () => {
		const dataForm = new FormData();
		dataForm.append(
			'groupId',
			getCurrentGroup(props.groupList.groups, groupname).groupId
		);
		dataForm.append('topicId', getCurrentTopic(props.topics, topic).topicId);
		dataForm.append(
			'periodId',
			getCurrentPeriod(props.lessonTree, season, period).periodId
		);

		axiosPost(groupresults_endpoint, dataForm, props.token, axiosResult => {
			if (axiosResult.status === 'success') {
				setContent(axiosResult.data.groupnotes);
				setReadOnly(axiosResult.data.groupnotes !== '');
			}
		});
	};

	const setGroupNotes = textcontent => {
		const dataForm = new FormData();
		dataForm.append(
			'groupId',
			getCurrentGroup(props.groupList.groups, groupname).groupId
		);
		dataForm.append('topicId', getCurrentTopic(props.topics, topic).topicId);
		dataForm.append(
			'periodId',
			getCurrentPeriod(props.lessonTree, season, period).periodId
		);
		dataForm.append('content', textcontent);

		axiosPost(groupnotes_endpoint, dataForm, props.token, axiosResult => {
			if (axiosResult.status === 'success') {
				setReadOnly(true);
				getGroupNotes();
				if (popupref.current.getLoader()) {
					popupref.current.setLoader();
					popupref.current.hidePopup();
				}
			}
		});
	};

	const handlePopupSubmit = () => {
		popupref.current.setLoader();
		setContent('');
		setGroupNotes('');
	};

	return (
		<div className="mainContainer">
			<Prompt
				when={!isreadOnly}
				message="Je wijzigingen zijn nog niet opgeslagen. Weet je zeker dat je verder wilt?"
			/>

			<div className="flexBlock">
				<ul className="verticalTab">
					<li className={`${topic}`}>
						<Link
							to={{
								pathname: `/groep/${grouptoSlug(
									groupname
								)}/groepsoverzicht/${season}/${period}`,
								state: {
									topic: props.topics.find(
										x => x.topicName.replace(/ /g,'_').toLowerCase() === topic
									)
								}
							}}
						>
							Resultaten
						</Link>
					</li>
					<li className={`active ${topic}`}>
						<Link
							to={`/groep/${grouptoSlug(
								groupname
							)}/groepsoverzicht/${season}/${period}/${topic}/notities`}
							onClick={e => {
								e.preventDefault();
							}}
						>
							Notities
						</Link>
					</li>
				</ul>

				<div className="reducedWidth">
					<div className="flexBlock flexSpaceCenter">
						<ul className="tabsStyle">
							{props.topics.map((item, i) => (
								<li key={i} className={`${item.topicName.replace(/ /g, '-').toLowerCase()}_tab`}>
									<Link
										to={{
											pathname: `/groep/${grouptoSlug(
												groupname
											)}/groepsoverzicht${
												season && period ? `/${season}/${period}` : ''
											}`,
											state: {
												topic: item
											}
										}}
									>
										{item.topicName}
									</Link>
								</li>
							))}
							<li className="behind_tab">
								<Link
									to={`/groep/${grouptoSlug(
										groupname
									)}/groepsoverzicht/behandelplan/in-ontwikkeling${
										season && period ? `/${season}/${period}` : ''
									}`}
								>
									In ontwikkeling
								</Link>
							</li>
							<li className="ahead_tab">
								<Link
									to={`/groep/${grouptoSlug(
										groupname
									)}/groepsoverzicht/behandelplan/leervoorsprong${
										season && period ? `/${season}/${period}` : ''
									}`}
								>
									Leervoorsprong
								</Link>
							</li>
						</ul>

						{season && period && (
							<Link
								className="groupLink"
								to={`/groep/${grouptoSlug(groupname)}/groepsoverzicht`}
							>
								Volledig Groepsoverzicht
							</Link>
						)}
					</div>

					<div ref={componentRef} className={`tabBorderStyle ${topic}_wrapper`}>
						<div className="pageContainer">
							<div className="groupResultsWrapper">
								<div className="notesTitleWrapper">
									<h1 className="headingStyle titleCase">{`${groupname} - Notities ${topic} - ${period.replace(/_/g, ' ')}`}</h1>
									<div className="buttonsWrapper">
										<button
											type="button"
											onClick={() => {
												if (isreadOnly) {
													setReadOnly(false);
													textareaRef.current.focus();
												} else {
													setGroupNotes(content);
												}
											}}
										>
											{isreadOnly ? 'bewerk' : 'opslaan'}
										</button>
										<button
											type="button"
											disabled={content === ''}
											onClick={() => {
												popupref.current.showPopup();
											}}
										>
											wis
										</button>
									</div>
								</div>

								<ReactToPrint
									trigger={() => (
										<button type="button" className="printButton">
											<PrintIcon />
										</button>
									)}
									pageStyle={
										'@media print{@page{size: landscape; margin: 5mm 7mm 5mm 7mm;} body{margin:0;} .pageContainer{background: white !important;} .printButton{display: none;}}'
									}
									content={() => componentRef.current}
								/>

								<div className="notesContainer">
									<textarea
										ref={textareaRef}
										value={content}
										onChange={e => {
											setTextAreaHeight('auto');
											setContent(e.target.value);
										}}
										readOnly={isreadOnly}
										style={{
											height: textAreaHeight
										}}
										placeholder={'Jouw aantekeningen'}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ConfirmationPopup
				ref={popupref}
				content="Weet je zeker dat je je aantekeningen wilt verwijderen?"
				handleSubmit={() => handlePopupSubmit()}
			/>
		</div>
	);
};

const mapStateToProps = state => ({
	token: state.initialData.token,
	topics: state.initialData.topics,
	groupList: state.initialData.groupList,
	lessonTree: state.initialData.lessonTree
});

export default connect(mapStateToProps, {
	sendErrorToHOC
})(Notities);

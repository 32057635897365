import React, { useState } from 'react';
import moment from 'moment';
import localization from 'moment/locale/nl';
// @import components
import ProductPopup from '../productPopup';
// @import utils
import { currency } from '../../../../utils/currency';
// @import styles
import './index.scss';

const ProductWrapper = ({
	userData,
	shopItemsList,
	downloadProduct,
	schoolDisplayname,
	purchased = false
}) => {
	const [activeIndex, setActiveIndex] = useState();
	const [showProductDetail, setShowProductDetail] = useState(false);
	const [showPopup, setShowPopup] = useState(false);

	return (
		<>
			<div className="pageContainer flexWrapper productWrapper">
				{!showProductDetail ? (
					shopItemsList.map((item, index) => {
						return (
							<div className="itemBox" key={`product-${index}`}>
								<a
									href="#"
									onClick={e => {
										e.preventDefault();
										setActiveIndex(index);
										setShowProductDetail(true);
									}}
								>
									<div className="aspectContainer">
										<img
											src={item.shopitemMainImage.replace(
												/(\.[^.]+)$/,
												'-400x280$1'
											)}
											srcSet={`${item.shopitemMainImage.replace(
												/(\.[^.]+)$/,
												'-800x560$1'
											)} 2x`}
											alt=""
										/>
										{!purchased && (
											<div className="pricebox">
												<span>
													{item.shopitemPriceExclTax == '0.00'
														? 'Gratis'
														: currency.format(item.shopitemPriceExclTax)}
												</span>
												<span>{item.shopitemVendorName}</span>
											</div>
										)}
									</div>
									<strong>{item.shopitemName}</strong>
									<span
										className="description"
										dangerouslySetInnerHTML={{
											__html: item.shopitemShortDescription
										}}
									/>
								</a>
							</div>
						);
					})
				) : (
					<div className="detailWrapper">
						<div className="detailHeader">
							<img
								src={shopItemsList[activeIndex].shopitemMainImage.replace(
									/(\.[^.]+)$/,
									'-282x200$1'
								)}
								// srcSet={`${item.src.replace(/(\.[^.]+)$/, '-564x394$1')} 2x`}
								alt=""
							/>
							<div className="itemIntro">
								<h2 className="headingStyle">
									{shopItemsList[activeIndex].shopitemName}
								</h2>
								<p
									dangerouslySetInnerHTML={{
										__html: shopItemsList[activeIndex].shopitemShortDescription
									}}
								/>
								<p>
									Verkrijgbaar bij:{' '}
									{shopItemsList[activeIndex].shopitemVendorName}
								</p>
								<p>
									{shopItemsList[activeIndex].shopitemPriceExclTax == '0.00' ? (
										'Gratis'
									) : (
										<>
											Prijs:{' '}
											{currency.format(
												purchased
													? shopItemsList[activeIndex].shoppurchasePriceExclTax
													: shopItemsList[activeIndex].shopitemPriceExclTax
											)}{' '}
											(excl.{' '}
											{purchased
												? shopItemsList[activeIndex]
														.shoppurchaseSalestaxpercentage
												: shopItemsList[activeIndex].shopitemSalestaxpercentage}
											% BTW){' '}
										</>
									)}
									{purchased &&
										`aangeschaft op ${moment(
											shopItemsList[activeIndex].shoppurchaseDatetime
										)
											.locale('nl', localization)
											.format('D MMM YYYY')}`}
								</p>
								{!purchased ? (
									<>
										{shopItemsList[activeIndex].shopitemPurchaseStatus ===
											'purchased' &&
										shopItemsList[activeIndex].shopitemType === '3' ? (
											<p>Fysiek product</p>
										) : (
											<button
												type="button"
												className="outlineButton planButton"
												onClick={() => {
													if (
														shopItemsList[activeIndex]
															.shopitemPurchaseStatus === 'purchased' &&
														shopItemsList[activeIndex].shopitemDownload
													) {
														window.open(
															shopItemsList[activeIndex].shopitemDownload
														);
													} else {
														setShowPopup(true);
													}
												}}
											>
												{shopItemsList[activeIndex].shopitemPurchaseStatus ===
													'purchased' &&
												shopItemsList[activeIndex].shopitemDownload
													? 'Opnieuw downloaden'
													: shopItemsList[activeIndex].shopitemPriceExclTax ==
													  '0.00'
													? 'Activeer gratis product'
													: 'Nu kopen'}
											</button>
										)}
									</>
								) : (
									<>
										{shopItemsList[activeIndex].shopitemType === '1' ? (
											<button
												type="button"
												className="outlineButton planButton"
												onClick={() => {
													if (shopItemsList[activeIndex].shopitemDownload) {
														window.open(
															shopItemsList[activeIndex].shopitemDownload
														);
													}
												}}
											>
												Opnieuw downloaden
											</button>
										) : (
											<p>fysiek product</p>
										)}
									</>
								)}
							</div>
							<button
								type="button"
								className="backBtn"
								onClick={() => {
									setShowProductDetail(false);
									setActiveIndex();
								}}
							>
								{'Terug'}
							</button>
						</div>
						<p
							dangerouslySetInnerHTML={{
								__html: shopItemsList[activeIndex].shopitemLongDescription
							}}
						/>
					</div>
				)}
			</div>
			{showPopup && (
				<ProductPopup
					userData={userData}
					data={shopItemsList[activeIndex]}
					schoolDisplayname={schoolDisplayname}
					closePopup={() => {
						setShowPopup(false);
					}}
					onClick={(shipping = null, formData = null) => {
						downloadProduct(
							shopItemsList[activeIndex].shopitemId,
							shipping,
							formData
						);
					}}
				/>
			)}
		</>
	);
};

export default ProductWrapper;

import { combineReducers } from 'redux';
import { initialReducer } from './rootReducers';
import { parentsReducer } from './parentsReducer';
import { academyReducer } from './academyReducer';

export default combineReducers({
	initialData: initialReducer,
	parents: parentsReducer,
	academy: academyReducer
	// auth: authReducer
});

import React, { useState, useEffect } from 'react';
// @import components
import Loader from 'js/models/loader';
// @import images
import { ReactComponent as Cross } from 'images/svg/cross.svg';
// @import styles
import './index.scss';

const ImageVideoPopup = ({
	src,
	type,
	videoId,
	videoHash,
	title = null,
	description = null,
	closePopup = () => {}
}) => {
	const [isLoading, setisLoading] = useState(true);
	const [imageWidth, setImageWidth] = useState(700);

	const escFunction = event => {
		if (event.keyCode === 27) {
			closePopup();
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', escFunction, false);
		return () => {
			document.removeEventListener('keydown', escFunction, false);
		};
	}, []);

	// player params can find here:
	// https://vimeo.zendesk.com/hc/en-us/articles/360001494447-Using-Player-Parameters

	return (
		<div className="popupWrapper mediaPreviewPopup">
			<div className={`popup ${type === 'picture' ? 'autoWidth' : ''}`}>
				<button type="button" className="closePopup" onClick={closePopup}>
					<Cross width={18} />
				</button>
				{title && <h2 className="headingStyle">{title}</h2>}

				{type === 'picture' && (
					<>
						{isLoading && (
							<div className="loaderContainer flexCenter">
								<Loader />
							</div>
						)}
						<img
							alt=""
							src={src}
							onLoad={e => {
								setisLoading(false);
								setImageWidth(e.target.naturalWidth);
							}}
						/>
					</>
				)}
				{type === 'video' && (
					<iframe
						frameBorder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						src={`https://player.vimeo.com/video/${videoId}?h=${videoHash}&autoplay=1`}
					/>
				)}
				{description && imageWidth && (
					<p
						style={{ maxWidth: imageWidth }}
						dangerouslySetInnerHTML={{ __html: description }}
					/>
				)}
			</div>
			<span className="overlayBackground" />
		</div>
	);
};

export default ImageVideoPopup;

export const topicColor = colorNumber => {
	let topicCategory;
	switch (colorNumber) {
		case '0':
			topicCategory = 'generic';
			break;
		case '1':
			topicCategory = 'taal';
			break;
		case '2':
			topicCategory = 'rekenen';
			break;
		case '3':
			topicCategory = 'motoriek';
			break;
		case '4':
			topicCategory = 'grote-motoriek';
			break;
		default:
			topicCategory = 'generic';
	}
	return topicCategory;
};

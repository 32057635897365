import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { TweenLite } from 'gsap/TweenMax';

// @import constants
import { faqlist_endpoint } from '../constants/endpoints';
// @import styles
import '../../css/containers/faq.scss';
import { ReactComponent as UpArrow } from '../../images/svg/up-arrow.svg';
// @import actions
import { sendErrorToHOC } from '../../actions/actions';
// @import utils
import { axiosPost } from '../utils/axiosCall';

export class Faq extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			faqCategories: null
		};
	}

	componentDidMount() {
		this.getFaqs();
	}

	getFaqs = () => {
		this.setState({
			isLoading: true
		});
		axiosPost(faqlist_endpoint, null, this.props.token, axiosResult => {
			if (axiosResult.status === 'success') {
				this.setState({
					isLoading: false,
					faqCategories: axiosResult.data.faqCategories
				});
			}
			if (axiosResult.status === 'no such data') {
				this.setState({
					isLoading: false
				});
			}
		});
	};

	handleReadMore = event => {
		const button = event.currentTarget;
		const collapsableWrapper = button.nextElementSibling;
		if (collapsableWrapper.classList.contains('closed')) {
			button.classList.add('active');
			TweenLite.set(collapsableWrapper, {
				height: 'auto',
				paddingTop: '10px'
			});
			TweenLite.from(collapsableWrapper, 0.2, {
				height: 0,
				paddingTop: 0,
				onComplete: () => {
					collapsableWrapper.classList.remove('closed');
					collapsableWrapper.classList.add('opened');
				}
			});
		} else {
			button.classList.remove('active');
			TweenLite.to(collapsableWrapper, 0.2, {
				height: 0,
				paddingTop: 0,
				onComplete: () => {
					collapsableWrapper.classList.remove('opened');
					collapsableWrapper.classList.add('closed');
				}
			});
		}
	};

	render() {
		// console.log(this.state.newsList);
		return (
			<div className="mainContainer">
				<div className="pageContainer faqScreen">
					<Helmet>
						<link
							href="https://fonts.googleapis.com/css?family=Schoolbell&display=swap"
							rel="stylesheet"
						/>
					</Helmet>

					{this.state.faqCategories && (
						<div className="innerContainer">
							<section className="faqWrapper">
								<h1 className="headingStyle">Veelgestelde vragen</h1>
								{this.state.faqCategories &&
									this.state.faqCategories.map(item => (
										<>
											{item.faqs && item.faqs.length > 0 && (
												<>
													<h2 className="sectionTitle">
														{item.faqcategoryTitle}
													</h2>
													<ul>
														{item.faqs.map(faq => (
															<li key={`faq_${faq.faqId}`} className="faq">
																<button
																	type="button"
																	onClick={this.handleReadMore}
																>
																	<div className="faqQuestion">
																		{faq.faqQuestion}
																	</div>
																	<UpArrow />
																</button>
																<div
																	className="faqAnswer closed"
																	dangerouslySetInnerHTML={{
																		__html: faq.faqAnswer
																	}}
																/>
															</li>
														))}
													</ul>
												</>
											)}
										</>
									))}
							</section>
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token
});

export default connect(mapStateToProps, { sendErrorToHOC })(Faq);

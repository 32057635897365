// @import packages
import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { instanceOf } from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';
// @import configs
import routes, { coordinatorRoutes, parentsRoutes } from 'config/routes';
// @import constants
import { newslist_endpoint } from 'js/constants/endpoints';
// @import components
import Header from 'js/components/header';
import Rightbar from 'js/components/rightbar';
// @import actions
import { fetchInitialData, logIn, setToken } from 'actions/actions';
// @import constants
import { app_name } from 'js/constants/endpoints';
import { themeConfig } from 'themeConfig';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
import { app_url, host_name, public_url } from 'js/utils/getEnv';

export class Layout extends Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);

		const { cookies } = props;

		this.state = {
			Authtoken: cookies.get('Authtoken') || '',
			defaulturl: cookies.get('defaulturl') || '',
			redirect: false
		};
	}

	componentDidMount() {
		const params = queryString.parse(this.props.location.search);
		const token = params.token;

		if (token) {
			var expDate = new Date();
			expDate.setTime(expDate.getTime() + 60 * 60 * 1000);
			this.props.cookies.set('Authtoken', token, {
				domain: 'onderbouwdonline.nl',
				expires: expDate
			});
			this.props.setToken(token);
		} else if (this.state.Authtoken) {
			this.props.setToken(this.state.Authtoken);
			this.props.fetchInitialData(this.props.token);
		} else {
			if (host_name === 'localhost') {
				this.props.logIn();
			} else {
				this.setState({
					redirect: true
				});
			}
		}
		document.addEventListener(
			'visibilitychange',
			this.handleVisibilityChange,
			false
		);
	}

	getNews = () => {
		const dataForm = new FormData();
		axiosPost(newslist_endpoint, dataForm, this.props.token, axiosResult => {
			if (axiosResult.status === 'success') {
				const localNewsId = localStorage.getItem('newsId');
				if (localNewsId) {
					if (localNewsId !== axiosResult.data.news[0].newsId) {
						localStorage.setItem('notify', 'true');
					}
				} else {
					localStorage.setItem('newsId', axiosResult.data.news[0].newsId);
				}
			}
		});
	};

	componentDidUpdate(prevProps) {
		if (prevProps.token !== this.props.token) {
			this.props.fetchInitialData(this.props.token);
			this.getNews(this.props.token);
		}

		if (prevProps.defaultUrl === null && this.props.defaultUrl !== null) {
			this.props.history.replace(this.props.defaultUrl);
		}
	}

	handleVisibilityChange = () => {
		const { cookies } = this.props;
		if (
			!document.hidden &&
			host_name !== 'localhost' &&
			cookies.get('Authtoken')
		) {
			if (cookies.get('Authtoken') !== this.props.token) {
				document.location = app_url + cookies.get('defaulturl');
				console.log('visibility change', app_url + cookies.get('defaulturl'));
			}
		}
	};

	render() {
		if (this.state.redirect && this.props.location.pathname !== '/login') {
			return <Redirect from="*" to="/login" />;
		}

		if (this.props.location.pathname !== '/login') {
			return (
				<Fragment>
					<Helmet
						defaultTitle={themeConfig[app_name].siteTitle}
						titleTemplate={`%s | ${themeConfig[app_name].siteTitle}`}
					>
						<link
							href="https://fonts.googleapis.com/css?family=Roboto:300,400|Schoolbell"
							rel="stylesheet"
						/>

						<link
							rel="icon"
							type="image/png"
							href={themeConfig[app_name].favIcon}
							sizes="16x16"
						/>
					</Helmet>
					<div className="mainWrapper">
						{this.props.userData &&
							this.props.userData.userRole === 'teacher' &&
							this.props.groupList && (
								<Fragment>
									<div className="flexWrapper">
										<div className="contentColumn">
											<Header routes={routes} />
											<div className="contentWrapper">
												{this.props.children}
											</div>
										</div>
										<div className="rightColumn">
											<Rightbar />
										</div>
									</div>
								</Fragment>
							)}

						{this.props.userData &&
							this.props.userData.userRole === 'teacher' &&
							!this.props.groupList && (
								<div className="mainWrapper loginWrapper">
									<img
										src={themeConfig[app_name].logo}
										alt="Onderbouwd"
										className="logo"
									/>
									<p>
										Je bent niet gekoppeld aan een groep, daarom kun je niet
										inloggen in Onderbouwd online
									</p>
								</div>
							)}

						{this.props.userData &&
							this.props.userData.userRole === 'coordinator' && (
								<div className="flexWrapper">
									<div className="contentColumn">
										<Header routes={coordinatorRoutes} />
										<div className="contentWrapper">{this.props.children}</div>
									</div>
									<div className="rightColumn">
										<Rightbar />
									</div>
								</div>
							)}

						{this.props.userData && this.props.userData.userRole === 'parent' && (
							<div className="flexWrapper">
								<div className="contentColumn">
									<Header routes={parentsRoutes} />
									<div className="contentWrapper">{this.props.children}</div>
								</div>
								<div className="rightColumn">
									<Rightbar />
								</div>
							</div>
						)}
					</div>
				</Fragment>
			);
		}
		if (!!this.props.userData) {
				return null;
		}

		return (
			<Fragment>
				<Helmet
					defaultTitle={themeConfig[app_name].siteTitle}
					titleTemplate={`%s | ${themeConfig[app_name].siteTitle}`}
				>
					<link
						href="https://fonts.googleapis.com/css?family=Roboto:300,400|Schoolbell"
						rel="stylesheet"
					/>
					<link
						rel="icon"
						type="image/png"
						href={themeConfig[app_name].favIcon}
						sizes="16x16"
					/>
				</Helmet>

				<div className="mainWrapper loginWrapper">
					<img
						src={themeConfig[app_name].logo}
						alt="Onderbouwd"
						className="logo"
					/>
					<p>
						Ga naar het volgende adres om in te loggen{' '}
						<a href={public_url}>{public_url}</a>
					</p>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
	userData: state.initialData.userData,
	defaultUrl: state.initialData.defaultUrl,
	periodId: state.initialData.periodId,
	topicId: state.initialData.topicId,
	groupList: state.initialData.groupList,
	groupId: state.initialData.groupId
});

export default withRouter(
	withCookies(
		connect(mapStateToProps, {
			fetchInitialData,
			logIn,
			setToken
		})(Layout)
	)
);

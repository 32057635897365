// @import packages
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TweenMax } from 'gsap/TweenMax';
// @import constants
import {
	edituser_endpoint,
	delteuserpicture_endpoint
} from '../constants/endpoints';
// @import components
import ImageEditor from 'js/models/ImageEditor';
// @import actions
import { fetchInitialData, sendErrorToHOC } from '../../actions/actions';
// @import styles
import '../../css/containers/profile.scss';
// @import utils
import { axiosPost } from '../utils/axiosCall';

export class Profile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userEmail: '',
			userFirstname: '',
			userMiddlename: '',
			userLastname: '',
			file: '',
			isWaiting: false,
			loaderRequested: false,
			success: ''
		};
		this.inputFile = null;
	}

	componentDidMount() {
		if (this.props.userData) {
			this.updateState(this.props.userData);
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.userData !== prevProps.userData) {
			this.updateState(this.props.userData);
		}
	}

	updateState = userData => {
		this.setState({
			userEmail: userData.userEmail,
			userFirstname: userData.userFirstname,
			userMiddlename: userData.userMiddlename,
			userLastname: userData.userLastname,
			file: userData.userPicture
		});
	};

	handleFirstNameChange = event => {
		this.setState({
			userFirstname: event.target.value.replace(/\\|\//g, '')
		});
	};

	handleMiddleNameChange = event => {
		this.setState({
			userMiddlename: event.target.value.replace(/\\|\//g, '')
		});
	};

	handleLastNameChange = event => {
		this.setState({
			userLastname: event.target.value.replace(/\\|\//g, '')
		});
	};

	handleEmailChange = event => {
		this.setState({
			userEmail: event.target.value
		});
	};

	handlePictureChange = event => {
		this.setState({
			imagePath: event.target.files[0]
		});
	};

	croppedImageCallback = file => {
		this.setState({
			imagePath: null,
			croppedImagePath: file,
			file: URL.createObjectURL(file)
		});
	};

	responseUpdate = axiosResult => {
		if (axiosResult.status === 'success') {
			this.props.fetchInitialData(this.props.token);
			this.setState(
				{
					isWaiting: false
				},
				() => {
					const loader = document.querySelector('.circleLoader');
					const check = loader.querySelector('.draw');
					loader.classList.add('loadComplete');
					check.classList.add('checkmark');
					TweenMax.to('.profileWrapper', 0.3, {
						autoAlpha: 0,
						onComplete: () => {
							this.setState({
								success: 'Je gegevens zijn opgeslagen.'
							});
						}
					});
				}
			);
		} else {
			this.setState({
				isWaiting: false,
				loaderRequested: false,
				errorMessage: axiosResult.failreason
			});
		}
	};

	deletePicture = () => {
		var result = window.confirm(
			'Weet je zeker dat je je profielfoto wilt verwijderen?'
		);
		if (result) {
			const dataForm = new FormData();
			dataForm.append('userPicture', this.state.imagePath);
			this.setState({
				isWaiting: true,
				loaderRequested: true
			});
			axiosPost(
				delteuserpicture_endpoint,
				dataForm,
				this.props.token,
				axiosResult => {
					this.responseUpdate(axiosResult);
				}
			);
		}
	};

	submitrequest = (userMode, userRole) => {
		const dataForm = new FormData();

		if (userMode === 'read-write' && userRole === 'teacher') {
			dataForm.append('userFirstname', this.state.userFirstname);
			dataForm.append('userMiddlename', this.state.userMiddlename);
			dataForm.append('userLastname', this.state.userLastname);
			dataForm.append('userEmail', this.state.userEmail);
		}

		if (userRole === 'coordinator') {
			dataForm.append('userFirstname', this.state.userFirstname);
			dataForm.append('userMiddlename', this.state.userMiddlename);
			dataForm.append('userLastname', this.state.userLastname);
			dataForm.append('userEmail', this.state.userEmail);
		}

		if (this.state.croppedImagePath) {
			dataForm.append('userPicture', this.state.croppedImagePath);
		}

		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		axiosPost(edituser_endpoint, dataForm, this.props.token, axiosResult => {
			this.responseUpdate(axiosResult);
		});
	};

	render() {
		const { userRole, userMode } = this.props.userData;
		const readOnlyUser = userMode === 'read';
		return (
			<div className="mainContainer">
				<section className="pageContainer">
					<h1 className="headingStyle">Gegevens wijzigen</h1>
					{!this.state.success && (
						<div className="profileWrapper">
							<label>Naam</label>
							<div className="nameRow">
								<input
									type="text"
									placeholder="Voornaam"
									className="inputField"
									value={this.state.userFirstname}
									onChange={this.handleFirstNameChange}
									required
									readOnly={userRole === 'teacher' && userMode === 'read'}
								/>
								<input
									type="text"
									placeholder="Tussenvoegsel"
									className="inputField"
									value={this.state.userMiddlename}
									onChange={this.handleMiddleNameChange}
									readOnly={userRole === 'teacher' && userMode === 'read'}
								/>
								<input
									type="text"
									placeholder="Achternaam"
									className="inputField"
									value={this.state.userLastname}
									onChange={this.handleLastNameChange}
									required
									readOnly={userRole === 'teacher' && userMode === 'read'}
								/>
							</div>
							<label>E-mailadres</label>
							<input
								type="text"
								placeholder="E-mailadres"
								className="inputField"
								value={this.state.userEmail}
								onChange={this.handleEmailChange}
								required
								readOnly={userRole === 'teacher' && userMode === 'read'}
							/>
							<label>Foto</label>
							<div className="row pictureWrapper">
								{!readOnlyUser && (
									<input
										ref={elem => (this.inputFile = elem)}
										type="file"
										accept="image/*"
										onChange={this.handlePictureChange}
										onClick={event => (event.target.value = '')}
									/>
								)}
								{this.state.file && (
									<div className="buttonWrapper">
										<img
											src={this.state.file}
											alt=""
											className={`profilePicture ${
												readOnlyUser ? 'removeMargin' : ''
											}`}
										/>
										{!this.props.userData.userPicture.includes('default') &&
											!readOnlyUser && (
												<button
													type="button"
													className="defaultBtn deleteBtn"
													onClick={this.deletePicture}
												>
													foto verwijderen
												</button>
											)}
									</div>
								)}
							</div>
							{this.state.errorMessage && (
								<div className="error">* {this.state.errorMessage}</div>
							)}
							<div className="row buttonsWrapper">
								<button
									type="button"
									className="outlineButton inlineBlock"
									onClick={() => this.submitrequest(userMode, userRole)}
								>
									Opslaan
								</button>
								{this.state.loaderRequested && (
									<div className="circleLoader">
										<div className="draw" />
									</div>
								)}
							</div>
						</div>
					)}
					{this.state.success && (
						<div className="success">{this.state.success}</div>
					)}
				</section>
				{this.state.imagePath && (
					<ImageEditor
						fileName={this.state.imagePath.name}
						src={URL.createObjectURL(this.state.imagePath)}
						handleClose={() => {
							this.setState({
								imagePath: null
							});
							this.inputFile.value = '';
						}}
						callback={this.croppedImageCallback}
					/>
				)}
			</div>
		);
	}
}

const mapStateToprops = state => ({
	token: state.initialData.token,
	userData: state.initialData.userData
});

export default connect(mapStateToprops, { fetchInitialData, sendErrorToHOC })(
	Profile
);

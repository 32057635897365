// @import packages
import React, { PureComponent } from 'react';
// @import icons
import { ReactComponent as Cross } from '../../../images/svg/cross.svg';
// @import utils
import { axiosPost } from '../../utils/axiosCall';
// @import endpoints
import { academyvalidatevoucher_endpoint } from '../../constants/endpoints';
// @import styles
import '../../../css/components/popup/voucherPopup.scss';

class VoucherPopup extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			voucherCode: '',
			voucherMessage: null,
			voucherValidated: false
		};
		this.escFunction = this.escFunction.bind(this);
	}

	componentDidMount() {
		document.addEventListener('keydown', this.escFunction, false);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.escFunction, false);
	}

	handleVoucherCodeChange = event => {
		const voucherCode = event.target.value;
		this.setState({
			voucherCode,
			voucherValidated: false,
			voucherMessage: null
		});
	};

	validateVoucher = () => {
		const { acourseId, token } = this.props;
		const inputData = new FormData();
		inputData.append('avoucherCode', this.state.voucherCode);
		inputData.append('fkAcourseId', acourseId);
		axiosPost(
			academyvalidatevoucher_endpoint,
			inputData,
			token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState(
						{
							voucherValidated: true,
							voucherMessage: 'Voucher code toegepast'
						},
						() => {
							this.closePopup();
						}
					);
				} else {
					this.setState({
						voucherValidated: false,
						voucherMessage: axiosResult.failreason
					});
				}
			}
		);
	};

	escFunction(event) {
		if (event.keyCode === 27) {
			this.closePopup();
		}
	}

	closePopup = (forceClose = false) => {
		if (forceClose) {
			this.setState({ voucherCode: '', voucherValidated: false });
			this.props.closePopup('', false);
		} else {
			let { voucherCode, voucherValidated } = this.state;
			this.props.closePopup(voucherCode, voucherValidated);
		}
	};

	render() {
		let { voucherCode, voucherMessage, voucherValidated } = this.state;
		return (
			<div className="popupWrapper voucherPopup">
				<div className="popup">
					<button
						type="button"
						className="closePopup"
						onClick={() => this.closePopup(true)}
					>
						<Cross width={18} />
					</button>
					<h2>Vul vouchercode in</h2>
					<div className="popupContent">
						<div className="voucherContent">
							<input
								id="avoucherCode"
								type="text"
								placeholder="Vouchercode"
								value={voucherCode}
								onChange={this.handleVoucherCodeChange}
							/>
							<button
								type="button"
								disabled={!voucherCode}
								className="defaultBtn inlineBlock"
								onClick={this.validateVoucher}
							>
								Opslaan
							</button>
						</div>
						{voucherMessage && (
							<div className={`message ${voucherValidated ? '' : 'error'}`}>
								{voucherMessage}
							</div>
						)}
					</div>
				</div>
				<span className="overlayBackground" />
			</div>
		);
	}
}

export default VoucherPopup;
